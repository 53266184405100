/* 
Template Name: Evea - Responsive App Landing Tailwind CSS Template.
Version: 1.0.0
Author: Zoyothemes
Email: zoyothemes@gmail.com
File: Main Css File
*/

/* Plugins */
@import "icons/materialdesignicons";
