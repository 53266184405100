@charset "UTF-8";
/* 
Template Name: Evea - Responsive App Landing Tailwind CSS Template.
Version: 1.0.0
Author: Zoyothemes
Email: zoyothemes@gmail.com
File: Main Css File
*/
/* Plugins */
/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url("../fonts/materialdesignicons-webfont.eot?v=6.5.95");
  src: url("../fonts/materialdesignicons-webfont.eot?#iefix&v=6.5.95") format("embedded-opentype"), url("../fonts/materialdesignicons-webfont.woff2?v=6.5.95") format("woff2"), url("../fonts/materialdesignicons-webfont.woff?v=6.5.95") format("woff"), url("../fonts/materialdesignicons-webfont.ttf?v=6.5.95") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mdi-ab-testing::before {
  content: "󰇉";
}

.mdi-abacus::before {
  content: "󱛠";
}

.mdi-abjad-arabic::before {
  content: "󱌨";
}

.mdi-abjad-hebrew::before {
  content: "󱌩";
}

.mdi-abugida-devanagari::before {
  content: "󱌪";
}

.mdi-abugida-thai::before {
  content: "󱌫";
}

.mdi-access-point::before {
  content: "󰀃";
}

.mdi-access-point-check::before {
  content: "󱔸";
}

.mdi-access-point-minus::before {
  content: "󱔹";
}

.mdi-access-point-network::before {
  content: "󰀂";
}

.mdi-access-point-network-off::before {
  content: "󰯡";
}

.mdi-access-point-off::before {
  content: "󱔑";
}

.mdi-access-point-plus::before {
  content: "󱔺";
}

.mdi-access-point-remove::before {
  content: "󱔻";
}

.mdi-account::before {
  content: "󰀄";
}

.mdi-account-alert::before {
  content: "󰀅";
}

.mdi-account-alert-outline::before {
  content: "󰭐";
}

.mdi-account-arrow-down::before {
  content: "󱡨";
}

.mdi-account-arrow-down-outline::before {
  content: "󱡩";
}

.mdi-account-arrow-left::before {
  content: "󰭑";
}

.mdi-account-arrow-left-outline::before {
  content: "󰭒";
}

.mdi-account-arrow-right::before {
  content: "󰭓";
}

.mdi-account-arrow-right-outline::before {
  content: "󰭔";
}

.mdi-account-arrow-up::before {
  content: "󱡧";
}

.mdi-account-arrow-up-outline::before {
  content: "󱡪";
}

.mdi-account-box::before {
  content: "󰀆";
}

.mdi-account-box-multiple::before {
  content: "󰤴";
}

.mdi-account-box-multiple-outline::before {
  content: "󱀊";
}

.mdi-account-box-outline::before {
  content: "󰀇";
}

.mdi-account-cancel::before {
  content: "󱋟";
}

.mdi-account-cancel-outline::before {
  content: "󱋠";
}

.mdi-account-cash::before {
  content: "󱂗";
}

.mdi-account-cash-outline::before {
  content: "󱂘";
}

.mdi-account-check::before {
  content: "󰀈";
}

.mdi-account-check-outline::before {
  content: "󰯢";
}

.mdi-account-child::before {
  content: "󰪉";
}

.mdi-account-child-circle::before {
  content: "󰪊";
}

.mdi-account-child-outline::before {
  content: "󱃈";
}

.mdi-account-circle::before {
  content: "󰀉";
}

.mdi-account-circle-outline::before {
  content: "󰭕";
}

.mdi-account-clock::before {
  content: "󰭖";
}

.mdi-account-clock-outline::before {
  content: "󰭗";
}

.mdi-account-cog::before {
  content: "󱍰";
}

.mdi-account-cog-outline::before {
  content: "󱍱";
}

.mdi-account-convert::before {
  content: "󰀊";
}

.mdi-account-convert-outline::before {
  content: "󱌁";
}

.mdi-account-cowboy-hat::before {
  content: "󰺛";
}

.mdi-account-cowboy-hat-outline::before {
  content: "󱟳";
}

.mdi-account-details::before {
  content: "󰘱";
}

.mdi-account-details-outline::before {
  content: "󱍲";
}

.mdi-account-edit::before {
  content: "󰚼";
}

.mdi-account-edit-outline::before {
  content: "󰿻";
}

.mdi-account-eye::before {
  content: "󰐠";
}

.mdi-account-eye-outline::before {
  content: "󱉻";
}

.mdi-account-filter::before {
  content: "󰤶";
}

.mdi-account-filter-outline::before {
  content: "󰾝";
}

.mdi-account-group::before {
  content: "󰡉";
}

.mdi-account-group-outline::before {
  content: "󰭘";
}

.mdi-account-hard-hat::before {
  content: "󰖵";
}

.mdi-account-heart::before {
  content: "󰢙";
}

.mdi-account-heart-outline::before {
  content: "󰯣";
}

.mdi-account-injury::before {
  content: "󱠕";
}

.mdi-account-injury-outline::before {
  content: "󱠖";
}

.mdi-account-key::before {
  content: "󰀋";
}

.mdi-account-key-outline::before {
  content: "󰯤";
}

.mdi-account-lock::before {
  content: "󱅞";
}

.mdi-account-lock-open::before {
  content: "󱥠";
}

.mdi-account-lock-open-outline::before {
  content: "󱥡";
}

.mdi-account-lock-outline::before {
  content: "󱅟";
}

.mdi-account-minus::before {
  content: "󰀍";
}

.mdi-account-minus-outline::before {
  content: "󰫬";
}

.mdi-account-multiple::before {
  content: "󰀎";
}

.mdi-account-multiple-check::before {
  content: "󰣅";
}

.mdi-account-multiple-check-outline::before {
  content: "󱇾";
}

.mdi-account-multiple-minus::before {
  content: "󰗓";
}

.mdi-account-multiple-minus-outline::before {
  content: "󰯥";
}

.mdi-account-multiple-outline::before {
  content: "󰀏";
}

.mdi-account-multiple-plus::before {
  content: "󰀐";
}

.mdi-account-multiple-plus-outline::before {
  content: "󰠀";
}

.mdi-account-multiple-remove::before {
  content: "󱈊";
}

.mdi-account-multiple-remove-outline::before {
  content: "󱈋";
}

.mdi-account-music::before {
  content: "󰠃";
}

.mdi-account-music-outline::before {
  content: "󰳩";
}

.mdi-account-network::before {
  content: "󰀑";
}

.mdi-account-network-outline::before {
  content: "󰯦";
}

.mdi-account-off::before {
  content: "󰀒";
}

.mdi-account-off-outline::before {
  content: "󰯧";
}

.mdi-account-outline::before {
  content: "󰀓";
}

.mdi-account-plus::before {
  content: "󰀔";
}

.mdi-account-plus-outline::before {
  content: "󰠁";
}

.mdi-account-question::before {
  content: "󰭙";
}

.mdi-account-question-outline::before {
  content: "󰭚";
}

.mdi-account-reactivate::before {
  content: "󱔫";
}

.mdi-account-reactivate-outline::before {
  content: "󱔬";
}

.mdi-account-remove::before {
  content: "󰀕";
}

.mdi-account-remove-outline::before {
  content: "󰫭";
}

.mdi-account-search::before {
  content: "󰀖";
}

.mdi-account-search-outline::before {
  content: "󰤵";
}

.mdi-account-settings::before {
  content: "󰘰";
}

.mdi-account-settings-outline::before {
  content: "󱃉";
}

.mdi-account-star::before {
  content: "󰀗";
}

.mdi-account-star-outline::before {
  content: "󰯨";
}

.mdi-account-supervisor::before {
  content: "󰪋";
}

.mdi-account-supervisor-circle::before {
  content: "󰪌";
}

.mdi-account-supervisor-circle-outline::before {
  content: "󱓬";
}

.mdi-account-supervisor-outline::before {
  content: "󱄭";
}

.mdi-account-switch::before {
  content: "󰀙";
}

.mdi-account-switch-outline::before {
  content: "󰓋";
}

.mdi-account-sync::before {
  content: "󱤛";
}

.mdi-account-sync-outline::before {
  content: "󱤜";
}

.mdi-account-tie::before {
  content: "󰳣";
}

.mdi-account-tie-hat::before {
  content: "󱢘";
}

.mdi-account-tie-hat-outline::before {
  content: "󱢙";
}

.mdi-account-tie-outline::before {
  content: "󱃊";
}

.mdi-account-tie-voice::before {
  content: "󱌈";
}

.mdi-account-tie-voice-off::before {
  content: "󱌊";
}

.mdi-account-tie-voice-off-outline::before {
  content: "󱌋";
}

.mdi-account-tie-voice-outline::before {
  content: "󱌉";
}

.mdi-account-voice::before {
  content: "󰗋";
}

.mdi-account-voice-off::before {
  content: "󰻔";
}

.mdi-account-wrench::before {
  content: "󱢚";
}

.mdi-account-wrench-outline::before {
  content: "󱢛";
}

.mdi-adjust::before {
  content: "󰀚";
}

.mdi-advertisements::before {
  content: "󱤪";
}

.mdi-advertisements-off::before {
  content: "󱤫";
}

.mdi-air-conditioner::before {
  content: "󰀛";
}

.mdi-air-filter::before {
  content: "󰵃";
}

.mdi-air-horn::before {
  content: "󰶬";
}

.mdi-air-humidifier::before {
  content: "󱂙";
}

.mdi-air-humidifier-off::before {
  content: "󱑦";
}

.mdi-air-purifier::before {
  content: "󰵄";
}

.mdi-airbag::before {
  content: "󰯩";
}

.mdi-airballoon::before {
  content: "󰀜";
}

.mdi-airballoon-outline::before {
  content: "󱀋";
}

.mdi-airplane::before {
  content: "󰀝";
}

.mdi-airplane-alert::before {
  content: "󱡺";
}

.mdi-airplane-check::before {
  content: "󱡻";
}

.mdi-airplane-clock::before {
  content: "󱡼";
}

.mdi-airplane-cog::before {
  content: "󱡽";
}

.mdi-airplane-edit::before {
  content: "󱡾";
}

.mdi-airplane-landing::before {
  content: "󰗔";
}

.mdi-airplane-marker::before {
  content: "󱡿";
}

.mdi-airplane-minus::before {
  content: "󱢀";
}

.mdi-airplane-off::before {
  content: "󰀞";
}

.mdi-airplane-plus::before {
  content: "󱢁";
}

.mdi-airplane-remove::before {
  content: "󱢂";
}

.mdi-airplane-search::before {
  content: "󱢃";
}

.mdi-airplane-settings::before {
  content: "󱢄";
}

.mdi-airplane-takeoff::before {
  content: "󰗕";
}

.mdi-airport::before {
  content: "󰡋";
}

.mdi-alarm::before {
  content: "󰀠";
}

.mdi-alarm-bell::before {
  content: "󰞎";
}

.mdi-alarm-check::before {
  content: "󰀡";
}

.mdi-alarm-light::before {
  content: "󰞏";
}

.mdi-alarm-light-off::before {
  content: "󱜞";
}

.mdi-alarm-light-off-outline::before {
  content: "󱜟";
}

.mdi-alarm-light-outline::before {
  content: "󰯪";
}

.mdi-alarm-multiple::before {
  content: "󰀢";
}

.mdi-alarm-note::before {
  content: "󰹱";
}

.mdi-alarm-note-off::before {
  content: "󰹲";
}

.mdi-alarm-off::before {
  content: "󰀣";
}

.mdi-alarm-panel::before {
  content: "󱗄";
}

.mdi-alarm-panel-outline::before {
  content: "󱗅";
}

.mdi-alarm-plus::before {
  content: "󰀤";
}

.mdi-alarm-snooze::before {
  content: "󰚎";
}

.mdi-album::before {
  content: "󰀥";
}

.mdi-alert::before {
  content: "󰀦";
}

.mdi-alert-box::before {
  content: "󰀧";
}

.mdi-alert-box-outline::before {
  content: "󰳤";
}

.mdi-alert-circle::before {
  content: "󰀨";
}

.mdi-alert-circle-check::before {
  content: "󱇭";
}

.mdi-alert-circle-check-outline::before {
  content: "󱇮";
}

.mdi-alert-circle-outline::before {
  content: "󰗖";
}

.mdi-alert-decagram::before {
  content: "󰚽";
}

.mdi-alert-decagram-outline::before {
  content: "󰳥";
}

.mdi-alert-minus::before {
  content: "󱒻";
}

.mdi-alert-minus-outline::before {
  content: "󱒾";
}

.mdi-alert-octagon::before {
  content: "󰀩";
}

.mdi-alert-octagon-outline::before {
  content: "󰳦";
}

.mdi-alert-octagram::before {
  content: "󰝧";
}

.mdi-alert-octagram-outline::before {
  content: "󰳧";
}

.mdi-alert-outline::before {
  content: "󰀪";
}

.mdi-alert-plus::before {
  content: "󱒺";
}

.mdi-alert-plus-outline::before {
  content: "󱒽";
}

.mdi-alert-remove::before {
  content: "󱒼";
}

.mdi-alert-remove-outline::before {
  content: "󱒿";
}

.mdi-alert-rhombus::before {
  content: "󱇎";
}

.mdi-alert-rhombus-outline::before {
  content: "󱇏";
}

.mdi-alien::before {
  content: "󰢚";
}

.mdi-alien-outline::before {
  content: "󱃋";
}

.mdi-align-horizontal-center::before {
  content: "󱇃";
}

.mdi-align-horizontal-distribute::before {
  content: "󱥢";
}

.mdi-align-horizontal-left::before {
  content: "󱇂";
}

.mdi-align-horizontal-right::before {
  content: "󱇄";
}

.mdi-align-vertical-bottom::before {
  content: "󱇅";
}

.mdi-align-vertical-center::before {
  content: "󱇆";
}

.mdi-align-vertical-distribute::before {
  content: "󱥣";
}

.mdi-align-vertical-top::before {
  content: "󱇇";
}

.mdi-all-inclusive::before {
  content: "󰚾";
}

.mdi-all-inclusive-box::before {
  content: "󱢍";
}

.mdi-all-inclusive-box-outline::before {
  content: "󱢎";
}

.mdi-allergy::before {
  content: "󱉘";
}

.mdi-alpha::before {
  content: "󰀫";
}

.mdi-alpha-a::before {
  content: "󰫮";
}

.mdi-alpha-a-box::before {
  content: "󰬈";
}

.mdi-alpha-a-box-outline::before {
  content: "󰯫";
}

.mdi-alpha-a-circle::before {
  content: "󰯬";
}

.mdi-alpha-a-circle-outline::before {
  content: "󰯭";
}

.mdi-alpha-b::before {
  content: "󰫯";
}

.mdi-alpha-b-box::before {
  content: "󰬉";
}

.mdi-alpha-b-box-outline::before {
  content: "󰯮";
}

.mdi-alpha-b-circle::before {
  content: "󰯯";
}

.mdi-alpha-b-circle-outline::before {
  content: "󰯰";
}

.mdi-alpha-c::before {
  content: "󰫰";
}

.mdi-alpha-c-box::before {
  content: "󰬊";
}

.mdi-alpha-c-box-outline::before {
  content: "󰯱";
}

.mdi-alpha-c-circle::before {
  content: "󰯲";
}

.mdi-alpha-c-circle-outline::before {
  content: "󰯳";
}

.mdi-alpha-d::before {
  content: "󰫱";
}

.mdi-alpha-d-box::before {
  content: "󰬋";
}

.mdi-alpha-d-box-outline::before {
  content: "󰯴";
}

.mdi-alpha-d-circle::before {
  content: "󰯵";
}

.mdi-alpha-d-circle-outline::before {
  content: "󰯶";
}

.mdi-alpha-e::before {
  content: "󰫲";
}

.mdi-alpha-e-box::before {
  content: "󰬌";
}

.mdi-alpha-e-box-outline::before {
  content: "󰯷";
}

.mdi-alpha-e-circle::before {
  content: "󰯸";
}

.mdi-alpha-e-circle-outline::before {
  content: "󰯹";
}

.mdi-alpha-f::before {
  content: "󰫳";
}

.mdi-alpha-f-box::before {
  content: "󰬍";
}

.mdi-alpha-f-box-outline::before {
  content: "󰯺";
}

.mdi-alpha-f-circle::before {
  content: "󰯻";
}

.mdi-alpha-f-circle-outline::before {
  content: "󰯼";
}

.mdi-alpha-g::before {
  content: "󰫴";
}

.mdi-alpha-g-box::before {
  content: "󰬎";
}

.mdi-alpha-g-box-outline::before {
  content: "󰯽";
}

.mdi-alpha-g-circle::before {
  content: "󰯾";
}

.mdi-alpha-g-circle-outline::before {
  content: "󰯿";
}

.mdi-alpha-h::before {
  content: "󰫵";
}

.mdi-alpha-h-box::before {
  content: "󰬏";
}

.mdi-alpha-h-box-outline::before {
  content: "󰰀";
}

.mdi-alpha-h-circle::before {
  content: "󰰁";
}

.mdi-alpha-h-circle-outline::before {
  content: "󰰂";
}

.mdi-alpha-i::before {
  content: "󰫶";
}

.mdi-alpha-i-box::before {
  content: "󰬐";
}

.mdi-alpha-i-box-outline::before {
  content: "󰰃";
}

.mdi-alpha-i-circle::before {
  content: "󰰄";
}

.mdi-alpha-i-circle-outline::before {
  content: "󰰅";
}

.mdi-alpha-j::before {
  content: "󰫷";
}

.mdi-alpha-j-box::before {
  content: "󰬑";
}

.mdi-alpha-j-box-outline::before {
  content: "󰰆";
}

.mdi-alpha-j-circle::before {
  content: "󰰇";
}

.mdi-alpha-j-circle-outline::before {
  content: "󰰈";
}

.mdi-alpha-k::before {
  content: "󰫸";
}

.mdi-alpha-k-box::before {
  content: "󰬒";
}

.mdi-alpha-k-box-outline::before {
  content: "󰰉";
}

.mdi-alpha-k-circle::before {
  content: "󰰊";
}

.mdi-alpha-k-circle-outline::before {
  content: "󰰋";
}

.mdi-alpha-l::before {
  content: "󰫹";
}

.mdi-alpha-l-box::before {
  content: "󰬓";
}

.mdi-alpha-l-box-outline::before {
  content: "󰰌";
}

.mdi-alpha-l-circle::before {
  content: "󰰍";
}

.mdi-alpha-l-circle-outline::before {
  content: "󰰎";
}

.mdi-alpha-m::before {
  content: "󰫺";
}

.mdi-alpha-m-box::before {
  content: "󰬔";
}

.mdi-alpha-m-box-outline::before {
  content: "󰰏";
}

.mdi-alpha-m-circle::before {
  content: "󰰐";
}

.mdi-alpha-m-circle-outline::before {
  content: "󰰑";
}

.mdi-alpha-n::before {
  content: "󰫻";
}

.mdi-alpha-n-box::before {
  content: "󰬕";
}

.mdi-alpha-n-box-outline::before {
  content: "󰰒";
}

.mdi-alpha-n-circle::before {
  content: "󰰓";
}

.mdi-alpha-n-circle-outline::before {
  content: "󰰔";
}

.mdi-alpha-o::before {
  content: "󰫼";
}

.mdi-alpha-o-box::before {
  content: "󰬖";
}

.mdi-alpha-o-box-outline::before {
  content: "󰰕";
}

.mdi-alpha-o-circle::before {
  content: "󰰖";
}

.mdi-alpha-o-circle-outline::before {
  content: "󰰗";
}

.mdi-alpha-p::before {
  content: "󰫽";
}

.mdi-alpha-p-box::before {
  content: "󰬗";
}

.mdi-alpha-p-box-outline::before {
  content: "󰰘";
}

.mdi-alpha-p-circle::before {
  content: "󰰙";
}

.mdi-alpha-p-circle-outline::before {
  content: "󰰚";
}

.mdi-alpha-q::before {
  content: "󰫾";
}

.mdi-alpha-q-box::before {
  content: "󰬘";
}

.mdi-alpha-q-box-outline::before {
  content: "󰰛";
}

.mdi-alpha-q-circle::before {
  content: "󰰜";
}

.mdi-alpha-q-circle-outline::before {
  content: "󰰝";
}

.mdi-alpha-r::before {
  content: "󰫿";
}

.mdi-alpha-r-box::before {
  content: "󰬙";
}

.mdi-alpha-r-box-outline::before {
  content: "󰰞";
}

.mdi-alpha-r-circle::before {
  content: "󰰟";
}

.mdi-alpha-r-circle-outline::before {
  content: "󰰠";
}

.mdi-alpha-s::before {
  content: "󰬀";
}

.mdi-alpha-s-box::before {
  content: "󰬚";
}

.mdi-alpha-s-box-outline::before {
  content: "󰰡";
}

.mdi-alpha-s-circle::before {
  content: "󰰢";
}

.mdi-alpha-s-circle-outline::before {
  content: "󰰣";
}

.mdi-alpha-t::before {
  content: "󰬁";
}

.mdi-alpha-t-box::before {
  content: "󰬛";
}

.mdi-alpha-t-box-outline::before {
  content: "󰰤";
}

.mdi-alpha-t-circle::before {
  content: "󰰥";
}

.mdi-alpha-t-circle-outline::before {
  content: "󰰦";
}

.mdi-alpha-u::before {
  content: "󰬂";
}

.mdi-alpha-u-box::before {
  content: "󰬜";
}

.mdi-alpha-u-box-outline::before {
  content: "󰰧";
}

.mdi-alpha-u-circle::before {
  content: "󰰨";
}

.mdi-alpha-u-circle-outline::before {
  content: "󰰩";
}

.mdi-alpha-v::before {
  content: "󰬃";
}

.mdi-alpha-v-box::before {
  content: "󰬝";
}

.mdi-alpha-v-box-outline::before {
  content: "󰰪";
}

.mdi-alpha-v-circle::before {
  content: "󰰫";
}

.mdi-alpha-v-circle-outline::before {
  content: "󰰬";
}

.mdi-alpha-w::before {
  content: "󰬄";
}

.mdi-alpha-w-box::before {
  content: "󰬞";
}

.mdi-alpha-w-box-outline::before {
  content: "󰰭";
}

.mdi-alpha-w-circle::before {
  content: "󰰮";
}

.mdi-alpha-w-circle-outline::before {
  content: "󰰯";
}

.mdi-alpha-x::before {
  content: "󰬅";
}

.mdi-alpha-x-box::before {
  content: "󰬟";
}

.mdi-alpha-x-box-outline::before {
  content: "󰰰";
}

.mdi-alpha-x-circle::before {
  content: "󰰱";
}

.mdi-alpha-x-circle-outline::before {
  content: "󰰲";
}

.mdi-alpha-y::before {
  content: "󰬆";
}

.mdi-alpha-y-box::before {
  content: "󰬠";
}

.mdi-alpha-y-box-outline::before {
  content: "󰰳";
}

.mdi-alpha-y-circle::before {
  content: "󰰴";
}

.mdi-alpha-y-circle-outline::before {
  content: "󰰵";
}

.mdi-alpha-z::before {
  content: "󰬇";
}

.mdi-alpha-z-box::before {
  content: "󰬡";
}

.mdi-alpha-z-box-outline::before {
  content: "󰰶";
}

.mdi-alpha-z-circle::before {
  content: "󰰷";
}

.mdi-alpha-z-circle-outline::before {
  content: "󰰸";
}

.mdi-alphabet-aurebesh::before {
  content: "󱌬";
}

.mdi-alphabet-cyrillic::before {
  content: "󱌭";
}

.mdi-alphabet-greek::before {
  content: "󱌮";
}

.mdi-alphabet-latin::before {
  content: "󱌯";
}

.mdi-alphabet-piqad::before {
  content: "󱌰";
}

.mdi-alphabet-tengwar::before {
  content: "󱌷";
}

.mdi-alphabetical::before {
  content: "󰀬";
}

.mdi-alphabetical-off::before {
  content: "󱀌";
}

.mdi-alphabetical-variant::before {
  content: "󱀍";
}

.mdi-alphabetical-variant-off::before {
  content: "󱀎";
}

.mdi-altimeter::before {
  content: "󰗗";
}

.mdi-ambulance::before {
  content: "󰀯";
}

.mdi-ammunition::before {
  content: "󰳨";
}

.mdi-ampersand::before {
  content: "󰪍";
}

.mdi-amplifier::before {
  content: "󰀰";
}

.mdi-amplifier-off::before {
  content: "󱆵";
}

.mdi-anchor::before {
  content: "󰀱";
}

.mdi-android::before {
  content: "󰀲";
}

.mdi-android-messages::before {
  content: "󰵅";
}

.mdi-android-studio::before {
  content: "󰀴";
}

.mdi-angle-acute::before {
  content: "󰤷";
}

.mdi-angle-obtuse::before {
  content: "󰤸";
}

.mdi-angle-right::before {
  content: "󰤹";
}

.mdi-angular::before {
  content: "󰚲";
}

.mdi-angularjs::before {
  content: "󰚿";
}

.mdi-animation::before {
  content: "󰗘";
}

.mdi-animation-outline::before {
  content: "󰪏";
}

.mdi-animation-play::before {
  content: "󰤺";
}

.mdi-animation-play-outline::before {
  content: "󰪐";
}

.mdi-ansible::before {
  content: "󱂚";
}

.mdi-antenna::before {
  content: "󱄙";
}

.mdi-anvil::before {
  content: "󰢛";
}

.mdi-apache-kafka::before {
  content: "󱀏";
}

.mdi-api::before {
  content: "󱂛";
}

.mdi-api-off::before {
  content: "󱉗";
}

.mdi-apple::before {
  content: "󰀵";
}

.mdi-apple-finder::before {
  content: "󰀶";
}

.mdi-apple-icloud::before {
  content: "󰀸";
}

.mdi-apple-ios::before {
  content: "󰀷";
}

.mdi-apple-keyboard-caps::before {
  content: "󰘲";
}

.mdi-apple-keyboard-command::before {
  content: "󰘳";
}

.mdi-apple-keyboard-control::before {
  content: "󰘴";
}

.mdi-apple-keyboard-option::before {
  content: "󰘵";
}

.mdi-apple-keyboard-shift::before {
  content: "󰘶";
}

.mdi-apple-safari::before {
  content: "󰀹";
}

.mdi-application::before {
  content: "󰣆";
}

.mdi-application-array::before {
  content: "󱃵";
}

.mdi-application-array-outline::before {
  content: "󱃶";
}

.mdi-application-braces::before {
  content: "󱃷";
}

.mdi-application-braces-outline::before {
  content: "󱃸";
}

.mdi-application-brackets::before {
  content: "󰲋";
}

.mdi-application-brackets-outline::before {
  content: "󰲌";
}

.mdi-application-cog::before {
  content: "󰙵";
}

.mdi-application-cog-outline::before {
  content: "󱕷";
}

.mdi-application-edit::before {
  content: "󰂮";
}

.mdi-application-edit-outline::before {
  content: "󰘙";
}

.mdi-application-export::before {
  content: "󰶭";
}

.mdi-application-import::before {
  content: "󰶮";
}

.mdi-application-outline::before {
  content: "󰘔";
}

.mdi-application-parentheses::before {
  content: "󱃹";
}

.mdi-application-parentheses-outline::before {
  content: "󱃺";
}

.mdi-application-settings::before {
  content: "󰭠";
}

.mdi-application-settings-outline::before {
  content: "󱕕";
}

.mdi-application-variable::before {
  content: "󱃻";
}

.mdi-application-variable-outline::before {
  content: "󱃼";
}

.mdi-approximately-equal::before {
  content: "󰾞";
}

.mdi-approximately-equal-box::before {
  content: "󰾟";
}

.mdi-apps::before {
  content: "󰀻";
}

.mdi-apps-box::before {
  content: "󰵆";
}

.mdi-arch::before {
  content: "󰣇";
}

.mdi-archive::before {
  content: "󰀼";
}

.mdi-archive-alert::before {
  content: "󱓽";
}

.mdi-archive-alert-outline::before {
  content: "󱓾";
}

.mdi-archive-arrow-down::before {
  content: "󱉙";
}

.mdi-archive-arrow-down-outline::before {
  content: "󱉚";
}

.mdi-archive-arrow-up::before {
  content: "󱉛";
}

.mdi-archive-arrow-up-outline::before {
  content: "󱉜";
}

.mdi-archive-cancel::before {
  content: "󱝋";
}

.mdi-archive-cancel-outline::before {
  content: "󱝌";
}

.mdi-archive-check::before {
  content: "󱝍";
}

.mdi-archive-check-outline::before {
  content: "󱝎";
}

.mdi-archive-clock::before {
  content: "󱝏";
}

.mdi-archive-clock-outline::before {
  content: "󱝐";
}

.mdi-archive-cog::before {
  content: "󱝑";
}

.mdi-archive-cog-outline::before {
  content: "󱝒";
}

.mdi-archive-edit::before {
  content: "󱝓";
}

.mdi-archive-edit-outline::before {
  content: "󱝔";
}

.mdi-archive-eye::before {
  content: "󱝕";
}

.mdi-archive-eye-outline::before {
  content: "󱝖";
}

.mdi-archive-lock::before {
  content: "󱝗";
}

.mdi-archive-lock-open::before {
  content: "󱝘";
}

.mdi-archive-lock-open-outline::before {
  content: "󱝙";
}

.mdi-archive-lock-outline::before {
  content: "󱝚";
}

.mdi-archive-marker::before {
  content: "󱝛";
}

.mdi-archive-marker-outline::before {
  content: "󱝜";
}

.mdi-archive-minus::before {
  content: "󱝝";
}

.mdi-archive-minus-outline::before {
  content: "󱝞";
}

.mdi-archive-music::before {
  content: "󱝟";
}

.mdi-archive-music-outline::before {
  content: "󱝠";
}

.mdi-archive-off::before {
  content: "󱝡";
}

.mdi-archive-off-outline::before {
  content: "󱝢";
}

.mdi-archive-outline::before {
  content: "󱈎";
}

.mdi-archive-plus::before {
  content: "󱝣";
}

.mdi-archive-plus-outline::before {
  content: "󱝤";
}

.mdi-archive-refresh::before {
  content: "󱝥";
}

.mdi-archive-refresh-outline::before {
  content: "󱝦";
}

.mdi-archive-remove::before {
  content: "󱝧";
}

.mdi-archive-remove-outline::before {
  content: "󱝨";
}

.mdi-archive-search::before {
  content: "󱝩";
}

.mdi-archive-search-outline::before {
  content: "󱝪";
}

.mdi-archive-settings::before {
  content: "󱝫";
}

.mdi-archive-settings-outline::before {
  content: "󱝬";
}

.mdi-archive-star::before {
  content: "󱝭";
}

.mdi-archive-star-outline::before {
  content: "󱝮";
}

.mdi-archive-sync::before {
  content: "󱝯";
}

.mdi-archive-sync-outline::before {
  content: "󱝰";
}

.mdi-arm-flex::before {
  content: "󰿗";
}

.mdi-arm-flex-outline::before {
  content: "󰿖";
}

.mdi-arrange-bring-forward::before {
  content: "󰀽";
}

.mdi-arrange-bring-to-front::before {
  content: "󰀾";
}

.mdi-arrange-send-backward::before {
  content: "󰀿";
}

.mdi-arrange-send-to-back::before {
  content: "󰁀";
}

.mdi-arrow-all::before {
  content: "󰁁";
}

.mdi-arrow-bottom-left::before {
  content: "󰁂";
}

.mdi-arrow-bottom-left-bold-box::before {
  content: "󱥤";
}

.mdi-arrow-bottom-left-bold-box-outline::before {
  content: "󱥥";
}

.mdi-arrow-bottom-left-bold-outline::before {
  content: "󰦷";
}

.mdi-arrow-bottom-left-thick::before {
  content: "󰦸";
}

.mdi-arrow-bottom-left-thin::before {
  content: "󱦶";
}

.mdi-arrow-bottom-left-thin-circle-outline::before {
  content: "󱖖";
}

.mdi-arrow-bottom-right::before {
  content: "󰁃";
}

.mdi-arrow-bottom-right-bold-box::before {
  content: "󱥦";
}

.mdi-arrow-bottom-right-bold-box-outline::before {
  content: "󱥧";
}

.mdi-arrow-bottom-right-bold-outline::before {
  content: "󰦹";
}

.mdi-arrow-bottom-right-thick::before {
  content: "󰦺";
}

.mdi-arrow-bottom-right-thin::before {
  content: "󱦷";
}

.mdi-arrow-bottom-right-thin-circle-outline::before {
  content: "󱖕";
}

.mdi-arrow-collapse::before {
  content: "󰘕";
}

.mdi-arrow-collapse-all::before {
  content: "󰁄";
}

.mdi-arrow-collapse-down::before {
  content: "󰞒";
}

.mdi-arrow-collapse-horizontal::before {
  content: "󰡌";
}

.mdi-arrow-collapse-left::before {
  content: "󰞓";
}

.mdi-arrow-collapse-right::before {
  content: "󰞔";
}

.mdi-arrow-collapse-up::before {
  content: "󰞕";
}

.mdi-arrow-collapse-vertical::before {
  content: "󰡍";
}

.mdi-arrow-decision::before {
  content: "󰦻";
}

.mdi-arrow-decision-auto::before {
  content: "󰦼";
}

.mdi-arrow-decision-auto-outline::before {
  content: "󰦽";
}

.mdi-arrow-decision-outline::before {
  content: "󰦾";
}

.mdi-arrow-down::before {
  content: "󰁅";
}

.mdi-arrow-down-bold::before {
  content: "󰜮";
}

.mdi-arrow-down-bold-box::before {
  content: "󰜯";
}

.mdi-arrow-down-bold-box-outline::before {
  content: "󰜰";
}

.mdi-arrow-down-bold-circle::before {
  content: "󰁇";
}

.mdi-arrow-down-bold-circle-outline::before {
  content: "󰁈";
}

.mdi-arrow-down-bold-hexagon-outline::before {
  content: "󰁉";
}

.mdi-arrow-down-bold-outline::before {
  content: "󰦿";
}

.mdi-arrow-down-box::before {
  content: "󰛀";
}

.mdi-arrow-down-circle::before {
  content: "󰳛";
}

.mdi-arrow-down-circle-outline::before {
  content: "󰳜";
}

.mdi-arrow-down-drop-circle::before {
  content: "󰁊";
}

.mdi-arrow-down-drop-circle-outline::before {
  content: "󰁋";
}

.mdi-arrow-down-left::before {
  content: "󱞡";
}

.mdi-arrow-down-left-bold::before {
  content: "󱞢";
}

.mdi-arrow-down-right::before {
  content: "󱞣";
}

.mdi-arrow-down-right-bold::before {
  content: "󱞤";
}

.mdi-arrow-down-thick::before {
  content: "󰁆";
}

.mdi-arrow-down-thin::before {
  content: "󱦳";
}

.mdi-arrow-down-thin-circle-outline::before {
  content: "󱖙";
}

.mdi-arrow-expand::before {
  content: "󰘖";
}

.mdi-arrow-expand-all::before {
  content: "󰁌";
}

.mdi-arrow-expand-down::before {
  content: "󰞖";
}

.mdi-arrow-expand-horizontal::before {
  content: "󰡎";
}

.mdi-arrow-expand-left::before {
  content: "󰞗";
}

.mdi-arrow-expand-right::before {
  content: "󰞘";
}

.mdi-arrow-expand-up::before {
  content: "󰞙";
}

.mdi-arrow-expand-vertical::before {
  content: "󰡏";
}

.mdi-arrow-horizontal-lock::before {
  content: "󱅛";
}

.mdi-arrow-left::before {
  content: "󰁍";
}

.mdi-arrow-left-bold::before {
  content: "󰜱";
}

.mdi-arrow-left-bold-box::before {
  content: "󰜲";
}

.mdi-arrow-left-bold-box-outline::before {
  content: "󰜳";
}

.mdi-arrow-left-bold-circle::before {
  content: "󰁏";
}

.mdi-arrow-left-bold-circle-outline::before {
  content: "󰁐";
}

.mdi-arrow-left-bold-hexagon-outline::before {
  content: "󰁑";
}

.mdi-arrow-left-bold-outline::before {
  content: "󰧀";
}

.mdi-arrow-left-bottom::before {
  content: "󱞥";
}

.mdi-arrow-left-bottom-bold::before {
  content: "󱞦";
}

.mdi-arrow-left-box::before {
  content: "󰛁";
}

.mdi-arrow-left-circle::before {
  content: "󰳝";
}

.mdi-arrow-left-circle-outline::before {
  content: "󰳞";
}

.mdi-arrow-left-drop-circle::before {
  content: "󰁒";
}

.mdi-arrow-left-drop-circle-outline::before {
  content: "󰁓";
}

.mdi-arrow-left-right::before {
  content: "󰹳";
}

.mdi-arrow-left-right-bold::before {
  content: "󰹴";
}

.mdi-arrow-left-right-bold-outline::before {
  content: "󰧁";
}

.mdi-arrow-left-thick::before {
  content: "󰁎";
}

.mdi-arrow-left-thin::before {
  content: "󱦱";
}

.mdi-arrow-left-thin-circle-outline::before {
  content: "󱖚";
}

.mdi-arrow-left-top::before {
  content: "󱞧";
}

.mdi-arrow-left-top-bold::before {
  content: "󱞨";
}

.mdi-arrow-projectile::before {
  content: "󱡀";
}

.mdi-arrow-projectile-multiple::before {
  content: "󱠿";
}

.mdi-arrow-right::before {
  content: "󰁔";
}

.mdi-arrow-right-bold::before {
  content: "󰜴";
}

.mdi-arrow-right-bold-box::before {
  content: "󰜵";
}

.mdi-arrow-right-bold-box-outline::before {
  content: "󰜶";
}

.mdi-arrow-right-bold-circle::before {
  content: "󰁖";
}

.mdi-arrow-right-bold-circle-outline::before {
  content: "󰁗";
}

.mdi-arrow-right-bold-hexagon-outline::before {
  content: "󰁘";
}

.mdi-arrow-right-bold-outline::before {
  content: "󰧂";
}

.mdi-arrow-right-bottom::before {
  content: "󱞩";
}

.mdi-arrow-right-bottom-bold::before {
  content: "󱞪";
}

.mdi-arrow-right-box::before {
  content: "󰛂";
}

.mdi-arrow-right-circle::before {
  content: "󰳟";
}

.mdi-arrow-right-circle-outline::before {
  content: "󰳠";
}

.mdi-arrow-right-drop-circle::before {
  content: "󰁙";
}

.mdi-arrow-right-drop-circle-outline::before {
  content: "󰁚";
}

.mdi-arrow-right-thick::before {
  content: "󰁕";
}

.mdi-arrow-right-thin::before {
  content: "󱦰";
}

.mdi-arrow-right-thin-circle-outline::before {
  content: "󱖘";
}

.mdi-arrow-right-top::before {
  content: "󱞫";
}

.mdi-arrow-right-top-bold::before {
  content: "󱞬";
}

.mdi-arrow-split-horizontal::before {
  content: "󰤻";
}

.mdi-arrow-split-vertical::before {
  content: "󰤼";
}

.mdi-arrow-top-left::before {
  content: "󰁛";
}

.mdi-arrow-top-left-bold-box::before {
  content: "󱥨";
}

.mdi-arrow-top-left-bold-box-outline::before {
  content: "󱥩";
}

.mdi-arrow-top-left-bold-outline::before {
  content: "󰧃";
}

.mdi-arrow-top-left-bottom-right::before {
  content: "󰹵";
}

.mdi-arrow-top-left-bottom-right-bold::before {
  content: "󰹶";
}

.mdi-arrow-top-left-thick::before {
  content: "󰧄";
}

.mdi-arrow-top-left-thin::before {
  content: "󱦵";
}

.mdi-arrow-top-left-thin-circle-outline::before {
  content: "󱖓";
}

.mdi-arrow-top-right::before {
  content: "󰁜";
}

.mdi-arrow-top-right-bold-box::before {
  content: "󱥪";
}

.mdi-arrow-top-right-bold-box-outline::before {
  content: "󱥫";
}

.mdi-arrow-top-right-bold-outline::before {
  content: "󰧅";
}

.mdi-arrow-top-right-bottom-left::before {
  content: "󰹷";
}

.mdi-arrow-top-right-bottom-left-bold::before {
  content: "󰹸";
}

.mdi-arrow-top-right-thick::before {
  content: "󰧆";
}

.mdi-arrow-top-right-thin::before {
  content: "󱦴";
}

.mdi-arrow-top-right-thin-circle-outline::before {
  content: "󱖔";
}

.mdi-arrow-u-down-left::before {
  content: "󱞭";
}

.mdi-arrow-u-down-left-bold::before {
  content: "󱞮";
}

.mdi-arrow-u-down-right::before {
  content: "󱞯";
}

.mdi-arrow-u-down-right-bold::before {
  content: "󱞰";
}

.mdi-arrow-u-left-bottom::before {
  content: "󱞱";
}

.mdi-arrow-u-left-bottom-bold::before {
  content: "󱞲";
}

.mdi-arrow-u-left-top::before {
  content: "󱞳";
}

.mdi-arrow-u-left-top-bold::before {
  content: "󱞴";
}

.mdi-arrow-u-right-bottom::before {
  content: "󱞵";
}

.mdi-arrow-u-right-bottom-bold::before {
  content: "󱞶";
}

.mdi-arrow-u-right-top::before {
  content: "󱞷";
}

.mdi-arrow-u-right-top-bold::before {
  content: "󱞸";
}

.mdi-arrow-u-up-left::before {
  content: "󱞹";
}

.mdi-arrow-u-up-left-bold::before {
  content: "󱞺";
}

.mdi-arrow-u-up-right::before {
  content: "󱞻";
}

.mdi-arrow-u-up-right-bold::before {
  content: "󱞼";
}

.mdi-arrow-up::before {
  content: "󰁝";
}

.mdi-arrow-up-bold::before {
  content: "󰜷";
}

.mdi-arrow-up-bold-box::before {
  content: "󰜸";
}

.mdi-arrow-up-bold-box-outline::before {
  content: "󰜹";
}

.mdi-arrow-up-bold-circle::before {
  content: "󰁟";
}

.mdi-arrow-up-bold-circle-outline::before {
  content: "󰁠";
}

.mdi-arrow-up-bold-hexagon-outline::before {
  content: "󰁡";
}

.mdi-arrow-up-bold-outline::before {
  content: "󰧇";
}

.mdi-arrow-up-box::before {
  content: "󰛃";
}

.mdi-arrow-up-circle::before {
  content: "󰳡";
}

.mdi-arrow-up-circle-outline::before {
  content: "󰳢";
}

.mdi-arrow-up-down::before {
  content: "󰹹";
}

.mdi-arrow-up-down-bold::before {
  content: "󰹺";
}

.mdi-arrow-up-down-bold-outline::before {
  content: "󰧈";
}

.mdi-arrow-up-drop-circle::before {
  content: "󰁢";
}

.mdi-arrow-up-drop-circle-outline::before {
  content: "󰁣";
}

.mdi-arrow-up-left::before {
  content: "󱞽";
}

.mdi-arrow-up-left-bold::before {
  content: "󱞾";
}

.mdi-arrow-up-right::before {
  content: "󱞿";
}

.mdi-arrow-up-right-bold::before {
  content: "󱟀";
}

.mdi-arrow-up-thick::before {
  content: "󰁞";
}

.mdi-arrow-up-thin::before {
  content: "󱦲";
}

.mdi-arrow-up-thin-circle-outline::before {
  content: "󱖗";
}

.mdi-arrow-vertical-lock::before {
  content: "󱅜";
}

.mdi-artstation::before {
  content: "󰭛";
}

.mdi-aspect-ratio::before {
  content: "󰨤";
}

.mdi-assistant::before {
  content: "󰁤";
}

.mdi-asterisk::before {
  content: "󰛄";
}

.mdi-at::before {
  content: "󰁥";
}

.mdi-atlassian::before {
  content: "󰠄";
}

.mdi-atm::before {
  content: "󰵇";
}

.mdi-atom::before {
  content: "󰝨";
}

.mdi-atom-variant::before {
  content: "󰹻";
}

.mdi-attachment::before {
  content: "󰁦";
}

.mdi-audio-input-rca::before {
  content: "󱡫";
}

.mdi-audio-input-stereo-minijack::before {
  content: "󱡬";
}

.mdi-audio-input-xlr::before {
  content: "󱡭";
}

.mdi-audio-video::before {
  content: "󰤽";
}

.mdi-audio-video-off::before {
  content: "󱆶";
}

.mdi-augmented-reality::before {
  content: "󰡐";
}

.mdi-auto-download::before {
  content: "󱍾";
}

.mdi-auto-fix::before {
  content: "󰁨";
}

.mdi-auto-upload::before {
  content: "󰁩";
}

.mdi-autorenew::before {
  content: "󰁪";
}

.mdi-av-timer::before {
  content: "󰁫";
}

.mdi-aws::before {
  content: "󰸏";
}

.mdi-axe::before {
  content: "󰣈";
}

.mdi-axe-battle::before {
  content: "󱡂";
}

.mdi-axis::before {
  content: "󰵈";
}

.mdi-axis-arrow::before {
  content: "󰵉";
}

.mdi-axis-arrow-info::before {
  content: "󱐎";
}

.mdi-axis-arrow-lock::before {
  content: "󰵊";
}

.mdi-axis-lock::before {
  content: "󰵋";
}

.mdi-axis-x-arrow::before {
  content: "󰵌";
}

.mdi-axis-x-arrow-lock::before {
  content: "󰵍";
}

.mdi-axis-x-rotate-clockwise::before {
  content: "󰵎";
}

.mdi-axis-x-rotate-counterclockwise::before {
  content: "󰵏";
}

.mdi-axis-x-y-arrow-lock::before {
  content: "󰵐";
}

.mdi-axis-y-arrow::before {
  content: "󰵑";
}

.mdi-axis-y-arrow-lock::before {
  content: "󰵒";
}

.mdi-axis-y-rotate-clockwise::before {
  content: "󰵓";
}

.mdi-axis-y-rotate-counterclockwise::before {
  content: "󰵔";
}

.mdi-axis-z-arrow::before {
  content: "󰵕";
}

.mdi-axis-z-arrow-lock::before {
  content: "󰵖";
}

.mdi-axis-z-rotate-clockwise::before {
  content: "󰵗";
}

.mdi-axis-z-rotate-counterclockwise::before {
  content: "󰵘";
}

.mdi-babel::before {
  content: "󰨥";
}

.mdi-baby::before {
  content: "󰁬";
}

.mdi-baby-bottle::before {
  content: "󰼹";
}

.mdi-baby-bottle-outline::before {
  content: "󰼺";
}

.mdi-baby-buggy::before {
  content: "󱏠";
}

.mdi-baby-carriage::before {
  content: "󰚏";
}

.mdi-baby-carriage-off::before {
  content: "󰾠";
}

.mdi-baby-face::before {
  content: "󰹼";
}

.mdi-baby-face-outline::before {
  content: "󰹽";
}

.mdi-backburger::before {
  content: "󰁭";
}

.mdi-backspace::before {
  content: "󰁮";
}

.mdi-backspace-outline::before {
  content: "󰭜";
}

.mdi-backspace-reverse::before {
  content: "󰹾";
}

.mdi-backspace-reverse-outline::before {
  content: "󰹿";
}

.mdi-backup-restore::before {
  content: "󰁯";
}

.mdi-bacteria::before {
  content: "󰻕";
}

.mdi-bacteria-outline::before {
  content: "󰻖";
}

.mdi-badge-account::before {
  content: "󰶧";
}

.mdi-badge-account-alert::before {
  content: "󰶨";
}

.mdi-badge-account-alert-outline::before {
  content: "󰶩";
}

.mdi-badge-account-horizontal::before {
  content: "󰸍";
}

.mdi-badge-account-horizontal-outline::before {
  content: "󰸎";
}

.mdi-badge-account-outline::before {
  content: "󰶪";
}

.mdi-badminton::before {
  content: "󰡑";
}

.mdi-bag-carry-on::before {
  content: "󰼻";
}

.mdi-bag-carry-on-check::before {
  content: "󰵥";
}

.mdi-bag-carry-on-off::before {
  content: "󰼼";
}

.mdi-bag-checked::before {
  content: "󰼽";
}

.mdi-bag-personal::before {
  content: "󰸐";
}

.mdi-bag-personal-off::before {
  content: "󰸑";
}

.mdi-bag-personal-off-outline::before {
  content: "󰸒";
}

.mdi-bag-personal-outline::before {
  content: "󰸓";
}

.mdi-bag-suitcase::before {
  content: "󱖋";
}

.mdi-bag-suitcase-off::before {
  content: "󱖍";
}

.mdi-bag-suitcase-off-outline::before {
  content: "󱖎";
}

.mdi-bag-suitcase-outline::before {
  content: "󱖌";
}

.mdi-baguette::before {
  content: "󰼾";
}

.mdi-balcony::before {
  content: "󱠗";
}

.mdi-balloon::before {
  content: "󰨦";
}

.mdi-ballot::before {
  content: "󰧉";
}

.mdi-ballot-outline::before {
  content: "󰧊";
}

.mdi-ballot-recount::before {
  content: "󰰹";
}

.mdi-ballot-recount-outline::before {
  content: "󰰺";
}

.mdi-bandage::before {
  content: "󰶯";
}

.mdi-bank::before {
  content: "󰁰";
}

.mdi-bank-check::before {
  content: "󱙕";
}

.mdi-bank-minus::before {
  content: "󰶰";
}

.mdi-bank-off::before {
  content: "󱙖";
}

.mdi-bank-off-outline::before {
  content: "󱙗";
}

.mdi-bank-outline::before {
  content: "󰺀";
}

.mdi-bank-plus::before {
  content: "󰶱";
}

.mdi-bank-remove::before {
  content: "󰶲";
}

.mdi-bank-transfer::before {
  content: "󰨧";
}

.mdi-bank-transfer-in::before {
  content: "󰨨";
}

.mdi-bank-transfer-out::before {
  content: "󰨩";
}

.mdi-barcode::before {
  content: "󰁱";
}

.mdi-barcode-off::before {
  content: "󱈶";
}

.mdi-barcode-scan::before {
  content: "󰁲";
}

.mdi-barley::before {
  content: "󰁳";
}

.mdi-barley-off::before {
  content: "󰭝";
}

.mdi-barn::before {
  content: "󰭞";
}

.mdi-barrel::before {
  content: "󰁴";
}

.mdi-baseball::before {
  content: "󰡒";
}

.mdi-baseball-bat::before {
  content: "󰡓";
}

.mdi-baseball-diamond::before {
  content: "󱗬";
}

.mdi-baseball-diamond-outline::before {
  content: "󱗭";
}

.mdi-bash::before {
  content: "󱆃";
}

.mdi-basket::before {
  content: "󰁶";
}

.mdi-basket-check::before {
  content: "󱣥";
}

.mdi-basket-check-outline::before {
  content: "󱣦";
}

.mdi-basket-fill::before {
  content: "󰁷";
}

.mdi-basket-minus::before {
  content: "󱔣";
}

.mdi-basket-minus-outline::before {
  content: "󱔤";
}

.mdi-basket-off::before {
  content: "󱔥";
}

.mdi-basket-off-outline::before {
  content: "󱔦";
}

.mdi-basket-outline::before {
  content: "󱆁";
}

.mdi-basket-plus::before {
  content: "󱔧";
}

.mdi-basket-plus-outline::before {
  content: "󱔨";
}

.mdi-basket-remove::before {
  content: "󱔩";
}

.mdi-basket-remove-outline::before {
  content: "󱔪";
}

.mdi-basket-unfill::before {
  content: "󰁸";
}

.mdi-basketball::before {
  content: "󰠆";
}

.mdi-basketball-hoop::before {
  content: "󰰻";
}

.mdi-basketball-hoop-outline::before {
  content: "󰰼";
}

.mdi-bat::before {
  content: "󰭟";
}

.mdi-bathtub::before {
  content: "󱠘";
}

.mdi-bathtub-outline::before {
  content: "󱠙";
}

.mdi-battery::before {
  content: "󰁹";
}

.mdi-battery-10::before {
  content: "󰁺";
}

.mdi-battery-10-bluetooth::before {
  content: "󰤾";
}

.mdi-battery-20::before {
  content: "󰁻";
}

.mdi-battery-20-bluetooth::before {
  content: "󰤿";
}

.mdi-battery-30::before {
  content: "󰁼";
}

.mdi-battery-30-bluetooth::before {
  content: "󰥀";
}

.mdi-battery-40::before {
  content: "󰁽";
}

.mdi-battery-40-bluetooth::before {
  content: "󰥁";
}

.mdi-battery-50::before {
  content: "󰁾";
}

.mdi-battery-50-bluetooth::before {
  content: "󰥂";
}

.mdi-battery-60::before {
  content: "󰁿";
}

.mdi-battery-60-bluetooth::before {
  content: "󰥃";
}

.mdi-battery-70::before {
  content: "󰂀";
}

.mdi-battery-70-bluetooth::before {
  content: "󰥄";
}

.mdi-battery-80::before {
  content: "󰂁";
}

.mdi-battery-80-bluetooth::before {
  content: "󰥅";
}

.mdi-battery-90::before {
  content: "󰂂";
}

.mdi-battery-90-bluetooth::before {
  content: "󰥆";
}

.mdi-battery-alert::before {
  content: "󰂃";
}

.mdi-battery-alert-bluetooth::before {
  content: "󰥇";
}

.mdi-battery-alert-variant::before {
  content: "󱃌";
}

.mdi-battery-alert-variant-outline::before {
  content: "󱃍";
}

.mdi-battery-arrow-down::before {
  content: "󱟞";
}

.mdi-battery-arrow-down-outline::before {
  content: "󱟟";
}

.mdi-battery-arrow-up::before {
  content: "󱟠";
}

.mdi-battery-arrow-up-outline::before {
  content: "󱟡";
}

.mdi-battery-bluetooth::before {
  content: "󰥈";
}

.mdi-battery-bluetooth-variant::before {
  content: "󰥉";
}

.mdi-battery-charging::before {
  content: "󰂄";
}

.mdi-battery-charging-10::before {
  content: "󰢜";
}

.mdi-battery-charging-100::before {
  content: "󰂅";
}

.mdi-battery-charging-20::before {
  content: "󰂆";
}

.mdi-battery-charging-30::before {
  content: "󰂇";
}

.mdi-battery-charging-40::before {
  content: "󰂈";
}

.mdi-battery-charging-50::before {
  content: "󰢝";
}

.mdi-battery-charging-60::before {
  content: "󰂉";
}

.mdi-battery-charging-70::before {
  content: "󰢞";
}

.mdi-battery-charging-80::before {
  content: "󰂊";
}

.mdi-battery-charging-90::before {
  content: "󰂋";
}

.mdi-battery-charging-high::before {
  content: "󱊦";
}

.mdi-battery-charging-low::before {
  content: "󱊤";
}

.mdi-battery-charging-medium::before {
  content: "󱊥";
}

.mdi-battery-charging-outline::before {
  content: "󰢟";
}

.mdi-battery-charging-wireless::before {
  content: "󰠇";
}

.mdi-battery-charging-wireless-10::before {
  content: "󰠈";
}

.mdi-battery-charging-wireless-20::before {
  content: "󰠉";
}

.mdi-battery-charging-wireless-30::before {
  content: "󰠊";
}

.mdi-battery-charging-wireless-40::before {
  content: "󰠋";
}

.mdi-battery-charging-wireless-50::before {
  content: "󰠌";
}

.mdi-battery-charging-wireless-60::before {
  content: "󰠍";
}

.mdi-battery-charging-wireless-70::before {
  content: "󰠎";
}

.mdi-battery-charging-wireless-80::before {
  content: "󰠏";
}

.mdi-battery-charging-wireless-90::before {
  content: "󰠐";
}

.mdi-battery-charging-wireless-alert::before {
  content: "󰠑";
}

.mdi-battery-charging-wireless-outline::before {
  content: "󰠒";
}

.mdi-battery-check::before {
  content: "󱟢";
}

.mdi-battery-check-outline::before {
  content: "󱟣";
}

.mdi-battery-heart::before {
  content: "󱈏";
}

.mdi-battery-heart-outline::before {
  content: "󱈐";
}

.mdi-battery-heart-variant::before {
  content: "󱈑";
}

.mdi-battery-high::before {
  content: "󱊣";
}

.mdi-battery-lock::before {
  content: "󱞜";
}

.mdi-battery-lock-open::before {
  content: "󱞝";
}

.mdi-battery-low::before {
  content: "󱊡";
}

.mdi-battery-medium::before {
  content: "󱊢";
}

.mdi-battery-minus::before {
  content: "󱟤";
}

.mdi-battery-minus-outline::before {
  content: "󱟥";
}

.mdi-battery-minus-variant::before {
  content: "󰂌";
}

.mdi-battery-negative::before {
  content: "󰂍";
}

.mdi-battery-off::before {
  content: "󱉝";
}

.mdi-battery-off-outline::before {
  content: "󱉞";
}

.mdi-battery-outline::before {
  content: "󰂎";
}

.mdi-battery-plus::before {
  content: "󱟦";
}

.mdi-battery-plus-outline::before {
  content: "󱟧";
}

.mdi-battery-plus-variant::before {
  content: "󰂏";
}

.mdi-battery-positive::before {
  content: "󰂐";
}

.mdi-battery-remove::before {
  content: "󱟨";
}

.mdi-battery-remove-outline::before {
  content: "󱟩";
}

.mdi-battery-sync::before {
  content: "󱠴";
}

.mdi-battery-sync-outline::before {
  content: "󱠵";
}

.mdi-battery-unknown::before {
  content: "󰂑";
}

.mdi-battery-unknown-bluetooth::before {
  content: "󰥊";
}

.mdi-beach::before {
  content: "󰂒";
}

.mdi-beaker::before {
  content: "󰳪";
}

.mdi-beaker-alert::before {
  content: "󱈩";
}

.mdi-beaker-alert-outline::before {
  content: "󱈪";
}

.mdi-beaker-check::before {
  content: "󱈫";
}

.mdi-beaker-check-outline::before {
  content: "󱈬";
}

.mdi-beaker-minus::before {
  content: "󱈭";
}

.mdi-beaker-minus-outline::before {
  content: "󱈮";
}

.mdi-beaker-outline::before {
  content: "󰚐";
}

.mdi-beaker-plus::before {
  content: "󱈯";
}

.mdi-beaker-plus-outline::before {
  content: "󱈰";
}

.mdi-beaker-question::before {
  content: "󱈱";
}

.mdi-beaker-question-outline::before {
  content: "󱈲";
}

.mdi-beaker-remove::before {
  content: "󱈳";
}

.mdi-beaker-remove-outline::before {
  content: "󱈴";
}

.mdi-bed::before {
  content: "󰋣";
}

.mdi-bed-double::before {
  content: "󰿔";
}

.mdi-bed-double-outline::before {
  content: "󰿓";
}

.mdi-bed-empty::before {
  content: "󰢠";
}

.mdi-bed-king::before {
  content: "󰿒";
}

.mdi-bed-king-outline::before {
  content: "󰿑";
}

.mdi-bed-outline::before {
  content: "󰂙";
}

.mdi-bed-queen::before {
  content: "󰿐";
}

.mdi-bed-queen-outline::before {
  content: "󰿛";
}

.mdi-bed-single::before {
  content: "󱁭";
}

.mdi-bed-single-outline::before {
  content: "󱁮";
}

.mdi-bee::before {
  content: "󰾡";
}

.mdi-bee-flower::before {
  content: "󰾢";
}

.mdi-beehive-off-outline::before {
  content: "󱏭";
}

.mdi-beehive-outline::before {
  content: "󱃎";
}

.mdi-beekeeper::before {
  content: "󱓢";
}

.mdi-beer::before {
  content: "󰂘";
}

.mdi-beer-outline::before {
  content: "󱌌";
}

.mdi-bell::before {
  content: "󰂚";
}

.mdi-bell-alert::before {
  content: "󰵙";
}

.mdi-bell-alert-outline::before {
  content: "󰺁";
}

.mdi-bell-badge::before {
  content: "󱅫";
}

.mdi-bell-badge-outline::before {
  content: "󰅸";
}

.mdi-bell-cancel::before {
  content: "󱏧";
}

.mdi-bell-cancel-outline::before {
  content: "󱏨";
}

.mdi-bell-check::before {
  content: "󱇥";
}

.mdi-bell-check-outline::before {
  content: "󱇦";
}

.mdi-bell-circle::before {
  content: "󰵚";
}

.mdi-bell-circle-outline::before {
  content: "󰵛";
}

.mdi-bell-minus::before {
  content: "󱏩";
}

.mdi-bell-minus-outline::before {
  content: "󱏪";
}

.mdi-bell-off::before {
  content: "󰂛";
}

.mdi-bell-off-outline::before {
  content: "󰪑";
}

.mdi-bell-outline::before {
  content: "󰂜";
}

.mdi-bell-plus::before {
  content: "󰂝";
}

.mdi-bell-plus-outline::before {
  content: "󰪒";
}

.mdi-bell-remove::before {
  content: "󱏫";
}

.mdi-bell-remove-outline::before {
  content: "󱏬";
}

.mdi-bell-ring::before {
  content: "󰂞";
}

.mdi-bell-ring-outline::before {
  content: "󰂟";
}

.mdi-bell-sleep::before {
  content: "󰂠";
}

.mdi-bell-sleep-outline::before {
  content: "󰪓";
}

.mdi-beta::before {
  content: "󰂡";
}

.mdi-betamax::before {
  content: "󰧋";
}

.mdi-biathlon::before {
  content: "󰸔";
}

.mdi-bicycle::before {
  content: "󱂜";
}

.mdi-bicycle-basket::before {
  content: "󱈵";
}

.mdi-bicycle-cargo::before {
  content: "󱢜";
}

.mdi-bicycle-electric::before {
  content: "󱖴";
}

.mdi-bicycle-penny-farthing::before {
  content: "󱗩";
}

.mdi-bike::before {
  content: "󰂣";
}

.mdi-bike-fast::before {
  content: "󱄟";
}

.mdi-billboard::before {
  content: "󱀐";
}

.mdi-billiards::before {
  content: "󰭡";
}

.mdi-billiards-rack::before {
  content: "󰭢";
}

.mdi-binoculars::before {
  content: "󰂥";
}

.mdi-bio::before {
  content: "󰂦";
}

.mdi-biohazard::before {
  content: "󰂧";
}

.mdi-bird::before {
  content: "󱗆";
}

.mdi-bitbucket::before {
  content: "󰂨";
}

.mdi-bitcoin::before {
  content: "󰠓";
}

.mdi-black-mesa::before {
  content: "󰂩";
}

.mdi-blender::before {
  content: "󰳫";
}

.mdi-blender-outline::before {
  content: "󱠚";
}

.mdi-blender-software::before {
  content: "󰂫";
}

.mdi-blinds::before {
  content: "󰂬";
}

.mdi-blinds-open::before {
  content: "󱀑";
}

.mdi-block-helper::before {
  content: "󰂭";
}

.mdi-blood-bag::before {
  content: "󰳬";
}

.mdi-bluetooth::before {
  content: "󰂯";
}

.mdi-bluetooth-audio::before {
  content: "󰂰";
}

.mdi-bluetooth-connect::before {
  content: "󰂱";
}

.mdi-bluetooth-off::before {
  content: "󰂲";
}

.mdi-bluetooth-settings::before {
  content: "󰂳";
}

.mdi-bluetooth-transfer::before {
  content: "󰂴";
}

.mdi-blur::before {
  content: "󰂵";
}

.mdi-blur-linear::before {
  content: "󰂶";
}

.mdi-blur-off::before {
  content: "󰂷";
}

.mdi-blur-radial::before {
  content: "󰂸";
}

.mdi-bolt::before {
  content: "󰶳";
}

.mdi-bomb::before {
  content: "󰚑";
}

.mdi-bomb-off::before {
  content: "󰛅";
}

.mdi-bone::before {
  content: "󰂹";
}

.mdi-book::before {
  content: "󰂺";
}

.mdi-book-account::before {
  content: "󱎭";
}

.mdi-book-account-outline::before {
  content: "󱎮";
}

.mdi-book-alert::before {
  content: "󱙼";
}

.mdi-book-alert-outline::before {
  content: "󱙽";
}

.mdi-book-alphabet::before {
  content: "󰘝";
}

.mdi-book-arrow-down::before {
  content: "󱙾";
}

.mdi-book-arrow-down-outline::before {
  content: "󱙿";
}

.mdi-book-arrow-left::before {
  content: "󱚀";
}

.mdi-book-arrow-left-outline::before {
  content: "󱚁";
}

.mdi-book-arrow-right::before {
  content: "󱚂";
}

.mdi-book-arrow-right-outline::before {
  content: "󱚃";
}

.mdi-book-arrow-up::before {
  content: "󱚄";
}

.mdi-book-arrow-up-outline::before {
  content: "󱚅";
}

.mdi-book-cancel::before {
  content: "󱚆";
}

.mdi-book-cancel-outline::before {
  content: "󱚇";
}

.mdi-book-check::before {
  content: "󱓳";
}

.mdi-book-check-outline::before {
  content: "󱓴";
}

.mdi-book-clock::before {
  content: "󱚈";
}

.mdi-book-clock-outline::before {
  content: "󱚉";
}

.mdi-book-cog::before {
  content: "󱚊";
}

.mdi-book-cog-outline::before {
  content: "󱚋";
}

.mdi-book-cross::before {
  content: "󰂢";
}

.mdi-book-edit::before {
  content: "󱚌";
}

.mdi-book-edit-outline::before {
  content: "󱚍";
}

.mdi-book-education::before {
  content: "󱛉";
}

.mdi-book-education-outline::before {
  content: "󱛊";
}

.mdi-book-information-variant::before {
  content: "󱁯";
}

.mdi-book-lock::before {
  content: "󰞚";
}

.mdi-book-lock-open::before {
  content: "󰞛";
}

.mdi-book-lock-open-outline::before {
  content: "󱚎";
}

.mdi-book-lock-outline::before {
  content: "󱚏";
}

.mdi-book-marker::before {
  content: "󱚐";
}

.mdi-book-marker-outline::before {
  content: "󱚑";
}

.mdi-book-minus::before {
  content: "󰗙";
}

.mdi-book-minus-multiple::before {
  content: "󰪔";
}

.mdi-book-minus-multiple-outline::before {
  content: "󰤋";
}

.mdi-book-minus-outline::before {
  content: "󱚒";
}

.mdi-book-multiple::before {
  content: "󰂻";
}

.mdi-book-multiple-outline::before {
  content: "󰐶";
}

.mdi-book-music::before {
  content: "󰁧";
}

.mdi-book-music-outline::before {
  content: "󱚓";
}

.mdi-book-off::before {
  content: "󱚔";
}

.mdi-book-off-outline::before {
  content: "󱚕";
}

.mdi-book-open::before {
  content: "󰂽";
}

.mdi-book-open-blank-variant::before {
  content: "󰂾";
}

.mdi-book-open-outline::before {
  content: "󰭣";
}

.mdi-book-open-page-variant::before {
  content: "󰗚";
}

.mdi-book-open-page-variant-outline::before {
  content: "󱗖";
}

.mdi-book-open-variant::before {
  content: "󱓷";
}

.mdi-book-outline::before {
  content: "󰭤";
}

.mdi-book-play::before {
  content: "󰺂";
}

.mdi-book-play-outline::before {
  content: "󰺃";
}

.mdi-book-plus::before {
  content: "󰗛";
}

.mdi-book-plus-multiple::before {
  content: "󰪕";
}

.mdi-book-plus-multiple-outline::before {
  content: "󰫞";
}

.mdi-book-plus-outline::before {
  content: "󱚖";
}

.mdi-book-refresh::before {
  content: "󱚗";
}

.mdi-book-refresh-outline::before {
  content: "󱚘";
}

.mdi-book-remove::before {
  content: "󰪗";
}

.mdi-book-remove-multiple::before {
  content: "󰪖";
}

.mdi-book-remove-multiple-outline::before {
  content: "󰓊";
}

.mdi-book-remove-outline::before {
  content: "󱚙";
}

.mdi-book-search::before {
  content: "󰺄";
}

.mdi-book-search-outline::before {
  content: "󰺅";
}

.mdi-book-settings::before {
  content: "󱚚";
}

.mdi-book-settings-outline::before {
  content: "󱚛";
}

.mdi-book-sync::before {
  content: "󱚜";
}

.mdi-book-sync-outline::before {
  content: "󱛈";
}

.mdi-book-variant::before {
  content: "󰂿";
}

.mdi-book-variant-multiple::before {
  content: "󰂼";
}

.mdi-bookmark::before {
  content: "󰃀";
}

.mdi-bookmark-box-multiple::before {
  content: "󱥬";
}

.mdi-bookmark-box-multiple-outline::before {
  content: "󱥭";
}

.mdi-bookmark-check::before {
  content: "󰃁";
}

.mdi-bookmark-check-outline::before {
  content: "󱍻";
}

.mdi-bookmark-minus::before {
  content: "󰧌";
}

.mdi-bookmark-minus-outline::before {
  content: "󰧍";
}

.mdi-bookmark-multiple::before {
  content: "󰸕";
}

.mdi-bookmark-multiple-outline::before {
  content: "󰸖";
}

.mdi-bookmark-music::before {
  content: "󰃂";
}

.mdi-bookmark-music-outline::before {
  content: "󱍹";
}

.mdi-bookmark-off::before {
  content: "󰧎";
}

.mdi-bookmark-off-outline::before {
  content: "󰧏";
}

.mdi-bookmark-outline::before {
  content: "󰃃";
}

.mdi-bookmark-plus::before {
  content: "󰃅";
}

.mdi-bookmark-plus-outline::before {
  content: "󰃄";
}

.mdi-bookmark-remove::before {
  content: "󰃆";
}

.mdi-bookmark-remove-outline::before {
  content: "󱍺";
}

.mdi-bookshelf::before {
  content: "󱉟";
}

.mdi-boom-gate::before {
  content: "󰺆";
}

.mdi-boom-gate-alert::before {
  content: "󰺇";
}

.mdi-boom-gate-alert-outline::before {
  content: "󰺈";
}

.mdi-boom-gate-arrow-down::before {
  content: "󰺉";
}

.mdi-boom-gate-arrow-down-outline::before {
  content: "󰺊";
}

.mdi-boom-gate-arrow-up::before {
  content: "󰺌";
}

.mdi-boom-gate-arrow-up-outline::before {
  content: "󰺍";
}

.mdi-boom-gate-outline::before {
  content: "󰺋";
}

.mdi-boom-gate-up::before {
  content: "󱟹";
}

.mdi-boom-gate-up-outline::before {
  content: "󱟺";
}

.mdi-boombox::before {
  content: "󰗜";
}

.mdi-boomerang::before {
  content: "󱃏";
}

.mdi-bootstrap::before {
  content: "󰛆";
}

.mdi-border-all::before {
  content: "󰃇";
}

.mdi-border-all-variant::before {
  content: "󰢡";
}

.mdi-border-bottom::before {
  content: "󰃈";
}

.mdi-border-bottom-variant::before {
  content: "󰢢";
}

.mdi-border-color::before {
  content: "󰃉";
}

.mdi-border-horizontal::before {
  content: "󰃊";
}

.mdi-border-inside::before {
  content: "󰃋";
}

.mdi-border-left::before {
  content: "󰃌";
}

.mdi-border-left-variant::before {
  content: "󰢣";
}

.mdi-border-none::before {
  content: "󰃍";
}

.mdi-border-none-variant::before {
  content: "󰢤";
}

.mdi-border-outside::before {
  content: "󰃎";
}

.mdi-border-right::before {
  content: "󰃏";
}

.mdi-border-right-variant::before {
  content: "󰢥";
}

.mdi-border-style::before {
  content: "󰃐";
}

.mdi-border-top::before {
  content: "󰃑";
}

.mdi-border-top-variant::before {
  content: "󰢦";
}

.mdi-border-vertical::before {
  content: "󰃒";
}

.mdi-bottle-soda::before {
  content: "󱁰";
}

.mdi-bottle-soda-classic::before {
  content: "󱁱";
}

.mdi-bottle-soda-classic-outline::before {
  content: "󱍣";
}

.mdi-bottle-soda-outline::before {
  content: "󱁲";
}

.mdi-bottle-tonic::before {
  content: "󱄮";
}

.mdi-bottle-tonic-outline::before {
  content: "󱄯";
}

.mdi-bottle-tonic-plus::before {
  content: "󱄰";
}

.mdi-bottle-tonic-plus-outline::before {
  content: "󱄱";
}

.mdi-bottle-tonic-skull::before {
  content: "󱄲";
}

.mdi-bottle-tonic-skull-outline::before {
  content: "󱄳";
}

.mdi-bottle-wine::before {
  content: "󰡔";
}

.mdi-bottle-wine-outline::before {
  content: "󱌐";
}

.mdi-bow-arrow::before {
  content: "󱡁";
}

.mdi-bow-tie::before {
  content: "󰙸";
}

.mdi-bowl::before {
  content: "󰊎";
}

.mdi-bowl-mix::before {
  content: "󰘗";
}

.mdi-bowl-mix-outline::before {
  content: "󰋤";
}

.mdi-bowl-outline::before {
  content: "󰊩";
}

.mdi-bowling::before {
  content: "󰃓";
}

.mdi-box::before {
  content: "󰃔";
}

.mdi-box-cutter::before {
  content: "󰃕";
}

.mdi-box-cutter-off::before {
  content: "󰭊";
}

.mdi-box-shadow::before {
  content: "󰘷";
}

.mdi-boxing-glove::before {
  content: "󰭥";
}

.mdi-braille::before {
  content: "󰧐";
}

.mdi-brain::before {
  content: "󰧑";
}

.mdi-bread-slice::before {
  content: "󰳮";
}

.mdi-bread-slice-outline::before {
  content: "󰳯";
}

.mdi-bridge::before {
  content: "󰘘";
}

.mdi-briefcase::before {
  content: "󰃖";
}

.mdi-briefcase-account::before {
  content: "󰳰";
}

.mdi-briefcase-account-outline::before {
  content: "󰳱";
}

.mdi-briefcase-check::before {
  content: "󰃗";
}

.mdi-briefcase-check-outline::before {
  content: "󱌞";
}

.mdi-briefcase-clock::before {
  content: "󱃐";
}

.mdi-briefcase-clock-outline::before {
  content: "󱃑";
}

.mdi-briefcase-download::before {
  content: "󰃘";
}

.mdi-briefcase-download-outline::before {
  content: "󰰽";
}

.mdi-briefcase-edit::before {
  content: "󰪘";
}

.mdi-briefcase-edit-outline::before {
  content: "󰰾";
}

.mdi-briefcase-eye::before {
  content: "󱟙";
}

.mdi-briefcase-eye-outline::before {
  content: "󱟚";
}

.mdi-briefcase-minus::before {
  content: "󰨪";
}

.mdi-briefcase-minus-outline::before {
  content: "󰰿";
}

.mdi-briefcase-off::before {
  content: "󱙘";
}

.mdi-briefcase-off-outline::before {
  content: "󱙙";
}

.mdi-briefcase-outline::before {
  content: "󰠔";
}

.mdi-briefcase-plus::before {
  content: "󰨫";
}

.mdi-briefcase-plus-outline::before {
  content: "󰱀";
}

.mdi-briefcase-remove::before {
  content: "󰨬";
}

.mdi-briefcase-remove-outline::before {
  content: "󰱁";
}

.mdi-briefcase-search::before {
  content: "󰨭";
}

.mdi-briefcase-search-outline::before {
  content: "󰱂";
}

.mdi-briefcase-upload::before {
  content: "󰃙";
}

.mdi-briefcase-upload-outline::before {
  content: "󰱃";
}

.mdi-briefcase-variant::before {
  content: "󱒔";
}

.mdi-briefcase-variant-off::before {
  content: "󱙚";
}

.mdi-briefcase-variant-off-outline::before {
  content: "󱙛";
}

.mdi-briefcase-variant-outline::before {
  content: "󱒕";
}

.mdi-brightness-1::before {
  content: "󰃚";
}

.mdi-brightness-2::before {
  content: "󰃛";
}

.mdi-brightness-3::before {
  content: "󰃜";
}

.mdi-brightness-4::before {
  content: "󰃝";
}

.mdi-brightness-5::before {
  content: "󰃞";
}

.mdi-brightness-6::before {
  content: "󰃟";
}

.mdi-brightness-7::before {
  content: "󰃠";
}

.mdi-brightness-auto::before {
  content: "󰃡";
}

.mdi-brightness-percent::before {
  content: "󰳲";
}

.mdi-broadcast::before {
  content: "󱜠";
}

.mdi-broadcast-off::before {
  content: "󱜡";
}

.mdi-broom::before {
  content: "󰃢";
}

.mdi-brush::before {
  content: "󰃣";
}

.mdi-brush-off::before {
  content: "󱝱";
}

.mdi-brush-variant::before {
  content: "󱠓";
}

.mdi-bucket::before {
  content: "󱐕";
}

.mdi-bucket-outline::before {
  content: "󱐖";
}

.mdi-buffet::before {
  content: "󰕸";
}

.mdi-bug::before {
  content: "󰃤";
}

.mdi-bug-check::before {
  content: "󰨮";
}

.mdi-bug-check-outline::before {
  content: "󰨯";
}

.mdi-bug-outline::before {
  content: "󰨰";
}

.mdi-bugle::before {
  content: "󰶴";
}

.mdi-bulldozer::before {
  content: "󰬢";
}

.mdi-bullet::before {
  content: "󰳳";
}

.mdi-bulletin-board::before {
  content: "󰃥";
}

.mdi-bullhorn::before {
  content: "󰃦";
}

.mdi-bullhorn-outline::before {
  content: "󰬣";
}

.mdi-bullhorn-variant::before {
  content: "󱥮";
}

.mdi-bullhorn-variant-outline::before {
  content: "󱥯";
}

.mdi-bullseye::before {
  content: "󰗝";
}

.mdi-bullseye-arrow::before {
  content: "󰣉";
}

.mdi-bulma::before {
  content: "󱋧";
}

.mdi-bunk-bed::before {
  content: "󱌂";
}

.mdi-bunk-bed-outline::before {
  content: "󰂗";
}

.mdi-bus::before {
  content: "󰃧";
}

.mdi-bus-alert::before {
  content: "󰪙";
}

.mdi-bus-articulated-end::before {
  content: "󰞜";
}

.mdi-bus-articulated-front::before {
  content: "󰞝";
}

.mdi-bus-clock::before {
  content: "󰣊";
}

.mdi-bus-double-decker::before {
  content: "󰞞";
}

.mdi-bus-electric::before {
  content: "󱤝";
}

.mdi-bus-marker::before {
  content: "󱈒";
}

.mdi-bus-multiple::before {
  content: "󰼿";
}

.mdi-bus-school::before {
  content: "󰞟";
}

.mdi-bus-side::before {
  content: "󰞠";
}

.mdi-bus-stop::before {
  content: "󱀒";
}

.mdi-bus-stop-covered::before {
  content: "󱀓";
}

.mdi-bus-stop-uncovered::before {
  content: "󱀔";
}

.mdi-butterfly::before {
  content: "󱖉";
}

.mdi-butterfly-outline::before {
  content: "󱖊";
}

.mdi-cabin-a-frame::before {
  content: "󱢌";
}

.mdi-cable-data::before {
  content: "󱎔";
}

.mdi-cached::before {
  content: "󰃨";
}

.mdi-cactus::before {
  content: "󰶵";
}

.mdi-cake::before {
  content: "󰃩";
}

.mdi-cake-layered::before {
  content: "󰃪";
}

.mdi-cake-variant::before {
  content: "󰃫";
}

.mdi-cake-variant-outline::before {
  content: "󱟰";
}

.mdi-calculator::before {
  content: "󰃬";
}

.mdi-calculator-variant::before {
  content: "󰪚";
}

.mdi-calculator-variant-outline::before {
  content: "󱖦";
}

.mdi-calendar::before {
  content: "󰃭";
}

.mdi-calendar-account::before {
  content: "󰻗";
}

.mdi-calendar-account-outline::before {
  content: "󰻘";
}

.mdi-calendar-alert::before {
  content: "󰨱";
}

.mdi-calendar-arrow-left::before {
  content: "󱄴";
}

.mdi-calendar-arrow-right::before {
  content: "󱄵";
}

.mdi-calendar-blank::before {
  content: "󰃮";
}

.mdi-calendar-blank-multiple::before {
  content: "󱁳";
}

.mdi-calendar-blank-outline::before {
  content: "󰭦";
}

.mdi-calendar-check::before {
  content: "󰃯";
}

.mdi-calendar-check-outline::before {
  content: "󰱄";
}

.mdi-calendar-clock::before {
  content: "󰃰";
}

.mdi-calendar-clock-outline::before {
  content: "󱛡";
}

.mdi-calendar-collapse-horizontal::before {
  content: "󱢝";
}

.mdi-calendar-cursor::before {
  content: "󱕻";
}

.mdi-calendar-edit::before {
  content: "󰢧";
}

.mdi-calendar-end::before {
  content: "󱙬";
}

.mdi-calendar-expand-horizontal::before {
  content: "󱢞";
}

.mdi-calendar-export::before {
  content: "󰬤";
}

.mdi-calendar-heart::before {
  content: "󰧒";
}

.mdi-calendar-import::before {
  content: "󰬥";
}

.mdi-calendar-lock::before {
  content: "󱙁";
}

.mdi-calendar-lock-outline::before {
  content: "󱙂";
}

.mdi-calendar-minus::before {
  content: "󰵜";
}

.mdi-calendar-month::before {
  content: "󰸗";
}

.mdi-calendar-month-outline::before {
  content: "󰸘";
}

.mdi-calendar-multiple::before {
  content: "󰃱";
}

.mdi-calendar-multiple-check::before {
  content: "󰃲";
}

.mdi-calendar-multiselect::before {
  content: "󰨲";
}

.mdi-calendar-outline::before {
  content: "󰭧";
}

.mdi-calendar-plus::before {
  content: "󰃳";
}

.mdi-calendar-question::before {
  content: "󰚒";
}

.mdi-calendar-range::before {
  content: "󰙹";
}

.mdi-calendar-range-outline::before {
  content: "󰭨";
}

.mdi-calendar-refresh::before {
  content: "󰇡";
}

.mdi-calendar-refresh-outline::before {
  content: "󰈃";
}

.mdi-calendar-remove::before {
  content: "󰃴";
}

.mdi-calendar-remove-outline::before {
  content: "󰱅";
}

.mdi-calendar-search::before {
  content: "󰥌";
}

.mdi-calendar-star::before {
  content: "󰧓";
}

.mdi-calendar-start::before {
  content: "󱙭";
}

.mdi-calendar-sync::before {
  content: "󰺎";
}

.mdi-calendar-sync-outline::before {
  content: "󰺏";
}

.mdi-calendar-text::before {
  content: "󰃵";
}

.mdi-calendar-text-outline::before {
  content: "󰱆";
}

.mdi-calendar-today::before {
  content: "󰃶";
}

.mdi-calendar-week::before {
  content: "󰨳";
}

.mdi-calendar-week-begin::before {
  content: "󰨴";
}

.mdi-calendar-weekend::before {
  content: "󰻙";
}

.mdi-calendar-weekend-outline::before {
  content: "󰻚";
}

.mdi-call-made::before {
  content: "󰃷";
}

.mdi-call-merge::before {
  content: "󰃸";
}

.mdi-call-missed::before {
  content: "󰃹";
}

.mdi-call-received::before {
  content: "󰃺";
}

.mdi-call-split::before {
  content: "󰃻";
}

.mdi-camcorder::before {
  content: "󰃼";
}

.mdi-camcorder-off::before {
  content: "󰃿";
}

.mdi-camera::before {
  content: "󰄀";
}

.mdi-camera-account::before {
  content: "󰣋";
}

.mdi-camera-burst::before {
  content: "󰚓";
}

.mdi-camera-control::before {
  content: "󰭩";
}

.mdi-camera-document::before {
  content: "󱡱";
}

.mdi-camera-document-off::before {
  content: "󱡲";
}

.mdi-camera-enhance::before {
  content: "󰄁";
}

.mdi-camera-enhance-outline::before {
  content: "󰭪";
}

.mdi-camera-flip::before {
  content: "󱗙";
}

.mdi-camera-flip-outline::before {
  content: "󱗚";
}

.mdi-camera-front::before {
  content: "󰄂";
}

.mdi-camera-front-variant::before {
  content: "󰄃";
}

.mdi-camera-gopro::before {
  content: "󰞡";
}

.mdi-camera-image::before {
  content: "󰣌";
}

.mdi-camera-iris::before {
  content: "󰄄";
}

.mdi-camera-marker::before {
  content: "󱦧";
}

.mdi-camera-marker-outline::before {
  content: "󱦨";
}

.mdi-camera-metering-center::before {
  content: "󰞢";
}

.mdi-camera-metering-matrix::before {
  content: "󰞣";
}

.mdi-camera-metering-partial::before {
  content: "󰞤";
}

.mdi-camera-metering-spot::before {
  content: "󰞥";
}

.mdi-camera-off::before {
  content: "󰗟";
}

.mdi-camera-off-outline::before {
  content: "󱦿";
}

.mdi-camera-outline::before {
  content: "󰵝";
}

.mdi-camera-party-mode::before {
  content: "󰄅";
}

.mdi-camera-plus::before {
  content: "󰻛";
}

.mdi-camera-plus-outline::before {
  content: "󰻜";
}

.mdi-camera-rear::before {
  content: "󰄆";
}

.mdi-camera-rear-variant::before {
  content: "󰄇";
}

.mdi-camera-retake::before {
  content: "󰸙";
}

.mdi-camera-retake-outline::before {
  content: "󰸚";
}

.mdi-camera-switch::before {
  content: "󰄈";
}

.mdi-camera-switch-outline::before {
  content: "󰡊";
}

.mdi-camera-timer::before {
  content: "󰄉";
}

.mdi-camera-wireless::before {
  content: "󰶶";
}

.mdi-camera-wireless-outline::before {
  content: "󰶷";
}

.mdi-campfire::before {
  content: "󰻝";
}

.mdi-cancel::before {
  content: "󰜺";
}

.mdi-candelabra::before {
  content: "󱟒";
}

.mdi-candelabra-fire::before {
  content: "󱟓";
}

.mdi-candle::before {
  content: "󰗢";
}

.mdi-candy::before {
  content: "󱥰";
}

.mdi-candy-off::before {
  content: "󱥱";
}

.mdi-candy-off-outline::before {
  content: "󱥲";
}

.mdi-candy-outline::before {
  content: "󱥳";
}

.mdi-candycane::before {
  content: "󰄊";
}

.mdi-cannabis::before {
  content: "󰞦";
}

.mdi-cannabis-off::before {
  content: "󱙮";
}

.mdi-caps-lock::before {
  content: "󰪛";
}

.mdi-car::before {
  content: "󰄋";
}

.mdi-car-2-plus::before {
  content: "󱀕";
}

.mdi-car-3-plus::before {
  content: "󱀖";
}

.mdi-car-arrow-left::before {
  content: "󱎲";
}

.mdi-car-arrow-right::before {
  content: "󱎳";
}

.mdi-car-back::before {
  content: "󰸛";
}

.mdi-car-battery::before {
  content: "󰄌";
}

.mdi-car-brake-abs::before {
  content: "󰱇";
}

.mdi-car-brake-alert::before {
  content: "󰱈";
}

.mdi-car-brake-fluid-level::before {
  content: "󱤉";
}

.mdi-car-brake-hold::before {
  content: "󰵞";
}

.mdi-car-brake-low-pressure::before {
  content: "󱤊";
}

.mdi-car-brake-parking::before {
  content: "󰵟";
}

.mdi-car-brake-retarder::before {
  content: "󱀗";
}

.mdi-car-brake-temperature::before {
  content: "󱤋";
}

.mdi-car-brake-worn-linings::before {
  content: "󱤌";
}

.mdi-car-child-seat::before {
  content: "󰾣";
}

.mdi-car-clock::before {
  content: "󱥴";
}

.mdi-car-clutch::before {
  content: "󱀘";
}

.mdi-car-cog::before {
  content: "󱏌";
}

.mdi-car-connected::before {
  content: "󰄍";
}

.mdi-car-convertible::before {
  content: "󰞧";
}

.mdi-car-coolant-level::before {
  content: "󱀙";
}

.mdi-car-cruise-control::before {
  content: "󰵠";
}

.mdi-car-defrost-front::before {
  content: "󰵡";
}

.mdi-car-defrost-rear::before {
  content: "󰵢";
}

.mdi-car-door::before {
  content: "󰭫";
}

.mdi-car-door-lock::before {
  content: "󱂝";
}

.mdi-car-electric::before {
  content: "󰭬";
}

.mdi-car-electric-outline::before {
  content: "󱖵";
}

.mdi-car-emergency::before {
  content: "󱘏";
}

.mdi-car-esp::before {
  content: "󰱉";
}

.mdi-car-estate::before {
  content: "󰞨";
}

.mdi-car-hatchback::before {
  content: "󰞩";
}

.mdi-car-info::before {
  content: "󱆾";
}

.mdi-car-key::before {
  content: "󰭭";
}

.mdi-car-lifted-pickup::before {
  content: "󱔭";
}

.mdi-car-light-alert::before {
  content: "󱤍";
}

.mdi-car-light-dimmed::before {
  content: "󰱊";
}

.mdi-car-light-fog::before {
  content: "󰱋";
}

.mdi-car-light-high::before {
  content: "󰱌";
}

.mdi-car-limousine::before {
  content: "󰣍";
}

.mdi-car-multiple::before {
  content: "󰭮";
}

.mdi-car-off::before {
  content: "󰸜";
}

.mdi-car-outline::before {
  content: "󱓭";
}

.mdi-car-parking-lights::before {
  content: "󰵣";
}

.mdi-car-pickup::before {
  content: "󰞪";
}

.mdi-car-seat::before {
  content: "󰾤";
}

.mdi-car-seat-cooler::before {
  content: "󰾥";
}

.mdi-car-seat-heater::before {
  content: "󰾦";
}

.mdi-car-select::before {
  content: "󱡹";
}

.mdi-car-settings::before {
  content: "󱏍";
}

.mdi-car-shift-pattern::before {
  content: "󰽀";
}

.mdi-car-side::before {
  content: "󰞫";
}

.mdi-car-speed-limiter::before {
  content: "󱤎";
}

.mdi-car-sports::before {
  content: "󰞬";
}

.mdi-car-tire-alert::before {
  content: "󰱍";
}

.mdi-car-traction-control::before {
  content: "󰵤";
}

.mdi-car-turbocharger::before {
  content: "󱀚";
}

.mdi-car-wash::before {
  content: "󰄎";
}

.mdi-car-windshield::before {
  content: "󱀛";
}

.mdi-car-windshield-outline::before {
  content: "󱀜";
}

.mdi-car-wireless::before {
  content: "󱡸";
}

.mdi-car-wrench::before {
  content: "󱠔";
}

.mdi-carabiner::before {
  content: "󱓀";
}

.mdi-caravan::before {
  content: "󰞭";
}

.mdi-card::before {
  content: "󰭯";
}

.mdi-card-account-details::before {
  content: "󰗒";
}

.mdi-card-account-details-outline::before {
  content: "󰶫";
}

.mdi-card-account-details-star::before {
  content: "󰊣";
}

.mdi-card-account-details-star-outline::before {
  content: "󰛛";
}

.mdi-card-account-mail::before {
  content: "󰆎";
}

.mdi-card-account-mail-outline::before {
  content: "󰺘";
}

.mdi-card-account-phone::before {
  content: "󰺙";
}

.mdi-card-account-phone-outline::before {
  content: "󰺚";
}

.mdi-card-bulleted::before {
  content: "󰭰";
}

.mdi-card-bulleted-off::before {
  content: "󰭱";
}

.mdi-card-bulleted-off-outline::before {
  content: "󰭲";
}

.mdi-card-bulleted-outline::before {
  content: "󰭳";
}

.mdi-card-bulleted-settings::before {
  content: "󰭴";
}

.mdi-card-bulleted-settings-outline::before {
  content: "󰭵";
}

.mdi-card-minus::before {
  content: "󱘀";
}

.mdi-card-minus-outline::before {
  content: "󱘁";
}

.mdi-card-multiple::before {
  content: "󱟱";
}

.mdi-card-multiple-outline::before {
  content: "󱟲";
}

.mdi-card-off::before {
  content: "󱘂";
}

.mdi-card-off-outline::before {
  content: "󱘃";
}

.mdi-card-outline::before {
  content: "󰭶";
}

.mdi-card-plus::before {
  content: "󱇿";
}

.mdi-card-plus-outline::before {
  content: "󱈀";
}

.mdi-card-remove::before {
  content: "󱘄";
}

.mdi-card-remove-outline::before {
  content: "󱘅";
}

.mdi-card-search::before {
  content: "󱁴";
}

.mdi-card-search-outline::before {
  content: "󱁵";
}

.mdi-card-text::before {
  content: "󰭷";
}

.mdi-card-text-outline::before {
  content: "󰭸";
}

.mdi-cards::before {
  content: "󰘸";
}

.mdi-cards-club::before {
  content: "󰣎";
}

.mdi-cards-club-outline::before {
  content: "󱢟";
}

.mdi-cards-diamond::before {
  content: "󰣏";
}

.mdi-cards-diamond-outline::before {
  content: "󱀝";
}

.mdi-cards-heart::before {
  content: "󰣐";
}

.mdi-cards-heart-outline::before {
  content: "󱢠";
}

.mdi-cards-outline::before {
  content: "󰘹";
}

.mdi-cards-playing::before {
  content: "󱢡";
}

.mdi-cards-playing-club::before {
  content: "󱢢";
}

.mdi-cards-playing-club-multiple::before {
  content: "󱢣";
}

.mdi-cards-playing-club-multiple-outline::before {
  content: "󱢤";
}

.mdi-cards-playing-club-outline::before {
  content: "󱢥";
}

.mdi-cards-playing-diamond::before {
  content: "󱢦";
}

.mdi-cards-playing-diamond-multiple::before {
  content: "󱢧";
}

.mdi-cards-playing-diamond-multiple-outline::before {
  content: "󱢨";
}

.mdi-cards-playing-diamond-outline::before {
  content: "󱢩";
}

.mdi-cards-playing-heart::before {
  content: "󱢪";
}

.mdi-cards-playing-heart-multiple::before {
  content: "󱢫";
}

.mdi-cards-playing-heart-multiple-outline::before {
  content: "󱢬";
}

.mdi-cards-playing-heart-outline::before {
  content: "󱢭";
}

.mdi-cards-playing-outline::before {
  content: "󰘺";
}

.mdi-cards-playing-spade::before {
  content: "󱢮";
}

.mdi-cards-playing-spade-multiple::before {
  content: "󱢯";
}

.mdi-cards-playing-spade-multiple-outline::before {
  content: "󱢰";
}

.mdi-cards-playing-spade-outline::before {
  content: "󱢱";
}

.mdi-cards-spade::before {
  content: "󰣑";
}

.mdi-cards-spade-outline::before {
  content: "󱢲";
}

.mdi-cards-variant::before {
  content: "󰛇";
}

.mdi-carrot::before {
  content: "󰄏";
}

.mdi-cart::before {
  content: "󰄐";
}

.mdi-cart-arrow-down::before {
  content: "󰵦";
}

.mdi-cart-arrow-right::before {
  content: "󰱎";
}

.mdi-cart-arrow-up::before {
  content: "󰵧";
}

.mdi-cart-check::before {
  content: "󱗪";
}

.mdi-cart-heart::before {
  content: "󱣠";
}

.mdi-cart-minus::before {
  content: "󰵨";
}

.mdi-cart-off::before {
  content: "󰙫";
}

.mdi-cart-outline::before {
  content: "󰄑";
}

.mdi-cart-plus::before {
  content: "󰄒";
}

.mdi-cart-remove::before {
  content: "󰵩";
}

.mdi-cart-variant::before {
  content: "󱗫";
}

.mdi-case-sensitive-alt::before {
  content: "󰄓";
}

.mdi-cash::before {
  content: "󰄔";
}

.mdi-cash-100::before {
  content: "󰄕";
}

.mdi-cash-check::before {
  content: "󱓮";
}

.mdi-cash-fast::before {
  content: "󱡜";
}

.mdi-cash-lock::before {
  content: "󱓪";
}

.mdi-cash-lock-open::before {
  content: "󱓫";
}

.mdi-cash-marker::before {
  content: "󰶸";
}

.mdi-cash-minus::before {
  content: "󱉠";
}

.mdi-cash-multiple::before {
  content: "󰄖";
}

.mdi-cash-plus::before {
  content: "󱉡";
}

.mdi-cash-refund::before {
  content: "󰪜";
}

.mdi-cash-register::before {
  content: "󰳴";
}

.mdi-cash-remove::before {
  content: "󱉢";
}

.mdi-cassette::before {
  content: "󰧔";
}

.mdi-cast::before {
  content: "󰄘";
}

.mdi-cast-audio::before {
  content: "󱀞";
}

.mdi-cast-audio-variant::before {
  content: "󱝉";
}

.mdi-cast-connected::before {
  content: "󰄙";
}

.mdi-cast-education::before {
  content: "󰸝";
}

.mdi-cast-off::before {
  content: "󰞊";
}

.mdi-cast-variant::before {
  content: "󰀟";
}

.mdi-castle::before {
  content: "󰄚";
}

.mdi-cat::before {
  content: "󰄛";
}

.mdi-cctv::before {
  content: "󰞮";
}

.mdi-cctv-off::before {
  content: "󱡟";
}

.mdi-ceiling-fan::before {
  content: "󱞗";
}

.mdi-ceiling-fan-light::before {
  content: "󱞘";
}

.mdi-ceiling-light::before {
  content: "󰝩";
}

.mdi-ceiling-light-multiple::before {
  content: "󱣝";
}

.mdi-ceiling-light-multiple-outline::before {
  content: "󱣞";
}

.mdi-ceiling-light-outline::before {
  content: "󱟇";
}

.mdi-cellphone::before {
  content: "󰄜";
}

.mdi-cellphone-arrow-down::before {
  content: "󰧕";
}

.mdi-cellphone-basic::before {
  content: "󰄞";
}

.mdi-cellphone-charging::before {
  content: "󱎗";
}

.mdi-cellphone-check::before {
  content: "󱟽";
}

.mdi-cellphone-cog::before {
  content: "󰥑";
}

.mdi-cellphone-dock::before {
  content: "󰄟";
}

.mdi-cellphone-information::before {
  content: "󰽁";
}

.mdi-cellphone-key::before {
  content: "󰥎";
}

.mdi-cellphone-link::before {
  content: "󰄡";
}

.mdi-cellphone-link-off::before {
  content: "󰄢";
}

.mdi-cellphone-lock::before {
  content: "󰥏";
}

.mdi-cellphone-marker::before {
  content: "󱠺";
}

.mdi-cellphone-message::before {
  content: "󰣓";
}

.mdi-cellphone-message-off::before {
  content: "󱃒";
}

.mdi-cellphone-nfc::before {
  content: "󰺐";
}

.mdi-cellphone-nfc-off::before {
  content: "󱋘";
}

.mdi-cellphone-off::before {
  content: "󰥐";
}

.mdi-cellphone-play::before {
  content: "󱀟";
}

.mdi-cellphone-remove::before {
  content: "󰥍";
}

.mdi-cellphone-screenshot::before {
  content: "󰨵";
}

.mdi-cellphone-settings::before {
  content: "󰄣";
}

.mdi-cellphone-sound::before {
  content: "󰥒";
}

.mdi-cellphone-text::before {
  content: "󰣒";
}

.mdi-cellphone-wireless::before {
  content: "󰠕";
}

.mdi-centos::before {
  content: "󱄚";
}

.mdi-certificate::before {
  content: "󰄤";
}

.mdi-certificate-outline::before {
  content: "󱆈";
}

.mdi-chair-rolling::before {
  content: "󰽈";
}

.mdi-chair-school::before {
  content: "󰄥";
}

.mdi-chandelier::before {
  content: "󱞓";
}

.mdi-charity::before {
  content: "󰱏";
}

.mdi-chart-arc::before {
  content: "󰄦";
}

.mdi-chart-areaspline::before {
  content: "󰄧";
}

.mdi-chart-areaspline-variant::before {
  content: "󰺑";
}

.mdi-chart-bar::before {
  content: "󰄨";
}

.mdi-chart-bar-stacked::before {
  content: "󰝪";
}

.mdi-chart-bell-curve::before {
  content: "󰱐";
}

.mdi-chart-bell-curve-cumulative::before {
  content: "󰾧";
}

.mdi-chart-box::before {
  content: "󱕍";
}

.mdi-chart-box-outline::before {
  content: "󱕎";
}

.mdi-chart-box-plus-outline::before {
  content: "󱕏";
}

.mdi-chart-bubble::before {
  content: "󰗣";
}

.mdi-chart-donut::before {
  content: "󰞯";
}

.mdi-chart-donut-variant::before {
  content: "󰞰";
}

.mdi-chart-gantt::before {
  content: "󰙬";
}

.mdi-chart-histogram::before {
  content: "󰄩";
}

.mdi-chart-line::before {
  content: "󰄪";
}

.mdi-chart-line-stacked::before {
  content: "󰝫";
}

.mdi-chart-line-variant::before {
  content: "󰞱";
}

.mdi-chart-multiline::before {
  content: "󰣔";
}

.mdi-chart-multiple::before {
  content: "󱈓";
}

.mdi-chart-pie::before {
  content: "󰄫";
}

.mdi-chart-ppf::before {
  content: "󱎀";
}

.mdi-chart-sankey::before {
  content: "󱇟";
}

.mdi-chart-sankey-variant::before {
  content: "󱇠";
}

.mdi-chart-scatter-plot::before {
  content: "󰺒";
}

.mdi-chart-scatter-plot-hexbin::before {
  content: "󰙭";
}

.mdi-chart-timeline::before {
  content: "󰙮";
}

.mdi-chart-timeline-variant::before {
  content: "󰺓";
}

.mdi-chart-timeline-variant-shimmer::before {
  content: "󱖶";
}

.mdi-chart-tree::before {
  content: "󰺔";
}

.mdi-chart-waterfall::before {
  content: "󱤘";
}

.mdi-chat::before {
  content: "󰭹";
}

.mdi-chat-alert::before {
  content: "󰭺";
}

.mdi-chat-alert-outline::before {
  content: "󱋉";
}

.mdi-chat-minus::before {
  content: "󱐐";
}

.mdi-chat-minus-outline::before {
  content: "󱐓";
}

.mdi-chat-outline::before {
  content: "󰻞";
}

.mdi-chat-plus::before {
  content: "󱐏";
}

.mdi-chat-plus-outline::before {
  content: "󱐒";
}

.mdi-chat-processing::before {
  content: "󰭻";
}

.mdi-chat-processing-outline::before {
  content: "󱋊";
}

.mdi-chat-question::before {
  content: "󱜸";
}

.mdi-chat-question-outline::before {
  content: "󱜹";
}

.mdi-chat-remove::before {
  content: "󱐑";
}

.mdi-chat-remove-outline::before {
  content: "󱐔";
}

.mdi-chat-sleep::before {
  content: "󱋑";
}

.mdi-chat-sleep-outline::before {
  content: "󱋒";
}

.mdi-check::before {
  content: "󰄬";
}

.mdi-check-all::before {
  content: "󰄭";
}

.mdi-check-bold::before {
  content: "󰸞";
}

.mdi-check-circle::before {
  content: "󰗠";
}

.mdi-check-circle-outline::before {
  content: "󰗡";
}

.mdi-check-decagram::before {
  content: "󰞑";
}

.mdi-check-decagram-outline::before {
  content: "󱝀";
}

.mdi-check-network::before {
  content: "󰱓";
}

.mdi-check-network-outline::before {
  content: "󰱔";
}

.mdi-check-outline::before {
  content: "󰡕";
}

.mdi-check-underline::before {
  content: "󰸟";
}

.mdi-check-underline-circle::before {
  content: "󰸠";
}

.mdi-check-underline-circle-outline::before {
  content: "󰸡";
}

.mdi-checkbook::before {
  content: "󰪝";
}

.mdi-checkbox-blank::before {
  content: "󰄮";
}

.mdi-checkbox-blank-badge::before {
  content: "󱅶";
}

.mdi-checkbox-blank-badge-outline::before {
  content: "󰄗";
}

.mdi-checkbox-blank-circle::before {
  content: "󰄯";
}

.mdi-checkbox-blank-circle-outline::before {
  content: "󰄰";
}

.mdi-checkbox-blank-off::before {
  content: "󱋬";
}

.mdi-checkbox-blank-off-outline::before {
  content: "󱋭";
}

.mdi-checkbox-blank-outline::before {
  content: "󰄱";
}

.mdi-checkbox-intermediate::before {
  content: "󰡖";
}

.mdi-checkbox-marked::before {
  content: "󰄲";
}

.mdi-checkbox-marked-circle::before {
  content: "󰄳";
}

.mdi-checkbox-marked-circle-outline::before {
  content: "󰄴";
}

.mdi-checkbox-marked-circle-plus-outline::before {
  content: "󱤧";
}

.mdi-checkbox-marked-outline::before {
  content: "󰄵";
}

.mdi-checkbox-multiple-blank::before {
  content: "󰄶";
}

.mdi-checkbox-multiple-blank-circle::before {
  content: "󰘻";
}

.mdi-checkbox-multiple-blank-circle-outline::before {
  content: "󰘼";
}

.mdi-checkbox-multiple-blank-outline::before {
  content: "󰄷";
}

.mdi-checkbox-multiple-marked::before {
  content: "󰄸";
}

.mdi-checkbox-multiple-marked-circle::before {
  content: "󰘽";
}

.mdi-checkbox-multiple-marked-circle-outline::before {
  content: "󰘾";
}

.mdi-checkbox-multiple-marked-outline::before {
  content: "󰄹";
}

.mdi-checkbox-multiple-outline::before {
  content: "󰱑";
}

.mdi-checkbox-outline::before {
  content: "󰱒";
}

.mdi-checkerboard::before {
  content: "󰄺";
}

.mdi-checkerboard-minus::before {
  content: "󱈂";
}

.mdi-checkerboard-plus::before {
  content: "󱈁";
}

.mdi-checkerboard-remove::before {
  content: "󱈃";
}

.mdi-cheese::before {
  content: "󱊹";
}

.mdi-cheese-off::before {
  content: "󱏮";
}

.mdi-chef-hat::before {
  content: "󰭼";
}

.mdi-chemical-weapon::before {
  content: "󰄻";
}

.mdi-chess-bishop::before {
  content: "󰡜";
}

.mdi-chess-king::before {
  content: "󰡗";
}

.mdi-chess-knight::before {
  content: "󰡘";
}

.mdi-chess-pawn::before {
  content: "󰡙";
}

.mdi-chess-queen::before {
  content: "󰡚";
}

.mdi-chess-rook::before {
  content: "󰡛";
}

.mdi-chevron-double-down::before {
  content: "󰄼";
}

.mdi-chevron-double-left::before {
  content: "󰄽";
}

.mdi-chevron-double-right::before {
  content: "󰄾";
}

.mdi-chevron-double-up::before {
  content: "󰄿";
}

.mdi-chevron-down::before {
  content: "󰅀";
}

.mdi-chevron-down-box::before {
  content: "󰧖";
}

.mdi-chevron-down-box-outline::before {
  content: "󰧗";
}

.mdi-chevron-down-circle::before {
  content: "󰬦";
}

.mdi-chevron-down-circle-outline::before {
  content: "󰬧";
}

.mdi-chevron-left::before {
  content: "󰅁";
}

.mdi-chevron-left-box::before {
  content: "󰧘";
}

.mdi-chevron-left-box-outline::before {
  content: "󰧙";
}

.mdi-chevron-left-circle::before {
  content: "󰬨";
}

.mdi-chevron-left-circle-outline::before {
  content: "󰬩";
}

.mdi-chevron-right::before {
  content: "󰅂";
}

.mdi-chevron-right-box::before {
  content: "󰧚";
}

.mdi-chevron-right-box-outline::before {
  content: "󰧛";
}

.mdi-chevron-right-circle::before {
  content: "󰬪";
}

.mdi-chevron-right-circle-outline::before {
  content: "󰬫";
}

.mdi-chevron-triple-down::before {
  content: "󰶹";
}

.mdi-chevron-triple-left::before {
  content: "󰶺";
}

.mdi-chevron-triple-right::before {
  content: "󰶻";
}

.mdi-chevron-triple-up::before {
  content: "󰶼";
}

.mdi-chevron-up::before {
  content: "󰅃";
}

.mdi-chevron-up-box::before {
  content: "󰧜";
}

.mdi-chevron-up-box-outline::before {
  content: "󰧝";
}

.mdi-chevron-up-circle::before {
  content: "󰬬";
}

.mdi-chevron-up-circle-outline::before {
  content: "󰬭";
}

.mdi-chili-alert::before {
  content: "󱟪";
}

.mdi-chili-alert-outline::before {
  content: "󱟫";
}

.mdi-chili-hot::before {
  content: "󰞲";
}

.mdi-chili-hot-outline::before {
  content: "󱟬";
}

.mdi-chili-medium::before {
  content: "󰞳";
}

.mdi-chili-medium-outline::before {
  content: "󱟭";
}

.mdi-chili-mild::before {
  content: "󰞴";
}

.mdi-chili-mild-outline::before {
  content: "󱟮";
}

.mdi-chili-off::before {
  content: "󱑧";
}

.mdi-chili-off-outline::before {
  content: "󱟯";
}

.mdi-chip::before {
  content: "󰘚";
}

.mdi-church::before {
  content: "󰅄";
}

.mdi-cigar::before {
  content: "󱆉";
}

.mdi-cigar-off::before {
  content: "󱐛";
}

.mdi-circle::before {
  content: "󰝥";
}

.mdi-circle-box::before {
  content: "󱗜";
}

.mdi-circle-box-outline::before {
  content: "󱗝";
}

.mdi-circle-double::before {
  content: "󰺕";
}

.mdi-circle-edit-outline::before {
  content: "󰣕";
}

.mdi-circle-expand::before {
  content: "󰺖";
}

.mdi-circle-half::before {
  content: "󱎕";
}

.mdi-circle-half-full::before {
  content: "󱎖";
}

.mdi-circle-medium::before {
  content: "󰧞";
}

.mdi-circle-multiple::before {
  content: "󰬸";
}

.mdi-circle-multiple-outline::before {
  content: "󰚕";
}

.mdi-circle-off-outline::before {
  content: "󱃓";
}

.mdi-circle-opacity::before {
  content: "󱡓";
}

.mdi-circle-outline::before {
  content: "󰝦";
}

.mdi-circle-slice-1::before {
  content: "󰪞";
}

.mdi-circle-slice-2::before {
  content: "󰪟";
}

.mdi-circle-slice-3::before {
  content: "󰪠";
}

.mdi-circle-slice-4::before {
  content: "󰪡";
}

.mdi-circle-slice-5::before {
  content: "󰪢";
}

.mdi-circle-slice-6::before {
  content: "󰪣";
}

.mdi-circle-slice-7::before {
  content: "󰪤";
}

.mdi-circle-slice-8::before {
  content: "󰪥";
}

.mdi-circle-small::before {
  content: "󰧟";
}

.mdi-circular-saw::before {
  content: "󰸢";
}

.mdi-city::before {
  content: "󰅆";
}

.mdi-city-variant::before {
  content: "󰨶";
}

.mdi-city-variant-outline::before {
  content: "󰨷";
}

.mdi-clipboard::before {
  content: "󰅇";
}

.mdi-clipboard-account::before {
  content: "󰅈";
}

.mdi-clipboard-account-outline::before {
  content: "󰱕";
}

.mdi-clipboard-alert::before {
  content: "󰅉";
}

.mdi-clipboard-alert-outline::before {
  content: "󰳷";
}

.mdi-clipboard-arrow-down::before {
  content: "󰅊";
}

.mdi-clipboard-arrow-down-outline::before {
  content: "󰱖";
}

.mdi-clipboard-arrow-left::before {
  content: "󰅋";
}

.mdi-clipboard-arrow-left-outline::before {
  content: "󰳸";
}

.mdi-clipboard-arrow-right::before {
  content: "󰳹";
}

.mdi-clipboard-arrow-right-outline::before {
  content: "󰳺";
}

.mdi-clipboard-arrow-up::before {
  content: "󰱗";
}

.mdi-clipboard-arrow-up-outline::before {
  content: "󰱘";
}

.mdi-clipboard-check::before {
  content: "󰅎";
}

.mdi-clipboard-check-multiple::before {
  content: "󱉣";
}

.mdi-clipboard-check-multiple-outline::before {
  content: "󱉤";
}

.mdi-clipboard-check-outline::before {
  content: "󰢨";
}

.mdi-clipboard-clock::before {
  content: "󱛢";
}

.mdi-clipboard-clock-outline::before {
  content: "󱛣";
}

.mdi-clipboard-edit::before {
  content: "󱓥";
}

.mdi-clipboard-edit-outline::before {
  content: "󱓦";
}

.mdi-clipboard-file::before {
  content: "󱉥";
}

.mdi-clipboard-file-outline::before {
  content: "󱉦";
}

.mdi-clipboard-flow::before {
  content: "󰛈";
}

.mdi-clipboard-flow-outline::before {
  content: "󱄗";
}

.mdi-clipboard-list::before {
  content: "󱃔";
}

.mdi-clipboard-list-outline::before {
  content: "󱃕";
}

.mdi-clipboard-minus::before {
  content: "󱘘";
}

.mdi-clipboard-minus-outline::before {
  content: "󱘙";
}

.mdi-clipboard-multiple::before {
  content: "󱉧";
}

.mdi-clipboard-multiple-outline::before {
  content: "󱉨";
}

.mdi-clipboard-off::before {
  content: "󱘚";
}

.mdi-clipboard-off-outline::before {
  content: "󱘛";
}

.mdi-clipboard-outline::before {
  content: "󰅌";
}

.mdi-clipboard-play::before {
  content: "󰱙";
}

.mdi-clipboard-play-multiple::before {
  content: "󱉩";
}

.mdi-clipboard-play-multiple-outline::before {
  content: "󱉪";
}

.mdi-clipboard-play-outline::before {
  content: "󰱚";
}

.mdi-clipboard-plus::before {
  content: "󰝑";
}

.mdi-clipboard-plus-outline::before {
  content: "󱌟";
}

.mdi-clipboard-pulse::before {
  content: "󰡝";
}

.mdi-clipboard-pulse-outline::before {
  content: "󰡞";
}

.mdi-clipboard-remove::before {
  content: "󱘜";
}

.mdi-clipboard-remove-outline::before {
  content: "󱘝";
}

.mdi-clipboard-search::before {
  content: "󱘞";
}

.mdi-clipboard-search-outline::before {
  content: "󱘟";
}

.mdi-clipboard-text::before {
  content: "󰅍";
}

.mdi-clipboard-text-clock::before {
  content: "󱣹";
}

.mdi-clipboard-text-clock-outline::before {
  content: "󱣺";
}

.mdi-clipboard-text-multiple::before {
  content: "󱉫";
}

.mdi-clipboard-text-multiple-outline::before {
  content: "󱉬";
}

.mdi-clipboard-text-off::before {
  content: "󱘠";
}

.mdi-clipboard-text-off-outline::before {
  content: "󱘡";
}

.mdi-clipboard-text-outline::before {
  content: "󰨸";
}

.mdi-clipboard-text-play::before {
  content: "󰱛";
}

.mdi-clipboard-text-play-outline::before {
  content: "󰱜";
}

.mdi-clipboard-text-search::before {
  content: "󱘢";
}

.mdi-clipboard-text-search-outline::before {
  content: "󱘣";
}

.mdi-clippy::before {
  content: "󰅏";
}

.mdi-clock::before {
  content: "󰥔";
}

.mdi-clock-alert::before {
  content: "󰥕";
}

.mdi-clock-alert-outline::before {
  content: "󰗎";
}

.mdi-clock-check::before {
  content: "󰾨";
}

.mdi-clock-check-outline::before {
  content: "󰾩";
}

.mdi-clock-digital::before {
  content: "󰺗";
}

.mdi-clock-edit::before {
  content: "󱦺";
}

.mdi-clock-edit-outline::before {
  content: "󱦻";
}

.mdi-clock-end::before {
  content: "󰅑";
}

.mdi-clock-fast::before {
  content: "󰅒";
}

.mdi-clock-in::before {
  content: "󰅓";
}

.mdi-clock-minus::before {
  content: "󱡣";
}

.mdi-clock-minus-outline::before {
  content: "󱡤";
}

.mdi-clock-out::before {
  content: "󰅔";
}

.mdi-clock-outline::before {
  content: "󰅐";
}

.mdi-clock-plus::before {
  content: "󱡡";
}

.mdi-clock-plus-outline::before {
  content: "󱡢";
}

.mdi-clock-remove::before {
  content: "󱡥";
}

.mdi-clock-remove-outline::before {
  content: "󱡦";
}

.mdi-clock-start::before {
  content: "󰅕";
}

.mdi-clock-time-eight::before {
  content: "󱑆";
}

.mdi-clock-time-eight-outline::before {
  content: "󱑒";
}

.mdi-clock-time-eleven::before {
  content: "󱑉";
}

.mdi-clock-time-eleven-outline::before {
  content: "󱑕";
}

.mdi-clock-time-five::before {
  content: "󱑃";
}

.mdi-clock-time-five-outline::before {
  content: "󱑏";
}

.mdi-clock-time-four::before {
  content: "󱑂";
}

.mdi-clock-time-four-outline::before {
  content: "󱑎";
}

.mdi-clock-time-nine::before {
  content: "󱑇";
}

.mdi-clock-time-nine-outline::before {
  content: "󱑓";
}

.mdi-clock-time-one::before {
  content: "󱐿";
}

.mdi-clock-time-one-outline::before {
  content: "󱑋";
}

.mdi-clock-time-seven::before {
  content: "󱑅";
}

.mdi-clock-time-seven-outline::before {
  content: "󱑑";
}

.mdi-clock-time-six::before {
  content: "󱑄";
}

.mdi-clock-time-six-outline::before {
  content: "󱑐";
}

.mdi-clock-time-ten::before {
  content: "󱑈";
}

.mdi-clock-time-ten-outline::before {
  content: "󱑔";
}

.mdi-clock-time-three::before {
  content: "󱑁";
}

.mdi-clock-time-three-outline::before {
  content: "󱑍";
}

.mdi-clock-time-twelve::before {
  content: "󱑊";
}

.mdi-clock-time-twelve-outline::before {
  content: "󱑖";
}

.mdi-clock-time-two::before {
  content: "󱑀";
}

.mdi-clock-time-two-outline::before {
  content: "󱑌";
}

.mdi-close::before {
  content: "󰅖";
}

.mdi-close-box::before {
  content: "󰅗";
}

.mdi-close-box-multiple::before {
  content: "󰱝";
}

.mdi-close-box-multiple-outline::before {
  content: "󰱞";
}

.mdi-close-box-outline::before {
  content: "󰅘";
}

.mdi-close-circle::before {
  content: "󰅙";
}

.mdi-close-circle-multiple::before {
  content: "󰘪";
}

.mdi-close-circle-multiple-outline::before {
  content: "󰢃";
}

.mdi-close-circle-outline::before {
  content: "󰅚";
}

.mdi-close-network::before {
  content: "󰅛";
}

.mdi-close-network-outline::before {
  content: "󰱟";
}

.mdi-close-octagon::before {
  content: "󰅜";
}

.mdi-close-octagon-outline::before {
  content: "󰅝";
}

.mdi-close-outline::before {
  content: "󰛉";
}

.mdi-close-thick::before {
  content: "󱎘";
}

.mdi-closed-caption::before {
  content: "󰅞";
}

.mdi-closed-caption-outline::before {
  content: "󰶽";
}

.mdi-cloud::before {
  content: "󰅟";
}

.mdi-cloud-alert::before {
  content: "󰧠";
}

.mdi-cloud-braces::before {
  content: "󰞵";
}

.mdi-cloud-check::before {
  content: "󰅠";
}

.mdi-cloud-check-outline::before {
  content: "󱋌";
}

.mdi-cloud-circle::before {
  content: "󰅡";
}

.mdi-cloud-download::before {
  content: "󰅢";
}

.mdi-cloud-download-outline::before {
  content: "󰭽";
}

.mdi-cloud-lock::before {
  content: "󱇱";
}

.mdi-cloud-lock-outline::before {
  content: "󱇲";
}

.mdi-cloud-off-outline::before {
  content: "󰅤";
}

.mdi-cloud-outline::before {
  content: "󰅣";
}

.mdi-cloud-print::before {
  content: "󰅥";
}

.mdi-cloud-print-outline::before {
  content: "󰅦";
}

.mdi-cloud-question::before {
  content: "󰨹";
}

.mdi-cloud-refresh::before {
  content: "󰔪";
}

.mdi-cloud-search::before {
  content: "󰥖";
}

.mdi-cloud-search-outline::before {
  content: "󰥗";
}

.mdi-cloud-sync::before {
  content: "󰘿";
}

.mdi-cloud-sync-outline::before {
  content: "󱋖";
}

.mdi-cloud-tags::before {
  content: "󰞶";
}

.mdi-cloud-upload::before {
  content: "󰅧";
}

.mdi-cloud-upload-outline::before {
  content: "󰭾";
}

.mdi-clover::before {
  content: "󰠖";
}

.mdi-coach-lamp::before {
  content: "󱀠";
}

.mdi-coat-rack::before {
  content: "󱂞";
}

.mdi-code-array::before {
  content: "󰅨";
}

.mdi-code-braces::before {
  content: "󰅩";
}

.mdi-code-braces-box::before {
  content: "󱃖";
}

.mdi-code-brackets::before {
  content: "󰅪";
}

.mdi-code-equal::before {
  content: "󰅫";
}

.mdi-code-greater-than::before {
  content: "󰅬";
}

.mdi-code-greater-than-or-equal::before {
  content: "󰅭";
}

.mdi-code-json::before {
  content: "󰘦";
}

.mdi-code-less-than::before {
  content: "󰅮";
}

.mdi-code-less-than-or-equal::before {
  content: "󰅯";
}

.mdi-code-not-equal::before {
  content: "󰅰";
}

.mdi-code-not-equal-variant::before {
  content: "󰅱";
}

.mdi-code-parentheses::before {
  content: "󰅲";
}

.mdi-code-parentheses-box::before {
  content: "󱃗";
}

.mdi-code-string::before {
  content: "󰅳";
}

.mdi-code-tags::before {
  content: "󰅴";
}

.mdi-code-tags-check::before {
  content: "󰚔";
}

.mdi-codepen::before {
  content: "󰅵";
}

.mdi-coffee::before {
  content: "󰅶";
}

.mdi-coffee-maker::before {
  content: "󱂟";
}

.mdi-coffee-maker-check::before {
  content: "󱤱";
}

.mdi-coffee-maker-check-outline::before {
  content: "󱤲";
}

.mdi-coffee-maker-outline::before {
  content: "󱠛";
}

.mdi-coffee-off::before {
  content: "󰾪";
}

.mdi-coffee-off-outline::before {
  content: "󰾫";
}

.mdi-coffee-outline::before {
  content: "󰛊";
}

.mdi-coffee-to-go::before {
  content: "󰅷";
}

.mdi-coffee-to-go-outline::before {
  content: "󱌎";
}

.mdi-coffin::before {
  content: "󰭿";
}

.mdi-cog::before {
  content: "󰒓";
}

.mdi-cog-box::before {
  content: "󰒔";
}

.mdi-cog-clockwise::before {
  content: "󱇝";
}

.mdi-cog-counterclockwise::before {
  content: "󱇞";
}

.mdi-cog-off::before {
  content: "󱏎";
}

.mdi-cog-off-outline::before {
  content: "󱏏";
}

.mdi-cog-outline::before {
  content: "󰢻";
}

.mdi-cog-pause::before {
  content: "󱤳";
}

.mdi-cog-pause-outline::before {
  content: "󱤴";
}

.mdi-cog-play::before {
  content: "󱤵";
}

.mdi-cog-play-outline::before {
  content: "󱤶";
}

.mdi-cog-refresh::before {
  content: "󱑞";
}

.mdi-cog-refresh-outline::before {
  content: "󱑟";
}

.mdi-cog-stop::before {
  content: "󱤷";
}

.mdi-cog-stop-outline::before {
  content: "󱤸";
}

.mdi-cog-sync::before {
  content: "󱑠";
}

.mdi-cog-sync-outline::before {
  content: "󱑡";
}

.mdi-cog-transfer::before {
  content: "󱁛";
}

.mdi-cog-transfer-outline::before {
  content: "󱁜";
}

.mdi-cogs::before {
  content: "󰣖";
}

.mdi-collage::before {
  content: "󰙀";
}

.mdi-collapse-all::before {
  content: "󰪦";
}

.mdi-collapse-all-outline::before {
  content: "󰪧";
}

.mdi-color-helper::before {
  content: "󰅹";
}

.mdi-comma::before {
  content: "󰸣";
}

.mdi-comma-box::before {
  content: "󰸫";
}

.mdi-comma-box-outline::before {
  content: "󰸤";
}

.mdi-comma-circle::before {
  content: "󰸥";
}

.mdi-comma-circle-outline::before {
  content: "󰸦";
}

.mdi-comment::before {
  content: "󰅺";
}

.mdi-comment-account::before {
  content: "󰅻";
}

.mdi-comment-account-outline::before {
  content: "󰅼";
}

.mdi-comment-alert::before {
  content: "󰅽";
}

.mdi-comment-alert-outline::before {
  content: "󰅾";
}

.mdi-comment-arrow-left::before {
  content: "󰧡";
}

.mdi-comment-arrow-left-outline::before {
  content: "󰧢";
}

.mdi-comment-arrow-right::before {
  content: "󰧣";
}

.mdi-comment-arrow-right-outline::before {
  content: "󰧤";
}

.mdi-comment-bookmark::before {
  content: "󱖮";
}

.mdi-comment-bookmark-outline::before {
  content: "󱖯";
}

.mdi-comment-check::before {
  content: "󰅿";
}

.mdi-comment-check-outline::before {
  content: "󰆀";
}

.mdi-comment-edit::before {
  content: "󱆿";
}

.mdi-comment-edit-outline::before {
  content: "󱋄";
}

.mdi-comment-eye::before {
  content: "󰨺";
}

.mdi-comment-eye-outline::before {
  content: "󰨻";
}

.mdi-comment-flash::before {
  content: "󱖰";
}

.mdi-comment-flash-outline::before {
  content: "󱖱";
}

.mdi-comment-minus::before {
  content: "󱗟";
}

.mdi-comment-minus-outline::before {
  content: "󱗠";
}

.mdi-comment-multiple::before {
  content: "󰡟";
}

.mdi-comment-multiple-outline::before {
  content: "󰆁";
}

.mdi-comment-off::before {
  content: "󱗡";
}

.mdi-comment-off-outline::before {
  content: "󱗢";
}

.mdi-comment-outline::before {
  content: "󰆂";
}

.mdi-comment-plus::before {
  content: "󰧥";
}

.mdi-comment-plus-outline::before {
  content: "󰆃";
}

.mdi-comment-processing::before {
  content: "󰆄";
}

.mdi-comment-processing-outline::before {
  content: "󰆅";
}

.mdi-comment-question::before {
  content: "󰠗";
}

.mdi-comment-question-outline::before {
  content: "󰆆";
}

.mdi-comment-quote::before {
  content: "󱀡";
}

.mdi-comment-quote-outline::before {
  content: "󱀢";
}

.mdi-comment-remove::before {
  content: "󰗞";
}

.mdi-comment-remove-outline::before {
  content: "󰆇";
}

.mdi-comment-search::before {
  content: "󰨼";
}

.mdi-comment-search-outline::before {
  content: "󰨽";
}

.mdi-comment-text::before {
  content: "󰆈";
}

.mdi-comment-text-multiple::before {
  content: "󰡠";
}

.mdi-comment-text-multiple-outline::before {
  content: "󰡡";
}

.mdi-comment-text-outline::before {
  content: "󰆉";
}

.mdi-compare::before {
  content: "󰆊";
}

.mdi-compare-horizontal::before {
  content: "󱒒";
}

.mdi-compare-remove::before {
  content: "󱢳";
}

.mdi-compare-vertical::before {
  content: "󱒓";
}

.mdi-compass::before {
  content: "󰆋";
}

.mdi-compass-off::before {
  content: "󰮀";
}

.mdi-compass-off-outline::before {
  content: "󰮁";
}

.mdi-compass-outline::before {
  content: "󰆌";
}

.mdi-compass-rose::before {
  content: "󱎂";
}

.mdi-cone::before {
  content: "󱥌";
}

.mdi-cone-off::before {
  content: "󱥍";
}

.mdi-connection::before {
  content: "󱘖";
}

.mdi-console::before {
  content: "󰆍";
}

.mdi-console-line::before {
  content: "󰞷";
}

.mdi-console-network::before {
  content: "󰢩";
}

.mdi-console-network-outline::before {
  content: "󰱠";
}

.mdi-consolidate::before {
  content: "󱃘";
}

.mdi-contactless-payment::before {
  content: "󰵪";
}

.mdi-contactless-payment-circle::before {
  content: "󰌡";
}

.mdi-contactless-payment-circle-outline::before {
  content: "󰐈";
}

.mdi-contacts::before {
  content: "󰛋";
}

.mdi-contacts-outline::before {
  content: "󰖸";
}

.mdi-contain::before {
  content: "󰨾";
}

.mdi-contain-end::before {
  content: "󰨿";
}

.mdi-contain-start::before {
  content: "󰩀";
}

.mdi-content-copy::before {
  content: "󰆏";
}

.mdi-content-cut::before {
  content: "󰆐";
}

.mdi-content-duplicate::before {
  content: "󰆑";
}

.mdi-content-paste::before {
  content: "󰆒";
}

.mdi-content-save::before {
  content: "󰆓";
}

.mdi-content-save-alert::before {
  content: "󰽂";
}

.mdi-content-save-alert-outline::before {
  content: "󰽃";
}

.mdi-content-save-all::before {
  content: "󰆔";
}

.mdi-content-save-all-outline::before {
  content: "󰽄";
}

.mdi-content-save-check::before {
  content: "󱣪";
}

.mdi-content-save-check-outline::before {
  content: "󱣫";
}

.mdi-content-save-cog::before {
  content: "󱑛";
}

.mdi-content-save-cog-outline::before {
  content: "󱑜";
}

.mdi-content-save-edit::before {
  content: "󰳻";
}

.mdi-content-save-edit-outline::before {
  content: "󰳼";
}

.mdi-content-save-move::before {
  content: "󰸧";
}

.mdi-content-save-move-outline::before {
  content: "󰸨";
}

.mdi-content-save-off::before {
  content: "󱙃";
}

.mdi-content-save-off-outline::before {
  content: "󱙄";
}

.mdi-content-save-outline::before {
  content: "󰠘";
}

.mdi-content-save-settings::before {
  content: "󰘛";
}

.mdi-content-save-settings-outline::before {
  content: "󰬮";
}

.mdi-contrast::before {
  content: "󰆕";
}

.mdi-contrast-box::before {
  content: "󰆖";
}

.mdi-contrast-circle::before {
  content: "󰆗";
}

.mdi-controller-classic::before {
  content: "󰮂";
}

.mdi-controller-classic-outline::before {
  content: "󰮃";
}

.mdi-cookie::before {
  content: "󰆘";
}

.mdi-cookie-alert::before {
  content: "󱛐";
}

.mdi-cookie-alert-outline::before {
  content: "󱛑";
}

.mdi-cookie-check::before {
  content: "󱛒";
}

.mdi-cookie-check-outline::before {
  content: "󱛓";
}

.mdi-cookie-clock::before {
  content: "󱛤";
}

.mdi-cookie-clock-outline::before {
  content: "󱛥";
}

.mdi-cookie-cog::before {
  content: "󱛔";
}

.mdi-cookie-cog-outline::before {
  content: "󱛕";
}

.mdi-cookie-edit::before {
  content: "󱛦";
}

.mdi-cookie-edit-outline::before {
  content: "󱛧";
}

.mdi-cookie-lock::before {
  content: "󱛨";
}

.mdi-cookie-lock-outline::before {
  content: "󱛩";
}

.mdi-cookie-minus::before {
  content: "󱛚";
}

.mdi-cookie-minus-outline::before {
  content: "󱛛";
}

.mdi-cookie-off::before {
  content: "󱛪";
}

.mdi-cookie-off-outline::before {
  content: "󱛫";
}

.mdi-cookie-outline::before {
  content: "󱛞";
}

.mdi-cookie-plus::before {
  content: "󱛖";
}

.mdi-cookie-plus-outline::before {
  content: "󱛗";
}

.mdi-cookie-refresh::before {
  content: "󱛬";
}

.mdi-cookie-refresh-outline::before {
  content: "󱛭";
}

.mdi-cookie-remove::before {
  content: "󱛘";
}

.mdi-cookie-remove-outline::before {
  content: "󱛙";
}

.mdi-cookie-settings::before {
  content: "󱛜";
}

.mdi-cookie-settings-outline::before {
  content: "󱛝";
}

.mdi-coolant-temperature::before {
  content: "󰏈";
}

.mdi-copyleft::before {
  content: "󱤹";
}

.mdi-copyright::before {
  content: "󰗦";
}

.mdi-cordova::before {
  content: "󰥘";
}

.mdi-corn::before {
  content: "󰞸";
}

.mdi-corn-off::before {
  content: "󱏯";
}

.mdi-cosine-wave::before {
  content: "󱑹";
}

.mdi-counter::before {
  content: "󰆙";
}

.mdi-countertop::before {
  content: "󱠜";
}

.mdi-countertop-outline::before {
  content: "󱠝";
}

.mdi-cow::before {
  content: "󰆚";
}

.mdi-cow-off::before {
  content: "󱣼";
}

.mdi-cpu-32-bit::before {
  content: "󰻟";
}

.mdi-cpu-64-bit::before {
  content: "󰻠";
}

.mdi-cradle::before {
  content: "󱦋";
}

.mdi-cradle-outline::before {
  content: "󱦑";
}

.mdi-crane::before {
  content: "󰡢";
}

.mdi-creation::before {
  content: "󰙴";
}

.mdi-creative-commons::before {
  content: "󰵫";
}

.mdi-credit-card::before {
  content: "󰿯";
}

.mdi-credit-card-check::before {
  content: "󱏐";
}

.mdi-credit-card-check-outline::before {
  content: "󱏑";
}

.mdi-credit-card-chip::before {
  content: "󱤏";
}

.mdi-credit-card-chip-outline::before {
  content: "󱤐";
}

.mdi-credit-card-clock::before {
  content: "󰻡";
}

.mdi-credit-card-clock-outline::before {
  content: "󰻢";
}

.mdi-credit-card-edit::before {
  content: "󱟗";
}

.mdi-credit-card-edit-outline::before {
  content: "󱟘";
}

.mdi-credit-card-fast::before {
  content: "󱤑";
}

.mdi-credit-card-fast-outline::before {
  content: "󱤒";
}

.mdi-credit-card-lock::before {
  content: "󱣧";
}

.mdi-credit-card-lock-outline::before {
  content: "󱣨";
}

.mdi-credit-card-marker::before {
  content: "󰚨";
}

.mdi-credit-card-marker-outline::before {
  content: "󰶾";
}

.mdi-credit-card-minus::before {
  content: "󰾬";
}

.mdi-credit-card-minus-outline::before {
  content: "󰾭";
}

.mdi-credit-card-multiple::before {
  content: "󰿰";
}

.mdi-credit-card-multiple-outline::before {
  content: "󰆜";
}

.mdi-credit-card-off::before {
  content: "󰿱";
}

.mdi-credit-card-off-outline::before {
  content: "󰗤";
}

.mdi-credit-card-outline::before {
  content: "󰆛";
}

.mdi-credit-card-plus::before {
  content: "󰿲";
}

.mdi-credit-card-plus-outline::before {
  content: "󰙶";
}

.mdi-credit-card-refresh::before {
  content: "󱙅";
}

.mdi-credit-card-refresh-outline::before {
  content: "󱙆";
}

.mdi-credit-card-refund::before {
  content: "󰿳";
}

.mdi-credit-card-refund-outline::before {
  content: "󰪨";
}

.mdi-credit-card-remove::before {
  content: "󰾮";
}

.mdi-credit-card-remove-outline::before {
  content: "󰾯";
}

.mdi-credit-card-scan::before {
  content: "󰿴";
}

.mdi-credit-card-scan-outline::before {
  content: "󰆝";
}

.mdi-credit-card-search::before {
  content: "󱙇";
}

.mdi-credit-card-search-outline::before {
  content: "󱙈";
}

.mdi-credit-card-settings::before {
  content: "󰿵";
}

.mdi-credit-card-settings-outline::before {
  content: "󰣗";
}

.mdi-credit-card-sync::before {
  content: "󱙉";
}

.mdi-credit-card-sync-outline::before {
  content: "󱙊";
}

.mdi-credit-card-wireless::before {
  content: "󰠂";
}

.mdi-credit-card-wireless-off::before {
  content: "󰕺";
}

.mdi-credit-card-wireless-off-outline::before {
  content: "󰕻";
}

.mdi-credit-card-wireless-outline::before {
  content: "󰵬";
}

.mdi-cricket::before {
  content: "󰵭";
}

.mdi-crop::before {
  content: "󰆞";
}

.mdi-crop-free::before {
  content: "󰆟";
}

.mdi-crop-landscape::before {
  content: "󰆠";
}

.mdi-crop-portrait::before {
  content: "󰆡";
}

.mdi-crop-rotate::before {
  content: "󰚖";
}

.mdi-crop-square::before {
  content: "󰆢";
}

.mdi-cross::before {
  content: "󰥓";
}

.mdi-cross-bolnisi::before {
  content: "󰳭";
}

.mdi-cross-celtic::before {
  content: "󰳵";
}

.mdi-cross-outline::before {
  content: "󰳶";
}

.mdi-crosshairs::before {
  content: "󰆣";
}

.mdi-crosshairs-gps::before {
  content: "󰆤";
}

.mdi-crosshairs-off::before {
  content: "󰽅";
}

.mdi-crosshairs-question::before {
  content: "󱄶";
}

.mdi-crowd::before {
  content: "󱥵";
}

.mdi-crown::before {
  content: "󰆥";
}

.mdi-crown-circle::before {
  content: "󱟜";
}

.mdi-crown-circle-outline::before {
  content: "󱟝";
}

.mdi-crown-outline::before {
  content: "󱇐";
}

.mdi-cryengine::before {
  content: "󰥙";
}

.mdi-crystal-ball::before {
  content: "󰬯";
}

.mdi-cube::before {
  content: "󰆦";
}

.mdi-cube-off::before {
  content: "󱐜";
}

.mdi-cube-off-outline::before {
  content: "󱐝";
}

.mdi-cube-outline::before {
  content: "󰆧";
}

.mdi-cube-scan::before {
  content: "󰮄";
}

.mdi-cube-send::before {
  content: "󰆨";
}

.mdi-cube-unfolded::before {
  content: "󰆩";
}

.mdi-cup::before {
  content: "󰆪";
}

.mdi-cup-off::before {
  content: "󰗥";
}

.mdi-cup-off-outline::before {
  content: "󱍽";
}

.mdi-cup-outline::before {
  content: "󱌏";
}

.mdi-cup-water::before {
  content: "󰆫";
}

.mdi-cupboard::before {
  content: "󰽆";
}

.mdi-cupboard-outline::before {
  content: "󰽇";
}

.mdi-cupcake::before {
  content: "󰥚";
}

.mdi-curling::before {
  content: "󰡣";
}

.mdi-currency-bdt::before {
  content: "󰡤";
}

.mdi-currency-brl::before {
  content: "󰮅";
}

.mdi-currency-btc::before {
  content: "󰆬";
}

.mdi-currency-cny::before {
  content: "󰞺";
}

.mdi-currency-eth::before {
  content: "󰞻";
}

.mdi-currency-eur::before {
  content: "󰆭";
}

.mdi-currency-eur-off::before {
  content: "󱌕";
}

.mdi-currency-gbp::before {
  content: "󰆮";
}

.mdi-currency-ils::before {
  content: "󰱡";
}

.mdi-currency-inr::before {
  content: "󰆯";
}

.mdi-currency-jpy::before {
  content: "󰞼";
}

.mdi-currency-krw::before {
  content: "󰞽";
}

.mdi-currency-kzt::before {
  content: "󰡥";
}

.mdi-currency-mnt::before {
  content: "󱔒";
}

.mdi-currency-ngn::before {
  content: "󰆰";
}

.mdi-currency-php::before {
  content: "󰧦";
}

.mdi-currency-rial::before {
  content: "󰺜";
}

.mdi-currency-rub::before {
  content: "󰆱";
}

.mdi-currency-rupee::before {
  content: "󱥶";
}

.mdi-currency-sign::before {
  content: "󰞾";
}

.mdi-currency-try::before {
  content: "󰆲";
}

.mdi-currency-twd::before {
  content: "󰞿";
}

.mdi-currency-usd::before {
  content: "󰇁";
}

.mdi-currency-usd-off::before {
  content: "󰙺";
}

.mdi-current-ac::before {
  content: "󱒀";
}

.mdi-current-dc::before {
  content: "󰥜";
}

.mdi-cursor-default::before {
  content: "󰇀";
}

.mdi-cursor-default-click::before {
  content: "󰳽";
}

.mdi-cursor-default-click-outline::before {
  content: "󰳾";
}

.mdi-cursor-default-gesture::before {
  content: "󱄧";
}

.mdi-cursor-default-gesture-outline::before {
  content: "󱄨";
}

.mdi-cursor-default-outline::before {
  content: "󰆿";
}

.mdi-cursor-move::before {
  content: "󰆾";
}

.mdi-cursor-pointer::before {
  content: "󰆽";
}

.mdi-cursor-text::before {
  content: "󰗧";
}

.mdi-curtains::before {
  content: "󱡆";
}

.mdi-curtains-closed::before {
  content: "󱡇";
}

.mdi-cylinder::before {
  content: "󱥎";
}

.mdi-cylinder-off::before {
  content: "󱥏";
}

.mdi-dance-ballroom::before {
  content: "󱗻";
}

.mdi-dance-pole::before {
  content: "󱕸";
}

.mdi-data-matrix::before {
  content: "󱔼";
}

.mdi-data-matrix-edit::before {
  content: "󱔽";
}

.mdi-data-matrix-minus::before {
  content: "󱔾";
}

.mdi-data-matrix-plus::before {
  content: "󱔿";
}

.mdi-data-matrix-remove::before {
  content: "󱕀";
}

.mdi-data-matrix-scan::before {
  content: "󱕁";
}

.mdi-database::before {
  content: "󰆼";
}

.mdi-database-alert::before {
  content: "󱘺";
}

.mdi-database-alert-outline::before {
  content: "󱘤";
}

.mdi-database-arrow-down::before {
  content: "󱘻";
}

.mdi-database-arrow-down-outline::before {
  content: "󱘥";
}

.mdi-database-arrow-left::before {
  content: "󱘼";
}

.mdi-database-arrow-left-outline::before {
  content: "󱘦";
}

.mdi-database-arrow-right::before {
  content: "󱘽";
}

.mdi-database-arrow-right-outline::before {
  content: "󱘧";
}

.mdi-database-arrow-up::before {
  content: "󱘾";
}

.mdi-database-arrow-up-outline::before {
  content: "󱘨";
}

.mdi-database-check::before {
  content: "󰪩";
}

.mdi-database-check-outline::before {
  content: "󱘩";
}

.mdi-database-clock::before {
  content: "󱘿";
}

.mdi-database-clock-outline::before {
  content: "󱘪";
}

.mdi-database-cog::before {
  content: "󱙋";
}

.mdi-database-cog-outline::before {
  content: "󱙌";
}

.mdi-database-edit::before {
  content: "󰮆";
}

.mdi-database-edit-outline::before {
  content: "󱘫";
}

.mdi-database-export::before {
  content: "󰥞";
}

.mdi-database-export-outline::before {
  content: "󱘬";
}

.mdi-database-eye::before {
  content: "󱤟";
}

.mdi-database-eye-off::before {
  content: "󱤠";
}

.mdi-database-eye-off-outline::before {
  content: "󱤡";
}

.mdi-database-eye-outline::before {
  content: "󱤢";
}

.mdi-database-import::before {
  content: "󰥝";
}

.mdi-database-import-outline::before {
  content: "󱘭";
}

.mdi-database-lock::before {
  content: "󰪪";
}

.mdi-database-lock-outline::before {
  content: "󱘮";
}

.mdi-database-marker::before {
  content: "󱋶";
}

.mdi-database-marker-outline::before {
  content: "󱘯";
}

.mdi-database-minus::before {
  content: "󰆻";
}

.mdi-database-minus-outline::before {
  content: "󱘰";
}

.mdi-database-off::before {
  content: "󱙀";
}

.mdi-database-off-outline::before {
  content: "󱘱";
}

.mdi-database-outline::before {
  content: "󱘲";
}

.mdi-database-plus::before {
  content: "󰆺";
}

.mdi-database-plus-outline::before {
  content: "󱘳";
}

.mdi-database-refresh::before {
  content: "󰗂";
}

.mdi-database-refresh-outline::before {
  content: "󱘴";
}

.mdi-database-remove::before {
  content: "󰴀";
}

.mdi-database-remove-outline::before {
  content: "󱘵";
}

.mdi-database-search::before {
  content: "󰡦";
}

.mdi-database-search-outline::before {
  content: "󱘶";
}

.mdi-database-settings::before {
  content: "󰴁";
}

.mdi-database-settings-outline::before {
  content: "󱘷";
}

.mdi-database-sync::before {
  content: "󰳿";
}

.mdi-database-sync-outline::before {
  content: "󱘸";
}

.mdi-death-star::before {
  content: "󰣘";
}

.mdi-death-star-variant::before {
  content: "󰣙";
}

.mdi-deathly-hallows::before {
  content: "󰮇";
}

.mdi-debian::before {
  content: "󰣚";
}

.mdi-debug-step-into::before {
  content: "󰆹";
}

.mdi-debug-step-out::before {
  content: "󰆸";
}

.mdi-debug-step-over::before {
  content: "󰆷";
}

.mdi-decagram::before {
  content: "󰝬";
}

.mdi-decagram-outline::before {
  content: "󰝭";
}

.mdi-decimal::before {
  content: "󱂡";
}

.mdi-decimal-comma::before {
  content: "󱂢";
}

.mdi-decimal-comma-decrease::before {
  content: "󱂣";
}

.mdi-decimal-comma-increase::before {
  content: "󱂤";
}

.mdi-decimal-decrease::before {
  content: "󰆶";
}

.mdi-decimal-increase::before {
  content: "󰆵";
}

.mdi-delete::before {
  content: "󰆴";
}

.mdi-delete-alert::before {
  content: "󱂥";
}

.mdi-delete-alert-outline::before {
  content: "󱂦";
}

.mdi-delete-circle::before {
  content: "󰚃";
}

.mdi-delete-circle-outline::before {
  content: "󰮈";
}

.mdi-delete-clock::before {
  content: "󱕖";
}

.mdi-delete-clock-outline::before {
  content: "󱕗";
}

.mdi-delete-empty::before {
  content: "󰛌";
}

.mdi-delete-empty-outline::before {
  content: "󰺝";
}

.mdi-delete-forever::before {
  content: "󰗨";
}

.mdi-delete-forever-outline::before {
  content: "󰮉";
}

.mdi-delete-off::before {
  content: "󱂧";
}

.mdi-delete-off-outline::before {
  content: "󱂨";
}

.mdi-delete-outline::before {
  content: "󰧧";
}

.mdi-delete-restore::before {
  content: "󰠙";
}

.mdi-delete-sweep::before {
  content: "󰗩";
}

.mdi-delete-sweep-outline::before {
  content: "󰱢";
}

.mdi-delete-variant::before {
  content: "󰆳";
}

.mdi-delta::before {
  content: "󰇂";
}

.mdi-desk::before {
  content: "󱈹";
}

.mdi-desk-lamp::before {
  content: "󰥟";
}

.mdi-deskphone::before {
  content: "󰇃";
}

.mdi-desktop-classic::before {
  content: "󰟀";
}

.mdi-desktop-mac::before {
  content: "󰇄";
}

.mdi-desktop-mac-dashboard::before {
  content: "󰧨";
}

.mdi-desktop-tower::before {
  content: "󰇅";
}

.mdi-desktop-tower-monitor::before {
  content: "󰪫";
}

.mdi-details::before {
  content: "󰇆";
}

.mdi-dev-to::before {
  content: "󰵮";
}

.mdi-developer-board::before {
  content: "󰚗";
}

.mdi-deviantart::before {
  content: "󰇇";
}

.mdi-devices::before {
  content: "󰾰";
}

.mdi-dharmachakra::before {
  content: "󰥋";
}

.mdi-diabetes::before {
  content: "󱄦";
}

.mdi-dialpad::before {
  content: "󰘜";
}

.mdi-diameter::before {
  content: "󰱣";
}

.mdi-diameter-outline::before {
  content: "󰱤";
}

.mdi-diameter-variant::before {
  content: "󰱥";
}

.mdi-diamond::before {
  content: "󰮊";
}

.mdi-diamond-outline::before {
  content: "󰮋";
}

.mdi-diamond-stone::before {
  content: "󰇈";
}

.mdi-dice-1::before {
  content: "󰇊";
}

.mdi-dice-1-outline::before {
  content: "󱅊";
}

.mdi-dice-2::before {
  content: "󰇋";
}

.mdi-dice-2-outline::before {
  content: "󱅋";
}

.mdi-dice-3::before {
  content: "󰇌";
}

.mdi-dice-3-outline::before {
  content: "󱅌";
}

.mdi-dice-4::before {
  content: "󰇍";
}

.mdi-dice-4-outline::before {
  content: "󱅍";
}

.mdi-dice-5::before {
  content: "󰇎";
}

.mdi-dice-5-outline::before {
  content: "󱅎";
}

.mdi-dice-6::before {
  content: "󰇏";
}

.mdi-dice-6-outline::before {
  content: "󱅏";
}

.mdi-dice-d10::before {
  content: "󱅓";
}

.mdi-dice-d10-outline::before {
  content: "󰝯";
}

.mdi-dice-d12::before {
  content: "󱅔";
}

.mdi-dice-d12-outline::before {
  content: "󰡧";
}

.mdi-dice-d20::before {
  content: "󱅕";
}

.mdi-dice-d20-outline::before {
  content: "󰗪";
}

.mdi-dice-d4::before {
  content: "󱅐";
}

.mdi-dice-d4-outline::before {
  content: "󰗫";
}

.mdi-dice-d6::before {
  content: "󱅑";
}

.mdi-dice-d6-outline::before {
  content: "󰗭";
}

.mdi-dice-d8::before {
  content: "󱅒";
}

.mdi-dice-d8-outline::before {
  content: "󰗬";
}

.mdi-dice-multiple::before {
  content: "󰝮";
}

.mdi-dice-multiple-outline::before {
  content: "󱅖";
}

.mdi-digital-ocean::before {
  content: "󱈷";
}

.mdi-dip-switch::before {
  content: "󰟁";
}

.mdi-directions::before {
  content: "󰇐";
}

.mdi-directions-fork::before {
  content: "󰙁";
}

.mdi-disc::before {
  content: "󰗮";
}

.mdi-disc-alert::before {
  content: "󰇑";
}

.mdi-disc-player::before {
  content: "󰥠";
}

.mdi-discord::before {
  content: "󰙯";
}

.mdi-dishwasher::before {
  content: "󰪬";
}

.mdi-dishwasher-alert::before {
  content: "󱆸";
}

.mdi-dishwasher-off::before {
  content: "󱆹";
}

.mdi-disqus::before {
  content: "󰇒";
}

.mdi-distribute-horizontal-center::before {
  content: "󱇉";
}

.mdi-distribute-horizontal-left::before {
  content: "󱇈";
}

.mdi-distribute-horizontal-right::before {
  content: "󱇊";
}

.mdi-distribute-vertical-bottom::before {
  content: "󱇋";
}

.mdi-distribute-vertical-center::before {
  content: "󱇌";
}

.mdi-distribute-vertical-top::before {
  content: "󱇍";
}

.mdi-diversify::before {
  content: "󱡷";
}

.mdi-diving::before {
  content: "󱥷";
}

.mdi-diving-flippers::before {
  content: "󰶿";
}

.mdi-diving-helmet::before {
  content: "󰷀";
}

.mdi-diving-scuba::before {
  content: "󰷁";
}

.mdi-diving-scuba-flag::before {
  content: "󰷂";
}

.mdi-diving-scuba-tank::before {
  content: "󰷃";
}

.mdi-diving-scuba-tank-multiple::before {
  content: "󰷄";
}

.mdi-diving-snorkel::before {
  content: "󰷅";
}

.mdi-division::before {
  content: "󰇔";
}

.mdi-division-box::before {
  content: "󰇕";
}

.mdi-dlna::before {
  content: "󰩁";
}

.mdi-dna::before {
  content: "󰚄";
}

.mdi-dns::before {
  content: "󰇖";
}

.mdi-dns-outline::before {
  content: "󰮌";
}

.mdi-dock-bottom::before {
  content: "󱂩";
}

.mdi-dock-left::before {
  content: "󱂪";
}

.mdi-dock-right::before {
  content: "󱂫";
}

.mdi-dock-top::before {
  content: "󱔓";
}

.mdi-dock-window::before {
  content: "󱂬";
}

.mdi-docker::before {
  content: "󰡨";
}

.mdi-doctor::before {
  content: "󰩂";
}

.mdi-dog::before {
  content: "󰩃";
}

.mdi-dog-service::before {
  content: "󰪭";
}

.mdi-dog-side::before {
  content: "󰩄";
}

.mdi-dog-side-off::before {
  content: "󱛮";
}

.mdi-dolby::before {
  content: "󰚳";
}

.mdi-dolly::before {
  content: "󰺞";
}

.mdi-dolphin::before {
  content: "󱢴";
}

.mdi-domain::before {
  content: "󰇗";
}

.mdi-domain-off::before {
  content: "󰵯";
}

.mdi-domain-plus::before {
  content: "󱂭";
}

.mdi-domain-remove::before {
  content: "󱂮";
}

.mdi-dome-light::before {
  content: "󱐞";
}

.mdi-domino-mask::before {
  content: "󱀣";
}

.mdi-donkey::before {
  content: "󰟂";
}

.mdi-door::before {
  content: "󰠚";
}

.mdi-door-closed::before {
  content: "󰠛";
}

.mdi-door-closed-lock::before {
  content: "󱂯";
}

.mdi-door-open::before {
  content: "󰠜";
}

.mdi-door-sliding::before {
  content: "󱠞";
}

.mdi-door-sliding-lock::before {
  content: "󱠟";
}

.mdi-door-sliding-open::before {
  content: "󱠠";
}

.mdi-doorbell::before {
  content: "󱋦";
}

.mdi-doorbell-video::before {
  content: "󰡩";
}

.mdi-dot-net::before {
  content: "󰪮";
}

.mdi-dots-circle::before {
  content: "󱥸";
}

.mdi-dots-grid::before {
  content: "󱗼";
}

.mdi-dots-hexagon::before {
  content: "󱗿";
}

.mdi-dots-horizontal::before {
  content: "󰇘";
}

.mdi-dots-horizontal-circle::before {
  content: "󰟃";
}

.mdi-dots-horizontal-circle-outline::before {
  content: "󰮍";
}

.mdi-dots-square::before {
  content: "󱗽";
}

.mdi-dots-triangle::before {
  content: "󱗾";
}

.mdi-dots-vertical::before {
  content: "󰇙";
}

.mdi-dots-vertical-circle::before {
  content: "󰟄";
}

.mdi-dots-vertical-circle-outline::before {
  content: "󰮎";
}

.mdi-download::before {
  content: "󰇚";
}

.mdi-download-box::before {
  content: "󱑢";
}

.mdi-download-box-outline::before {
  content: "󱑣";
}

.mdi-download-circle::before {
  content: "󱑤";
}

.mdi-download-circle-outline::before {
  content: "󱑥";
}

.mdi-download-lock::before {
  content: "󱌠";
}

.mdi-download-lock-outline::before {
  content: "󱌡";
}

.mdi-download-multiple::before {
  content: "󰧩";
}

.mdi-download-network::before {
  content: "󰛴";
}

.mdi-download-network-outline::before {
  content: "󰱦";
}

.mdi-download-off::before {
  content: "󱂰";
}

.mdi-download-off-outline::before {
  content: "󱂱";
}

.mdi-download-outline::before {
  content: "󰮏";
}

.mdi-drag::before {
  content: "󰇛";
}

.mdi-drag-horizontal::before {
  content: "󰇜";
}

.mdi-drag-horizontal-variant::before {
  content: "󱋰";
}

.mdi-drag-variant::before {
  content: "󰮐";
}

.mdi-drag-vertical::before {
  content: "󰇝";
}

.mdi-drag-vertical-variant::before {
  content: "󱋱";
}

.mdi-drama-masks::before {
  content: "󰴂";
}

.mdi-draw::before {
  content: "󰽉";
}

.mdi-draw-pen::before {
  content: "󱦹";
}

.mdi-drawing::before {
  content: "󰇞";
}

.mdi-drawing-box::before {
  content: "󰇟";
}

.mdi-dresser::before {
  content: "󰽊";
}

.mdi-dresser-outline::before {
  content: "󰽋";
}

.mdi-drone::before {
  content: "󰇢";
}

.mdi-dropbox::before {
  content: "󰇣";
}

.mdi-drupal::before {
  content: "󰇤";
}

.mdi-duck::before {
  content: "󰇥";
}

.mdi-dumbbell::before {
  content: "󰇦";
}

.mdi-dump-truck::before {
  content: "󰱧";
}

.mdi-ear-hearing::before {
  content: "󰟅";
}

.mdi-ear-hearing-off::before {
  content: "󰩅";
}

.mdi-earbuds::before {
  content: "󱡏";
}

.mdi-earbuds-off::before {
  content: "󱡐";
}

.mdi-earbuds-off-outline::before {
  content: "󱡑";
}

.mdi-earbuds-outline::before {
  content: "󱡒";
}

.mdi-earth::before {
  content: "󰇧";
}

.mdi-earth-arrow-right::before {
  content: "󱌑";
}

.mdi-earth-box::before {
  content: "󰛍";
}

.mdi-earth-box-minus::before {
  content: "󱐇";
}

.mdi-earth-box-off::before {
  content: "󰛎";
}

.mdi-earth-box-plus::before {
  content: "󱐆";
}

.mdi-earth-box-remove::before {
  content: "󱐈";
}

.mdi-earth-minus::before {
  content: "󱐄";
}

.mdi-earth-off::before {
  content: "󰇨";
}

.mdi-earth-plus::before {
  content: "󱐃";
}

.mdi-earth-remove::before {
  content: "󱐅";
}

.mdi-egg::before {
  content: "󰪯";
}

.mdi-egg-easter::before {
  content: "󰪰";
}

.mdi-egg-fried::before {
  content: "󱡊";
}

.mdi-egg-off::before {
  content: "󱏰";
}

.mdi-egg-off-outline::before {
  content: "󱏱";
}

.mdi-egg-outline::before {
  content: "󱏲";
}

.mdi-eiffel-tower::before {
  content: "󱕫";
}

.mdi-eight-track::before {
  content: "󰧪";
}

.mdi-eject::before {
  content: "󰇪";
}

.mdi-eject-outline::before {
  content: "󰮑";
}

.mdi-electric-switch::before {
  content: "󰺟";
}

.mdi-electric-switch-closed::before {
  content: "󱃙";
}

.mdi-electron-framework::before {
  content: "󱀤";
}

.mdi-elephant::before {
  content: "󰟆";
}

.mdi-elevation-decline::before {
  content: "󰇫";
}

.mdi-elevation-rise::before {
  content: "󰇬";
}

.mdi-elevator::before {
  content: "󰇭";
}

.mdi-elevator-down::before {
  content: "󱋂";
}

.mdi-elevator-passenger::before {
  content: "󱎁";
}

.mdi-elevator-passenger-off::before {
  content: "󱥹";
}

.mdi-elevator-passenger-off-outline::before {
  content: "󱥺";
}

.mdi-elevator-passenger-outline::before {
  content: "󱥻";
}

.mdi-elevator-up::before {
  content: "󱋁";
}

.mdi-ellipse::before {
  content: "󰺠";
}

.mdi-ellipse-outline::before {
  content: "󰺡";
}

.mdi-email::before {
  content: "󰇮";
}

.mdi-email-alert::before {
  content: "󰛏";
}

.mdi-email-alert-outline::before {
  content: "󰵂";
}

.mdi-email-box::before {
  content: "󰴃";
}

.mdi-email-check::before {
  content: "󰪱";
}

.mdi-email-check-outline::before {
  content: "󰪲";
}

.mdi-email-edit::before {
  content: "󰻣";
}

.mdi-email-edit-outline::before {
  content: "󰻤";
}

.mdi-email-fast::before {
  content: "󱡯";
}

.mdi-email-fast-outline::before {
  content: "󱡰";
}

.mdi-email-lock::before {
  content: "󰇱";
}

.mdi-email-mark-as-unread::before {
  content: "󰮒";
}

.mdi-email-minus::before {
  content: "󰻥";
}

.mdi-email-minus-outline::before {
  content: "󰻦";
}

.mdi-email-multiple::before {
  content: "󰻧";
}

.mdi-email-multiple-outline::before {
  content: "󰻨";
}

.mdi-email-newsletter::before {
  content: "󰾱";
}

.mdi-email-off::before {
  content: "󱏣";
}

.mdi-email-off-outline::before {
  content: "󱏤";
}

.mdi-email-open::before {
  content: "󰇯";
}

.mdi-email-open-multiple::before {
  content: "󰻩";
}

.mdi-email-open-multiple-outline::before {
  content: "󰻪";
}

.mdi-email-open-outline::before {
  content: "󰗯";
}

.mdi-email-outline::before {
  content: "󰇰";
}

.mdi-email-plus::before {
  content: "󰧫";
}

.mdi-email-plus-outline::before {
  content: "󰧬";
}

.mdi-email-receive::before {
  content: "󱃚";
}

.mdi-email-receive-outline::before {
  content: "󱃛";
}

.mdi-email-remove::before {
  content: "󱙡";
}

.mdi-email-remove-outline::before {
  content: "󱙢";
}

.mdi-email-seal::before {
  content: "󱥛";
}

.mdi-email-seal-outline::before {
  content: "󱥜";
}

.mdi-email-search::before {
  content: "󰥡";
}

.mdi-email-search-outline::before {
  content: "󰥢";
}

.mdi-email-send::before {
  content: "󱃜";
}

.mdi-email-send-outline::before {
  content: "󱃝";
}

.mdi-email-sync::before {
  content: "󱋇";
}

.mdi-email-sync-outline::before {
  content: "󱋈";
}

.mdi-email-variant::before {
  content: "󰗰";
}

.mdi-ember::before {
  content: "󰬰";
}

.mdi-emby::before {
  content: "󰚴";
}

.mdi-emoticon::before {
  content: "󰱨";
}

.mdi-emoticon-angry::before {
  content: "󰱩";
}

.mdi-emoticon-angry-outline::before {
  content: "󰱪";
}

.mdi-emoticon-confused::before {
  content: "󱃞";
}

.mdi-emoticon-confused-outline::before {
  content: "󱃟";
}

.mdi-emoticon-cool::before {
  content: "󰱫";
}

.mdi-emoticon-cool-outline::before {
  content: "󰇳";
}

.mdi-emoticon-cry::before {
  content: "󰱬";
}

.mdi-emoticon-cry-outline::before {
  content: "󰱭";
}

.mdi-emoticon-dead::before {
  content: "󰱮";
}

.mdi-emoticon-dead-outline::before {
  content: "󰚛";
}

.mdi-emoticon-devil::before {
  content: "󰱯";
}

.mdi-emoticon-devil-outline::before {
  content: "󰇴";
}

.mdi-emoticon-excited::before {
  content: "󰱰";
}

.mdi-emoticon-excited-outline::before {
  content: "󰚜";
}

.mdi-emoticon-frown::before {
  content: "󰽌";
}

.mdi-emoticon-frown-outline::before {
  content: "󰽍";
}

.mdi-emoticon-happy::before {
  content: "󰱱";
}

.mdi-emoticon-happy-outline::before {
  content: "󰇵";
}

.mdi-emoticon-kiss::before {
  content: "󰱲";
}

.mdi-emoticon-kiss-outline::before {
  content: "󰱳";
}

.mdi-emoticon-lol::before {
  content: "󱈔";
}

.mdi-emoticon-lol-outline::before {
  content: "󱈕";
}

.mdi-emoticon-neutral::before {
  content: "󰱴";
}

.mdi-emoticon-neutral-outline::before {
  content: "󰇶";
}

.mdi-emoticon-outline::before {
  content: "󰇲";
}

.mdi-emoticon-poop::before {
  content: "󰇷";
}

.mdi-emoticon-poop-outline::before {
  content: "󰱵";
}

.mdi-emoticon-sad::before {
  content: "󰱶";
}

.mdi-emoticon-sad-outline::before {
  content: "󰇸";
}

.mdi-emoticon-sick::before {
  content: "󱕼";
}

.mdi-emoticon-sick-outline::before {
  content: "󱕽";
}

.mdi-emoticon-tongue::before {
  content: "󰇹";
}

.mdi-emoticon-tongue-outline::before {
  content: "󰱷";
}

.mdi-emoticon-wink::before {
  content: "󰱸";
}

.mdi-emoticon-wink-outline::before {
  content: "󰱹";
}

.mdi-engine::before {
  content: "󰇺";
}

.mdi-engine-off::before {
  content: "󰩆";
}

.mdi-engine-off-outline::before {
  content: "󰩇";
}

.mdi-engine-outline::before {
  content: "󰇻";
}

.mdi-epsilon::before {
  content: "󱃠";
}

.mdi-equal::before {
  content: "󰇼";
}

.mdi-equal-box::before {
  content: "󰇽";
}

.mdi-equalizer::before {
  content: "󰺢";
}

.mdi-equalizer-outline::before {
  content: "󰺣";
}

.mdi-eraser::before {
  content: "󰇾";
}

.mdi-eraser-variant::before {
  content: "󰙂";
}

.mdi-escalator::before {
  content: "󰇿";
}

.mdi-escalator-box::before {
  content: "󱎙";
}

.mdi-escalator-down::before {
  content: "󱋀";
}

.mdi-escalator-up::before {
  content: "󱊿";
}

.mdi-eslint::before {
  content: "󰱺";
}

.mdi-et::before {
  content: "󰪳";
}

.mdi-ethereum::before {
  content: "󰡪";
}

.mdi-ethernet::before {
  content: "󰈀";
}

.mdi-ethernet-cable::before {
  content: "󰈁";
}

.mdi-ethernet-cable-off::before {
  content: "󰈂";
}

.mdi-ev-plug-ccs1::before {
  content: "󱔙";
}

.mdi-ev-plug-ccs2::before {
  content: "󱔚";
}

.mdi-ev-plug-chademo::before {
  content: "󱔛";
}

.mdi-ev-plug-tesla::before {
  content: "󱔜";
}

.mdi-ev-plug-type1::before {
  content: "󱔝";
}

.mdi-ev-plug-type2::before {
  content: "󱔞";
}

.mdi-ev-station::before {
  content: "󰗱";
}

.mdi-evernote::before {
  content: "󰈄";
}

.mdi-excavator::before {
  content: "󱀥";
}

.mdi-exclamation::before {
  content: "󰈅";
}

.mdi-exclamation-thick::before {
  content: "󱈸";
}

.mdi-exit-run::before {
  content: "󰩈";
}

.mdi-exit-to-app::before {
  content: "󰈆";
}

.mdi-expand-all::before {
  content: "󰪴";
}

.mdi-expand-all-outline::before {
  content: "󰪵";
}

.mdi-expansion-card::before {
  content: "󰢮";
}

.mdi-expansion-card-variant::before {
  content: "󰾲";
}

.mdi-exponent::before {
  content: "󰥣";
}

.mdi-exponent-box::before {
  content: "󰥤";
}

.mdi-export::before {
  content: "󰈇";
}

.mdi-export-variant::before {
  content: "󰮓";
}

.mdi-eye::before {
  content: "󰈈";
}

.mdi-eye-arrow-left::before {
  content: "󱣽";
}

.mdi-eye-arrow-left-outline::before {
  content: "󱣾";
}

.mdi-eye-arrow-right::before {
  content: "󱣿";
}

.mdi-eye-arrow-right-outline::before {
  content: "󱤀";
}

.mdi-eye-check::before {
  content: "󰴄";
}

.mdi-eye-check-outline::before {
  content: "󰴅";
}

.mdi-eye-circle::before {
  content: "󰮔";
}

.mdi-eye-circle-outline::before {
  content: "󰮕";
}

.mdi-eye-minus::before {
  content: "󱀦";
}

.mdi-eye-minus-outline::before {
  content: "󱀧";
}

.mdi-eye-off::before {
  content: "󰈉";
}

.mdi-eye-off-outline::before {
  content: "󰛑";
}

.mdi-eye-outline::before {
  content: "󰛐";
}

.mdi-eye-plus::before {
  content: "󰡫";
}

.mdi-eye-plus-outline::before {
  content: "󰡬";
}

.mdi-eye-refresh::before {
  content: "󱥼";
}

.mdi-eye-refresh-outline::before {
  content: "󱥽";
}

.mdi-eye-remove::before {
  content: "󱗣";
}

.mdi-eye-remove-outline::before {
  content: "󱗤";
}

.mdi-eye-settings::before {
  content: "󰡭";
}

.mdi-eye-settings-outline::before {
  content: "󰡮";
}

.mdi-eyedropper::before {
  content: "󰈊";
}

.mdi-eyedropper-minus::before {
  content: "󱏝";
}

.mdi-eyedropper-off::before {
  content: "󱏟";
}

.mdi-eyedropper-plus::before {
  content: "󱏜";
}

.mdi-eyedropper-remove::before {
  content: "󱏞";
}

.mdi-eyedropper-variant::before {
  content: "󰈋";
}

.mdi-face-agent::before {
  content: "󰵰";
}

.mdi-face-man::before {
  content: "󰙃";
}

.mdi-face-man-outline::before {
  content: "󰮖";
}

.mdi-face-man-profile::before {
  content: "󰙄";
}

.mdi-face-man-shimmer::before {
  content: "󱗌";
}

.mdi-face-man-shimmer-outline::before {
  content: "󱗍";
}

.mdi-face-mask::before {
  content: "󱖆";
}

.mdi-face-mask-outline::before {
  content: "󱖇";
}

.mdi-face-recognition::before {
  content: "󰱻";
}

.mdi-face-woman::before {
  content: "󱁷";
}

.mdi-face-woman-outline::before {
  content: "󱁸";
}

.mdi-face-woman-profile::before {
  content: "󱁶";
}

.mdi-face-woman-shimmer::before {
  content: "󱗎";
}

.mdi-face-woman-shimmer-outline::before {
  content: "󱗏";
}

.mdi-facebook::before {
  content: "󰈌";
}

.mdi-facebook-gaming::before {
  content: "󰟝";
}

.mdi-facebook-messenger::before {
  content: "󰈎";
}

.mdi-facebook-workplace::before {
  content: "󰬱";
}

.mdi-factory::before {
  content: "󰈏";
}

.mdi-family-tree::before {
  content: "󱘎";
}

.mdi-fan::before {
  content: "󰈐";
}

.mdi-fan-alert::before {
  content: "󱑬";
}

.mdi-fan-auto::before {
  content: "󱜝";
}

.mdi-fan-chevron-down::before {
  content: "󱑭";
}

.mdi-fan-chevron-up::before {
  content: "󱑮";
}

.mdi-fan-minus::before {
  content: "󱑰";
}

.mdi-fan-off::before {
  content: "󰠝";
}

.mdi-fan-plus::before {
  content: "󱑯";
}

.mdi-fan-remove::before {
  content: "󱑱";
}

.mdi-fan-speed-1::before {
  content: "󱑲";
}

.mdi-fan-speed-2::before {
  content: "󱑳";
}

.mdi-fan-speed-3::before {
  content: "󱑴";
}

.mdi-fast-forward::before {
  content: "󰈑";
}

.mdi-fast-forward-10::before {
  content: "󰵱";
}

.mdi-fast-forward-15::before {
  content: "󱤺";
}

.mdi-fast-forward-30::before {
  content: "󰴆";
}

.mdi-fast-forward-5::before {
  content: "󱇸";
}

.mdi-fast-forward-60::before {
  content: "󱘋";
}

.mdi-fast-forward-outline::before {
  content: "󰛒";
}

.mdi-fax::before {
  content: "󰈒";
}

.mdi-feather::before {
  content: "󰛓";
}

.mdi-feature-search::before {
  content: "󰩉";
}

.mdi-feature-search-outline::before {
  content: "󰩊";
}

.mdi-fedora::before {
  content: "󰣛";
}

.mdi-fence::before {
  content: "󱞚";
}

.mdi-fence-electric::before {
  content: "󱟶";
}

.mdi-fencing::before {
  content: "󱓁";
}

.mdi-ferris-wheel::before {
  content: "󰺤";
}

.mdi-ferry::before {
  content: "󰈓";
}

.mdi-file::before {
  content: "󰈔";
}

.mdi-file-account::before {
  content: "󰜻";
}

.mdi-file-account-outline::before {
  content: "󱀨";
}

.mdi-file-alert::before {
  content: "󰩋";
}

.mdi-file-alert-outline::before {
  content: "󰩌";
}

.mdi-file-cabinet::before {
  content: "󰪶";
}

.mdi-file-cad::before {
  content: "󰻫";
}

.mdi-file-cad-box::before {
  content: "󰻬";
}

.mdi-file-cancel::before {
  content: "󰷆";
}

.mdi-file-cancel-outline::before {
  content: "󰷇";
}

.mdi-file-certificate::before {
  content: "󱆆";
}

.mdi-file-certificate-outline::before {
  content: "󱆇";
}

.mdi-file-chart::before {
  content: "󰈕";
}

.mdi-file-chart-outline::before {
  content: "󱀩";
}

.mdi-file-check::before {
  content: "󰈖";
}

.mdi-file-check-outline::before {
  content: "󰸩";
}

.mdi-file-clock::before {
  content: "󱋡";
}

.mdi-file-clock-outline::before {
  content: "󱋢";
}

.mdi-file-cloud::before {
  content: "󰈗";
}

.mdi-file-cloud-outline::before {
  content: "󱀪";
}

.mdi-file-code::before {
  content: "󰈮";
}

.mdi-file-code-outline::before {
  content: "󱀫";
}

.mdi-file-cog::before {
  content: "󱁻";
}

.mdi-file-cog-outline::before {
  content: "󱁼";
}

.mdi-file-compare::before {
  content: "󰢪";
}

.mdi-file-delimited::before {
  content: "󰈘";
}

.mdi-file-delimited-outline::before {
  content: "󰺥";
}

.mdi-file-document::before {
  content: "󰈙";
}

.mdi-file-document-edit::before {
  content: "󰷈";
}

.mdi-file-document-edit-outline::before {
  content: "󰷉";
}

.mdi-file-document-multiple::before {
  content: "󱔗";
}

.mdi-file-document-multiple-outline::before {
  content: "󱔘";
}

.mdi-file-document-outline::before {
  content: "󰧮";
}

.mdi-file-download::before {
  content: "󰥥";
}

.mdi-file-download-outline::before {
  content: "󰥦";
}

.mdi-file-edit::before {
  content: "󱇧";
}

.mdi-file-edit-outline::before {
  content: "󱇨";
}

.mdi-file-excel::before {
  content: "󰈛";
}

.mdi-file-excel-box::before {
  content: "󰈜";
}

.mdi-file-excel-box-outline::before {
  content: "󱀬";
}

.mdi-file-excel-outline::before {
  content: "󱀭";
}

.mdi-file-export::before {
  content: "󰈝";
}

.mdi-file-export-outline::before {
  content: "󱀮";
}

.mdi-file-eye::before {
  content: "󰷊";
}

.mdi-file-eye-outline::before {
  content: "󰷋";
}

.mdi-file-find::before {
  content: "󰈞";
}

.mdi-file-find-outline::before {
  content: "󰮗";
}

.mdi-file-gif-box::before {
  content: "󰵸";
}

.mdi-file-hidden::before {
  content: "󰘓";
}

.mdi-file-image::before {
  content: "󰈟";
}

.mdi-file-image-marker::before {
  content: "󱝲";
}

.mdi-file-image-marker-outline::before {
  content: "󱝳";
}

.mdi-file-image-minus::before {
  content: "󱤻";
}

.mdi-file-image-minus-outline::before {
  content: "󱤼";
}

.mdi-file-image-outline::before {
  content: "󰺰";
}

.mdi-file-image-plus::before {
  content: "󱤽";
}

.mdi-file-image-plus-outline::before {
  content: "󱤾";
}

.mdi-file-image-remove::before {
  content: "󱤿";
}

.mdi-file-image-remove-outline::before {
  content: "󱥀";
}

.mdi-file-import::before {
  content: "󰈠";
}

.mdi-file-import-outline::before {
  content: "󱀯";
}

.mdi-file-jpg-box::before {
  content: "󰈥";
}

.mdi-file-key::before {
  content: "󱆄";
}

.mdi-file-key-outline::before {
  content: "󱆅";
}

.mdi-file-link::before {
  content: "󱅷";
}

.mdi-file-link-outline::before {
  content: "󱅸";
}

.mdi-file-lock::before {
  content: "󰈡";
}

.mdi-file-lock-outline::before {
  content: "󱀰";
}

.mdi-file-marker::before {
  content: "󱝴";
}

.mdi-file-marker-outline::before {
  content: "󱝵";
}

.mdi-file-move::before {
  content: "󰪹";
}

.mdi-file-move-outline::before {
  content: "󱀱";
}

.mdi-file-multiple::before {
  content: "󰈢";
}

.mdi-file-multiple-outline::before {
  content: "󱀲";
}

.mdi-file-music::before {
  content: "󰈣";
}

.mdi-file-music-outline::before {
  content: "󰸪";
}

.mdi-file-outline::before {
  content: "󰈤";
}

.mdi-file-pdf-box::before {
  content: "󰈦";
}

.mdi-file-percent::before {
  content: "󰠞";
}

.mdi-file-percent-outline::before {
  content: "󱀳";
}

.mdi-file-phone::before {
  content: "󱅹";
}

.mdi-file-phone-outline::before {
  content: "󱅺";
}

.mdi-file-plus::before {
  content: "󰝒";
}

.mdi-file-plus-outline::before {
  content: "󰻭";
}

.mdi-file-png-box::before {
  content: "󰸭";
}

.mdi-file-powerpoint::before {
  content: "󰈧";
}

.mdi-file-powerpoint-box::before {
  content: "󰈨";
}

.mdi-file-powerpoint-box-outline::before {
  content: "󱀴";
}

.mdi-file-powerpoint-outline::before {
  content: "󱀵";
}

.mdi-file-presentation-box::before {
  content: "󰈩";
}

.mdi-file-question::before {
  content: "󰡯";
}

.mdi-file-question-outline::before {
  content: "󱀶";
}

.mdi-file-refresh::before {
  content: "󰤘";
}

.mdi-file-refresh-outline::before {
  content: "󰕁";
}

.mdi-file-remove::before {
  content: "󰮘";
}

.mdi-file-remove-outline::before {
  content: "󱀷";
}

.mdi-file-replace::before {
  content: "󰬲";
}

.mdi-file-replace-outline::before {
  content: "󰬳";
}

.mdi-file-restore::before {
  content: "󰙰";
}

.mdi-file-restore-outline::before {
  content: "󱀸";
}

.mdi-file-search::before {
  content: "󰱼";
}

.mdi-file-search-outline::before {
  content: "󰱽";
}

.mdi-file-send::before {
  content: "󰈪";
}

.mdi-file-send-outline::before {
  content: "󱀹";
}

.mdi-file-settings::before {
  content: "󱁹";
}

.mdi-file-settings-outline::before {
  content: "󱁺";
}

.mdi-file-sign::before {
  content: "󱧃";
}

.mdi-file-star::before {
  content: "󱀺";
}

.mdi-file-star-outline::before {
  content: "󱀻";
}

.mdi-file-swap::before {
  content: "󰾴";
}

.mdi-file-swap-outline::before {
  content: "󰾵";
}

.mdi-file-sync::before {
  content: "󱈖";
}

.mdi-file-sync-outline::before {
  content: "󱈗";
}

.mdi-file-table::before {
  content: "󰱾";
}

.mdi-file-table-box::before {
  content: "󱃡";
}

.mdi-file-table-box-multiple::before {
  content: "󱃢";
}

.mdi-file-table-box-multiple-outline::before {
  content: "󱃣";
}

.mdi-file-table-box-outline::before {
  content: "󱃤";
}

.mdi-file-table-outline::before {
  content: "󰱿";
}

.mdi-file-tree::before {
  content: "󰙅";
}

.mdi-file-tree-outline::before {
  content: "󱏒";
}

.mdi-file-undo::before {
  content: "󰣜";
}

.mdi-file-undo-outline::before {
  content: "󱀼";
}

.mdi-file-upload::before {
  content: "󰩍";
}

.mdi-file-upload-outline::before {
  content: "󰩎";
}

.mdi-file-video::before {
  content: "󰈫";
}

.mdi-file-video-outline::before {
  content: "󰸬";
}

.mdi-file-word::before {
  content: "󰈬";
}

.mdi-file-word-box::before {
  content: "󰈭";
}

.mdi-file-word-box-outline::before {
  content: "󱀽";
}

.mdi-file-word-outline::before {
  content: "󱀾";
}

.mdi-film::before {
  content: "󰈯";
}

.mdi-filmstrip::before {
  content: "󰈰";
}

.mdi-filmstrip-box::before {
  content: "󰌲";
}

.mdi-filmstrip-box-multiple::before {
  content: "󰴘";
}

.mdi-filmstrip-off::before {
  content: "󰈱";
}

.mdi-filter::before {
  content: "󰈲";
}

.mdi-filter-check::before {
  content: "󱣬";
}

.mdi-filter-check-outline::before {
  content: "󱣭";
}

.mdi-filter-menu::before {
  content: "󱃥";
}

.mdi-filter-menu-outline::before {
  content: "󱃦";
}

.mdi-filter-minus::before {
  content: "󰻮";
}

.mdi-filter-minus-outline::before {
  content: "󰻯";
}

.mdi-filter-off::before {
  content: "󱓯";
}

.mdi-filter-off-outline::before {
  content: "󱓰";
}

.mdi-filter-outline::before {
  content: "󰈳";
}

.mdi-filter-plus::before {
  content: "󰻰";
}

.mdi-filter-plus-outline::before {
  content: "󰻱";
}

.mdi-filter-remove::before {
  content: "󰈴";
}

.mdi-filter-remove-outline::before {
  content: "󰈵";
}

.mdi-filter-variant::before {
  content: "󰈶";
}

.mdi-filter-variant-minus::before {
  content: "󱄒";
}

.mdi-filter-variant-plus::before {
  content: "󱄓";
}

.mdi-filter-variant-remove::before {
  content: "󱀿";
}

.mdi-finance::before {
  content: "󰠟";
}

.mdi-find-replace::before {
  content: "󰛔";
}

.mdi-fingerprint::before {
  content: "󰈷";
}

.mdi-fingerprint-off::before {
  content: "󰺱";
}

.mdi-fire::before {
  content: "󰈸";
}

.mdi-fire-alert::before {
  content: "󱗗";
}

.mdi-fire-circle::before {
  content: "󱠇";
}

.mdi-fire-extinguisher::before {
  content: "󰻲";
}

.mdi-fire-hydrant::before {
  content: "󱄷";
}

.mdi-fire-hydrant-alert::before {
  content: "󱄸";
}

.mdi-fire-hydrant-off::before {
  content: "󱄹";
}

.mdi-fire-off::before {
  content: "󱜢";
}

.mdi-fire-truck::before {
  content: "󰢫";
}

.mdi-firebase::before {
  content: "󰥧";
}

.mdi-firefox::before {
  content: "󰈹";
}

.mdi-fireplace::before {
  content: "󰸮";
}

.mdi-fireplace-off::before {
  content: "󰸯";
}

.mdi-firewire::before {
  content: "󰖾";
}

.mdi-firework::before {
  content: "󰸰";
}

.mdi-firework-off::before {
  content: "󱜣";
}

.mdi-fish::before {
  content: "󰈺";
}

.mdi-fish-off::before {
  content: "󱏳";
}

.mdi-fishbowl::before {
  content: "󰻳";
}

.mdi-fishbowl-outline::before {
  content: "󰻴";
}

.mdi-fit-to-page::before {
  content: "󰻵";
}

.mdi-fit-to-page-outline::before {
  content: "󰻶";
}

.mdi-fit-to-screen::before {
  content: "󱣴";
}

.mdi-fit-to-screen-outline::before {
  content: "󱣵";
}

.mdi-flag::before {
  content: "󰈻";
}

.mdi-flag-checkered::before {
  content: "󰈼";
}

.mdi-flag-minus::before {
  content: "󰮙";
}

.mdi-flag-minus-outline::before {
  content: "󱂲";
}

.mdi-flag-off::before {
  content: "󱣮";
}

.mdi-flag-off-outline::before {
  content: "󱣯";
}

.mdi-flag-outline::before {
  content: "󰈽";
}

.mdi-flag-plus::before {
  content: "󰮚";
}

.mdi-flag-plus-outline::before {
  content: "󱂳";
}

.mdi-flag-remove::before {
  content: "󰮛";
}

.mdi-flag-remove-outline::before {
  content: "󱂴";
}

.mdi-flag-triangle::before {
  content: "󰈿";
}

.mdi-flag-variant::before {
  content: "󰉀";
}

.mdi-flag-variant-outline::before {
  content: "󰈾";
}

.mdi-flare::before {
  content: "󰵲";
}

.mdi-flash::before {
  content: "󰉁";
}

.mdi-flash-alert::before {
  content: "󰻷";
}

.mdi-flash-alert-outline::before {
  content: "󰻸";
}

.mdi-flash-auto::before {
  content: "󰉂";
}

.mdi-flash-off::before {
  content: "󰉃";
}

.mdi-flash-outline::before {
  content: "󰛕";
}

.mdi-flash-orange-eye::before {
  content: "󰙻";
}

.mdi-flashlight::before {
  content: "󰉄";
}

.mdi-flashlight-off::before {
  content: "󰉅";
}

.mdi-flask::before {
  content: "󰂓";
}

.mdi-flask-empty::before {
  content: "󰂔";
}

.mdi-flask-empty-minus::before {
  content: "󱈺";
}

.mdi-flask-empty-minus-outline::before {
  content: "󱈻";
}

.mdi-flask-empty-off::before {
  content: "󱏴";
}

.mdi-flask-empty-off-outline::before {
  content: "󱏵";
}

.mdi-flask-empty-outline::before {
  content: "󰂕";
}

.mdi-flask-empty-plus::before {
  content: "󱈼";
}

.mdi-flask-empty-plus-outline::before {
  content: "󱈽";
}

.mdi-flask-empty-remove::before {
  content: "󱈾";
}

.mdi-flask-empty-remove-outline::before {
  content: "󱈿";
}

.mdi-flask-minus::before {
  content: "󱉀";
}

.mdi-flask-minus-outline::before {
  content: "󱉁";
}

.mdi-flask-off::before {
  content: "󱏶";
}

.mdi-flask-off-outline::before {
  content: "󱏷";
}

.mdi-flask-outline::before {
  content: "󰂖";
}

.mdi-flask-plus::before {
  content: "󱉂";
}

.mdi-flask-plus-outline::before {
  content: "󱉃";
}

.mdi-flask-remove::before {
  content: "󱉄";
}

.mdi-flask-remove-outline::before {
  content: "󱉅";
}

.mdi-flask-round-bottom::before {
  content: "󱉋";
}

.mdi-flask-round-bottom-empty::before {
  content: "󱉌";
}

.mdi-flask-round-bottom-empty-outline::before {
  content: "󱉍";
}

.mdi-flask-round-bottom-outline::before {
  content: "󱉎";
}

.mdi-fleur-de-lis::before {
  content: "󱌃";
}

.mdi-flip-horizontal::before {
  content: "󱃧";
}

.mdi-flip-to-back::before {
  content: "󰉇";
}

.mdi-flip-to-front::before {
  content: "󰉈";
}

.mdi-flip-vertical::before {
  content: "󱃨";
}

.mdi-floor-lamp::before {
  content: "󰣝";
}

.mdi-floor-lamp-dual::before {
  content: "󱁀";
}

.mdi-floor-lamp-dual-outline::before {
  content: "󱟎";
}

.mdi-floor-lamp-outline::before {
  content: "󱟈";
}

.mdi-floor-lamp-torchiere::before {
  content: "󱝇";
}

.mdi-floor-lamp-torchiere-outline::before {
  content: "󱟖";
}

.mdi-floor-lamp-torchiere-variant::before {
  content: "󱁁";
}

.mdi-floor-lamp-torchiere-variant-outline::before {
  content: "󱟏";
}

.mdi-floor-plan::before {
  content: "󰠡";
}

.mdi-floppy::before {
  content: "󰉉";
}

.mdi-floppy-variant::before {
  content: "󰧯";
}

.mdi-flower::before {
  content: "󰉊";
}

.mdi-flower-outline::before {
  content: "󰧰";
}

.mdi-flower-pollen::before {
  content: "󱢅";
}

.mdi-flower-pollen-outline::before {
  content: "󱢆";
}

.mdi-flower-poppy::before {
  content: "󰴈";
}

.mdi-flower-tulip::before {
  content: "󰧱";
}

.mdi-flower-tulip-outline::before {
  content: "󰧲";
}

.mdi-focus-auto::before {
  content: "󰽎";
}

.mdi-focus-field::before {
  content: "󰽏";
}

.mdi-focus-field-horizontal::before {
  content: "󰽐";
}

.mdi-focus-field-vertical::before {
  content: "󰽑";
}

.mdi-folder::before {
  content: "󰉋";
}

.mdi-folder-account::before {
  content: "󰉌";
}

.mdi-folder-account-outline::before {
  content: "󰮜";
}

.mdi-folder-alert::before {
  content: "󰷌";
}

.mdi-folder-alert-outline::before {
  content: "󰷍";
}

.mdi-folder-check::before {
  content: "󱥾";
}

.mdi-folder-check-outline::before {
  content: "󱥿";
}

.mdi-folder-clock::before {
  content: "󰪺";
}

.mdi-folder-clock-outline::before {
  content: "󰪻";
}

.mdi-folder-cog::before {
  content: "󱁿";
}

.mdi-folder-cog-outline::before {
  content: "󱂀";
}

.mdi-folder-download::before {
  content: "󰉍";
}

.mdi-folder-download-outline::before {
  content: "󱃩";
}

.mdi-folder-edit::before {
  content: "󰣞";
}

.mdi-folder-edit-outline::before {
  content: "󰷎";
}

.mdi-folder-eye::before {
  content: "󱞊";
}

.mdi-folder-eye-outline::before {
  content: "󱞋";
}

.mdi-folder-google-drive::before {
  content: "󰉎";
}

.mdi-folder-heart::before {
  content: "󱃪";
}

.mdi-folder-heart-outline::before {
  content: "󱃫";
}

.mdi-folder-hidden::before {
  content: "󱞞";
}

.mdi-folder-home::before {
  content: "󱂵";
}

.mdi-folder-home-outline::before {
  content: "󱂶";
}

.mdi-folder-image::before {
  content: "󰉏";
}

.mdi-folder-information::before {
  content: "󱂷";
}

.mdi-folder-information-outline::before {
  content: "󱂸";
}

.mdi-folder-key::before {
  content: "󰢬";
}

.mdi-folder-key-network::before {
  content: "󰢭";
}

.mdi-folder-key-network-outline::before {
  content: "󰲀";
}

.mdi-folder-key-outline::before {
  content: "󱃬";
}

.mdi-folder-lock::before {
  content: "󰉐";
}

.mdi-folder-lock-open::before {
  content: "󰉑";
}

.mdi-folder-marker::before {
  content: "󱉭";
}

.mdi-folder-marker-outline::before {
  content: "󱉮";
}

.mdi-folder-move::before {
  content: "󰉒";
}

.mdi-folder-move-outline::before {
  content: "󱉆";
}

.mdi-folder-multiple::before {
  content: "󰉓";
}

.mdi-folder-multiple-image::before {
  content: "󰉔";
}

.mdi-folder-multiple-outline::before {
  content: "󰉕";
}

.mdi-folder-multiple-plus::before {
  content: "󱑾";
}

.mdi-folder-multiple-plus-outline::before {
  content: "󱑿";
}

.mdi-folder-music::before {
  content: "󱍙";
}

.mdi-folder-music-outline::before {
  content: "󱍚";
}

.mdi-folder-network::before {
  content: "󰡰";
}

.mdi-folder-network-outline::before {
  content: "󰲁";
}

.mdi-folder-open::before {
  content: "󰝰";
}

.mdi-folder-open-outline::before {
  content: "󰷏";
}

.mdi-folder-outline::before {
  content: "󰉖";
}

.mdi-folder-plus::before {
  content: "󰉗";
}

.mdi-folder-plus-outline::before {
  content: "󰮝";
}

.mdi-folder-pound::before {
  content: "󰴉";
}

.mdi-folder-pound-outline::before {
  content: "󰴊";
}

.mdi-folder-refresh::before {
  content: "󰝉";
}

.mdi-folder-refresh-outline::before {
  content: "󰕂";
}

.mdi-folder-remove::before {
  content: "󰉘";
}

.mdi-folder-remove-outline::before {
  content: "󰮞";
}

.mdi-folder-search::before {
  content: "󰥨";
}

.mdi-folder-search-outline::before {
  content: "󰥩";
}

.mdi-folder-settings::before {
  content: "󱁽";
}

.mdi-folder-settings-outline::before {
  content: "󱁾";
}

.mdi-folder-star::before {
  content: "󰚝";
}

.mdi-folder-star-multiple::before {
  content: "󱏓";
}

.mdi-folder-star-multiple-outline::before {
  content: "󱏔";
}

.mdi-folder-star-outline::before {
  content: "󰮟";
}

.mdi-folder-swap::before {
  content: "󰾶";
}

.mdi-folder-swap-outline::before {
  content: "󰾷";
}

.mdi-folder-sync::before {
  content: "󰴋";
}

.mdi-folder-sync-outline::before {
  content: "󰴌";
}

.mdi-folder-table::before {
  content: "󱋣";
}

.mdi-folder-table-outline::before {
  content: "󱋤";
}

.mdi-folder-text::before {
  content: "󰲂";
}

.mdi-folder-text-outline::before {
  content: "󰲃";
}

.mdi-folder-upload::before {
  content: "󰉙";
}

.mdi-folder-upload-outline::before {
  content: "󱃭";
}

.mdi-folder-zip::before {
  content: "󰛫";
}

.mdi-folder-zip-outline::before {
  content: "󰞹";
}

.mdi-font-awesome::before {
  content: "󰀺";
}

.mdi-food::before {
  content: "󰉚";
}

.mdi-food-apple::before {
  content: "󰉛";
}

.mdi-food-apple-outline::before {
  content: "󰲄";
}

.mdi-food-croissant::before {
  content: "󰟈";
}

.mdi-food-drumstick::before {
  content: "󱐟";
}

.mdi-food-drumstick-off::before {
  content: "󱑨";
}

.mdi-food-drumstick-off-outline::before {
  content: "󱑩";
}

.mdi-food-drumstick-outline::before {
  content: "󱐠";
}

.mdi-food-fork-drink::before {
  content: "󰗲";
}

.mdi-food-halal::before {
  content: "󱕲";
}

.mdi-food-hot-dog::before {
  content: "󱡋";
}

.mdi-food-kosher::before {
  content: "󱕳";
}

.mdi-food-off::before {
  content: "󰗳";
}

.mdi-food-off-outline::before {
  content: "󱤕";
}

.mdi-food-outline::before {
  content: "󱤖";
}

.mdi-food-steak::before {
  content: "󱑪";
}

.mdi-food-steak-off::before {
  content: "󱑫";
}

.mdi-food-takeout-box::before {
  content: "󱠶";
}

.mdi-food-takeout-box-outline::before {
  content: "󱠷";
}

.mdi-food-turkey::before {
  content: "󱜜";
}

.mdi-food-variant::before {
  content: "󰉜";
}

.mdi-food-variant-off::before {
  content: "󱏥";
}

.mdi-foot-print::before {
  content: "󰽒";
}

.mdi-football::before {
  content: "󰉝";
}

.mdi-football-australian::before {
  content: "󰉞";
}

.mdi-football-helmet::before {
  content: "󰉟";
}

.mdi-forest::before {
  content: "󱢗";
}

.mdi-forklift::before {
  content: "󰟉";
}

.mdi-form-dropdown::before {
  content: "󱐀";
}

.mdi-form-select::before {
  content: "󱐁";
}

.mdi-form-textarea::before {
  content: "󱂕";
}

.mdi-form-textbox::before {
  content: "󰘎";
}

.mdi-form-textbox-lock::before {
  content: "󱍝";
}

.mdi-form-textbox-password::before {
  content: "󰟵";
}

.mdi-format-align-bottom::before {
  content: "󰝓";
}

.mdi-format-align-center::before {
  content: "󰉠";
}

.mdi-format-align-justify::before {
  content: "󰉡";
}

.mdi-format-align-left::before {
  content: "󰉢";
}

.mdi-format-align-middle::before {
  content: "󰝔";
}

.mdi-format-align-right::before {
  content: "󰉣";
}

.mdi-format-align-top::before {
  content: "󰝕";
}

.mdi-format-annotation-minus::before {
  content: "󰪼";
}

.mdi-format-annotation-plus::before {
  content: "󰙆";
}

.mdi-format-bold::before {
  content: "󰉤";
}

.mdi-format-clear::before {
  content: "󰉥";
}

.mdi-format-color-fill::before {
  content: "󰉦";
}

.mdi-format-color-highlight::before {
  content: "󰸱";
}

.mdi-format-color-marker-cancel::before {
  content: "󱌓";
}

.mdi-format-color-text::before {
  content: "󰚞";
}

.mdi-format-columns::before {
  content: "󰣟";
}

.mdi-format-float-center::before {
  content: "󰉧";
}

.mdi-format-float-left::before {
  content: "󰉨";
}

.mdi-format-float-none::before {
  content: "󰉩";
}

.mdi-format-float-right::before {
  content: "󰉪";
}

.mdi-format-font::before {
  content: "󰛖";
}

.mdi-format-font-size-decrease::before {
  content: "󰧳";
}

.mdi-format-font-size-increase::before {
  content: "󰧴";
}

.mdi-format-header-1::before {
  content: "󰉫";
}

.mdi-format-header-2::before {
  content: "󰉬";
}

.mdi-format-header-3::before {
  content: "󰉭";
}

.mdi-format-header-4::before {
  content: "󰉮";
}

.mdi-format-header-5::before {
  content: "󰉯";
}

.mdi-format-header-6::before {
  content: "󰉰";
}

.mdi-format-header-decrease::before {
  content: "󰉱";
}

.mdi-format-header-equal::before {
  content: "󰉲";
}

.mdi-format-header-increase::before {
  content: "󰉳";
}

.mdi-format-header-pound::before {
  content: "󰉴";
}

.mdi-format-horizontal-align-center::before {
  content: "󰘞";
}

.mdi-format-horizontal-align-left::before {
  content: "󰘟";
}

.mdi-format-horizontal-align-right::before {
  content: "󰘠";
}

.mdi-format-indent-decrease::before {
  content: "󰉵";
}

.mdi-format-indent-increase::before {
  content: "󰉶";
}

.mdi-format-italic::before {
  content: "󰉷";
}

.mdi-format-letter-case::before {
  content: "󰬴";
}

.mdi-format-letter-case-lower::before {
  content: "󰬵";
}

.mdi-format-letter-case-upper::before {
  content: "󰬶";
}

.mdi-format-letter-ends-with::before {
  content: "󰾸";
}

.mdi-format-letter-matches::before {
  content: "󰾹";
}

.mdi-format-letter-spacing::before {
  content: "󱥖";
}

.mdi-format-letter-starts-with::before {
  content: "󰾺";
}

.mdi-format-line-spacing::before {
  content: "󰉸";
}

.mdi-format-line-style::before {
  content: "󰗈";
}

.mdi-format-line-weight::before {
  content: "󰗉";
}

.mdi-format-list-bulleted::before {
  content: "󰉹";
}

.mdi-format-list-bulleted-square::before {
  content: "󰷐";
}

.mdi-format-list-bulleted-triangle::before {
  content: "󰺲";
}

.mdi-format-list-bulleted-type::before {
  content: "󰉺";
}

.mdi-format-list-checkbox::before {
  content: "󰥪";
}

.mdi-format-list-checks::before {
  content: "󰝖";
}

.mdi-format-list-group::before {
  content: "󱡠";
}

.mdi-format-list-numbered::before {
  content: "󰉻";
}

.mdi-format-list-numbered-rtl::before {
  content: "󰴍";
}

.mdi-format-list-text::before {
  content: "󱉯";
}

.mdi-format-overline::before {
  content: "󰺳";
}

.mdi-format-page-break::before {
  content: "󰛗";
}

.mdi-format-page-split::before {
  content: "󱤗";
}

.mdi-format-paint::before {
  content: "󰉼";
}

.mdi-format-paragraph::before {
  content: "󰉽";
}

.mdi-format-pilcrow::before {
  content: "󰛘";
}

.mdi-format-quote-close::before {
  content: "󰉾";
}

.mdi-format-quote-close-outline::before {
  content: "󱆨";
}

.mdi-format-quote-open::before {
  content: "󰝗";
}

.mdi-format-quote-open-outline::before {
  content: "󱆧";
}

.mdi-format-rotate-90::before {
  content: "󰚪";
}

.mdi-format-section::before {
  content: "󰚟";
}

.mdi-format-size::before {
  content: "󰉿";
}

.mdi-format-strikethrough::before {
  content: "󰊀";
}

.mdi-format-strikethrough-variant::before {
  content: "󰊁";
}

.mdi-format-subscript::before {
  content: "󰊂";
}

.mdi-format-superscript::before {
  content: "󰊃";
}

.mdi-format-text::before {
  content: "󰊄";
}

.mdi-format-text-rotation-angle-down::before {
  content: "󰾻";
}

.mdi-format-text-rotation-angle-up::before {
  content: "󰾼";
}

.mdi-format-text-rotation-down::before {
  content: "󰵳";
}

.mdi-format-text-rotation-down-vertical::before {
  content: "󰾽";
}

.mdi-format-text-rotation-none::before {
  content: "󰵴";
}

.mdi-format-text-rotation-up::before {
  content: "󰾾";
}

.mdi-format-text-rotation-vertical::before {
  content: "󰾿";
}

.mdi-format-text-variant::before {
  content: "󰸲";
}

.mdi-format-text-variant-outline::before {
  content: "󱔏";
}

.mdi-format-text-wrapping-clip::before {
  content: "󰴎";
}

.mdi-format-text-wrapping-overflow::before {
  content: "󰴏";
}

.mdi-format-text-wrapping-wrap::before {
  content: "󰴐";
}

.mdi-format-textbox::before {
  content: "󰴑";
}

.mdi-format-textdirection-l-to-r::before {
  content: "󰊅";
}

.mdi-format-textdirection-r-to-l::before {
  content: "󰊆";
}

.mdi-format-title::before {
  content: "󰗴";
}

.mdi-format-underline::before {
  content: "󰊇";
}

.mdi-format-underline-wavy::before {
  content: "󱣩";
}

.mdi-format-vertical-align-bottom::before {
  content: "󰘡";
}

.mdi-format-vertical-align-center::before {
  content: "󰘢";
}

.mdi-format-vertical-align-top::before {
  content: "󰘣";
}

.mdi-format-wrap-inline::before {
  content: "󰊈";
}

.mdi-format-wrap-square::before {
  content: "󰊉";
}

.mdi-format-wrap-tight::before {
  content: "󰊊";
}

.mdi-format-wrap-top-bottom::before {
  content: "󰊋";
}

.mdi-forum::before {
  content: "󰊌";
}

.mdi-forum-outline::before {
  content: "󰠢";
}

.mdi-forward::before {
  content: "󰊍";
}

.mdi-forwardburger::before {
  content: "󰵵";
}

.mdi-fountain::before {
  content: "󰥫";
}

.mdi-fountain-pen::before {
  content: "󰴒";
}

.mdi-fountain-pen-tip::before {
  content: "󰴓";
}

.mdi-fraction-one-half::before {
  content: "󱦒";
}

.mdi-freebsd::before {
  content: "󰣠";
}

.mdi-french-fries::before {
  content: "󱥗";
}

.mdi-frequently-asked-questions::before {
  content: "󰺴";
}

.mdi-fridge::before {
  content: "󰊐";
}

.mdi-fridge-alert::before {
  content: "󱆱";
}

.mdi-fridge-alert-outline::before {
  content: "󱆲";
}

.mdi-fridge-bottom::before {
  content: "󰊒";
}

.mdi-fridge-industrial::before {
  content: "󱗮";
}

.mdi-fridge-industrial-alert::before {
  content: "󱗯";
}

.mdi-fridge-industrial-alert-outline::before {
  content: "󱗰";
}

.mdi-fridge-industrial-off::before {
  content: "󱗱";
}

.mdi-fridge-industrial-off-outline::before {
  content: "󱗲";
}

.mdi-fridge-industrial-outline::before {
  content: "󱗳";
}

.mdi-fridge-off::before {
  content: "󱆯";
}

.mdi-fridge-off-outline::before {
  content: "󱆰";
}

.mdi-fridge-outline::before {
  content: "󰊏";
}

.mdi-fridge-top::before {
  content: "󰊑";
}

.mdi-fridge-variant::before {
  content: "󱗴";
}

.mdi-fridge-variant-alert::before {
  content: "󱗵";
}

.mdi-fridge-variant-alert-outline::before {
  content: "󱗶";
}

.mdi-fridge-variant-off::before {
  content: "󱗷";
}

.mdi-fridge-variant-off-outline::before {
  content: "󱗸";
}

.mdi-fridge-variant-outline::before {
  content: "󱗹";
}

.mdi-fruit-cherries::before {
  content: "󱁂";
}

.mdi-fruit-cherries-off::before {
  content: "󱏸";
}

.mdi-fruit-citrus::before {
  content: "󱁃";
}

.mdi-fruit-citrus-off::before {
  content: "󱏹";
}

.mdi-fruit-grapes::before {
  content: "󱁄";
}

.mdi-fruit-grapes-outline::before {
  content: "󱁅";
}

.mdi-fruit-pineapple::before {
  content: "󱁆";
}

.mdi-fruit-watermelon::before {
  content: "󱁇";
}

.mdi-fuel::before {
  content: "󰟊";
}

.mdi-fuel-cell::before {
  content: "󱢵";
}

.mdi-fullscreen::before {
  content: "󰊓";
}

.mdi-fullscreen-exit::before {
  content: "󰊔";
}

.mdi-function::before {
  content: "󰊕";
}

.mdi-function-variant::before {
  content: "󰡱";
}

.mdi-furigana-horizontal::before {
  content: "󱂁";
}

.mdi-furigana-vertical::before {
  content: "󱂂";
}

.mdi-fuse::before {
  content: "󰲅";
}

.mdi-fuse-alert::before {
  content: "󱐭";
}

.mdi-fuse-blade::before {
  content: "󰲆";
}

.mdi-fuse-off::before {
  content: "󱐬";
}

.mdi-gamepad::before {
  content: "󰊖";
}

.mdi-gamepad-circle::before {
  content: "󰸳";
}

.mdi-gamepad-circle-down::before {
  content: "󰸴";
}

.mdi-gamepad-circle-left::before {
  content: "󰸵";
}

.mdi-gamepad-circle-outline::before {
  content: "󰸶";
}

.mdi-gamepad-circle-right::before {
  content: "󰸷";
}

.mdi-gamepad-circle-up::before {
  content: "󰸸";
}

.mdi-gamepad-down::before {
  content: "󰸹";
}

.mdi-gamepad-left::before {
  content: "󰸺";
}

.mdi-gamepad-outline::before {
  content: "󱤙";
}

.mdi-gamepad-right::before {
  content: "󰸻";
}

.mdi-gamepad-round::before {
  content: "󰸼";
}

.mdi-gamepad-round-down::before {
  content: "󰸽";
}

.mdi-gamepad-round-left::before {
  content: "󰸾";
}

.mdi-gamepad-round-outline::before {
  content: "󰸿";
}

.mdi-gamepad-round-right::before {
  content: "󰹀";
}

.mdi-gamepad-round-up::before {
  content: "󰹁";
}

.mdi-gamepad-square::before {
  content: "󰺵";
}

.mdi-gamepad-square-outline::before {
  content: "󰺶";
}

.mdi-gamepad-up::before {
  content: "󰹂";
}

.mdi-gamepad-variant::before {
  content: "󰊗";
}

.mdi-gamepad-variant-outline::before {
  content: "󰺷";
}

.mdi-gamma::before {
  content: "󱃮";
}

.mdi-gantry-crane::before {
  content: "󰷑";
}

.mdi-garage::before {
  content: "󰛙";
}

.mdi-garage-alert::before {
  content: "󰡲";
}

.mdi-garage-alert-variant::before {
  content: "󱋕";
}

.mdi-garage-lock::before {
  content: "󱟻";
}

.mdi-garage-open::before {
  content: "󰛚";
}

.mdi-garage-open-variant::before {
  content: "󱋔";
}

.mdi-garage-variant::before {
  content: "󱋓";
}

.mdi-garage-variant-lock::before {
  content: "󱟼";
}

.mdi-gas-cylinder::before {
  content: "󰙇";
}

.mdi-gas-station::before {
  content: "󰊘";
}

.mdi-gas-station-off::before {
  content: "󱐉";
}

.mdi-gas-station-off-outline::before {
  content: "󱐊";
}

.mdi-gas-station-outline::before {
  content: "󰺸";
}

.mdi-gate::before {
  content: "󰊙";
}

.mdi-gate-alert::before {
  content: "󱟸";
}

.mdi-gate-and::before {
  content: "󰣡";
}

.mdi-gate-arrow-left::before {
  content: "󱟷";
}

.mdi-gate-arrow-right::before {
  content: "󱅩";
}

.mdi-gate-nand::before {
  content: "󰣢";
}

.mdi-gate-nor::before {
  content: "󰣣";
}

.mdi-gate-not::before {
  content: "󰣤";
}

.mdi-gate-open::before {
  content: "󱅪";
}

.mdi-gate-or::before {
  content: "󰣥";
}

.mdi-gate-xnor::before {
  content: "󰣦";
}

.mdi-gate-xor::before {
  content: "󰣧";
}

.mdi-gatsby::before {
  content: "󰹃";
}

.mdi-gauge::before {
  content: "󰊚";
}

.mdi-gauge-empty::before {
  content: "󰡳";
}

.mdi-gauge-full::before {
  content: "󰡴";
}

.mdi-gauge-low::before {
  content: "󰡵";
}

.mdi-gavel::before {
  content: "󰊛";
}

.mdi-gender-female::before {
  content: "󰊜";
}

.mdi-gender-male::before {
  content: "󰊝";
}

.mdi-gender-male-female::before {
  content: "󰊞";
}

.mdi-gender-male-female-variant::before {
  content: "󱄿";
}

.mdi-gender-non-binary::before {
  content: "󱅀";
}

.mdi-gender-transgender::before {
  content: "󰊟";
}

.mdi-gentoo::before {
  content: "󰣨";
}

.mdi-gesture::before {
  content: "󰟋";
}

.mdi-gesture-double-tap::before {
  content: "󰜼";
}

.mdi-gesture-pinch::before {
  content: "󰪽";
}

.mdi-gesture-spread::before {
  content: "󰪾";
}

.mdi-gesture-swipe::before {
  content: "󰵶";
}

.mdi-gesture-swipe-down::before {
  content: "󰜽";
}

.mdi-gesture-swipe-horizontal::before {
  content: "󰪿";
}

.mdi-gesture-swipe-left::before {
  content: "󰜾";
}

.mdi-gesture-swipe-right::before {
  content: "󰜿";
}

.mdi-gesture-swipe-up::before {
  content: "󰝀";
}

.mdi-gesture-swipe-vertical::before {
  content: "󰫀";
}

.mdi-gesture-tap::before {
  content: "󰝁";
}

.mdi-gesture-tap-box::before {
  content: "󱊩";
}

.mdi-gesture-tap-button::before {
  content: "󱊨";
}

.mdi-gesture-tap-hold::before {
  content: "󰵷";
}

.mdi-gesture-two-double-tap::before {
  content: "󰝂";
}

.mdi-gesture-two-tap::before {
  content: "󰝃";
}

.mdi-ghost::before {
  content: "󰊠";
}

.mdi-ghost-off::before {
  content: "󰧵";
}

.mdi-ghost-off-outline::before {
  content: "󱙜";
}

.mdi-ghost-outline::before {
  content: "󱙝";
}

.mdi-gift::before {
  content: "󰹄";
}

.mdi-gift-off::before {
  content: "󱛯";
}

.mdi-gift-off-outline::before {
  content: "󱛰";
}

.mdi-gift-open::before {
  content: "󱛱";
}

.mdi-gift-open-outline::before {
  content: "󱛲";
}

.mdi-gift-outline::before {
  content: "󰊡";
}

.mdi-git::before {
  content: "󰊢";
}

.mdi-github::before {
  content: "󰊤";
}

.mdi-gitlab::before {
  content: "󰮠";
}

.mdi-glass-cocktail::before {
  content: "󰍖";
}

.mdi-glass-cocktail-off::before {
  content: "󱗦";
}

.mdi-glass-flute::before {
  content: "󰊥";
}

.mdi-glass-fragile::before {
  content: "󱡳";
}

.mdi-glass-mug::before {
  content: "󰊦";
}

.mdi-glass-mug-off::before {
  content: "󱗧";
}

.mdi-glass-mug-variant::before {
  content: "󱄖";
}

.mdi-glass-mug-variant-off::before {
  content: "󱗨";
}

.mdi-glass-pint-outline::before {
  content: "󱌍";
}

.mdi-glass-stange::before {
  content: "󰊧";
}

.mdi-glass-tulip::before {
  content: "󰊨";
}

.mdi-glass-wine::before {
  content: "󰡶";
}

.mdi-glasses::before {
  content: "󰊪";
}

.mdi-globe-light::before {
  content: "󱋗";
}

.mdi-globe-model::before {
  content: "󰣩";
}

.mdi-gmail::before {
  content: "󰊫";
}

.mdi-gnome::before {
  content: "󰊬";
}

.mdi-go-kart::before {
  content: "󰵹";
}

.mdi-go-kart-track::before {
  content: "󰵺";
}

.mdi-gog::before {
  content: "󰮡";
}

.mdi-gold::before {
  content: "󱉏";
}

.mdi-golf::before {
  content: "󰠣";
}

.mdi-golf-cart::before {
  content: "󱆤";
}

.mdi-golf-tee::before {
  content: "󱂃";
}

.mdi-gondola::before {
  content: "󰚆";
}

.mdi-goodreads::before {
  content: "󰵻";
}

.mdi-google::before {
  content: "󰊭";
}

.mdi-google-ads::before {
  content: "󰲇";
}

.mdi-google-analytics::before {
  content: "󰟌";
}

.mdi-google-assistant::before {
  content: "󰟍";
}

.mdi-google-cardboard::before {
  content: "󰊮";
}

.mdi-google-chrome::before {
  content: "󰊯";
}

.mdi-google-circles::before {
  content: "󰊰";
}

.mdi-google-circles-communities::before {
  content: "󰊱";
}

.mdi-google-circles-extended::before {
  content: "󰊲";
}

.mdi-google-circles-group::before {
  content: "󰊳";
}

.mdi-google-classroom::before {
  content: "󰋀";
}

.mdi-google-cloud::before {
  content: "󱇶";
}

.mdi-google-controller::before {
  content: "󰊴";
}

.mdi-google-controller-off::before {
  content: "󰊵";
}

.mdi-google-downasaur::before {
  content: "󱍢";
}

.mdi-google-drive::before {
  content: "󰊶";
}

.mdi-google-earth::before {
  content: "󰊷";
}

.mdi-google-fit::before {
  content: "󰥬";
}

.mdi-google-glass::before {
  content: "󰊸";
}

.mdi-google-hangouts::before {
  content: "󰋉";
}

.mdi-google-home::before {
  content: "󰠤";
}

.mdi-google-keep::before {
  content: "󰛜";
}

.mdi-google-lens::before {
  content: "󰧶";
}

.mdi-google-maps::before {
  content: "󰗵";
}

.mdi-google-my-business::before {
  content: "󱁈";
}

.mdi-google-nearby::before {
  content: "󰊹";
}

.mdi-google-play::before {
  content: "󰊼";
}

.mdi-google-plus::before {
  content: "󰊽";
}

.mdi-google-podcast::before {
  content: "󰺹";
}

.mdi-google-spreadsheet::before {
  content: "󰧷";
}

.mdi-google-street-view::before {
  content: "󰲈";
}

.mdi-google-translate::before {
  content: "󰊿";
}

.mdi-gradient-horizontal::before {
  content: "󱝊";
}

.mdi-gradient-vertical::before {
  content: "󰚠";
}

.mdi-grain::before {
  content: "󰵼";
}

.mdi-graph::before {
  content: "󱁉";
}

.mdi-graph-outline::before {
  content: "󱁊";
}

.mdi-graphql::before {
  content: "󰡷";
}

.mdi-grass::before {
  content: "󱔐";
}

.mdi-grave-stone::before {
  content: "󰮢";
}

.mdi-grease-pencil::before {
  content: "󰙈";
}

.mdi-greater-than::before {
  content: "󰥭";
}

.mdi-greater-than-or-equal::before {
  content: "󰥮";
}

.mdi-greenhouse::before {
  content: "󰀭";
}

.mdi-grid::before {
  content: "󰋁";
}

.mdi-grid-large::before {
  content: "󰝘";
}

.mdi-grid-off::before {
  content: "󰋂";
}

.mdi-grill::before {
  content: "󰹅";
}

.mdi-grill-outline::before {
  content: "󱆊";
}

.mdi-group::before {
  content: "󰋃";
}

.mdi-guitar-acoustic::before {
  content: "󰝱";
}

.mdi-guitar-electric::before {
  content: "󰋄";
}

.mdi-guitar-pick::before {
  content: "󰋅";
}

.mdi-guitar-pick-outline::before {
  content: "󰋆";
}

.mdi-guy-fawkes-mask::before {
  content: "󰠥";
}

.mdi-hail::before {
  content: "󰫁";
}

.mdi-hair-dryer::before {
  content: "󱃯";
}

.mdi-hair-dryer-outline::before {
  content: "󱃰";
}

.mdi-halloween::before {
  content: "󰮣";
}

.mdi-hamburger::before {
  content: "󰚅";
}

.mdi-hamburger-check::before {
  content: "󱝶";
}

.mdi-hamburger-minus::before {
  content: "󱝷";
}

.mdi-hamburger-off::before {
  content: "󱝸";
}

.mdi-hamburger-plus::before {
  content: "󱝹";
}

.mdi-hamburger-remove::before {
  content: "󱝺";
}

.mdi-hammer::before {
  content: "󰣪";
}

.mdi-hammer-screwdriver::before {
  content: "󱌢";
}

.mdi-hammer-sickle::before {
  content: "󱢇";
}

.mdi-hammer-wrench::before {
  content: "󱌣";
}

.mdi-hand-back-left::before {
  content: "󰹆";
}

.mdi-hand-back-left-off::before {
  content: "󱠰";
}

.mdi-hand-back-left-off-outline::before {
  content: "󱠲";
}

.mdi-hand-back-left-outline::before {
  content: "󱠬";
}

.mdi-hand-back-right::before {
  content: "󰹇";
}

.mdi-hand-back-right-off::before {
  content: "󱠱";
}

.mdi-hand-back-right-off-outline::before {
  content: "󱠳";
}

.mdi-hand-back-right-outline::before {
  content: "󱠭";
}

.mdi-hand-clap::before {
  content: "󱥋";
}

.mdi-hand-coin::before {
  content: "󱢏";
}

.mdi-hand-coin-outline::before {
  content: "󱢐";
}

.mdi-hand-extended::before {
  content: "󱢶";
}

.mdi-hand-extended-outline::before {
  content: "󱢷";
}

.mdi-hand-front-left::before {
  content: "󱠫";
}

.mdi-hand-front-left-outline::before {
  content: "󱠮";
}

.mdi-hand-front-right::before {
  content: "󰩏";
}

.mdi-hand-front-right-outline::before {
  content: "󱠯";
}

.mdi-hand-heart::before {
  content: "󱃱";
}

.mdi-hand-heart-outline::before {
  content: "󱕾";
}

.mdi-hand-okay::before {
  content: "󰩐";
}

.mdi-hand-peace::before {
  content: "󰩑";
}

.mdi-hand-peace-variant::before {
  content: "󰩒";
}

.mdi-hand-pointing-down::before {
  content: "󰩓";
}

.mdi-hand-pointing-left::before {
  content: "󰩔";
}

.mdi-hand-pointing-right::before {
  content: "󰋇";
}

.mdi-hand-pointing-up::before {
  content: "󰩕";
}

.mdi-hand-saw::before {
  content: "󰹈";
}

.mdi-hand-wash::before {
  content: "󱕿";
}

.mdi-hand-wash-outline::before {
  content: "󱖀";
}

.mdi-hand-water::before {
  content: "󱎟";
}

.mdi-hand-wave::before {
  content: "󱠡";
}

.mdi-hand-wave-outline::before {
  content: "󱠢";
}

.mdi-handball::before {
  content: "󰽓";
}

.mdi-handcuffs::before {
  content: "󱄾";
}

.mdi-hands-pray::before {
  content: "󰕹";
}

.mdi-handshake::before {
  content: "󱈘";
}

.mdi-handshake-outline::before {
  content: "󱖡";
}

.mdi-hanger::before {
  content: "󰋈";
}

.mdi-hard-hat::before {
  content: "󰥯";
}

.mdi-harddisk::before {
  content: "󰋊";
}

.mdi-harddisk-plus::before {
  content: "󱁋";
}

.mdi-harddisk-remove::before {
  content: "󱁌";
}

.mdi-hat-fedora::before {
  content: "󰮤";
}

.mdi-hazard-lights::before {
  content: "󰲉";
}

.mdi-hdr::before {
  content: "󰵽";
}

.mdi-hdr-off::before {
  content: "󰵾";
}

.mdi-head::before {
  content: "󱍞";
}

.mdi-head-alert::before {
  content: "󱌸";
}

.mdi-head-alert-outline::before {
  content: "󱌹";
}

.mdi-head-check::before {
  content: "󱌺";
}

.mdi-head-check-outline::before {
  content: "󱌻";
}

.mdi-head-cog::before {
  content: "󱌼";
}

.mdi-head-cog-outline::before {
  content: "󱌽";
}

.mdi-head-dots-horizontal::before {
  content: "󱌾";
}

.mdi-head-dots-horizontal-outline::before {
  content: "󱌿";
}

.mdi-head-flash::before {
  content: "󱍀";
}

.mdi-head-flash-outline::before {
  content: "󱍁";
}

.mdi-head-heart::before {
  content: "󱍂";
}

.mdi-head-heart-outline::before {
  content: "󱍃";
}

.mdi-head-lightbulb::before {
  content: "󱍄";
}

.mdi-head-lightbulb-outline::before {
  content: "󱍅";
}

.mdi-head-minus::before {
  content: "󱍆";
}

.mdi-head-minus-outline::before {
  content: "󱍇";
}

.mdi-head-outline::before {
  content: "󱍟";
}

.mdi-head-plus::before {
  content: "󱍈";
}

.mdi-head-plus-outline::before {
  content: "󱍉";
}

.mdi-head-question::before {
  content: "󱍊";
}

.mdi-head-question-outline::before {
  content: "󱍋";
}

.mdi-head-remove::before {
  content: "󱍌";
}

.mdi-head-remove-outline::before {
  content: "󱍍";
}

.mdi-head-snowflake::before {
  content: "󱍎";
}

.mdi-head-snowflake-outline::before {
  content: "󱍏";
}

.mdi-head-sync::before {
  content: "󱍐";
}

.mdi-head-sync-outline::before {
  content: "󱍑";
}

.mdi-headphones::before {
  content: "󰋋";
}

.mdi-headphones-bluetooth::before {
  content: "󰥰";
}

.mdi-headphones-box::before {
  content: "󰋌";
}

.mdi-headphones-off::before {
  content: "󰟎";
}

.mdi-headphones-settings::before {
  content: "󰋍";
}

.mdi-headset::before {
  content: "󰋎";
}

.mdi-headset-dock::before {
  content: "󰋏";
}

.mdi-headset-off::before {
  content: "󰋐";
}

.mdi-heart::before {
  content: "󰋑";
}

.mdi-heart-box::before {
  content: "󰋒";
}

.mdi-heart-box-outline::before {
  content: "󰋓";
}

.mdi-heart-broken::before {
  content: "󰋔";
}

.mdi-heart-broken-outline::before {
  content: "󰴔";
}

.mdi-heart-circle::before {
  content: "󰥱";
}

.mdi-heart-circle-outline::before {
  content: "󰥲";
}

.mdi-heart-cog::before {
  content: "󱙣";
}

.mdi-heart-cog-outline::before {
  content: "󱙤";
}

.mdi-heart-flash::before {
  content: "󰻹";
}

.mdi-heart-half::before {
  content: "󰛟";
}

.mdi-heart-half-full::before {
  content: "󰛞";
}

.mdi-heart-half-outline::before {
  content: "󰛠";
}

.mdi-heart-minus::before {
  content: "󱐯";
}

.mdi-heart-minus-outline::before {
  content: "󱐲";
}

.mdi-heart-multiple::before {
  content: "󰩖";
}

.mdi-heart-multiple-outline::before {
  content: "󰩗";
}

.mdi-heart-off::before {
  content: "󰝙";
}

.mdi-heart-off-outline::before {
  content: "󱐴";
}

.mdi-heart-outline::before {
  content: "󰋕";
}

.mdi-heart-plus::before {
  content: "󱐮";
}

.mdi-heart-plus-outline::before {
  content: "󱐱";
}

.mdi-heart-pulse::before {
  content: "󰗶";
}

.mdi-heart-remove::before {
  content: "󱐰";
}

.mdi-heart-remove-outline::before {
  content: "󱐳";
}

.mdi-heart-settings::before {
  content: "󱙥";
}

.mdi-heart-settings-outline::before {
  content: "󱙦";
}

.mdi-helicopter::before {
  content: "󰫂";
}

.mdi-help::before {
  content: "󰋖";
}

.mdi-help-box::before {
  content: "󰞋";
}

.mdi-help-circle::before {
  content: "󰋗";
}

.mdi-help-circle-outline::before {
  content: "󰘥";
}

.mdi-help-network::before {
  content: "󰛵";
}

.mdi-help-network-outline::before {
  content: "󰲊";
}

.mdi-help-rhombus::before {
  content: "󰮥";
}

.mdi-help-rhombus-outline::before {
  content: "󰮦";
}

.mdi-hexadecimal::before {
  content: "󱊧";
}

.mdi-hexagon::before {
  content: "󰋘";
}

.mdi-hexagon-multiple::before {
  content: "󰛡";
}

.mdi-hexagon-multiple-outline::before {
  content: "󱃲";
}

.mdi-hexagon-outline::before {
  content: "󰋙";
}

.mdi-hexagon-slice-1::before {
  content: "󰫃";
}

.mdi-hexagon-slice-2::before {
  content: "󰫄";
}

.mdi-hexagon-slice-3::before {
  content: "󰫅";
}

.mdi-hexagon-slice-4::before {
  content: "󰫆";
}

.mdi-hexagon-slice-5::before {
  content: "󰫇";
}

.mdi-hexagon-slice-6::before {
  content: "󰫈";
}

.mdi-hexagram::before {
  content: "󰫉";
}

.mdi-hexagram-outline::before {
  content: "󰫊";
}

.mdi-high-definition::before {
  content: "󰟏";
}

.mdi-high-definition-box::before {
  content: "󰡸";
}

.mdi-highway::before {
  content: "󰗷";
}

.mdi-hiking::before {
  content: "󰵿";
}

.mdi-history::before {
  content: "󰋚";
}

.mdi-hockey-puck::before {
  content: "󰡹";
}

.mdi-hockey-sticks::before {
  content: "󰡺";
}

.mdi-hololens::before {
  content: "󰋛";
}

.mdi-home::before {
  content: "󰋜";
}

.mdi-home-account::before {
  content: "󰠦";
}

.mdi-home-alert::before {
  content: "󰡻";
}

.mdi-home-alert-outline::before {
  content: "󱗐";
}

.mdi-home-analytics::before {
  content: "󰺺";
}

.mdi-home-assistant::before {
  content: "󰟐";
}

.mdi-home-automation::before {
  content: "󰟑";
}

.mdi-home-battery::before {
  content: "󱤁";
}

.mdi-home-battery-outline::before {
  content: "󱤂";
}

.mdi-home-circle::before {
  content: "󰟒";
}

.mdi-home-circle-outline::before {
  content: "󱁍";
}

.mdi-home-city::before {
  content: "󰴕";
}

.mdi-home-city-outline::before {
  content: "󰴖";
}

.mdi-home-edit::before {
  content: "󱅙";
}

.mdi-home-edit-outline::before {
  content: "󱅚";
}

.mdi-home-export-outline::before {
  content: "󰾛";
}

.mdi-home-flood::before {
  content: "󰻺";
}

.mdi-home-floor-0::before {
  content: "󰷒";
}

.mdi-home-floor-1::before {
  content: "󰶀";
}

.mdi-home-floor-2::before {
  content: "󰶁";
}

.mdi-home-floor-3::before {
  content: "󰶂";
}

.mdi-home-floor-a::before {
  content: "󰶃";
}

.mdi-home-floor-b::before {
  content: "󰶄";
}

.mdi-home-floor-g::before {
  content: "󰶅";
}

.mdi-home-floor-l::before {
  content: "󰶆";
}

.mdi-home-floor-negative-1::before {
  content: "󰷓";
}

.mdi-home-group::before {
  content: "󰷔";
}

.mdi-home-group-minus::before {
  content: "󱧁";
}

.mdi-home-group-plus::before {
  content: "󱧀";
}

.mdi-home-group-remove::before {
  content: "󱧂";
}

.mdi-home-heart::before {
  content: "󰠧";
}

.mdi-home-import-outline::before {
  content: "󰾜";
}

.mdi-home-lightbulb::before {
  content: "󱉑";
}

.mdi-home-lightbulb-outline::before {
  content: "󱉒";
}

.mdi-home-lightning-bolt::before {
  content: "󱤃";
}

.mdi-home-lightning-bolt-outline::before {
  content: "󱤄";
}

.mdi-home-lock::before {
  content: "󰣫";
}

.mdi-home-lock-open::before {
  content: "󰣬";
}

.mdi-home-map-marker::before {
  content: "󰗸";
}

.mdi-home-minus::before {
  content: "󰥴";
}

.mdi-home-minus-outline::before {
  content: "󱏕";
}

.mdi-home-modern::before {
  content: "󰋝";
}

.mdi-home-outline::before {
  content: "󰚡";
}

.mdi-home-plus::before {
  content: "󰥵";
}

.mdi-home-plus-outline::before {
  content: "󱏖";
}

.mdi-home-remove::before {
  content: "󱉇";
}

.mdi-home-remove-outline::before {
  content: "󱏗";
}

.mdi-home-roof::before {
  content: "󱄫";
}

.mdi-home-search::before {
  content: "󱎰";
}

.mdi-home-search-outline::before {
  content: "󱎱";
}

.mdi-home-switch::before {
  content: "󱞔";
}

.mdi-home-switch-outline::before {
  content: "󱞕";
}

.mdi-home-thermometer::before {
  content: "󰽔";
}

.mdi-home-thermometer-outline::before {
  content: "󰽕";
}

.mdi-home-variant::before {
  content: "󰋞";
}

.mdi-home-variant-outline::before {
  content: "󰮧";
}

.mdi-hook::before {
  content: "󰛢";
}

.mdi-hook-off::before {
  content: "󰛣";
}

.mdi-hoop-house::before {
  content: "󰹖";
}

.mdi-hops::before {
  content: "󰋟";
}

.mdi-horizontal-rotate-clockwise::before {
  content: "󱃳";
}

.mdi-horizontal-rotate-counterclockwise::before {
  content: "󱃴";
}

.mdi-horse::before {
  content: "󱖿";
}

.mdi-horse-human::before {
  content: "󱗀";
}

.mdi-horse-variant::before {
  content: "󱗁";
}

.mdi-horse-variant-fast::before {
  content: "󱡮";
}

.mdi-horseshoe::before {
  content: "󰩘";
}

.mdi-hospital::before {
  content: "󰿶";
}

.mdi-hospital-box::before {
  content: "󰋠";
}

.mdi-hospital-box-outline::before {
  content: "󰿷";
}

.mdi-hospital-building::before {
  content: "󰋡";
}

.mdi-hospital-marker::before {
  content: "󰋢";
}

.mdi-hot-tub::before {
  content: "󰠨";
}

.mdi-hours-24::before {
  content: "󱑸";
}

.mdi-hubspot::before {
  content: "󰴗";
}

.mdi-hulu::before {
  content: "󰠩";
}

.mdi-human::before {
  content: "󰋦";
}

.mdi-human-baby-changing-table::before {
  content: "󱎋";
}

.mdi-human-cane::before {
  content: "󱖁";
}

.mdi-human-capacity-decrease::before {
  content: "󱖛";
}

.mdi-human-capacity-increase::before {
  content: "󱖜";
}

.mdi-human-child::before {
  content: "󰋧";
}

.mdi-human-dolly::before {
  content: "󱦀";
}

.mdi-human-edit::before {
  content: "󱓨";
}

.mdi-human-female::before {
  content: "󰙉";
}

.mdi-human-female-boy::before {
  content: "󰩙";
}

.mdi-human-female-dance::before {
  content: "󱗉";
}

.mdi-human-female-female::before {
  content: "󰩚";
}

.mdi-human-female-girl::before {
  content: "󰩛";
}

.mdi-human-greeting::before {
  content: "󱟄";
}

.mdi-human-greeting-proximity::before {
  content: "󱖝";
}

.mdi-human-greeting-variant::before {
  content: "󰙊";
}

.mdi-human-handsdown::before {
  content: "󰙋";
}

.mdi-human-handsup::before {
  content: "󰙌";
}

.mdi-human-male::before {
  content: "󰙍";
}

.mdi-human-male-board::before {
  content: "󰢐";
}

.mdi-human-male-board-poll::before {
  content: "󰡆";
}

.mdi-human-male-boy::before {
  content: "󰩜";
}

.mdi-human-male-child::before {
  content: "󱎌";
}

.mdi-human-male-female::before {
  content: "󰋨";
}

.mdi-human-male-female-child::before {
  content: "󱠣";
}

.mdi-human-male-girl::before {
  content: "󰩝";
}

.mdi-human-male-height::before {
  content: "󰻻";
}

.mdi-human-male-height-variant::before {
  content: "󰻼";
}

.mdi-human-male-male::before {
  content: "󰩞";
}

.mdi-human-non-binary::before {
  content: "󱡈";
}

.mdi-human-pregnant::before {
  content: "󰗏";
}

.mdi-human-queue::before {
  content: "󱕱";
}

.mdi-human-scooter::before {
  content: "󱇩";
}

.mdi-human-wheelchair::before {
  content: "󱎍";
}

.mdi-human-white-cane::before {
  content: "󱦁";
}

.mdi-humble-bundle::before {
  content: "󰝄";
}

.mdi-hvac::before {
  content: "󱍒";
}

.mdi-hvac-off::before {
  content: "󱖞";
}

.mdi-hydraulic-oil-level::before {
  content: "󱌤";
}

.mdi-hydraulic-oil-temperature::before {
  content: "󱌥";
}

.mdi-hydro-power::before {
  content: "󱋥";
}

.mdi-hydrogen-station::before {
  content: "󱢔";
}

.mdi-ice-cream::before {
  content: "󰠪";
}

.mdi-ice-cream-off::before {
  content: "󰹒";
}

.mdi-ice-pop::before {
  content: "󰻽";
}

.mdi-id-card::before {
  content: "󰿀";
}

.mdi-identifier::before {
  content: "󰻾";
}

.mdi-ideogram-cjk::before {
  content: "󱌱";
}

.mdi-ideogram-cjk-variant::before {
  content: "󱌲";
}

.mdi-image::before {
  content: "󰋩";
}

.mdi-image-album::before {
  content: "󰋪";
}

.mdi-image-area::before {
  content: "󰋫";
}

.mdi-image-area-close::before {
  content: "󰋬";
}

.mdi-image-auto-adjust::before {
  content: "󰿁";
}

.mdi-image-broken::before {
  content: "󰋭";
}

.mdi-image-broken-variant::before {
  content: "󰋮";
}

.mdi-image-edit::before {
  content: "󱇣";
}

.mdi-image-edit-outline::before {
  content: "󱇤";
}

.mdi-image-filter-black-white::before {
  content: "󰋰";
}

.mdi-image-filter-center-focus::before {
  content: "󰋱";
}

.mdi-image-filter-center-focus-strong::before {
  content: "󰻿";
}

.mdi-image-filter-center-focus-strong-outline::before {
  content: "󰼀";
}

.mdi-image-filter-center-focus-weak::before {
  content: "󰋲";
}

.mdi-image-filter-drama::before {
  content: "󰋳";
}

.mdi-image-filter-frames::before {
  content: "󰋴";
}

.mdi-image-filter-hdr::before {
  content: "󰋵";
}

.mdi-image-filter-none::before {
  content: "󰋶";
}

.mdi-image-filter-tilt-shift::before {
  content: "󰋷";
}

.mdi-image-filter-vintage::before {
  content: "󰋸";
}

.mdi-image-frame::before {
  content: "󰹉";
}

.mdi-image-marker::before {
  content: "󱝻";
}

.mdi-image-marker-outline::before {
  content: "󱝼";
}

.mdi-image-minus::before {
  content: "󱐙";
}

.mdi-image-move::before {
  content: "󰧸";
}

.mdi-image-multiple::before {
  content: "󰋹";
}

.mdi-image-multiple-outline::before {
  content: "󰋯";
}

.mdi-image-off::before {
  content: "󰠫";
}

.mdi-image-off-outline::before {
  content: "󱇑";
}

.mdi-image-outline::before {
  content: "󰥶";
}

.mdi-image-plus::before {
  content: "󰡼";
}

.mdi-image-remove::before {
  content: "󱐘";
}

.mdi-image-search::before {
  content: "󰥷";
}

.mdi-image-search-outline::before {
  content: "󰥸";
}

.mdi-image-size-select-actual::before {
  content: "󰲍";
}

.mdi-image-size-select-large::before {
  content: "󰲎";
}

.mdi-image-size-select-small::before {
  content: "󰲏";
}

.mdi-image-text::before {
  content: "󱘍";
}

.mdi-import::before {
  content: "󰋺";
}

.mdi-inbox::before {
  content: "󰚇";
}

.mdi-inbox-arrow-down::before {
  content: "󰋻";
}

.mdi-inbox-arrow-down-outline::before {
  content: "󱉰";
}

.mdi-inbox-arrow-up::before {
  content: "󰏑";
}

.mdi-inbox-arrow-up-outline::before {
  content: "󱉱";
}

.mdi-inbox-full::before {
  content: "󱉲";
}

.mdi-inbox-full-outline::before {
  content: "󱉳";
}

.mdi-inbox-multiple::before {
  content: "󰢰";
}

.mdi-inbox-multiple-outline::before {
  content: "󰮨";
}

.mdi-inbox-outline::before {
  content: "󱉴";
}

.mdi-inbox-remove::before {
  content: "󱖟";
}

.mdi-inbox-remove-outline::before {
  content: "󱖠";
}

.mdi-incognito::before {
  content: "󰗹";
}

.mdi-incognito-circle::before {
  content: "󱐡";
}

.mdi-incognito-circle-off::before {
  content: "󱐢";
}

.mdi-incognito-off::before {
  content: "󰁵";
}

.mdi-induction::before {
  content: "󱡌";
}

.mdi-infinity::before {
  content: "󰛤";
}

.mdi-information::before {
  content: "󰋼";
}

.mdi-information-off::before {
  content: "󱞌";
}

.mdi-information-off-outline::before {
  content: "󱞍";
}

.mdi-information-outline::before {
  content: "󰋽";
}

.mdi-information-variant::before {
  content: "󰙎";
}

.mdi-instagram::before {
  content: "󰋾";
}

.mdi-instrument-triangle::before {
  content: "󱁎";
}

.mdi-integrated-circuit-chip::before {
  content: "󱤓";
}

.mdi-invert-colors::before {
  content: "󰌁";
}

.mdi-invert-colors-off::before {
  content: "󰹊";
}

.mdi-iobroker::before {
  content: "󱋨";
}

.mdi-ip::before {
  content: "󰩟";
}

.mdi-ip-network::before {
  content: "󰩠";
}

.mdi-ip-network-outline::before {
  content: "󰲐";
}

.mdi-ip-outline::before {
  content: "󱦂";
}

.mdi-ipod::before {
  content: "󰲑";
}

.mdi-iron::before {
  content: "󱠤";
}

.mdi-iron-board::before {
  content: "󱠸";
}

.mdi-iron-outline::before {
  content: "󱠥";
}

.mdi-island::before {
  content: "󱁏";
}

.mdi-iv-bag::before {
  content: "󱂹";
}

.mdi-jabber::before {
  content: "󰷕";
}

.mdi-jeepney::before {
  content: "󰌂";
}

.mdi-jellyfish::before {
  content: "󰼁";
}

.mdi-jellyfish-outline::before {
  content: "󰼂";
}

.mdi-jira::before {
  content: "󰌃";
}

.mdi-jquery::before {
  content: "󰡽";
}

.mdi-jsfiddle::before {
  content: "󰌄";
}

.mdi-jump-rope::before {
  content: "󱋿";
}

.mdi-kabaddi::before {
  content: "󰶇";
}

.mdi-kangaroo::before {
  content: "󱕘";
}

.mdi-karate::before {
  content: "󰠬";
}

.mdi-kayaking::before {
  content: "󰢯";
}

.mdi-keg::before {
  content: "󰌅";
}

.mdi-kettle::before {
  content: "󰗺";
}

.mdi-kettle-alert::before {
  content: "󱌗";
}

.mdi-kettle-alert-outline::before {
  content: "󱌘";
}

.mdi-kettle-off::before {
  content: "󱌛";
}

.mdi-kettle-off-outline::before {
  content: "󱌜";
}

.mdi-kettle-outline::before {
  content: "󰽖";
}

.mdi-kettle-pour-over::before {
  content: "󱜼";
}

.mdi-kettle-steam::before {
  content: "󱌙";
}

.mdi-kettle-steam-outline::before {
  content: "󱌚";
}

.mdi-kettlebell::before {
  content: "󱌀";
}

.mdi-key::before {
  content: "󰌆";
}

.mdi-key-alert::before {
  content: "󱦃";
}

.mdi-key-alert-outline::before {
  content: "󱦄";
}

.mdi-key-arrow-right::before {
  content: "󱌒";
}

.mdi-key-chain::before {
  content: "󱕴";
}

.mdi-key-chain-variant::before {
  content: "󱕵";
}

.mdi-key-change::before {
  content: "󰌇";
}

.mdi-key-link::before {
  content: "󱆟";
}

.mdi-key-minus::before {
  content: "󰌈";
}

.mdi-key-outline::before {
  content: "󰷖";
}

.mdi-key-plus::before {
  content: "󰌉";
}

.mdi-key-remove::before {
  content: "󰌊";
}

.mdi-key-star::before {
  content: "󱆞";
}

.mdi-key-variant::before {
  content: "󰌋";
}

.mdi-key-wireless::before {
  content: "󰿂";
}

.mdi-keyboard::before {
  content: "󰌌";
}

.mdi-keyboard-backspace::before {
  content: "󰌍";
}

.mdi-keyboard-caps::before {
  content: "󰌎";
}

.mdi-keyboard-close::before {
  content: "󰌏";
}

.mdi-keyboard-esc::before {
  content: "󱊷";
}

.mdi-keyboard-f1::before {
  content: "󱊫";
}

.mdi-keyboard-f10::before {
  content: "󱊴";
}

.mdi-keyboard-f11::before {
  content: "󱊵";
}

.mdi-keyboard-f12::before {
  content: "󱊶";
}

.mdi-keyboard-f2::before {
  content: "󱊬";
}

.mdi-keyboard-f3::before {
  content: "󱊭";
}

.mdi-keyboard-f4::before {
  content: "󱊮";
}

.mdi-keyboard-f5::before {
  content: "󱊯";
}

.mdi-keyboard-f6::before {
  content: "󱊰";
}

.mdi-keyboard-f7::before {
  content: "󱊱";
}

.mdi-keyboard-f8::before {
  content: "󱊲";
}

.mdi-keyboard-f9::before {
  content: "󱊳";
}

.mdi-keyboard-off::before {
  content: "󰌐";
}

.mdi-keyboard-off-outline::before {
  content: "󰹋";
}

.mdi-keyboard-outline::before {
  content: "󰥻";
}

.mdi-keyboard-return::before {
  content: "󰌑";
}

.mdi-keyboard-settings::before {
  content: "󰧹";
}

.mdi-keyboard-settings-outline::before {
  content: "󰧺";
}

.mdi-keyboard-space::before {
  content: "󱁐";
}

.mdi-keyboard-tab::before {
  content: "󰌒";
}

.mdi-keyboard-tab-reverse::before {
  content: "󰌥";
}

.mdi-keyboard-variant::before {
  content: "󰌓";
}

.mdi-khanda::before {
  content: "󱃽";
}

.mdi-kickstarter::before {
  content: "󰝅";
}

.mdi-kite::before {
  content: "󱦅";
}

.mdi-kite-outline::before {
  content: "󱦆";
}

.mdi-kitesurfing::before {
  content: "󱝄";
}

.mdi-klingon::before {
  content: "󱍛";
}

.mdi-knife::before {
  content: "󰧻";
}

.mdi-knife-military::before {
  content: "󰧼";
}

.mdi-koala::before {
  content: "󱜿";
}

.mdi-kodi::before {
  content: "󰌔";
}

.mdi-kubernetes::before {
  content: "󱃾";
}

.mdi-label::before {
  content: "󰌕";
}

.mdi-label-multiple::before {
  content: "󱍵";
}

.mdi-label-multiple-outline::before {
  content: "󱍶";
}

.mdi-label-off::before {
  content: "󰫋";
}

.mdi-label-off-outline::before {
  content: "󰫌";
}

.mdi-label-outline::before {
  content: "󰌖";
}

.mdi-label-percent::before {
  content: "󱋪";
}

.mdi-label-percent-outline::before {
  content: "󱋫";
}

.mdi-label-variant::before {
  content: "󰫍";
}

.mdi-label-variant-outline::before {
  content: "󰫎";
}

.mdi-ladder::before {
  content: "󱖢";
}

.mdi-ladybug::before {
  content: "󰠭";
}

.mdi-lambda::before {
  content: "󰘧";
}

.mdi-lamp::before {
  content: "󰚵";
}

.mdi-lamp-outline::before {
  content: "󱟐";
}

.mdi-lamps::before {
  content: "󱕶";
}

.mdi-lamps-outline::before {
  content: "󱟑";
}

.mdi-lan::before {
  content: "󰌗";
}

.mdi-lan-check::before {
  content: "󱊪";
}

.mdi-lan-connect::before {
  content: "󰌘";
}

.mdi-lan-disconnect::before {
  content: "󰌙";
}

.mdi-lan-pending::before {
  content: "󰌚";
}

.mdi-language-c::before {
  content: "󰙱";
}

.mdi-language-cpp::before {
  content: "󰙲";
}

.mdi-language-csharp::before {
  content: "󰌛";
}

.mdi-language-css3::before {
  content: "󰌜";
}

.mdi-language-fortran::before {
  content: "󱈚";
}

.mdi-language-go::before {
  content: "󰟓";
}

.mdi-language-haskell::before {
  content: "󰲒";
}

.mdi-language-html5::before {
  content: "󰌝";
}

.mdi-language-java::before {
  content: "󰬷";
}

.mdi-language-javascript::before {
  content: "󰌞";
}

.mdi-language-kotlin::before {
  content: "󱈙";
}

.mdi-language-lua::before {
  content: "󰢱";
}

.mdi-language-markdown::before {
  content: "󰍔";
}

.mdi-language-markdown-outline::before {
  content: "󰽛";
}

.mdi-language-php::before {
  content: "󰌟";
}

.mdi-language-python::before {
  content: "󰌠";
}

.mdi-language-r::before {
  content: "󰟔";
}

.mdi-language-ruby::before {
  content: "󰴭";
}

.mdi-language-ruby-on-rails::before {
  content: "󰫏";
}

.mdi-language-rust::before {
  content: "󱘗";
}

.mdi-language-swift::before {
  content: "󰛥";
}

.mdi-language-typescript::before {
  content: "󰛦";
}

.mdi-language-xaml::before {
  content: "󰙳";
}

.mdi-laptop::before {
  content: "󰌢";
}

.mdi-laptop-off::before {
  content: "󰛧";
}

.mdi-laravel::before {
  content: "󰫐";
}

.mdi-laser-pointer::before {
  content: "󱒄";
}

.mdi-lasso::before {
  content: "󰼃";
}

.mdi-lastpass::before {
  content: "󰑆";
}

.mdi-latitude::before {
  content: "󰽗";
}

.mdi-launch::before {
  content: "󰌧";
}

.mdi-lava-lamp::before {
  content: "󰟕";
}

.mdi-layers::before {
  content: "󰌨";
}

.mdi-layers-edit::before {
  content: "󱢒";
}

.mdi-layers-minus::before {
  content: "󰹌";
}

.mdi-layers-off::before {
  content: "󰌩";
}

.mdi-layers-off-outline::before {
  content: "󰧽";
}

.mdi-layers-outline::before {
  content: "󰧾";
}

.mdi-layers-plus::before {
  content: "󰹍";
}

.mdi-layers-remove::before {
  content: "󰹎";
}

.mdi-layers-search::before {
  content: "󱈆";
}

.mdi-layers-search-outline::before {
  content: "󱈇";
}

.mdi-layers-triple::before {
  content: "󰽘";
}

.mdi-layers-triple-outline::before {
  content: "󰽙";
}

.mdi-lead-pencil::before {
  content: "󰙏";
}

.mdi-leaf::before {
  content: "󰌪";
}

.mdi-leaf-circle::before {
  content: "󱤅";
}

.mdi-leaf-circle-outline::before {
  content: "󱤆";
}

.mdi-leaf-maple::before {
  content: "󰲓";
}

.mdi-leaf-maple-off::before {
  content: "󱋚";
}

.mdi-leaf-off::before {
  content: "󱋙";
}

.mdi-leak::before {
  content: "󰷗";
}

.mdi-leak-off::before {
  content: "󰷘";
}

.mdi-led-off::before {
  content: "󰌫";
}

.mdi-led-on::before {
  content: "󰌬";
}

.mdi-led-outline::before {
  content: "󰌭";
}

.mdi-led-strip::before {
  content: "󰟖";
}

.mdi-led-strip-variant::before {
  content: "󱁑";
}

.mdi-led-variant-off::before {
  content: "󰌮";
}

.mdi-led-variant-on::before {
  content: "󰌯";
}

.mdi-led-variant-outline::before {
  content: "󰌰";
}

.mdi-leek::before {
  content: "󱅽";
}

.mdi-less-than::before {
  content: "󰥼";
}

.mdi-less-than-or-equal::before {
  content: "󰥽";
}

.mdi-library::before {
  content: "󰌱";
}

.mdi-library-shelves::before {
  content: "󰮩";
}

.mdi-license::before {
  content: "󰿃";
}

.mdi-lifebuoy::before {
  content: "󰡾";
}

.mdi-light-flood-down::before {
  content: "󱦇";
}

.mdi-light-flood-up::before {
  content: "󱦈";
}

.mdi-light-recessed::before {
  content: "󱞛";
}

.mdi-light-switch::before {
  content: "󰥾";
}

.mdi-lightbulb::before {
  content: "󰌵";
}

.mdi-lightbulb-auto::before {
  content: "󱠀";
}

.mdi-lightbulb-auto-outline::before {
  content: "󱠁";
}

.mdi-lightbulb-cfl::before {
  content: "󱈈";
}

.mdi-lightbulb-cfl-off::before {
  content: "󱈉";
}

.mdi-lightbulb-cfl-spiral::before {
  content: "󱉵";
}

.mdi-lightbulb-cfl-spiral-off::before {
  content: "󱋃";
}

.mdi-lightbulb-fluorescent-tube::before {
  content: "󱠄";
}

.mdi-lightbulb-fluorescent-tube-outline::before {
  content: "󱠅";
}

.mdi-lightbulb-group::before {
  content: "󱉓";
}

.mdi-lightbulb-group-off::before {
  content: "󱋍";
}

.mdi-lightbulb-group-off-outline::before {
  content: "󱋎";
}

.mdi-lightbulb-group-outline::before {
  content: "󱉔";
}

.mdi-lightbulb-multiple::before {
  content: "󱉕";
}

.mdi-lightbulb-multiple-off::before {
  content: "󱋏";
}

.mdi-lightbulb-multiple-off-outline::before {
  content: "󱋐";
}

.mdi-lightbulb-multiple-outline::before {
  content: "󱉖";
}

.mdi-lightbulb-off::before {
  content: "󰹏";
}

.mdi-lightbulb-off-outline::before {
  content: "󰹐";
}

.mdi-lightbulb-on::before {
  content: "󰛨";
}

.mdi-lightbulb-on-outline::before {
  content: "󰛩";
}

.mdi-lightbulb-outline::before {
  content: "󰌶";
}

.mdi-lightbulb-spot::before {
  content: "󱟴";
}

.mdi-lightbulb-spot-off::before {
  content: "󱟵";
}

.mdi-lightbulb-variant::before {
  content: "󱠂";
}

.mdi-lightbulb-variant-outline::before {
  content: "󱠃";
}

.mdi-lighthouse::before {
  content: "󰧿";
}

.mdi-lighthouse-on::before {
  content: "󰨀";
}

.mdi-lightning-bolt::before {
  content: "󱐋";
}

.mdi-lightning-bolt-circle::before {
  content: "󰠠";
}

.mdi-lightning-bolt-outline::before {
  content: "󱐌";
}

.mdi-line-scan::before {
  content: "󰘤";
}

.mdi-lingerie::before {
  content: "󱑶";
}

.mdi-link::before {
  content: "󰌷";
}

.mdi-link-box::before {
  content: "󰴚";
}

.mdi-link-box-outline::before {
  content: "󰴛";
}

.mdi-link-box-variant::before {
  content: "󰴜";
}

.mdi-link-box-variant-outline::before {
  content: "󰴝";
}

.mdi-link-lock::before {
  content: "󱂺";
}

.mdi-link-off::before {
  content: "󰌸";
}

.mdi-link-plus::before {
  content: "󰲔";
}

.mdi-link-variant::before {
  content: "󰌹";
}

.mdi-link-variant-minus::before {
  content: "󱃿";
}

.mdi-link-variant-off::before {
  content: "󰌺";
}

.mdi-link-variant-plus::before {
  content: "󱄀";
}

.mdi-link-variant-remove::before {
  content: "󱄁";
}

.mdi-linkedin::before {
  content: "󰌻";
}

.mdi-linux::before {
  content: "󰌽";
}

.mdi-linux-mint::before {
  content: "󰣭";
}

.mdi-lipstick::before {
  content: "󱎵";
}

.mdi-liquid-spot::before {
  content: "󱠦";
}

.mdi-liquor::before {
  content: "󱤞";
}

.mdi-list-status::before {
  content: "󱖫";
}

.mdi-litecoin::before {
  content: "󰩡";
}

.mdi-loading::before {
  content: "󰝲";
}

.mdi-location-enter::before {
  content: "󰿄";
}

.mdi-location-exit::before {
  content: "󰿅";
}

.mdi-lock::before {
  content: "󰌾";
}

.mdi-lock-alert::before {
  content: "󰣮";
}

.mdi-lock-alert-outline::before {
  content: "󱗑";
}

.mdi-lock-check::before {
  content: "󱎚";
}

.mdi-lock-check-outline::before {
  content: "󱚨";
}

.mdi-lock-clock::before {
  content: "󰥿";
}

.mdi-lock-minus::before {
  content: "󱚩";
}

.mdi-lock-minus-outline::before {
  content: "󱚪";
}

.mdi-lock-off::before {
  content: "󱙱";
}

.mdi-lock-off-outline::before {
  content: "󱙲";
}

.mdi-lock-open::before {
  content: "󰌿";
}

.mdi-lock-open-alert::before {
  content: "󱎛";
}

.mdi-lock-open-alert-outline::before {
  content: "󱗒";
}

.mdi-lock-open-check::before {
  content: "󱎜";
}

.mdi-lock-open-check-outline::before {
  content: "󱚫";
}

.mdi-lock-open-minus::before {
  content: "󱚬";
}

.mdi-lock-open-minus-outline::before {
  content: "󱚭";
}

.mdi-lock-open-outline::before {
  content: "󰍀";
}

.mdi-lock-open-plus::before {
  content: "󱚮";
}

.mdi-lock-open-plus-outline::before {
  content: "󱚯";
}

.mdi-lock-open-remove::before {
  content: "󱚰";
}

.mdi-lock-open-remove-outline::before {
  content: "󱚱";
}

.mdi-lock-open-variant::before {
  content: "󰿆";
}

.mdi-lock-open-variant-outline::before {
  content: "󰿇";
}

.mdi-lock-outline::before {
  content: "󰍁";
}

.mdi-lock-pattern::before {
  content: "󰛪";
}

.mdi-lock-plus::before {
  content: "󰗻";
}

.mdi-lock-plus-outline::before {
  content: "󱚲";
}

.mdi-lock-question::before {
  content: "󰣯";
}

.mdi-lock-remove::before {
  content: "󱚳";
}

.mdi-lock-remove-outline::before {
  content: "󱚴";
}

.mdi-lock-reset::before {
  content: "󰝳";
}

.mdi-lock-smart::before {
  content: "󰢲";
}

.mdi-locker::before {
  content: "󰟗";
}

.mdi-locker-multiple::before {
  content: "󰟘";
}

.mdi-login::before {
  content: "󰍂";
}

.mdi-login-variant::before {
  content: "󰗼";
}

.mdi-logout::before {
  content: "󰍃";
}

.mdi-logout-variant::before {
  content: "󰗽";
}

.mdi-longitude::before {
  content: "󰽚";
}

.mdi-looks::before {
  content: "󰍄";
}

.mdi-lotion::before {
  content: "󱖂";
}

.mdi-lotion-outline::before {
  content: "󱖃";
}

.mdi-lotion-plus::before {
  content: "󱖄";
}

.mdi-lotion-plus-outline::before {
  content: "󱖅";
}

.mdi-loupe::before {
  content: "󰍅";
}

.mdi-lumx::before {
  content: "󰍆";
}

.mdi-lungs::before {
  content: "󱂄";
}

.mdi-mace::before {
  content: "󱡃";
}

.mdi-magazine-pistol::before {
  content: "󰌤";
}

.mdi-magazine-rifle::before {
  content: "󰌣";
}

.mdi-magic-staff::before {
  content: "󱡄";
}

.mdi-magnet::before {
  content: "󰍇";
}

.mdi-magnet-on::before {
  content: "󰍈";
}

.mdi-magnify::before {
  content: "󰍉";
}

.mdi-magnify-close::before {
  content: "󰦀";
}

.mdi-magnify-expand::before {
  content: "󱡴";
}

.mdi-magnify-minus::before {
  content: "󰍊";
}

.mdi-magnify-minus-cursor::before {
  content: "󰩢";
}

.mdi-magnify-minus-outline::before {
  content: "󰛬";
}

.mdi-magnify-plus::before {
  content: "󰍋";
}

.mdi-magnify-plus-cursor::before {
  content: "󰩣";
}

.mdi-magnify-plus-outline::before {
  content: "󰛭";
}

.mdi-magnify-remove-cursor::before {
  content: "󱈌";
}

.mdi-magnify-remove-outline::before {
  content: "󱈍";
}

.mdi-magnify-scan::before {
  content: "󱉶";
}

.mdi-mail::before {
  content: "󰺻";
}

.mdi-mailbox::before {
  content: "󰛮";
}

.mdi-mailbox-open::before {
  content: "󰶈";
}

.mdi-mailbox-open-outline::before {
  content: "󰶉";
}

.mdi-mailbox-open-up::before {
  content: "󰶊";
}

.mdi-mailbox-open-up-outline::before {
  content: "󰶋";
}

.mdi-mailbox-outline::before {
  content: "󰶌";
}

.mdi-mailbox-up::before {
  content: "󰶍";
}

.mdi-mailbox-up-outline::before {
  content: "󰶎";
}

.mdi-manjaro::before {
  content: "󱘊";
}

.mdi-map::before {
  content: "󰍍";
}

.mdi-map-check::before {
  content: "󰺼";
}

.mdi-map-check-outline::before {
  content: "󰺽";
}

.mdi-map-clock::before {
  content: "󰴞";
}

.mdi-map-clock-outline::before {
  content: "󰴟";
}

.mdi-map-legend::before {
  content: "󰨁";
}

.mdi-map-marker::before {
  content: "󰍎";
}

.mdi-map-marker-account::before {
  content: "󱣣";
}

.mdi-map-marker-account-outline::before {
  content: "󱣤";
}

.mdi-map-marker-alert::before {
  content: "󰼅";
}

.mdi-map-marker-alert-outline::before {
  content: "󰼆";
}

.mdi-map-marker-check::before {
  content: "󰲕";
}

.mdi-map-marker-check-outline::before {
  content: "󱋻";
}

.mdi-map-marker-circle::before {
  content: "󰍏";
}

.mdi-map-marker-distance::before {
  content: "󰣰";
}

.mdi-map-marker-down::before {
  content: "󱄂";
}

.mdi-map-marker-left::before {
  content: "󱋛";
}

.mdi-map-marker-left-outline::before {
  content: "󱋝";
}

.mdi-map-marker-minus::before {
  content: "󰙐";
}

.mdi-map-marker-minus-outline::before {
  content: "󱋹";
}

.mdi-map-marker-multiple::before {
  content: "󰍐";
}

.mdi-map-marker-multiple-outline::before {
  content: "󱉷";
}

.mdi-map-marker-off::before {
  content: "󰍑";
}

.mdi-map-marker-off-outline::before {
  content: "󱋽";
}

.mdi-map-marker-outline::before {
  content: "󰟙";
}

.mdi-map-marker-path::before {
  content: "󰴠";
}

.mdi-map-marker-plus::before {
  content: "󰙑";
}

.mdi-map-marker-plus-outline::before {
  content: "󱋸";
}

.mdi-map-marker-question::before {
  content: "󰼇";
}

.mdi-map-marker-question-outline::before {
  content: "󰼈";
}

.mdi-map-marker-radius::before {
  content: "󰍒";
}

.mdi-map-marker-radius-outline::before {
  content: "󱋼";
}

.mdi-map-marker-remove::before {
  content: "󰼉";
}

.mdi-map-marker-remove-outline::before {
  content: "󱋺";
}

.mdi-map-marker-remove-variant::before {
  content: "󰼊";
}

.mdi-map-marker-right::before {
  content: "󱋜";
}

.mdi-map-marker-right-outline::before {
  content: "󱋞";
}

.mdi-map-marker-star::before {
  content: "󱘈";
}

.mdi-map-marker-star-outline::before {
  content: "󱘉";
}

.mdi-map-marker-up::before {
  content: "󱄃";
}

.mdi-map-minus::before {
  content: "󰦁";
}

.mdi-map-outline::before {
  content: "󰦂";
}

.mdi-map-plus::before {
  content: "󰦃";
}

.mdi-map-search::before {
  content: "󰦄";
}

.mdi-map-search-outline::before {
  content: "󰦅";
}

.mdi-mapbox::before {
  content: "󰮪";
}

.mdi-margin::before {
  content: "󰍓";
}

.mdi-marker::before {
  content: "󰙒";
}

.mdi-marker-cancel::before {
  content: "󰷙";
}

.mdi-marker-check::before {
  content: "󰍕";
}

.mdi-mastodon::before {
  content: "󰫑";
}

.mdi-material-design::before {
  content: "󰦆";
}

.mdi-material-ui::before {
  content: "󰍗";
}

.mdi-math-compass::before {
  content: "󰍘";
}

.mdi-math-cos::before {
  content: "󰲖";
}

.mdi-math-integral::before {
  content: "󰿈";
}

.mdi-math-integral-box::before {
  content: "󰿉";
}

.mdi-math-log::before {
  content: "󱂅";
}

.mdi-math-norm::before {
  content: "󰿊";
}

.mdi-math-norm-box::before {
  content: "󰿋";
}

.mdi-math-sin::before {
  content: "󰲗";
}

.mdi-math-tan::before {
  content: "󰲘";
}

.mdi-matrix::before {
  content: "󰘨";
}

.mdi-medal::before {
  content: "󰦇";
}

.mdi-medal-outline::before {
  content: "󱌦";
}

.mdi-medical-bag::before {
  content: "󰛯";
}

.mdi-meditation::before {
  content: "󱅻";
}

.mdi-memory::before {
  content: "󰍛";
}

.mdi-menorah::before {
  content: "󱟔";
}

.mdi-menorah-fire::before {
  content: "󱟕";
}

.mdi-menu::before {
  content: "󰍜";
}

.mdi-menu-down::before {
  content: "󰍝";
}

.mdi-menu-down-outline::before {
  content: "󰚶";
}

.mdi-menu-left::before {
  content: "󰍞";
}

.mdi-menu-left-outline::before {
  content: "󰨂";
}

.mdi-menu-open::before {
  content: "󰮫";
}

.mdi-menu-right::before {
  content: "󰍟";
}

.mdi-menu-right-outline::before {
  content: "󰨃";
}

.mdi-menu-swap::before {
  content: "󰩤";
}

.mdi-menu-swap-outline::before {
  content: "󰩥";
}

.mdi-menu-up::before {
  content: "󰍠";
}

.mdi-menu-up-outline::before {
  content: "󰚷";
}

.mdi-merge::before {
  content: "󰽜";
}

.mdi-message::before {
  content: "󰍡";
}

.mdi-message-alert::before {
  content: "󰍢";
}

.mdi-message-alert-outline::before {
  content: "󰨄";
}

.mdi-message-arrow-left::before {
  content: "󱋲";
}

.mdi-message-arrow-left-outline::before {
  content: "󱋳";
}

.mdi-message-arrow-right::before {
  content: "󱋴";
}

.mdi-message-arrow-right-outline::before {
  content: "󱋵";
}

.mdi-message-badge::before {
  content: "󱥁";
}

.mdi-message-badge-outline::before {
  content: "󱥂";
}

.mdi-message-bookmark::before {
  content: "󱖬";
}

.mdi-message-bookmark-outline::before {
  content: "󱖭";
}

.mdi-message-bulleted::before {
  content: "󰚢";
}

.mdi-message-bulleted-off::before {
  content: "󰚣";
}

.mdi-message-cog::before {
  content: "󰛱";
}

.mdi-message-cog-outline::before {
  content: "󱅲";
}

.mdi-message-draw::before {
  content: "󰍣";
}

.mdi-message-flash::before {
  content: "󱖩";
}

.mdi-message-flash-outline::before {
  content: "󱖪";
}

.mdi-message-image::before {
  content: "󰍤";
}

.mdi-message-image-outline::before {
  content: "󱅬";
}

.mdi-message-lock::before {
  content: "󰿌";
}

.mdi-message-lock-outline::before {
  content: "󱅭";
}

.mdi-message-minus::before {
  content: "󱅮";
}

.mdi-message-minus-outline::before {
  content: "󱅯";
}

.mdi-message-off::before {
  content: "󱙍";
}

.mdi-message-off-outline::before {
  content: "󱙎";
}

.mdi-message-outline::before {
  content: "󰍥";
}

.mdi-message-plus::before {
  content: "󰙓";
}

.mdi-message-plus-outline::before {
  content: "󱂻";
}

.mdi-message-processing::before {
  content: "󰍦";
}

.mdi-message-processing-outline::before {
  content: "󱅰";
}

.mdi-message-question::before {
  content: "󱜺";
}

.mdi-message-question-outline::before {
  content: "󱜻";
}

.mdi-message-reply::before {
  content: "󰍧";
}

.mdi-message-reply-outline::before {
  content: "󱜽";
}

.mdi-message-reply-text::before {
  content: "󰍨";
}

.mdi-message-reply-text-outline::before {
  content: "󱜾";
}

.mdi-message-settings::before {
  content: "󰛰";
}

.mdi-message-settings-outline::before {
  content: "󱅱";
}

.mdi-message-star::before {
  content: "󰚚";
}

.mdi-message-star-outline::before {
  content: "󱉐";
}

.mdi-message-text::before {
  content: "󰍩";
}

.mdi-message-text-clock::before {
  content: "󱅳";
}

.mdi-message-text-clock-outline::before {
  content: "󱅴";
}

.mdi-message-text-lock::before {
  content: "󰿍";
}

.mdi-message-text-lock-outline::before {
  content: "󱅵";
}

.mdi-message-text-outline::before {
  content: "󰍪";
}

.mdi-message-video::before {
  content: "󰍫";
}

.mdi-meteor::before {
  content: "󰘩";
}

.mdi-metronome::before {
  content: "󰟚";
}

.mdi-metronome-tick::before {
  content: "󰟛";
}

.mdi-micro-sd::before {
  content: "󰟜";
}

.mdi-microphone::before {
  content: "󰍬";
}

.mdi-microphone-minus::before {
  content: "󰢳";
}

.mdi-microphone-off::before {
  content: "󰍭";
}

.mdi-microphone-outline::before {
  content: "󰍮";
}

.mdi-microphone-plus::before {
  content: "󰢴";
}

.mdi-microphone-question::before {
  content: "󱦉";
}

.mdi-microphone-question-outline::before {
  content: "󱦊";
}

.mdi-microphone-settings::before {
  content: "󰍯";
}

.mdi-microphone-variant::before {
  content: "󰍰";
}

.mdi-microphone-variant-off::before {
  content: "󰍱";
}

.mdi-microscope::before {
  content: "󰙔";
}

.mdi-microsoft::before {
  content: "󰍲";
}

.mdi-microsoft-access::before {
  content: "󱎎";
}

.mdi-microsoft-azure::before {
  content: "󰠅";
}

.mdi-microsoft-azure-devops::before {
  content: "󰿕";
}

.mdi-microsoft-bing::before {
  content: "󰂤";
}

.mdi-microsoft-dynamics-365::before {
  content: "󰦈";
}

.mdi-microsoft-edge::before {
  content: "󰇩";
}

.mdi-microsoft-excel::before {
  content: "󱎏";
}

.mdi-microsoft-internet-explorer::before {
  content: "󰌀";
}

.mdi-microsoft-office::before {
  content: "󰏆";
}

.mdi-microsoft-onedrive::before {
  content: "󰏊";
}

.mdi-microsoft-onenote::before {
  content: "󰝇";
}

.mdi-microsoft-outlook::before {
  content: "󰴢";
}

.mdi-microsoft-powerpoint::before {
  content: "󱎐";
}

.mdi-microsoft-sharepoint::before {
  content: "󱎑";
}

.mdi-microsoft-teams::before {
  content: "󰊻";
}

.mdi-microsoft-visual-studio::before {
  content: "󰘐";
}

.mdi-microsoft-visual-studio-code::before {
  content: "󰨞";
}

.mdi-microsoft-windows::before {
  content: "󰖳";
}

.mdi-microsoft-windows-classic::before {
  content: "󰨡";
}

.mdi-microsoft-word::before {
  content: "󱎒";
}

.mdi-microsoft-xbox::before {
  content: "󰖹";
}

.mdi-microsoft-xbox-controller::before {
  content: "󰖺";
}

.mdi-microsoft-xbox-controller-battery-alert::before {
  content: "󰝋";
}

.mdi-microsoft-xbox-controller-battery-charging::before {
  content: "󰨢";
}

.mdi-microsoft-xbox-controller-battery-empty::before {
  content: "󰝌";
}

.mdi-microsoft-xbox-controller-battery-full::before {
  content: "󰝍";
}

.mdi-microsoft-xbox-controller-battery-low::before {
  content: "󰝎";
}

.mdi-microsoft-xbox-controller-battery-medium::before {
  content: "󰝏";
}

.mdi-microsoft-xbox-controller-battery-unknown::before {
  content: "󰝐";
}

.mdi-microsoft-xbox-controller-menu::before {
  content: "󰹯";
}

.mdi-microsoft-xbox-controller-off::before {
  content: "󰖻";
}

.mdi-microsoft-xbox-controller-view::before {
  content: "󰹰";
}

.mdi-microwave::before {
  content: "󰲙";
}

.mdi-microwave-off::before {
  content: "󱐣";
}

.mdi-middleware::before {
  content: "󰽝";
}

.mdi-middleware-outline::before {
  content: "󰽞";
}

.mdi-midi::before {
  content: "󰣱";
}

.mdi-midi-port::before {
  content: "󰣲";
}

.mdi-mine::before {
  content: "󰷚";
}

.mdi-minecraft::before {
  content: "󰍳";
}

.mdi-mini-sd::before {
  content: "󰨅";
}

.mdi-minidisc::before {
  content: "󰨆";
}

.mdi-minus::before {
  content: "󰍴";
}

.mdi-minus-box::before {
  content: "󰍵";
}

.mdi-minus-box-multiple::before {
  content: "󱅁";
}

.mdi-minus-box-multiple-outline::before {
  content: "󱅂";
}

.mdi-minus-box-outline::before {
  content: "󰛲";
}

.mdi-minus-circle::before {
  content: "󰍶";
}

.mdi-minus-circle-multiple::before {
  content: "󰍚";
}

.mdi-minus-circle-multiple-outline::before {
  content: "󰫓";
}

.mdi-minus-circle-off::before {
  content: "󱑙";
}

.mdi-minus-circle-off-outline::before {
  content: "󱑚";
}

.mdi-minus-circle-outline::before {
  content: "󰍷";
}

.mdi-minus-network::before {
  content: "󰍸";
}

.mdi-minus-network-outline::before {
  content: "󰲚";
}

.mdi-minus-thick::before {
  content: "󱘹";
}

.mdi-mirror::before {
  content: "󱇽";
}

.mdi-mirror-rectangle::before {
  content: "󱞟";
}

.mdi-mirror-variant::before {
  content: "󱞠";
}

.mdi-mixed-martial-arts::before {
  content: "󰶏";
}

.mdi-mixed-reality::before {
  content: "󰡿";
}

.mdi-molecule::before {
  content: "󰮬";
}

.mdi-molecule-co::before {
  content: "󱋾";
}

.mdi-molecule-co2::before {
  content: "󰟤";
}

.mdi-monitor::before {
  content: "󰍹";
}

.mdi-monitor-cellphone::before {
  content: "󰦉";
}

.mdi-monitor-cellphone-star::before {
  content: "󰦊";
}

.mdi-monitor-dashboard::before {
  content: "󰨇";
}

.mdi-monitor-edit::before {
  content: "󱋆";
}

.mdi-monitor-eye::before {
  content: "󱎴";
}

.mdi-monitor-lock::before {
  content: "󰷛";
}

.mdi-monitor-multiple::before {
  content: "󰍺";
}

.mdi-monitor-off::before {
  content: "󰶐";
}

.mdi-monitor-screenshot::before {
  content: "󰹑";
}

.mdi-monitor-share::before {
  content: "󱒃";
}

.mdi-monitor-shimmer::before {
  content: "󱄄";
}

.mdi-monitor-small::before {
  content: "󱡶";
}

.mdi-monitor-speaker::before {
  content: "󰽟";
}

.mdi-monitor-speaker-off::before {
  content: "󰽠";
}

.mdi-monitor-star::before {
  content: "󰷜";
}

.mdi-moon-first-quarter::before {
  content: "󰽡";
}

.mdi-moon-full::before {
  content: "󰽢";
}

.mdi-moon-last-quarter::before {
  content: "󰽣";
}

.mdi-moon-new::before {
  content: "󰽤";
}

.mdi-moon-waning-crescent::before {
  content: "󰽥";
}

.mdi-moon-waning-gibbous::before {
  content: "󰽦";
}

.mdi-moon-waxing-crescent::before {
  content: "󰽧";
}

.mdi-moon-waxing-gibbous::before {
  content: "󰽨";
}

.mdi-moped::before {
  content: "󱂆";
}

.mdi-moped-electric::before {
  content: "󱖷";
}

.mdi-moped-electric-outline::before {
  content: "󱖸";
}

.mdi-moped-outline::before {
  content: "󱖹";
}

.mdi-more::before {
  content: "󰍻";
}

.mdi-mortar-pestle::before {
  content: "󱝈";
}

.mdi-mortar-pestle-plus::before {
  content: "󰏱";
}

.mdi-mosque::before {
  content: "󱠧";
}

.mdi-mother-heart::before {
  content: "󱌔";
}

.mdi-mother-nurse::before {
  content: "󰴡";
}

.mdi-motion::before {
  content: "󱖲";
}

.mdi-motion-outline::before {
  content: "󱖳";
}

.mdi-motion-pause::before {
  content: "󱖐";
}

.mdi-motion-pause-outline::before {
  content: "󱖒";
}

.mdi-motion-play::before {
  content: "󱖏";
}

.mdi-motion-play-outline::before {
  content: "󱖑";
}

.mdi-motion-sensor::before {
  content: "󰶑";
}

.mdi-motion-sensor-off::before {
  content: "󱐵";
}

.mdi-motorbike::before {
  content: "󰍼";
}

.mdi-motorbike-electric::before {
  content: "󱖺";
}

.mdi-mouse::before {
  content: "󰍽";
}

.mdi-mouse-bluetooth::before {
  content: "󰦋";
}

.mdi-mouse-move-down::before {
  content: "󱕐";
}

.mdi-mouse-move-up::before {
  content: "󱕑";
}

.mdi-mouse-move-vertical::before {
  content: "󱕒";
}

.mdi-mouse-off::before {
  content: "󰍾";
}

.mdi-mouse-variant::before {
  content: "󰍿";
}

.mdi-mouse-variant-off::before {
  content: "󰎀";
}

.mdi-move-resize::before {
  content: "󰙕";
}

.mdi-move-resize-variant::before {
  content: "󰙖";
}

.mdi-movie::before {
  content: "󰎁";
}

.mdi-movie-check::before {
  content: "󱛳";
}

.mdi-movie-check-outline::before {
  content: "󱛴";
}

.mdi-movie-cog::before {
  content: "󱛵";
}

.mdi-movie-cog-outline::before {
  content: "󱛶";
}

.mdi-movie-edit::before {
  content: "󱄢";
}

.mdi-movie-edit-outline::before {
  content: "󱄣";
}

.mdi-movie-filter::before {
  content: "󱄤";
}

.mdi-movie-filter-outline::before {
  content: "󱄥";
}

.mdi-movie-minus::before {
  content: "󱛷";
}

.mdi-movie-minus-outline::before {
  content: "󱛸";
}

.mdi-movie-off::before {
  content: "󱛹";
}

.mdi-movie-off-outline::before {
  content: "󱛺";
}

.mdi-movie-open::before {
  content: "󰿎";
}

.mdi-movie-open-check::before {
  content: "󱛻";
}

.mdi-movie-open-check-outline::before {
  content: "󱛼";
}

.mdi-movie-open-cog::before {
  content: "󱛽";
}

.mdi-movie-open-cog-outline::before {
  content: "󱛾";
}

.mdi-movie-open-edit::before {
  content: "󱛿";
}

.mdi-movie-open-edit-outline::before {
  content: "󱜀";
}

.mdi-movie-open-minus::before {
  content: "󱜁";
}

.mdi-movie-open-minus-outline::before {
  content: "󱜂";
}

.mdi-movie-open-off::before {
  content: "󱜃";
}

.mdi-movie-open-off-outline::before {
  content: "󱜄";
}

.mdi-movie-open-outline::before {
  content: "󰿏";
}

.mdi-movie-open-play::before {
  content: "󱜅";
}

.mdi-movie-open-play-outline::before {
  content: "󱜆";
}

.mdi-movie-open-plus::before {
  content: "󱜇";
}

.mdi-movie-open-plus-outline::before {
  content: "󱜈";
}

.mdi-movie-open-remove::before {
  content: "󱜉";
}

.mdi-movie-open-remove-outline::before {
  content: "󱜊";
}

.mdi-movie-open-settings::before {
  content: "󱜋";
}

.mdi-movie-open-settings-outline::before {
  content: "󱜌";
}

.mdi-movie-open-star::before {
  content: "󱜍";
}

.mdi-movie-open-star-outline::before {
  content: "󱜎";
}

.mdi-movie-outline::before {
  content: "󰷝";
}

.mdi-movie-play::before {
  content: "󱜏";
}

.mdi-movie-play-outline::before {
  content: "󱜐";
}

.mdi-movie-plus::before {
  content: "󱜑";
}

.mdi-movie-plus-outline::before {
  content: "󱜒";
}

.mdi-movie-remove::before {
  content: "󱜓";
}

.mdi-movie-remove-outline::before {
  content: "󱜔";
}

.mdi-movie-roll::before {
  content: "󰟞";
}

.mdi-movie-search::before {
  content: "󱇒";
}

.mdi-movie-search-outline::before {
  content: "󱇓";
}

.mdi-movie-settings::before {
  content: "󱜕";
}

.mdi-movie-settings-outline::before {
  content: "󱜖";
}

.mdi-movie-star::before {
  content: "󱜗";
}

.mdi-movie-star-outline::before {
  content: "󱜘";
}

.mdi-mower::before {
  content: "󱙯";
}

.mdi-mower-bag::before {
  content: "󱙰";
}

.mdi-muffin::before {
  content: "󰦌";
}

.mdi-multicast::before {
  content: "󱢓";
}

.mdi-multiplication::before {
  content: "󰎂";
}

.mdi-multiplication-box::before {
  content: "󰎃";
}

.mdi-mushroom::before {
  content: "󰟟";
}

.mdi-mushroom-off::before {
  content: "󱏺";
}

.mdi-mushroom-off-outline::before {
  content: "󱏻";
}

.mdi-mushroom-outline::before {
  content: "󰟠";
}

.mdi-music::before {
  content: "󰝚";
}

.mdi-music-accidental-double-flat::before {
  content: "󰽩";
}

.mdi-music-accidental-double-sharp::before {
  content: "󰽪";
}

.mdi-music-accidental-flat::before {
  content: "󰽫";
}

.mdi-music-accidental-natural::before {
  content: "󰽬";
}

.mdi-music-accidental-sharp::before {
  content: "󰽭";
}

.mdi-music-box::before {
  content: "󰎄";
}

.mdi-music-box-multiple::before {
  content: "󰌳";
}

.mdi-music-box-multiple-outline::before {
  content: "󰼄";
}

.mdi-music-box-outline::before {
  content: "󰎅";
}

.mdi-music-circle::before {
  content: "󰎆";
}

.mdi-music-circle-outline::before {
  content: "󰫔";
}

.mdi-music-clef-alto::before {
  content: "󰽮";
}

.mdi-music-clef-bass::before {
  content: "󰽯";
}

.mdi-music-clef-treble::before {
  content: "󰽰";
}

.mdi-music-note::before {
  content: "󰎇";
}

.mdi-music-note-bluetooth::before {
  content: "󰗾";
}

.mdi-music-note-bluetooth-off::before {
  content: "󰗿";
}

.mdi-music-note-eighth::before {
  content: "󰎈";
}

.mdi-music-note-eighth-dotted::before {
  content: "󰽱";
}

.mdi-music-note-half::before {
  content: "󰎉";
}

.mdi-music-note-half-dotted::before {
  content: "󰽲";
}

.mdi-music-note-off::before {
  content: "󰎊";
}

.mdi-music-note-off-outline::before {
  content: "󰽳";
}

.mdi-music-note-outline::before {
  content: "󰽴";
}

.mdi-music-note-plus::before {
  content: "󰷞";
}

.mdi-music-note-quarter::before {
  content: "󰎋";
}

.mdi-music-note-quarter-dotted::before {
  content: "󰽵";
}

.mdi-music-note-sixteenth::before {
  content: "󰎌";
}

.mdi-music-note-sixteenth-dotted::before {
  content: "󰽶";
}

.mdi-music-note-whole::before {
  content: "󰎍";
}

.mdi-music-note-whole-dotted::before {
  content: "󰽷";
}

.mdi-music-off::before {
  content: "󰝛";
}

.mdi-music-rest-eighth::before {
  content: "󰽸";
}

.mdi-music-rest-half::before {
  content: "󰽹";
}

.mdi-music-rest-quarter::before {
  content: "󰽺";
}

.mdi-music-rest-sixteenth::before {
  content: "󰽻";
}

.mdi-music-rest-whole::before {
  content: "󰽼";
}

.mdi-mustache::before {
  content: "󱗞";
}

.mdi-nail::before {
  content: "󰷟";
}

.mdi-nas::before {
  content: "󰣳";
}

.mdi-nativescript::before {
  content: "󰢀";
}

.mdi-nature::before {
  content: "󰎎";
}

.mdi-nature-people::before {
  content: "󰎏";
}

.mdi-navigation::before {
  content: "󰎐";
}

.mdi-navigation-outline::before {
  content: "󱘇";
}

.mdi-navigation-variant::before {
  content: "󱣰";
}

.mdi-navigation-variant-outline::before {
  content: "󱣱";
}

.mdi-near-me::before {
  content: "󰗍";
}

.mdi-necklace::before {
  content: "󰼋";
}

.mdi-needle::before {
  content: "󰎑";
}

.mdi-netflix::before {
  content: "󰝆";
}

.mdi-network::before {
  content: "󰛳";
}

.mdi-network-off::before {
  content: "󰲛";
}

.mdi-network-off-outline::before {
  content: "󰲜";
}

.mdi-network-outline::before {
  content: "󰲝";
}

.mdi-network-strength-1::before {
  content: "󰣴";
}

.mdi-network-strength-1-alert::before {
  content: "󰣵";
}

.mdi-network-strength-2::before {
  content: "󰣶";
}

.mdi-network-strength-2-alert::before {
  content: "󰣷";
}

.mdi-network-strength-3::before {
  content: "󰣸";
}

.mdi-network-strength-3-alert::before {
  content: "󰣹";
}

.mdi-network-strength-4::before {
  content: "󰣺";
}

.mdi-network-strength-4-alert::before {
  content: "󰣻";
}

.mdi-network-strength-4-cog::before {
  content: "󱤚";
}

.mdi-network-strength-off::before {
  content: "󰣼";
}

.mdi-network-strength-off-outline::before {
  content: "󰣽";
}

.mdi-network-strength-outline::before {
  content: "󰣾";
}

.mdi-new-box::before {
  content: "󰎔";
}

.mdi-newspaper::before {
  content: "󰎕";
}

.mdi-newspaper-check::before {
  content: "󱥃";
}

.mdi-newspaper-minus::before {
  content: "󰼌";
}

.mdi-newspaper-plus::before {
  content: "󰼍";
}

.mdi-newspaper-remove::before {
  content: "󱥄";
}

.mdi-newspaper-variant::before {
  content: "󱀁";
}

.mdi-newspaper-variant-multiple::before {
  content: "󱀂";
}

.mdi-newspaper-variant-multiple-outline::before {
  content: "󱀃";
}

.mdi-newspaper-variant-outline::before {
  content: "󱀄";
}

.mdi-nfc::before {
  content: "󰎖";
}

.mdi-nfc-search-variant::before {
  content: "󰹓";
}

.mdi-nfc-tap::before {
  content: "󰎗";
}

.mdi-nfc-variant::before {
  content: "󰎘";
}

.mdi-nfc-variant-off::before {
  content: "󰹔";
}

.mdi-ninja::before {
  content: "󰝴";
}

.mdi-nintendo-game-boy::before {
  content: "󱎓";
}

.mdi-nintendo-switch::before {
  content: "󰟡";
}

.mdi-nintendo-wii::before {
  content: "󰖫";
}

.mdi-nintendo-wiiu::before {
  content: "󰜭";
}

.mdi-nix::before {
  content: "󱄅";
}

.mdi-nodejs::before {
  content: "󰎙";
}

.mdi-noodles::before {
  content: "󱅾";
}

.mdi-not-equal::before {
  content: "󰦍";
}

.mdi-not-equal-variant::before {
  content: "󰦎";
}

.mdi-note::before {
  content: "󰎚";
}

.mdi-note-alert::before {
  content: "󱝽";
}

.mdi-note-alert-outline::before {
  content: "󱝾";
}

.mdi-note-check::before {
  content: "󱝿";
}

.mdi-note-check-outline::before {
  content: "󱞀";
}

.mdi-note-edit::before {
  content: "󱞁";
}

.mdi-note-edit-outline::before {
  content: "󱞂";
}

.mdi-note-minus::before {
  content: "󱙏";
}

.mdi-note-minus-outline::before {
  content: "󱙐";
}

.mdi-note-multiple::before {
  content: "󰚸";
}

.mdi-note-multiple-outline::before {
  content: "󰚹";
}

.mdi-note-off::before {
  content: "󱞃";
}

.mdi-note-off-outline::before {
  content: "󱞄";
}

.mdi-note-outline::before {
  content: "󰎛";
}

.mdi-note-plus::before {
  content: "󰎜";
}

.mdi-note-plus-outline::before {
  content: "󰎝";
}

.mdi-note-remove::before {
  content: "󱙑";
}

.mdi-note-remove-outline::before {
  content: "󱙒";
}

.mdi-note-search::before {
  content: "󱙓";
}

.mdi-note-search-outline::before {
  content: "󱙔";
}

.mdi-note-text::before {
  content: "󰎞";
}

.mdi-note-text-outline::before {
  content: "󱇗";
}

.mdi-notebook::before {
  content: "󰠮";
}

.mdi-notebook-check::before {
  content: "󱓵";
}

.mdi-notebook-check-outline::before {
  content: "󱓶";
}

.mdi-notebook-edit::before {
  content: "󱓧";
}

.mdi-notebook-edit-outline::before {
  content: "󱓩";
}

.mdi-notebook-minus::before {
  content: "󱘐";
}

.mdi-notebook-minus-outline::before {
  content: "󱘑";
}

.mdi-notebook-multiple::before {
  content: "󰹕";
}

.mdi-notebook-outline::before {
  content: "󰺿";
}

.mdi-notebook-plus::before {
  content: "󱘒";
}

.mdi-notebook-plus-outline::before {
  content: "󱘓";
}

.mdi-notebook-remove::before {
  content: "󱘔";
}

.mdi-notebook-remove-outline::before {
  content: "󱘕";
}

.mdi-notification-clear-all::before {
  content: "󰎟";
}

.mdi-npm::before {
  content: "󰛷";
}

.mdi-nuke::before {
  content: "󰚤";
}

.mdi-null::before {
  content: "󰟢";
}

.mdi-numeric::before {
  content: "󰎠";
}

.mdi-numeric-0::before {
  content: "󰬹";
}

.mdi-numeric-0-box::before {
  content: "󰎡";
}

.mdi-numeric-0-box-multiple::before {
  content: "󰼎";
}

.mdi-numeric-0-box-multiple-outline::before {
  content: "󰎢";
}

.mdi-numeric-0-box-outline::before {
  content: "󰎣";
}

.mdi-numeric-0-circle::before {
  content: "󰲞";
}

.mdi-numeric-0-circle-outline::before {
  content: "󰲟";
}

.mdi-numeric-1::before {
  content: "󰬺";
}

.mdi-numeric-1-box::before {
  content: "󰎤";
}

.mdi-numeric-1-box-multiple::before {
  content: "󰼏";
}

.mdi-numeric-1-box-multiple-outline::before {
  content: "󰎥";
}

.mdi-numeric-1-box-outline::before {
  content: "󰎦";
}

.mdi-numeric-1-circle::before {
  content: "󰲠";
}

.mdi-numeric-1-circle-outline::before {
  content: "󰲡";
}

.mdi-numeric-10::before {
  content: "󰿩";
}

.mdi-numeric-10-box::before {
  content: "󰽽";
}

.mdi-numeric-10-box-multiple::before {
  content: "󰿪";
}

.mdi-numeric-10-box-multiple-outline::before {
  content: "󰿫";
}

.mdi-numeric-10-box-outline::before {
  content: "󰽾";
}

.mdi-numeric-10-circle::before {
  content: "󰿬";
}

.mdi-numeric-10-circle-outline::before {
  content: "󰿭";
}

.mdi-numeric-2::before {
  content: "󰬻";
}

.mdi-numeric-2-box::before {
  content: "󰎧";
}

.mdi-numeric-2-box-multiple::before {
  content: "󰼐";
}

.mdi-numeric-2-box-multiple-outline::before {
  content: "󰎨";
}

.mdi-numeric-2-box-outline::before {
  content: "󰎩";
}

.mdi-numeric-2-circle::before {
  content: "󰲢";
}

.mdi-numeric-2-circle-outline::before {
  content: "󰲣";
}

.mdi-numeric-3::before {
  content: "󰬼";
}

.mdi-numeric-3-box::before {
  content: "󰎪";
}

.mdi-numeric-3-box-multiple::before {
  content: "󰼑";
}

.mdi-numeric-3-box-multiple-outline::before {
  content: "󰎫";
}

.mdi-numeric-3-box-outline::before {
  content: "󰎬";
}

.mdi-numeric-3-circle::before {
  content: "󰲤";
}

.mdi-numeric-3-circle-outline::before {
  content: "󰲥";
}

.mdi-numeric-4::before {
  content: "󰬽";
}

.mdi-numeric-4-box::before {
  content: "󰎭";
}

.mdi-numeric-4-box-multiple::before {
  content: "󰼒";
}

.mdi-numeric-4-box-multiple-outline::before {
  content: "󰎲";
}

.mdi-numeric-4-box-outline::before {
  content: "󰎮";
}

.mdi-numeric-4-circle::before {
  content: "󰲦";
}

.mdi-numeric-4-circle-outline::before {
  content: "󰲧";
}

.mdi-numeric-5::before {
  content: "󰬾";
}

.mdi-numeric-5-box::before {
  content: "󰎱";
}

.mdi-numeric-5-box-multiple::before {
  content: "󰼓";
}

.mdi-numeric-5-box-multiple-outline::before {
  content: "󰎯";
}

.mdi-numeric-5-box-outline::before {
  content: "󰎰";
}

.mdi-numeric-5-circle::before {
  content: "󰲨";
}

.mdi-numeric-5-circle-outline::before {
  content: "󰲩";
}

.mdi-numeric-6::before {
  content: "󰬿";
}

.mdi-numeric-6-box::before {
  content: "󰎳";
}

.mdi-numeric-6-box-multiple::before {
  content: "󰼔";
}

.mdi-numeric-6-box-multiple-outline::before {
  content: "󰎴";
}

.mdi-numeric-6-box-outline::before {
  content: "󰎵";
}

.mdi-numeric-6-circle::before {
  content: "󰲪";
}

.mdi-numeric-6-circle-outline::before {
  content: "󰲫";
}

.mdi-numeric-7::before {
  content: "󰭀";
}

.mdi-numeric-7-box::before {
  content: "󰎶";
}

.mdi-numeric-7-box-multiple::before {
  content: "󰼕";
}

.mdi-numeric-7-box-multiple-outline::before {
  content: "󰎷";
}

.mdi-numeric-7-box-outline::before {
  content: "󰎸";
}

.mdi-numeric-7-circle::before {
  content: "󰲬";
}

.mdi-numeric-7-circle-outline::before {
  content: "󰲭";
}

.mdi-numeric-8::before {
  content: "󰭁";
}

.mdi-numeric-8-box::before {
  content: "󰎹";
}

.mdi-numeric-8-box-multiple::before {
  content: "󰼖";
}

.mdi-numeric-8-box-multiple-outline::before {
  content: "󰎺";
}

.mdi-numeric-8-box-outline::before {
  content: "󰎻";
}

.mdi-numeric-8-circle::before {
  content: "󰲮";
}

.mdi-numeric-8-circle-outline::before {
  content: "󰲯";
}

.mdi-numeric-9::before {
  content: "󰭂";
}

.mdi-numeric-9-box::before {
  content: "󰎼";
}

.mdi-numeric-9-box-multiple::before {
  content: "󰼗";
}

.mdi-numeric-9-box-multiple-outline::before {
  content: "󰎽";
}

.mdi-numeric-9-box-outline::before {
  content: "󰎾";
}

.mdi-numeric-9-circle::before {
  content: "󰲰";
}

.mdi-numeric-9-circle-outline::before {
  content: "󰲱";
}

.mdi-numeric-9-plus::before {
  content: "󰿮";
}

.mdi-numeric-9-plus-box::before {
  content: "󰎿";
}

.mdi-numeric-9-plus-box-multiple::before {
  content: "󰼘";
}

.mdi-numeric-9-plus-box-multiple-outline::before {
  content: "󰏀";
}

.mdi-numeric-9-plus-box-outline::before {
  content: "󰏁";
}

.mdi-numeric-9-plus-circle::before {
  content: "󰲲";
}

.mdi-numeric-9-plus-circle-outline::before {
  content: "󰲳";
}

.mdi-numeric-negative-1::before {
  content: "󱁒";
}

.mdi-numeric-positive-1::before {
  content: "󱗋";
}

.mdi-nut::before {
  content: "󰛸";
}

.mdi-nutrition::before {
  content: "󰏂";
}

.mdi-nuxt::before {
  content: "󱄆";
}

.mdi-oar::before {
  content: "󰙼";
}

.mdi-ocarina::before {
  content: "󰷠";
}

.mdi-oci::before {
  content: "󱋩";
}

.mdi-ocr::before {
  content: "󱄺";
}

.mdi-octagon::before {
  content: "󰏃";
}

.mdi-octagon-outline::before {
  content: "󰏄";
}

.mdi-octagram::before {
  content: "󰛹";
}

.mdi-octagram-outline::before {
  content: "󰝵";
}

.mdi-octahedron::before {
  content: "󱥐";
}

.mdi-octahedron-off::before {
  content: "󱥑";
}

.mdi-odnoklassniki::before {
  content: "󰏅";
}

.mdi-offer::before {
  content: "󱈛";
}

.mdi-office-building::before {
  content: "󰦑";
}

.mdi-office-building-cog::before {
  content: "󱥉";
}

.mdi-office-building-cog-outline::before {
  content: "󱥊";
}

.mdi-office-building-marker::before {
  content: "󱔠";
}

.mdi-office-building-marker-outline::before {
  content: "󱔡";
}

.mdi-office-building-outline::before {
  content: "󱔟";
}

.mdi-oil::before {
  content: "󰏇";
}

.mdi-oil-lamp::before {
  content: "󰼙";
}

.mdi-oil-level::before {
  content: "󱁓";
}

.mdi-oil-temperature::before {
  content: "󰿸";
}

.mdi-om::before {
  content: "󰥳";
}

.mdi-omega::before {
  content: "󰏉";
}

.mdi-one-up::before {
  content: "󰮭";
}

.mdi-onepassword::before {
  content: "󰢁";
}

.mdi-opacity::before {
  content: "󰗌";
}

.mdi-open-in-app::before {
  content: "󰏋";
}

.mdi-open-in-new::before {
  content: "󰏌";
}

.mdi-open-source-initiative::before {
  content: "󰮮";
}

.mdi-openid::before {
  content: "󰏍";
}

.mdi-opera::before {
  content: "󰏎";
}

.mdi-orbit::before {
  content: "󰀘";
}

.mdi-orbit-variant::before {
  content: "󱗛";
}

.mdi-order-alphabetical-ascending::before {
  content: "󰈍";
}

.mdi-order-alphabetical-descending::before {
  content: "󰴇";
}

.mdi-order-bool-ascending::before {
  content: "󰊾";
}

.mdi-order-bool-ascending-variant::before {
  content: "󰦏";
}

.mdi-order-bool-descending::before {
  content: "󱎄";
}

.mdi-order-bool-descending-variant::before {
  content: "󰦐";
}

.mdi-order-numeric-ascending::before {
  content: "󰕅";
}

.mdi-order-numeric-descending::before {
  content: "󰕆";
}

.mdi-origin::before {
  content: "󰭃";
}

.mdi-ornament::before {
  content: "󰏏";
}

.mdi-ornament-variant::before {
  content: "󰏐";
}

.mdi-outdoor-lamp::before {
  content: "󱁔";
}

.mdi-overscan::before {
  content: "󱀅";
}

.mdi-owl::before {
  content: "󰏒";
}

.mdi-pac-man::before {
  content: "󰮯";
}

.mdi-package::before {
  content: "󰏓";
}

.mdi-package-down::before {
  content: "󰏔";
}

.mdi-package-up::before {
  content: "󰏕";
}

.mdi-package-variant::before {
  content: "󰏖";
}

.mdi-package-variant-closed::before {
  content: "󰏗";
}

.mdi-page-first::before {
  content: "󰘀";
}

.mdi-page-last::before {
  content: "󰘁";
}

.mdi-page-layout-body::before {
  content: "󰛺";
}

.mdi-page-layout-footer::before {
  content: "󰛻";
}

.mdi-page-layout-header::before {
  content: "󰛼";
}

.mdi-page-layout-header-footer::before {
  content: "󰽿";
}

.mdi-page-layout-sidebar-left::before {
  content: "󰛽";
}

.mdi-page-layout-sidebar-right::before {
  content: "󰛾";
}

.mdi-page-next::before {
  content: "󰮰";
}

.mdi-page-next-outline::before {
  content: "󰮱";
}

.mdi-page-previous::before {
  content: "󰮲";
}

.mdi-page-previous-outline::before {
  content: "󰮳";
}

.mdi-pail::before {
  content: "󱐗";
}

.mdi-pail-minus::before {
  content: "󱐷";
}

.mdi-pail-minus-outline::before {
  content: "󱐼";
}

.mdi-pail-off::before {
  content: "󱐹";
}

.mdi-pail-off-outline::before {
  content: "󱐾";
}

.mdi-pail-outline::before {
  content: "󱐺";
}

.mdi-pail-plus::before {
  content: "󱐶";
}

.mdi-pail-plus-outline::before {
  content: "󱐻";
}

.mdi-pail-remove::before {
  content: "󱐸";
}

.mdi-pail-remove-outline::before {
  content: "󱐽";
}

.mdi-palette::before {
  content: "󰏘";
}

.mdi-palette-advanced::before {
  content: "󰏙";
}

.mdi-palette-outline::before {
  content: "󰸌";
}

.mdi-palette-swatch::before {
  content: "󰢵";
}

.mdi-palette-swatch-outline::before {
  content: "󱍜";
}

.mdi-palette-swatch-variant::before {
  content: "󱥚";
}

.mdi-palm-tree::before {
  content: "󱁕";
}

.mdi-pan::before {
  content: "󰮴";
}

.mdi-pan-bottom-left::before {
  content: "󰮵";
}

.mdi-pan-bottom-right::before {
  content: "󰮶";
}

.mdi-pan-down::before {
  content: "󰮷";
}

.mdi-pan-horizontal::before {
  content: "󰮸";
}

.mdi-pan-left::before {
  content: "󰮹";
}

.mdi-pan-right::before {
  content: "󰮺";
}

.mdi-pan-top-left::before {
  content: "󰮻";
}

.mdi-pan-top-right::before {
  content: "󰮼";
}

.mdi-pan-up::before {
  content: "󰮽";
}

.mdi-pan-vertical::before {
  content: "󰮾";
}

.mdi-panda::before {
  content: "󰏚";
}

.mdi-pandora::before {
  content: "󰏛";
}

.mdi-panorama::before {
  content: "󰏜";
}

.mdi-panorama-fisheye::before {
  content: "󰏝";
}

.mdi-panorama-horizontal::before {
  content: "󱤨";
}

.mdi-panorama-horizontal-outline::before {
  content: "󰏞";
}

.mdi-panorama-outline::before {
  content: "󱦌";
}

.mdi-panorama-sphere::before {
  content: "󱦍";
}

.mdi-panorama-sphere-outline::before {
  content: "󱦎";
}

.mdi-panorama-variant::before {
  content: "󱦏";
}

.mdi-panorama-variant-outline::before {
  content: "󱦐";
}

.mdi-panorama-vertical::before {
  content: "󱤩";
}

.mdi-panorama-vertical-outline::before {
  content: "󰏟";
}

.mdi-panorama-wide-angle::before {
  content: "󱥟";
}

.mdi-panorama-wide-angle-outline::before {
  content: "󰏠";
}

.mdi-paper-cut-vertical::before {
  content: "󰏡";
}

.mdi-paper-roll::before {
  content: "󱅗";
}

.mdi-paper-roll-outline::before {
  content: "󱅘";
}

.mdi-paperclip::before {
  content: "󰏢";
}

.mdi-parachute::before {
  content: "󰲴";
}

.mdi-parachute-outline::before {
  content: "󰲵";
}

.mdi-paragliding::before {
  content: "󱝅";
}

.mdi-parking::before {
  content: "󰏣";
}

.mdi-party-popper::before {
  content: "󱁖";
}

.mdi-passport::before {
  content: "󰟣";
}

.mdi-passport-biometric::before {
  content: "󰷡";
}

.mdi-pasta::before {
  content: "󱅠";
}

.mdi-patio-heater::before {
  content: "󰾀";
}

.mdi-patreon::before {
  content: "󰢂";
}

.mdi-pause::before {
  content: "󰏤";
}

.mdi-pause-circle::before {
  content: "󰏥";
}

.mdi-pause-circle-outline::before {
  content: "󰏦";
}

.mdi-pause-octagon::before {
  content: "󰏧";
}

.mdi-pause-octagon-outline::before {
  content: "󰏨";
}

.mdi-paw::before {
  content: "󰏩";
}

.mdi-paw-off::before {
  content: "󰙗";
}

.mdi-paw-off-outline::before {
  content: "󱙶";
}

.mdi-paw-outline::before {
  content: "󱙵";
}

.mdi-peace::before {
  content: "󰢄";
}

.mdi-peanut::before {
  content: "󰿼";
}

.mdi-peanut-off::before {
  content: "󰿽";
}

.mdi-peanut-off-outline::before {
  content: "󰿿";
}

.mdi-peanut-outline::before {
  content: "󰿾";
}

.mdi-pen::before {
  content: "󰏪";
}

.mdi-pen-lock::before {
  content: "󰷢";
}

.mdi-pen-minus::before {
  content: "󰷣";
}

.mdi-pen-off::before {
  content: "󰷤";
}

.mdi-pen-plus::before {
  content: "󰷥";
}

.mdi-pen-remove::before {
  content: "󰷦";
}

.mdi-pencil::before {
  content: "󰏫";
}

.mdi-pencil-box::before {
  content: "󰏬";
}

.mdi-pencil-box-multiple::before {
  content: "󱅄";
}

.mdi-pencil-box-multiple-outline::before {
  content: "󱅅";
}

.mdi-pencil-box-outline::before {
  content: "󰏭";
}

.mdi-pencil-circle::before {
  content: "󰛿";
}

.mdi-pencil-circle-outline::before {
  content: "󰝶";
}

.mdi-pencil-lock::before {
  content: "󰏮";
}

.mdi-pencil-lock-outline::before {
  content: "󰷧";
}

.mdi-pencil-minus::before {
  content: "󰷨";
}

.mdi-pencil-minus-outline::before {
  content: "󰷩";
}

.mdi-pencil-off::before {
  content: "󰏯";
}

.mdi-pencil-off-outline::before {
  content: "󰷪";
}

.mdi-pencil-outline::before {
  content: "󰲶";
}

.mdi-pencil-plus::before {
  content: "󰷫";
}

.mdi-pencil-plus-outline::before {
  content: "󰷬";
}

.mdi-pencil-remove::before {
  content: "󰷭";
}

.mdi-pencil-remove-outline::before {
  content: "󰷮";
}

.mdi-pencil-ruler::before {
  content: "󱍓";
}

.mdi-penguin::before {
  content: "󰻀";
}

.mdi-pentagon::before {
  content: "󰜁";
}

.mdi-pentagon-outline::before {
  content: "󰜀";
}

.mdi-pentagram::before {
  content: "󱙧";
}

.mdi-percent::before {
  content: "󰏰";
}

.mdi-percent-outline::before {
  content: "󱉸";
}

.mdi-periodic-table::before {
  content: "󰢶";
}

.mdi-perspective-less::before {
  content: "󰴣";
}

.mdi-perspective-more::before {
  content: "󰴤";
}

.mdi-ph::before {
  content: "󱟅";
}

.mdi-phone::before {
  content: "󰏲";
}

.mdi-phone-alert::before {
  content: "󰼚";
}

.mdi-phone-alert-outline::before {
  content: "󱆎";
}

.mdi-phone-bluetooth::before {
  content: "󰏳";
}

.mdi-phone-bluetooth-outline::before {
  content: "󱆏";
}

.mdi-phone-cancel::before {
  content: "󱂼";
}

.mdi-phone-cancel-outline::before {
  content: "󱆐";
}

.mdi-phone-check::before {
  content: "󱆩";
}

.mdi-phone-check-outline::before {
  content: "󱆪";
}

.mdi-phone-classic::before {
  content: "󰘂";
}

.mdi-phone-classic-off::before {
  content: "󱉹";
}

.mdi-phone-dial::before {
  content: "󱕙";
}

.mdi-phone-dial-outline::before {
  content: "󱕚";
}

.mdi-phone-forward::before {
  content: "󰏴";
}

.mdi-phone-forward-outline::before {
  content: "󱆑";
}

.mdi-phone-hangup::before {
  content: "󰏵";
}

.mdi-phone-hangup-outline::before {
  content: "󱆒";
}

.mdi-phone-in-talk::before {
  content: "󰏶";
}

.mdi-phone-in-talk-outline::before {
  content: "󱆂";
}

.mdi-phone-incoming::before {
  content: "󰏷";
}

.mdi-phone-incoming-outline::before {
  content: "󱆓";
}

.mdi-phone-lock::before {
  content: "󰏸";
}

.mdi-phone-lock-outline::before {
  content: "󱆔";
}

.mdi-phone-log::before {
  content: "󰏹";
}

.mdi-phone-log-outline::before {
  content: "󱆕";
}

.mdi-phone-message::before {
  content: "󱆖";
}

.mdi-phone-message-outline::before {
  content: "󱆗";
}

.mdi-phone-minus::before {
  content: "󰙘";
}

.mdi-phone-minus-outline::before {
  content: "󱆘";
}

.mdi-phone-missed::before {
  content: "󰏺";
}

.mdi-phone-missed-outline::before {
  content: "󱆥";
}

.mdi-phone-off::before {
  content: "󰷯";
}

.mdi-phone-off-outline::before {
  content: "󱆦";
}

.mdi-phone-outgoing::before {
  content: "󰏻";
}

.mdi-phone-outgoing-outline::before {
  content: "󱆙";
}

.mdi-phone-outline::before {
  content: "󰷰";
}

.mdi-phone-paused::before {
  content: "󰏼";
}

.mdi-phone-paused-outline::before {
  content: "󱆚";
}

.mdi-phone-plus::before {
  content: "󰙙";
}

.mdi-phone-plus-outline::before {
  content: "󱆛";
}

.mdi-phone-refresh::before {
  content: "󱦓";
}

.mdi-phone-refresh-outline::before {
  content: "󱦔";
}

.mdi-phone-remove::before {
  content: "󱔯";
}

.mdi-phone-remove-outline::before {
  content: "󱔰";
}

.mdi-phone-return::before {
  content: "󰠯";
}

.mdi-phone-return-outline::before {
  content: "󱆜";
}

.mdi-phone-ring::before {
  content: "󱆫";
}

.mdi-phone-ring-outline::before {
  content: "󱆬";
}

.mdi-phone-rotate-landscape::before {
  content: "󰢅";
}

.mdi-phone-rotate-portrait::before {
  content: "󰢆";
}

.mdi-phone-settings::before {
  content: "󰏽";
}

.mdi-phone-settings-outline::before {
  content: "󱆝";
}

.mdi-phone-sync::before {
  content: "󱦕";
}

.mdi-phone-sync-outline::before {
  content: "󱦖";
}

.mdi-phone-voip::before {
  content: "󰏾";
}

.mdi-pi::before {
  content: "󰏿";
}

.mdi-pi-box::before {
  content: "󰐀";
}

.mdi-pi-hole::before {
  content: "󰷱";
}

.mdi-piano::before {
  content: "󰙽";
}

.mdi-piano-off::before {
  content: "󰚘";
}

.mdi-pickaxe::before {
  content: "󰢷";
}

.mdi-picture-in-picture-bottom-right::before {
  content: "󰹗";
}

.mdi-picture-in-picture-bottom-right-outline::before {
  content: "󰹘";
}

.mdi-picture-in-picture-top-right::before {
  content: "󰹙";
}

.mdi-picture-in-picture-top-right-outline::before {
  content: "󰹚";
}

.mdi-pier::before {
  content: "󰢇";
}

.mdi-pier-crane::before {
  content: "󰢈";
}

.mdi-pig::before {
  content: "󰐁";
}

.mdi-pig-variant::before {
  content: "󱀆";
}

.mdi-pig-variant-outline::before {
  content: "󱙸";
}

.mdi-piggy-bank::before {
  content: "󱀇";
}

.mdi-piggy-bank-outline::before {
  content: "󱙹";
}

.mdi-pill::before {
  content: "󰐂";
}

.mdi-pillar::before {
  content: "󰜂";
}

.mdi-pin::before {
  content: "󰐃";
}

.mdi-pin-off::before {
  content: "󰐄";
}

.mdi-pin-off-outline::before {
  content: "󰤰";
}

.mdi-pin-outline::before {
  content: "󰤱";
}

.mdi-pine-tree::before {
  content: "󰐅";
}

.mdi-pine-tree-box::before {
  content: "󰐆";
}

.mdi-pine-tree-fire::before {
  content: "󱐚";
}

.mdi-pinterest::before {
  content: "󰐇";
}

.mdi-pinwheel::before {
  content: "󰫕";
}

.mdi-pinwheel-outline::before {
  content: "󰫖";
}

.mdi-pipe::before {
  content: "󰟥";
}

.mdi-pipe-disconnected::before {
  content: "󰟦";
}

.mdi-pipe-leak::before {
  content: "󰢉";
}

.mdi-pipe-valve::before {
  content: "󱡍";
}

.mdi-pipe-wrench::before {
  content: "󱍔";
}

.mdi-pirate::before {
  content: "󰨈";
}

.mdi-pistol::before {
  content: "󰜃";
}

.mdi-piston::before {
  content: "󰢊";
}

.mdi-pitchfork::before {
  content: "󱕓";
}

.mdi-pizza::before {
  content: "󰐉";
}

.mdi-play::before {
  content: "󰐊";
}

.mdi-play-box::before {
  content: "󱉺";
}

.mdi-play-box-multiple::before {
  content: "󰴙";
}

.mdi-play-box-multiple-outline::before {
  content: "󱏦";
}

.mdi-play-box-outline::before {
  content: "󰐋";
}

.mdi-play-circle::before {
  content: "󰐌";
}

.mdi-play-circle-outline::before {
  content: "󰐍";
}

.mdi-play-network::before {
  content: "󰢋";
}

.mdi-play-network-outline::before {
  content: "󰲷";
}

.mdi-play-outline::before {
  content: "󰼛";
}

.mdi-play-pause::before {
  content: "󰐎";
}

.mdi-play-protected-content::before {
  content: "󰐏";
}

.mdi-play-speed::before {
  content: "󰣿";
}

.mdi-playlist-check::before {
  content: "󰗇";
}

.mdi-playlist-edit::before {
  content: "󰤀";
}

.mdi-playlist-minus::before {
  content: "󰐐";
}

.mdi-playlist-music::before {
  content: "󰲸";
}

.mdi-playlist-music-outline::before {
  content: "󰲹";
}

.mdi-playlist-play::before {
  content: "󰐑";
}

.mdi-playlist-plus::before {
  content: "󰐒";
}

.mdi-playlist-remove::before {
  content: "󰐓";
}

.mdi-playlist-star::before {
  content: "󰷲";
}

.mdi-plex::before {
  content: "󰚺";
}

.mdi-pliers::before {
  content: "󱦤";
}

.mdi-plus::before {
  content: "󰐕";
}

.mdi-plus-box::before {
  content: "󰐖";
}

.mdi-plus-box-multiple::before {
  content: "󰌴";
}

.mdi-plus-box-multiple-outline::before {
  content: "󱅃";
}

.mdi-plus-box-outline::before {
  content: "󰜄";
}

.mdi-plus-circle::before {
  content: "󰐗";
}

.mdi-plus-circle-multiple::before {
  content: "󰍌";
}

.mdi-plus-circle-multiple-outline::before {
  content: "󰐘";
}

.mdi-plus-circle-outline::before {
  content: "󰐙";
}

.mdi-plus-minus::before {
  content: "󰦒";
}

.mdi-plus-minus-box::before {
  content: "󰦓";
}

.mdi-plus-minus-variant::before {
  content: "󱓉";
}

.mdi-plus-network::before {
  content: "󰐚";
}

.mdi-plus-network-outline::before {
  content: "󰲺";
}

.mdi-plus-outline::before {
  content: "󰜅";
}

.mdi-plus-thick::before {
  content: "󱇬";
}

.mdi-podcast::before {
  content: "󰦔";
}

.mdi-podium::before {
  content: "󰴥";
}

.mdi-podium-bronze::before {
  content: "󰴦";
}

.mdi-podium-gold::before {
  content: "󰴧";
}

.mdi-podium-silver::before {
  content: "󰴨";
}

.mdi-point-of-sale::before {
  content: "󰶒";
}

.mdi-pokeball::before {
  content: "󰐝";
}

.mdi-pokemon-go::before {
  content: "󰨉";
}

.mdi-poker-chip::before {
  content: "󰠰";
}

.mdi-polaroid::before {
  content: "󰐞";
}

.mdi-police-badge::before {
  content: "󱅧";
}

.mdi-police-badge-outline::before {
  content: "󱅨";
}

.mdi-police-station::before {
  content: "󱠹";
}

.mdi-poll::before {
  content: "󰐟";
}

.mdi-polo::before {
  content: "󱓃";
}

.mdi-polymer::before {
  content: "󰐡";
}

.mdi-pool::before {
  content: "󰘆";
}

.mdi-popcorn::before {
  content: "󰐢";
}

.mdi-post::before {
  content: "󱀈";
}

.mdi-post-outline::before {
  content: "󱀉";
}

.mdi-postage-stamp::before {
  content: "󰲻";
}

.mdi-pot::before {
  content: "󰋥";
}

.mdi-pot-mix::before {
  content: "󰙛";
}

.mdi-pot-mix-outline::before {
  content: "󰙷";
}

.mdi-pot-outline::before {
  content: "󰋿";
}

.mdi-pot-steam::before {
  content: "󰙚";
}

.mdi-pot-steam-outline::before {
  content: "󰌦";
}

.mdi-pound::before {
  content: "󰐣";
}

.mdi-pound-box::before {
  content: "󰐤";
}

.mdi-pound-box-outline::before {
  content: "󱅿";
}

.mdi-power::before {
  content: "󰐥";
}

.mdi-power-cycle::before {
  content: "󰤁";
}

.mdi-power-off::before {
  content: "󰤂";
}

.mdi-power-on::before {
  content: "󰤃";
}

.mdi-power-plug::before {
  content: "󰚥";
}

.mdi-power-plug-off::before {
  content: "󰚦";
}

.mdi-power-plug-off-outline::before {
  content: "󱐤";
}

.mdi-power-plug-outline::before {
  content: "󱐥";
}

.mdi-power-settings::before {
  content: "󰐦";
}

.mdi-power-sleep::before {
  content: "󰤄";
}

.mdi-power-socket::before {
  content: "󰐧";
}

.mdi-power-socket-au::before {
  content: "󰤅";
}

.mdi-power-socket-ch::before {
  content: "󰾳";
}

.mdi-power-socket-de::before {
  content: "󱄇";
}

.mdi-power-socket-eu::before {
  content: "󰟧";
}

.mdi-power-socket-fr::before {
  content: "󱄈";
}

.mdi-power-socket-it::before {
  content: "󱓿";
}

.mdi-power-socket-jp::before {
  content: "󱄉";
}

.mdi-power-socket-uk::before {
  content: "󰟨";
}

.mdi-power-socket-us::before {
  content: "󰟩";
}

.mdi-power-standby::before {
  content: "󰤆";
}

.mdi-powershell::before {
  content: "󰨊";
}

.mdi-prescription::before {
  content: "󰜆";
}

.mdi-presentation::before {
  content: "󰐨";
}

.mdi-presentation-play::before {
  content: "󰐩";
}

.mdi-pretzel::before {
  content: "󱕢";
}

.mdi-printer::before {
  content: "󰐪";
}

.mdi-printer-3d::before {
  content: "󰐫";
}

.mdi-printer-3d-nozzle::before {
  content: "󰹛";
}

.mdi-printer-3d-nozzle-alert::before {
  content: "󱇀";
}

.mdi-printer-3d-nozzle-alert-outline::before {
  content: "󱇁";
}

.mdi-printer-3d-nozzle-heat::before {
  content: "󱢸";
}

.mdi-printer-3d-nozzle-heat-outline::before {
  content: "󱢹";
}

.mdi-printer-3d-nozzle-outline::before {
  content: "󰹜";
}

.mdi-printer-alert::before {
  content: "󰐬";
}

.mdi-printer-check::before {
  content: "󱅆";
}

.mdi-printer-eye::before {
  content: "󱑘";
}

.mdi-printer-off::before {
  content: "󰹝";
}

.mdi-printer-off-outline::before {
  content: "󱞅";
}

.mdi-printer-outline::before {
  content: "󱞆";
}

.mdi-printer-pos::before {
  content: "󱁗";
}

.mdi-printer-search::before {
  content: "󱑗";
}

.mdi-printer-settings::before {
  content: "󰜇";
}

.mdi-printer-wireless::before {
  content: "󰨋";
}

.mdi-priority-high::before {
  content: "󰘃";
}

.mdi-priority-low::before {
  content: "󰘄";
}

.mdi-professional-hexagon::before {
  content: "󰐭";
}

.mdi-progress-alert::before {
  content: "󰲼";
}

.mdi-progress-check::before {
  content: "󰦕";
}

.mdi-progress-clock::before {
  content: "󰦖";
}

.mdi-progress-close::before {
  content: "󱄊";
}

.mdi-progress-download::before {
  content: "󰦗";
}

.mdi-progress-pencil::before {
  content: "󱞇";
}

.mdi-progress-question::before {
  content: "󱔢";
}

.mdi-progress-star::before {
  content: "󱞈";
}

.mdi-progress-upload::before {
  content: "󰦘";
}

.mdi-progress-wrench::before {
  content: "󰲽";
}

.mdi-projector::before {
  content: "󰐮";
}

.mdi-projector-screen::before {
  content: "󰐯";
}

.mdi-projector-screen-off::before {
  content: "󱠍";
}

.mdi-projector-screen-off-outline::before {
  content: "󱠎";
}

.mdi-projector-screen-outline::before {
  content: "󱜤";
}

.mdi-projector-screen-variant::before {
  content: "󱠏";
}

.mdi-projector-screen-variant-off::before {
  content: "󱠐";
}

.mdi-projector-screen-variant-off-outline::before {
  content: "󱠑";
}

.mdi-projector-screen-variant-outline::before {
  content: "󱠒";
}

.mdi-propane-tank::before {
  content: "󱍗";
}

.mdi-propane-tank-outline::before {
  content: "󱍘";
}

.mdi-protocol::before {
  content: "󰿘";
}

.mdi-publish::before {
  content: "󰚧";
}

.mdi-publish-off::before {
  content: "󱥅";
}

.mdi-pulse::before {
  content: "󰐰";
}

.mdi-pump::before {
  content: "󱐂";
}

.mdi-pumpkin::before {
  content: "󰮿";
}

.mdi-purse::before {
  content: "󰼜";
}

.mdi-purse-outline::before {
  content: "󰼝";
}

.mdi-puzzle::before {
  content: "󰐱";
}

.mdi-puzzle-check::before {
  content: "󱐦";
}

.mdi-puzzle-check-outline::before {
  content: "󱐧";
}

.mdi-puzzle-edit::before {
  content: "󱓓";
}

.mdi-puzzle-edit-outline::before {
  content: "󱓙";
}

.mdi-puzzle-heart::before {
  content: "󱓔";
}

.mdi-puzzle-heart-outline::before {
  content: "󱓚";
}

.mdi-puzzle-minus::before {
  content: "󱓑";
}

.mdi-puzzle-minus-outline::before {
  content: "󱓗";
}

.mdi-puzzle-outline::before {
  content: "󰩦";
}

.mdi-puzzle-plus::before {
  content: "󱓐";
}

.mdi-puzzle-plus-outline::before {
  content: "󱓖";
}

.mdi-puzzle-remove::before {
  content: "󱓒";
}

.mdi-puzzle-remove-outline::before {
  content: "󱓘";
}

.mdi-puzzle-star::before {
  content: "󱓕";
}

.mdi-puzzle-star-outline::before {
  content: "󱓛";
}

.mdi-pyramid::before {
  content: "󱥒";
}

.mdi-pyramid-off::before {
  content: "󱥓";
}

.mdi-qi::before {
  content: "󰦙";
}

.mdi-qqchat::before {
  content: "󰘅";
}

.mdi-qrcode::before {
  content: "󰐲";
}

.mdi-qrcode-edit::before {
  content: "󰢸";
}

.mdi-qrcode-minus::before {
  content: "󱆌";
}

.mdi-qrcode-plus::before {
  content: "󱆋";
}

.mdi-qrcode-remove::before {
  content: "󱆍";
}

.mdi-qrcode-scan::before {
  content: "󰐳";
}

.mdi-quadcopter::before {
  content: "󰐴";
}

.mdi-quality-high::before {
  content: "󰐵";
}

.mdi-quality-low::before {
  content: "󰨌";
}

.mdi-quality-medium::before {
  content: "󰨍";
}

.mdi-quora::before {
  content: "󰴩";
}

.mdi-rabbit::before {
  content: "󰤇";
}

.mdi-racing-helmet::before {
  content: "󰶓";
}

.mdi-racquetball::before {
  content: "󰶔";
}

.mdi-radar::before {
  content: "󰐷";
}

.mdi-radiator::before {
  content: "󰐸";
}

.mdi-radiator-disabled::before {
  content: "󰫗";
}

.mdi-radiator-off::before {
  content: "󰫘";
}

.mdi-radio::before {
  content: "󰐹";
}

.mdi-radio-am::before {
  content: "󰲾";
}

.mdi-radio-fm::before {
  content: "󰲿";
}

.mdi-radio-handheld::before {
  content: "󰐺";
}

.mdi-radio-off::before {
  content: "󱈜";
}

.mdi-radio-tower::before {
  content: "󰐻";
}

.mdi-radioactive::before {
  content: "󰐼";
}

.mdi-radioactive-circle::before {
  content: "󱡝";
}

.mdi-radioactive-circle-outline::before {
  content: "󱡞";
}

.mdi-radioactive-off::before {
  content: "󰻁";
}

.mdi-radiobox-blank::before {
  content: "󰐽";
}

.mdi-radiobox-marked::before {
  content: "󰐾";
}

.mdi-radiology-box::before {
  content: "󱓅";
}

.mdi-radiology-box-outline::before {
  content: "󱓆";
}

.mdi-radius::before {
  content: "󰳀";
}

.mdi-radius-outline::before {
  content: "󰳁";
}

.mdi-railroad-light::before {
  content: "󰼞";
}

.mdi-rake::before {
  content: "󱕄";
}

.mdi-raspberry-pi::before {
  content: "󰐿";
}

.mdi-ray-end::before {
  content: "󰑀";
}

.mdi-ray-end-arrow::before {
  content: "󰑁";
}

.mdi-ray-start::before {
  content: "󰑂";
}

.mdi-ray-start-arrow::before {
  content: "󰑃";
}

.mdi-ray-start-end::before {
  content: "󰑄";
}

.mdi-ray-start-vertex-end::before {
  content: "󱗘";
}

.mdi-ray-vertex::before {
  content: "󰑅";
}

.mdi-razor-double-edge::before {
  content: "󱦗";
}

.mdi-razor-single-edge::before {
  content: "󱦘";
}

.mdi-react::before {
  content: "󰜈";
}

.mdi-read::before {
  content: "󰑇";
}

.mdi-receipt::before {
  content: "󰑉";
}

.mdi-record::before {
  content: "󰑊";
}

.mdi-record-circle::before {
  content: "󰻂";
}

.mdi-record-circle-outline::before {
  content: "󰻃";
}

.mdi-record-player::before {
  content: "󰦚";
}

.mdi-record-rec::before {
  content: "󰑋";
}

.mdi-rectangle::before {
  content: "󰹞";
}

.mdi-rectangle-outline::before {
  content: "󰹟";
}

.mdi-recycle::before {
  content: "󰑌";
}

.mdi-recycle-variant::before {
  content: "󱎝";
}

.mdi-reddit::before {
  content: "󰑍";
}

.mdi-redhat::before {
  content: "󱄛";
}

.mdi-redo::before {
  content: "󰑎";
}

.mdi-redo-variant::before {
  content: "󰑏";
}

.mdi-reflect-horizontal::before {
  content: "󰨎";
}

.mdi-reflect-vertical::before {
  content: "󰨏";
}

.mdi-refresh::before {
  content: "󰑐";
}

.mdi-refresh-auto::before {
  content: "󱣲";
}

.mdi-refresh-circle::before {
  content: "󱍷";
}

.mdi-regex::before {
  content: "󰑑";
}

.mdi-registered-trademark::before {
  content: "󰩧";
}

.mdi-reiterate::before {
  content: "󱖈";
}

.mdi-relation-many-to-many::before {
  content: "󱒖";
}

.mdi-relation-many-to-one::before {
  content: "󱒗";
}

.mdi-relation-many-to-one-or-many::before {
  content: "󱒘";
}

.mdi-relation-many-to-only-one::before {
  content: "󱒙";
}

.mdi-relation-many-to-zero-or-many::before {
  content: "󱒚";
}

.mdi-relation-many-to-zero-or-one::before {
  content: "󱒛";
}

.mdi-relation-one-or-many-to-many::before {
  content: "󱒜";
}

.mdi-relation-one-or-many-to-one::before {
  content: "󱒝";
}

.mdi-relation-one-or-many-to-one-or-many::before {
  content: "󱒞";
}

.mdi-relation-one-or-many-to-only-one::before {
  content: "󱒟";
}

.mdi-relation-one-or-many-to-zero-or-many::before {
  content: "󱒠";
}

.mdi-relation-one-or-many-to-zero-or-one::before {
  content: "󱒡";
}

.mdi-relation-one-to-many::before {
  content: "󱒢";
}

.mdi-relation-one-to-one::before {
  content: "󱒣";
}

.mdi-relation-one-to-one-or-many::before {
  content: "󱒤";
}

.mdi-relation-one-to-only-one::before {
  content: "󱒥";
}

.mdi-relation-one-to-zero-or-many::before {
  content: "󱒦";
}

.mdi-relation-one-to-zero-or-one::before {
  content: "󱒧";
}

.mdi-relation-only-one-to-many::before {
  content: "󱒨";
}

.mdi-relation-only-one-to-one::before {
  content: "󱒩";
}

.mdi-relation-only-one-to-one-or-many::before {
  content: "󱒪";
}

.mdi-relation-only-one-to-only-one::before {
  content: "󱒫";
}

.mdi-relation-only-one-to-zero-or-many::before {
  content: "󱒬";
}

.mdi-relation-only-one-to-zero-or-one::before {
  content: "󱒭";
}

.mdi-relation-zero-or-many-to-many::before {
  content: "󱒮";
}

.mdi-relation-zero-or-many-to-one::before {
  content: "󱒯";
}

.mdi-relation-zero-or-many-to-one-or-many::before {
  content: "󱒰";
}

.mdi-relation-zero-or-many-to-only-one::before {
  content: "󱒱";
}

.mdi-relation-zero-or-many-to-zero-or-many::before {
  content: "󱒲";
}

.mdi-relation-zero-or-many-to-zero-or-one::before {
  content: "󱒳";
}

.mdi-relation-zero-or-one-to-many::before {
  content: "󱒴";
}

.mdi-relation-zero-or-one-to-one::before {
  content: "󱒵";
}

.mdi-relation-zero-or-one-to-one-or-many::before {
  content: "󱒶";
}

.mdi-relation-zero-or-one-to-only-one::before {
  content: "󱒷";
}

.mdi-relation-zero-or-one-to-zero-or-many::before {
  content: "󱒸";
}

.mdi-relation-zero-or-one-to-zero-or-one::before {
  content: "󱒹";
}

.mdi-relative-scale::before {
  content: "󰑒";
}

.mdi-reload::before {
  content: "󰑓";
}

.mdi-reload-alert::before {
  content: "󱄋";
}

.mdi-reminder::before {
  content: "󰢌";
}

.mdi-remote::before {
  content: "󰑔";
}

.mdi-remote-desktop::before {
  content: "󰢹";
}

.mdi-remote-off::before {
  content: "󰻄";
}

.mdi-remote-tv::before {
  content: "󰻅";
}

.mdi-remote-tv-off::before {
  content: "󰻆";
}

.mdi-rename-box::before {
  content: "󰑕";
}

.mdi-reorder-horizontal::before {
  content: "󰚈";
}

.mdi-reorder-vertical::before {
  content: "󰚉";
}

.mdi-repeat::before {
  content: "󰑖";
}

.mdi-repeat-off::before {
  content: "󰑗";
}

.mdi-repeat-once::before {
  content: "󰑘";
}

.mdi-repeat-variant::before {
  content: "󰕇";
}

.mdi-replay::before {
  content: "󰑙";
}

.mdi-reply::before {
  content: "󰑚";
}

.mdi-reply-all::before {
  content: "󰑛";
}

.mdi-reply-all-outline::before {
  content: "󰼟";
}

.mdi-reply-circle::before {
  content: "󱆮";
}

.mdi-reply-outline::before {
  content: "󰼠";
}

.mdi-reproduction::before {
  content: "󰑜";
}

.mdi-resistor::before {
  content: "󰭄";
}

.mdi-resistor-nodes::before {
  content: "󰭅";
}

.mdi-resize::before {
  content: "󰩨";
}

.mdi-resize-bottom-right::before {
  content: "󰑝";
}

.mdi-responsive::before {
  content: "󰑞";
}

.mdi-restart::before {
  content: "󰜉";
}

.mdi-restart-alert::before {
  content: "󱄌";
}

.mdi-restart-off::before {
  content: "󰶕";
}

.mdi-restore::before {
  content: "󰦛";
}

.mdi-restore-alert::before {
  content: "󱄍";
}

.mdi-rewind::before {
  content: "󰑟";
}

.mdi-rewind-10::before {
  content: "󰴪";
}

.mdi-rewind-15::before {
  content: "󱥆";
}

.mdi-rewind-30::before {
  content: "󰶖";
}

.mdi-rewind-5::before {
  content: "󱇹";
}

.mdi-rewind-60::before {
  content: "󱘌";
}

.mdi-rewind-outline::before {
  content: "󰜊";
}

.mdi-rhombus::before {
  content: "󰜋";
}

.mdi-rhombus-medium::before {
  content: "󰨐";
}

.mdi-rhombus-medium-outline::before {
  content: "󱓜";
}

.mdi-rhombus-outline::before {
  content: "󰜌";
}

.mdi-rhombus-split::before {
  content: "󰨑";
}

.mdi-rhombus-split-outline::before {
  content: "󱓝";
}

.mdi-ribbon::before {
  content: "󰑠";
}

.mdi-rice::before {
  content: "󰟪";
}

.mdi-rickshaw::before {
  content: "󱖻";
}

.mdi-rickshaw-electric::before {
  content: "󱖼";
}

.mdi-ring::before {
  content: "󰟫";
}

.mdi-rivet::before {
  content: "󰹠";
}

.mdi-road::before {
  content: "󰑡";
}

.mdi-road-variant::before {
  content: "󰑢";
}

.mdi-robber::before {
  content: "󱁘";
}

.mdi-robot::before {
  content: "󰚩";
}

.mdi-robot-angry::before {
  content: "󱚝";
}

.mdi-robot-angry-outline::before {
  content: "󱚞";
}

.mdi-robot-confused::before {
  content: "󱚟";
}

.mdi-robot-confused-outline::before {
  content: "󱚠";
}

.mdi-robot-dead::before {
  content: "󱚡";
}

.mdi-robot-dead-outline::before {
  content: "󱚢";
}

.mdi-robot-excited::before {
  content: "󱚣";
}

.mdi-robot-excited-outline::before {
  content: "󱚤";
}

.mdi-robot-happy::before {
  content: "󱜙";
}

.mdi-robot-happy-outline::before {
  content: "󱜚";
}

.mdi-robot-industrial::before {
  content: "󰭆";
}

.mdi-robot-love::before {
  content: "󱚥";
}

.mdi-robot-love-outline::before {
  content: "󱚦";
}

.mdi-robot-mower::before {
  content: "󱇷";
}

.mdi-robot-mower-outline::before {
  content: "󱇳";
}

.mdi-robot-off::before {
  content: "󱚧";
}

.mdi-robot-off-outline::before {
  content: "󱙻";
}

.mdi-robot-outline::before {
  content: "󱙺";
}

.mdi-robot-vacuum::before {
  content: "󰜍";
}

.mdi-robot-vacuum-variant::before {
  content: "󰤈";
}

.mdi-rocket::before {
  content: "󰑣";
}

.mdi-rocket-launch::before {
  content: "󱓞";
}

.mdi-rocket-launch-outline::before {
  content: "󱓟";
}

.mdi-rocket-outline::before {
  content: "󱎯";
}

.mdi-rodent::before {
  content: "󱌧";
}

.mdi-roller-skate::before {
  content: "󰴫";
}

.mdi-roller-skate-off::before {
  content: "󰅅";
}

.mdi-rollerblade::before {
  content: "󰴬";
}

.mdi-rollerblade-off::before {
  content: "󰀮";
}

.mdi-rollupjs::before {
  content: "󰯀";
}

.mdi-roman-numeral-1::before {
  content: "󱂈";
}

.mdi-roman-numeral-10::before {
  content: "󱂑";
}

.mdi-roman-numeral-2::before {
  content: "󱂉";
}

.mdi-roman-numeral-3::before {
  content: "󱂊";
}

.mdi-roman-numeral-4::before {
  content: "󱂋";
}

.mdi-roman-numeral-5::before {
  content: "󱂌";
}

.mdi-roman-numeral-6::before {
  content: "󱂍";
}

.mdi-roman-numeral-7::before {
  content: "󱂎";
}

.mdi-roman-numeral-8::before {
  content: "󱂏";
}

.mdi-roman-numeral-9::before {
  content: "󱂐";
}

.mdi-room-service::before {
  content: "󰢍";
}

.mdi-room-service-outline::before {
  content: "󰶗";
}

.mdi-rotate-360::before {
  content: "󱦙";
}

.mdi-rotate-3d::before {
  content: "󰻇";
}

.mdi-rotate-3d-variant::before {
  content: "󰑤";
}

.mdi-rotate-left::before {
  content: "󰑥";
}

.mdi-rotate-left-variant::before {
  content: "󰑦";
}

.mdi-rotate-orbit::before {
  content: "󰶘";
}

.mdi-rotate-right::before {
  content: "󰑧";
}

.mdi-rotate-right-variant::before {
  content: "󰑨";
}

.mdi-rounded-corner::before {
  content: "󰘇";
}

.mdi-router::before {
  content: "󱇢";
}

.mdi-router-network::before {
  content: "󱂇";
}

.mdi-router-wireless::before {
  content: "󰑩";
}

.mdi-router-wireless-off::before {
  content: "󱖣";
}

.mdi-router-wireless-settings::before {
  content: "󰩩";
}

.mdi-routes::before {
  content: "󰑪";
}

.mdi-routes-clock::before {
  content: "󱁙";
}

.mdi-rowing::before {
  content: "󰘈";
}

.mdi-rss::before {
  content: "󰑫";
}

.mdi-rss-box::before {
  content: "󰑬";
}

.mdi-rss-off::before {
  content: "󰼡";
}

.mdi-rug::before {
  content: "󱑵";
}

.mdi-rugby::before {
  content: "󰶙";
}

.mdi-ruler::before {
  content: "󰑭";
}

.mdi-ruler-square::before {
  content: "󰳂";
}

.mdi-ruler-square-compass::before {
  content: "󰺾";
}

.mdi-run::before {
  content: "󰜎";
}

.mdi-run-fast::before {
  content: "󰑮";
}

.mdi-rv-truck::before {
  content: "󱇔";
}

.mdi-sack::before {
  content: "󰴮";
}

.mdi-sack-percent::before {
  content: "󰴯";
}

.mdi-safe::before {
  content: "󰩪";
}

.mdi-safe-square::before {
  content: "󱉼";
}

.mdi-safe-square-outline::before {
  content: "󱉽";
}

.mdi-safety-goggles::before {
  content: "󰴰";
}

.mdi-sail-boat::before {
  content: "󰻈";
}

.mdi-sale::before {
  content: "󰑯";
}

.mdi-salesforce::before {
  content: "󰢎";
}

.mdi-sass::before {
  content: "󰟬";
}

.mdi-satellite::before {
  content: "󰑰";
}

.mdi-satellite-uplink::before {
  content: "󰤉";
}

.mdi-satellite-variant::before {
  content: "󰑱";
}

.mdi-sausage::before {
  content: "󰢺";
}

.mdi-sausage-off::before {
  content: "󱞉";
}

.mdi-saw-blade::before {
  content: "󰹡";
}

.mdi-sawtooth-wave::before {
  content: "󱑺";
}

.mdi-saxophone::before {
  content: "󰘉";
}

.mdi-scale::before {
  content: "󰑲";
}

.mdi-scale-balance::before {
  content: "󰗑";
}

.mdi-scale-bathroom::before {
  content: "󰑳";
}

.mdi-scale-off::before {
  content: "󱁚";
}

.mdi-scale-unbalanced::before {
  content: "󱦸";
}

.mdi-scan-helper::before {
  content: "󱏘";
}

.mdi-scanner::before {
  content: "󰚫";
}

.mdi-scanner-off::before {
  content: "󰤊";
}

.mdi-scatter-plot::before {
  content: "󰻉";
}

.mdi-scatter-plot-outline::before {
  content: "󰻊";
}

.mdi-scent::before {
  content: "󱥘";
}

.mdi-scent-off::before {
  content: "󱥙";
}

.mdi-school::before {
  content: "󰑴";
}

.mdi-school-outline::before {
  content: "󱆀";
}

.mdi-scissors-cutting::before {
  content: "󰩫";
}

.mdi-scooter::before {
  content: "󱖽";
}

.mdi-scooter-electric::before {
  content: "󱖾";
}

.mdi-scoreboard::before {
  content: "󱉾";
}

.mdi-scoreboard-outline::before {
  content: "󱉿";
}

.mdi-screen-rotation::before {
  content: "󰑵";
}

.mdi-screen-rotation-lock::before {
  content: "󰑸";
}

.mdi-screw-flat-top::before {
  content: "󰷳";
}

.mdi-screw-lag::before {
  content: "󰷴";
}

.mdi-screw-machine-flat-top::before {
  content: "󰷵";
}

.mdi-screw-machine-round-top::before {
  content: "󰷶";
}

.mdi-screw-round-top::before {
  content: "󰷷";
}

.mdi-screwdriver::before {
  content: "󰑶";
}

.mdi-script::before {
  content: "󰯁";
}

.mdi-script-outline::before {
  content: "󰑷";
}

.mdi-script-text::before {
  content: "󰯂";
}

.mdi-script-text-key::before {
  content: "󱜥";
}

.mdi-script-text-key-outline::before {
  content: "󱜦";
}

.mdi-script-text-outline::before {
  content: "󰯃";
}

.mdi-script-text-play::before {
  content: "󱜧";
}

.mdi-script-text-play-outline::before {
  content: "󱜨";
}

.mdi-sd::before {
  content: "󰑹";
}

.mdi-seal::before {
  content: "󰑺";
}

.mdi-seal-variant::before {
  content: "󰿙";
}

.mdi-search-web::before {
  content: "󰜏";
}

.mdi-seat::before {
  content: "󰳃";
}

.mdi-seat-flat::before {
  content: "󰑻";
}

.mdi-seat-flat-angled::before {
  content: "󰑼";
}

.mdi-seat-individual-suite::before {
  content: "󰑽";
}

.mdi-seat-legroom-extra::before {
  content: "󰑾";
}

.mdi-seat-legroom-normal::before {
  content: "󰑿";
}

.mdi-seat-legroom-reduced::before {
  content: "󰒀";
}

.mdi-seat-outline::before {
  content: "󰳄";
}

.mdi-seat-passenger::before {
  content: "󱉉";
}

.mdi-seat-recline-extra::before {
  content: "󰒁";
}

.mdi-seat-recline-normal::before {
  content: "󰒂";
}

.mdi-seatbelt::before {
  content: "󰳅";
}

.mdi-security::before {
  content: "󰒃";
}

.mdi-security-network::before {
  content: "󰒄";
}

.mdi-seed::before {
  content: "󰹢";
}

.mdi-seed-off::before {
  content: "󱏽";
}

.mdi-seed-off-outline::before {
  content: "󱏾";
}

.mdi-seed-outline::before {
  content: "󰹣";
}

.mdi-seesaw::before {
  content: "󱖤";
}

.mdi-segment::before {
  content: "󰻋";
}

.mdi-select::before {
  content: "󰒅";
}

.mdi-select-all::before {
  content: "󰒆";
}

.mdi-select-color::before {
  content: "󰴱";
}

.mdi-select-compare::before {
  content: "󰫙";
}

.mdi-select-drag::before {
  content: "󰩬";
}

.mdi-select-group::before {
  content: "󰾂";
}

.mdi-select-inverse::before {
  content: "󰒇";
}

.mdi-select-marker::before {
  content: "󱊀";
}

.mdi-select-multiple::before {
  content: "󱊁";
}

.mdi-select-multiple-marker::before {
  content: "󱊂";
}

.mdi-select-off::before {
  content: "󰒈";
}

.mdi-select-place::before {
  content: "󰿚";
}

.mdi-select-remove::before {
  content: "󱟁";
}

.mdi-select-search::before {
  content: "󱈄";
}

.mdi-selection::before {
  content: "󰒉";
}

.mdi-selection-drag::before {
  content: "󰩭";
}

.mdi-selection-ellipse::before {
  content: "󰴲";
}

.mdi-selection-ellipse-arrow-inside::before {
  content: "󰼢";
}

.mdi-selection-ellipse-remove::before {
  content: "󱟂";
}

.mdi-selection-marker::before {
  content: "󱊃";
}

.mdi-selection-multiple::before {
  content: "󱊅";
}

.mdi-selection-multiple-marker::before {
  content: "󱊄";
}

.mdi-selection-off::before {
  content: "󰝷";
}

.mdi-selection-remove::before {
  content: "󱟃";
}

.mdi-selection-search::before {
  content: "󱈅";
}

.mdi-semantic-web::before {
  content: "󱌖";
}

.mdi-send::before {
  content: "󰒊";
}

.mdi-send-check::before {
  content: "󱅡";
}

.mdi-send-check-outline::before {
  content: "󱅢";
}

.mdi-send-circle::before {
  content: "󰷸";
}

.mdi-send-circle-outline::before {
  content: "󰷹";
}

.mdi-send-clock::before {
  content: "󱅣";
}

.mdi-send-clock-outline::before {
  content: "󱅤";
}

.mdi-send-lock::before {
  content: "󰟭";
}

.mdi-send-lock-outline::before {
  content: "󱅦";
}

.mdi-send-outline::before {
  content: "󱅥";
}

.mdi-serial-port::before {
  content: "󰙜";
}

.mdi-server::before {
  content: "󰒋";
}

.mdi-server-minus::before {
  content: "󰒌";
}

.mdi-server-network::before {
  content: "󰒍";
}

.mdi-server-network-off::before {
  content: "󰒎";
}

.mdi-server-off::before {
  content: "󰒏";
}

.mdi-server-plus::before {
  content: "󰒐";
}

.mdi-server-remove::before {
  content: "󰒑";
}

.mdi-server-security::before {
  content: "󰒒";
}

.mdi-set-all::before {
  content: "󰝸";
}

.mdi-set-center::before {
  content: "󰝹";
}

.mdi-set-center-right::before {
  content: "󰝺";
}

.mdi-set-left::before {
  content: "󰝻";
}

.mdi-set-left-center::before {
  content: "󰝼";
}

.mdi-set-left-right::before {
  content: "󰝽";
}

.mdi-set-merge::before {
  content: "󱓠";
}

.mdi-set-none::before {
  content: "󰝾";
}

.mdi-set-right::before {
  content: "󰝿";
}

.mdi-set-split::before {
  content: "󱓡";
}

.mdi-set-square::before {
  content: "󱑝";
}

.mdi-set-top-box::before {
  content: "󰦟";
}

.mdi-settings-helper::before {
  content: "󰩮";
}

.mdi-shaker::before {
  content: "󱄎";
}

.mdi-shaker-outline::before {
  content: "󱄏";
}

.mdi-shape::before {
  content: "󰠱";
}

.mdi-shape-circle-plus::before {
  content: "󰙝";
}

.mdi-shape-outline::before {
  content: "󰠲";
}

.mdi-shape-oval-plus::before {
  content: "󱇺";
}

.mdi-shape-plus::before {
  content: "󰒕";
}

.mdi-shape-polygon-plus::before {
  content: "󰙞";
}

.mdi-shape-rectangle-plus::before {
  content: "󰙟";
}

.mdi-shape-square-plus::before {
  content: "󰙠";
}

.mdi-shape-square-rounded-plus::before {
  content: "󱓺";
}

.mdi-share::before {
  content: "󰒖";
}

.mdi-share-all::before {
  content: "󱇴";
}

.mdi-share-all-outline::before {
  content: "󱇵";
}

.mdi-share-circle::before {
  content: "󱆭";
}

.mdi-share-off::before {
  content: "󰼣";
}

.mdi-share-off-outline::before {
  content: "󰼤";
}

.mdi-share-outline::before {
  content: "󰤲";
}

.mdi-share-variant::before {
  content: "󰒗";
}

.mdi-share-variant-outline::before {
  content: "󱔔";
}

.mdi-shark::before {
  content: "󱢺";
}

.mdi-shark-fin::before {
  content: "󱙳";
}

.mdi-shark-fin-outline::before {
  content: "󱙴";
}

.mdi-shark-off::before {
  content: "󱢻";
}

.mdi-sheep::before {
  content: "󰳆";
}

.mdi-shield::before {
  content: "󰒘";
}

.mdi-shield-account::before {
  content: "󰢏";
}

.mdi-shield-account-outline::before {
  content: "󰨒";
}

.mdi-shield-account-variant::before {
  content: "󱖧";
}

.mdi-shield-account-variant-outline::before {
  content: "󱖨";
}

.mdi-shield-airplane::before {
  content: "󰚻";
}

.mdi-shield-airplane-outline::before {
  content: "󰳇";
}

.mdi-shield-alert::before {
  content: "󰻌";
}

.mdi-shield-alert-outline::before {
  content: "󰻍";
}

.mdi-shield-bug::before {
  content: "󱏚";
}

.mdi-shield-bug-outline::before {
  content: "󱏛";
}

.mdi-shield-car::before {
  content: "󰾃";
}

.mdi-shield-check::before {
  content: "󰕥";
}

.mdi-shield-check-outline::before {
  content: "󰳈";
}

.mdi-shield-cross::before {
  content: "󰳉";
}

.mdi-shield-cross-outline::before {
  content: "󰳊";
}

.mdi-shield-crown::before {
  content: "󱢼";
}

.mdi-shield-crown-outline::before {
  content: "󱢽";
}

.mdi-shield-edit::before {
  content: "󱆠";
}

.mdi-shield-edit-outline::before {
  content: "󱆡";
}

.mdi-shield-half::before {
  content: "󱍠";
}

.mdi-shield-half-full::before {
  content: "󰞀";
}

.mdi-shield-home::before {
  content: "󰚊";
}

.mdi-shield-home-outline::before {
  content: "󰳋";
}

.mdi-shield-key::before {
  content: "󰯄";
}

.mdi-shield-key-outline::before {
  content: "󰯅";
}

.mdi-shield-link-variant::before {
  content: "󰴳";
}

.mdi-shield-link-variant-outline::before {
  content: "󰴴";
}

.mdi-shield-lock::before {
  content: "󰦝";
}

.mdi-shield-lock-open::before {
  content: "󱦚";
}

.mdi-shield-lock-open-outline::before {
  content: "󱦛";
}

.mdi-shield-lock-outline::before {
  content: "󰳌";
}

.mdi-shield-moon::before {
  content: "󱠨";
}

.mdi-shield-moon-outline::before {
  content: "󱠩";
}

.mdi-shield-off::before {
  content: "󰦞";
}

.mdi-shield-off-outline::before {
  content: "󰦜";
}

.mdi-shield-outline::before {
  content: "󰒙";
}

.mdi-shield-plus::before {
  content: "󰫚";
}

.mdi-shield-plus-outline::before {
  content: "󰫛";
}

.mdi-shield-refresh::before {
  content: "󰂪";
}

.mdi-shield-refresh-outline::before {
  content: "󰇠";
}

.mdi-shield-remove::before {
  content: "󰫜";
}

.mdi-shield-remove-outline::before {
  content: "󰫝";
}

.mdi-shield-search::before {
  content: "󰶚";
}

.mdi-shield-star::before {
  content: "󱄻";
}

.mdi-shield-star-outline::before {
  content: "󱄼";
}

.mdi-shield-sun::before {
  content: "󱁝";
}

.mdi-shield-sun-outline::before {
  content: "󱁞";
}

.mdi-shield-sword::before {
  content: "󱢾";
}

.mdi-shield-sword-outline::before {
  content: "󱢿";
}

.mdi-shield-sync::before {
  content: "󱆢";
}

.mdi-shield-sync-outline::before {
  content: "󱆣";
}

.mdi-shimmer::before {
  content: "󱕅";
}

.mdi-ship-wheel::before {
  content: "󰠳";
}

.mdi-shipping-pallet::before {
  content: "󱡎";
}

.mdi-shoe-ballet::before {
  content: "󱗊";
}

.mdi-shoe-cleat::before {
  content: "󱗇";
}

.mdi-shoe-formal::before {
  content: "󰭇";
}

.mdi-shoe-heel::before {
  content: "󰭈";
}

.mdi-shoe-print::before {
  content: "󰷺";
}

.mdi-shoe-sneaker::before {
  content: "󱗈";
}

.mdi-shopping::before {
  content: "󰒚";
}

.mdi-shopping-music::before {
  content: "󰒛";
}

.mdi-shopping-outline::before {
  content: "󱇕";
}

.mdi-shopping-search::before {
  content: "󰾄";
}

.mdi-shore::before {
  content: "󱓹";
}

.mdi-shovel::before {
  content: "󰜐";
}

.mdi-shovel-off::before {
  content: "󰜑";
}

.mdi-shower::before {
  content: "󰦠";
}

.mdi-shower-head::before {
  content: "󰦡";
}

.mdi-shredder::before {
  content: "󰒜";
}

.mdi-shuffle::before {
  content: "󰒝";
}

.mdi-shuffle-disabled::before {
  content: "󰒞";
}

.mdi-shuffle-variant::before {
  content: "󰒟";
}

.mdi-shuriken::before {
  content: "󱍿";
}

.mdi-sickle::before {
  content: "󱣀";
}

.mdi-sigma::before {
  content: "󰒠";
}

.mdi-sigma-lower::before {
  content: "󰘫";
}

.mdi-sign-caution::before {
  content: "󰒡";
}

.mdi-sign-direction::before {
  content: "󰞁";
}

.mdi-sign-direction-minus::before {
  content: "󱀀";
}

.mdi-sign-direction-plus::before {
  content: "󰿜";
}

.mdi-sign-direction-remove::before {
  content: "󰿝";
}

.mdi-sign-pole::before {
  content: "󱓸";
}

.mdi-sign-real-estate::before {
  content: "󱄘";
}

.mdi-sign-text::before {
  content: "󰞂";
}

.mdi-signal::before {
  content: "󰒢";
}

.mdi-signal-2g::before {
  content: "󰜒";
}

.mdi-signal-3g::before {
  content: "󰜓";
}

.mdi-signal-4g::before {
  content: "󰜔";
}

.mdi-signal-5g::before {
  content: "󰩯";
}

.mdi-signal-cellular-1::before {
  content: "󰢼";
}

.mdi-signal-cellular-2::before {
  content: "󰢽";
}

.mdi-signal-cellular-3::before {
  content: "󰢾";
}

.mdi-signal-cellular-outline::before {
  content: "󰢿";
}

.mdi-signal-distance-variant::before {
  content: "󰹤";
}

.mdi-signal-hspa::before {
  content: "󰜕";
}

.mdi-signal-hspa-plus::before {
  content: "󰜖";
}

.mdi-signal-off::before {
  content: "󰞃";
}

.mdi-signal-variant::before {
  content: "󰘊";
}

.mdi-signature::before {
  content: "󰷻";
}

.mdi-signature-freehand::before {
  content: "󰷼";
}

.mdi-signature-image::before {
  content: "󰷽";
}

.mdi-signature-text::before {
  content: "󰷾";
}

.mdi-silo::before {
  content: "󰭉";
}

.mdi-silverware::before {
  content: "󰒣";
}

.mdi-silverware-clean::before {
  content: "󰿞";
}

.mdi-silverware-fork::before {
  content: "󰒤";
}

.mdi-silverware-fork-knife::before {
  content: "󰩰";
}

.mdi-silverware-spoon::before {
  content: "󰒥";
}

.mdi-silverware-variant::before {
  content: "󰒦";
}

.mdi-sim::before {
  content: "󰒧";
}

.mdi-sim-alert::before {
  content: "󰒨";
}

.mdi-sim-alert-outline::before {
  content: "󱗓";
}

.mdi-sim-off::before {
  content: "󰒩";
}

.mdi-sim-off-outline::before {
  content: "󱗔";
}

.mdi-sim-outline::before {
  content: "󱗕";
}

.mdi-simple-icons::before {
  content: "󱌝";
}

.mdi-sina-weibo::before {
  content: "󰫟";
}

.mdi-sine-wave::before {
  content: "󰥛";
}

.mdi-sitemap::before {
  content: "󰒪";
}

.mdi-sitemap-outline::before {
  content: "󱦜";
}

.mdi-size-l::before {
  content: "󱎦";
}

.mdi-size-m::before {
  content: "󱎥";
}

.mdi-size-s::before {
  content: "󱎤";
}

.mdi-size-xl::before {
  content: "󱎧";
}

.mdi-size-xs::before {
  content: "󱎣";
}

.mdi-size-xxl::before {
  content: "󱎨";
}

.mdi-size-xxs::before {
  content: "󱎢";
}

.mdi-size-xxxl::before {
  content: "󱎩";
}

.mdi-skate::before {
  content: "󰴵";
}

.mdi-skate-off::before {
  content: "󰚙";
}

.mdi-skateboard::before {
  content: "󱓂";
}

.mdi-skateboarding::before {
  content: "󰔁";
}

.mdi-skew-less::before {
  content: "󰴶";
}

.mdi-skew-more::before {
  content: "󰴷";
}

.mdi-ski::before {
  content: "󱌄";
}

.mdi-ski-cross-country::before {
  content: "󱌅";
}

.mdi-ski-water::before {
  content: "󱌆";
}

.mdi-skip-backward::before {
  content: "󰒫";
}

.mdi-skip-backward-outline::before {
  content: "󰼥";
}

.mdi-skip-forward::before {
  content: "󰒬";
}

.mdi-skip-forward-outline::before {
  content: "󰼦";
}

.mdi-skip-next::before {
  content: "󰒭";
}

.mdi-skip-next-circle::before {
  content: "󰙡";
}

.mdi-skip-next-circle-outline::before {
  content: "󰙢";
}

.mdi-skip-next-outline::before {
  content: "󰼧";
}

.mdi-skip-previous::before {
  content: "󰒮";
}

.mdi-skip-previous-circle::before {
  content: "󰙣";
}

.mdi-skip-previous-circle-outline::before {
  content: "󰙤";
}

.mdi-skip-previous-outline::before {
  content: "󰼨";
}

.mdi-skull::before {
  content: "󰚌";
}

.mdi-skull-crossbones::before {
  content: "󰯆";
}

.mdi-skull-crossbones-outline::before {
  content: "󰯇";
}

.mdi-skull-outline::before {
  content: "󰯈";
}

.mdi-skull-scan::before {
  content: "󱓇";
}

.mdi-skull-scan-outline::before {
  content: "󱓈";
}

.mdi-skype::before {
  content: "󰒯";
}

.mdi-skype-business::before {
  content: "󰒰";
}

.mdi-slack::before {
  content: "󰒱";
}

.mdi-slash-forward::before {
  content: "󰿟";
}

.mdi-slash-forward-box::before {
  content: "󰿠";
}

.mdi-sledding::before {
  content: "󰐛";
}

.mdi-sleep::before {
  content: "󰒲";
}

.mdi-sleep-off::before {
  content: "󰒳";
}

.mdi-slide::before {
  content: "󱖥";
}

.mdi-slope-downhill::before {
  content: "󰷿";
}

.mdi-slope-uphill::before {
  content: "󰸀";
}

.mdi-slot-machine::before {
  content: "󱄔";
}

.mdi-slot-machine-outline::before {
  content: "󱄕";
}

.mdi-smart-card::before {
  content: "󱂽";
}

.mdi-smart-card-off::before {
  content: "󱣷";
}

.mdi-smart-card-off-outline::before {
  content: "󱣸";
}

.mdi-smart-card-outline::before {
  content: "󱂾";
}

.mdi-smart-card-reader::before {
  content: "󱂿";
}

.mdi-smart-card-reader-outline::before {
  content: "󱃀";
}

.mdi-smog::before {
  content: "󰩱";
}

.mdi-smoke::before {
  content: "󱞙";
}

.mdi-smoke-detector::before {
  content: "󰎒";
}

.mdi-smoke-detector-alert::before {
  content: "󱤮";
}

.mdi-smoke-detector-alert-outline::before {
  content: "󱤯";
}

.mdi-smoke-detector-off::before {
  content: "󱠉";
}

.mdi-smoke-detector-off-outline::before {
  content: "󱠊";
}

.mdi-smoke-detector-outline::before {
  content: "󱠈";
}

.mdi-smoke-detector-variant::before {
  content: "󱠋";
}

.mdi-smoke-detector-variant-alert::before {
  content: "󱤰";
}

.mdi-smoke-detector-variant-off::before {
  content: "󱠌";
}

.mdi-smoking::before {
  content: "󰒴";
}

.mdi-smoking-off::before {
  content: "󰒵";
}

.mdi-smoking-pipe::before {
  content: "󱐍";
}

.mdi-smoking-pipe-off::before {
  content: "󱐨";
}

.mdi-snail::before {
  content: "󱙷";
}

.mdi-snake::before {
  content: "󱔎";
}

.mdi-snapchat::before {
  content: "󰒶";
}

.mdi-snowboard::before {
  content: "󱌇";
}

.mdi-snowflake::before {
  content: "󰜗";
}

.mdi-snowflake-alert::before {
  content: "󰼩";
}

.mdi-snowflake-melt::before {
  content: "󱋋";
}

.mdi-snowflake-off::before {
  content: "󱓣";
}

.mdi-snowflake-variant::before {
  content: "󰼪";
}

.mdi-snowman::before {
  content: "󰒷";
}

.mdi-snowmobile::before {
  content: "󰛝";
}

.mdi-soccer::before {
  content: "󰒸";
}

.mdi-soccer-field::before {
  content: "󰠴";
}

.mdi-social-distance-2-meters::before {
  content: "󱕹";
}

.mdi-social-distance-6-feet::before {
  content: "󱕺";
}

.mdi-sofa::before {
  content: "󰒹";
}

.mdi-sofa-outline::before {
  content: "󱕭";
}

.mdi-sofa-single::before {
  content: "󱕮";
}

.mdi-sofa-single-outline::before {
  content: "󱕯";
}

.mdi-solar-panel::before {
  content: "󰶛";
}

.mdi-solar-panel-large::before {
  content: "󰶜";
}

.mdi-solar-power::before {
  content: "󰩲";
}

.mdi-soldering-iron::before {
  content: "󱂒";
}

.mdi-solid::before {
  content: "󰚍";
}

.mdi-sony-playstation::before {
  content: "󰐔";
}

.mdi-sort::before {
  content: "󰒺";
}

.mdi-sort-alphabetical-ascending::before {
  content: "󰖽";
}

.mdi-sort-alphabetical-ascending-variant::before {
  content: "󱅈";
}

.mdi-sort-alphabetical-descending::before {
  content: "󰖿";
}

.mdi-sort-alphabetical-descending-variant::before {
  content: "󱅉";
}

.mdi-sort-alphabetical-variant::before {
  content: "󰒻";
}

.mdi-sort-ascending::before {
  content: "󰒼";
}

.mdi-sort-bool-ascending::before {
  content: "󱎅";
}

.mdi-sort-bool-ascending-variant::before {
  content: "󱎆";
}

.mdi-sort-bool-descending::before {
  content: "󱎇";
}

.mdi-sort-bool-descending-variant::before {
  content: "󱎈";
}

.mdi-sort-calendar-ascending::before {
  content: "󱕇";
}

.mdi-sort-calendar-descending::before {
  content: "󱕈";
}

.mdi-sort-clock-ascending::before {
  content: "󱕉";
}

.mdi-sort-clock-ascending-outline::before {
  content: "󱕊";
}

.mdi-sort-clock-descending::before {
  content: "󱕋";
}

.mdi-sort-clock-descending-outline::before {
  content: "󱕌";
}

.mdi-sort-descending::before {
  content: "󰒽";
}

.mdi-sort-numeric-ascending::before {
  content: "󱎉";
}

.mdi-sort-numeric-ascending-variant::before {
  content: "󰤍";
}

.mdi-sort-numeric-descending::before {
  content: "󱎊";
}

.mdi-sort-numeric-descending-variant::before {
  content: "󰫒";
}

.mdi-sort-numeric-variant::before {
  content: "󰒾";
}

.mdi-sort-reverse-variant::before {
  content: "󰌼";
}

.mdi-sort-variant::before {
  content: "󰒿";
}

.mdi-sort-variant-lock::before {
  content: "󰳍";
}

.mdi-sort-variant-lock-open::before {
  content: "󰳎";
}

.mdi-sort-variant-remove::before {
  content: "󱅇";
}

.mdi-soundbar::before {
  content: "󱟛";
}

.mdi-soundcloud::before {
  content: "󰓀";
}

.mdi-source-branch::before {
  content: "󰘬";
}

.mdi-source-branch-check::before {
  content: "󱓏";
}

.mdi-source-branch-minus::before {
  content: "󱓋";
}

.mdi-source-branch-plus::before {
  content: "󱓊";
}

.mdi-source-branch-refresh::before {
  content: "󱓍";
}

.mdi-source-branch-remove::before {
  content: "󱓌";
}

.mdi-source-branch-sync::before {
  content: "󱓎";
}

.mdi-source-commit::before {
  content: "󰜘";
}

.mdi-source-commit-end::before {
  content: "󰜙";
}

.mdi-source-commit-end-local::before {
  content: "󰜚";
}

.mdi-source-commit-local::before {
  content: "󰜛";
}

.mdi-source-commit-next-local::before {
  content: "󰜜";
}

.mdi-source-commit-start::before {
  content: "󰜝";
}

.mdi-source-commit-start-next-local::before {
  content: "󰜞";
}

.mdi-source-fork::before {
  content: "󰓁";
}

.mdi-source-merge::before {
  content: "󰘭";
}

.mdi-source-pull::before {
  content: "󰓂";
}

.mdi-source-repository::before {
  content: "󰳏";
}

.mdi-source-repository-multiple::before {
  content: "󰳐";
}

.mdi-soy-sauce::before {
  content: "󰟮";
}

.mdi-soy-sauce-off::before {
  content: "󱏼";
}

.mdi-spa::before {
  content: "󰳑";
}

.mdi-spa-outline::before {
  content: "󰳒";
}

.mdi-space-invaders::before {
  content: "󰯉";
}

.mdi-space-station::before {
  content: "󱎃";
}

.mdi-spade::before {
  content: "󰹥";
}

.mdi-speaker::before {
  content: "󰓃";
}

.mdi-speaker-bluetooth::before {
  content: "󰦢";
}

.mdi-speaker-multiple::before {
  content: "󰴸";
}

.mdi-speaker-off::before {
  content: "󰓄";
}

.mdi-speaker-wireless::before {
  content: "󰜟";
}

.mdi-spear::before {
  content: "󱡅";
}

.mdi-speedometer::before {
  content: "󰓅";
}

.mdi-speedometer-medium::before {
  content: "󰾅";
}

.mdi-speedometer-slow::before {
  content: "󰾆";
}

.mdi-spellcheck::before {
  content: "󰓆";
}

.mdi-sphere::before {
  content: "󱥔";
}

.mdi-sphere-off::before {
  content: "󱥕";
}

.mdi-spider::before {
  content: "󱇪";
}

.mdi-spider-thread::before {
  content: "󱇫";
}

.mdi-spider-web::before {
  content: "󰯊";
}

.mdi-spirit-level::before {
  content: "󱓱";
}

.mdi-spoon-sugar::before {
  content: "󱐩";
}

.mdi-spotify::before {
  content: "󰓇";
}

.mdi-spotlight::before {
  content: "󰓈";
}

.mdi-spotlight-beam::before {
  content: "󰓉";
}

.mdi-spray::before {
  content: "󰙥";
}

.mdi-spray-bottle::before {
  content: "󰫠";
}

.mdi-sprinkler::before {
  content: "󱁟";
}

.mdi-sprinkler-fire::before {
  content: "󱦝";
}

.mdi-sprinkler-variant::before {
  content: "󱁠";
}

.mdi-sprout::before {
  content: "󰹦";
}

.mdi-sprout-outline::before {
  content: "󰹧";
}

.mdi-square::before {
  content: "󰝤";
}

.mdi-square-circle::before {
  content: "󱔀";
}

.mdi-square-edit-outline::before {
  content: "󰤌";
}

.mdi-square-medium::before {
  content: "󰨓";
}

.mdi-square-medium-outline::before {
  content: "󰨔";
}

.mdi-square-off::before {
  content: "󱋮";
}

.mdi-square-off-outline::before {
  content: "󱋯";
}

.mdi-square-opacity::before {
  content: "󱡔";
}

.mdi-square-outline::before {
  content: "󰝣";
}

.mdi-square-root::before {
  content: "󰞄";
}

.mdi-square-root-box::before {
  content: "󰦣";
}

.mdi-square-rounded::before {
  content: "󱓻";
}

.mdi-square-rounded-outline::before {
  content: "󱓼";
}

.mdi-square-small::before {
  content: "󰨕";
}

.mdi-square-wave::before {
  content: "󱑻";
}

.mdi-squeegee::before {
  content: "󰫡";
}

.mdi-ssh::before {
  content: "󰣀";
}

.mdi-stack-exchange::before {
  content: "󰘋";
}

.mdi-stack-overflow::before {
  content: "󰓌";
}

.mdi-stackpath::before {
  content: "󰍙";
}

.mdi-stadium::before {
  content: "󰿹";
}

.mdi-stadium-variant::before {
  content: "󰜠";
}

.mdi-stairs::before {
  content: "󰓍";
}

.mdi-stairs-box::before {
  content: "󱎞";
}

.mdi-stairs-down::before {
  content: "󱊾";
}

.mdi-stairs-up::before {
  content: "󱊽";
}

.mdi-stamper::before {
  content: "󰴹";
}

.mdi-standard-definition::before {
  content: "󰟯";
}

.mdi-star::before {
  content: "󰓎";
}

.mdi-star-box::before {
  content: "󰩳";
}

.mdi-star-box-multiple::before {
  content: "󱊆";
}

.mdi-star-box-multiple-outline::before {
  content: "󱊇";
}

.mdi-star-box-outline::before {
  content: "󰩴";
}

.mdi-star-check::before {
  content: "󱕦";
}

.mdi-star-check-outline::before {
  content: "󱕪";
}

.mdi-star-circle::before {
  content: "󰓏";
}

.mdi-star-circle-outline::before {
  content: "󰦤";
}

.mdi-star-cog::before {
  content: "󱙨";
}

.mdi-star-cog-outline::before {
  content: "󱙩";
}

.mdi-star-crescent::before {
  content: "󰥹";
}

.mdi-star-david::before {
  content: "󰥺";
}

.mdi-star-face::before {
  content: "󰦥";
}

.mdi-star-four-points::before {
  content: "󰫢";
}

.mdi-star-four-points-outline::before {
  content: "󰫣";
}

.mdi-star-half::before {
  content: "󰉆";
}

.mdi-star-half-full::before {
  content: "󰓐";
}

.mdi-star-minus::before {
  content: "󱕤";
}

.mdi-star-minus-outline::before {
  content: "󱕨";
}

.mdi-star-off::before {
  content: "󰓑";
}

.mdi-star-off-outline::before {
  content: "󱕛";
}

.mdi-star-outline::before {
  content: "󰓒";
}

.mdi-star-plus::before {
  content: "󱕣";
}

.mdi-star-plus-outline::before {
  content: "󱕧";
}

.mdi-star-remove::before {
  content: "󱕥";
}

.mdi-star-remove-outline::before {
  content: "󱕩";
}

.mdi-star-settings::before {
  content: "󱙪";
}

.mdi-star-settings-outline::before {
  content: "󱙫";
}

.mdi-star-shooting::before {
  content: "󱝁";
}

.mdi-star-shooting-outline::before {
  content: "󱝂";
}

.mdi-star-three-points::before {
  content: "󰫤";
}

.mdi-star-three-points-outline::before {
  content: "󰫥";
}

.mdi-state-machine::before {
  content: "󱇯";
}

.mdi-steam::before {
  content: "󰓓";
}

.mdi-steering::before {
  content: "󰓔";
}

.mdi-steering-off::before {
  content: "󰤎";
}

.mdi-step-backward::before {
  content: "󰓕";
}

.mdi-step-backward-2::before {
  content: "󰓖";
}

.mdi-step-forward::before {
  content: "󰓗";
}

.mdi-step-forward-2::before {
  content: "󰓘";
}

.mdi-stethoscope::before {
  content: "󰓙";
}

.mdi-sticker::before {
  content: "󱍤";
}

.mdi-sticker-alert::before {
  content: "󱍥";
}

.mdi-sticker-alert-outline::before {
  content: "󱍦";
}

.mdi-sticker-check::before {
  content: "󱍧";
}

.mdi-sticker-check-outline::before {
  content: "󱍨";
}

.mdi-sticker-circle-outline::before {
  content: "󰗐";
}

.mdi-sticker-emoji::before {
  content: "󰞅";
}

.mdi-sticker-minus::before {
  content: "󱍩";
}

.mdi-sticker-minus-outline::before {
  content: "󱍪";
}

.mdi-sticker-outline::before {
  content: "󱍫";
}

.mdi-sticker-plus::before {
  content: "󱍬";
}

.mdi-sticker-plus-outline::before {
  content: "󱍭";
}

.mdi-sticker-remove::before {
  content: "󱍮";
}

.mdi-sticker-remove-outline::before {
  content: "󱍯";
}

.mdi-sticker-text::before {
  content: "󱞎";
}

.mdi-sticker-text-outline::before {
  content: "󱞏";
}

.mdi-stocking::before {
  content: "󰓚";
}

.mdi-stomach::before {
  content: "󱂓";
}

.mdi-stool::before {
  content: "󱥝";
}

.mdi-stool-outline::before {
  content: "󱥞";
}

.mdi-stop::before {
  content: "󰓛";
}

.mdi-stop-circle::before {
  content: "󰙦";
}

.mdi-stop-circle-outline::before {
  content: "󰙧";
}

.mdi-store::before {
  content: "󰓜";
}

.mdi-store-24-hour::before {
  content: "󰓝";
}

.mdi-store-alert::before {
  content: "󱣁";
}

.mdi-store-alert-outline::before {
  content: "󱣂";
}

.mdi-store-check::before {
  content: "󱣃";
}

.mdi-store-check-outline::before {
  content: "󱣄";
}

.mdi-store-clock::before {
  content: "󱣅";
}

.mdi-store-clock-outline::before {
  content: "󱣆";
}

.mdi-store-cog::before {
  content: "󱣇";
}

.mdi-store-cog-outline::before {
  content: "󱣈";
}

.mdi-store-edit::before {
  content: "󱣉";
}

.mdi-store-edit-outline::before {
  content: "󱣊";
}

.mdi-store-marker::before {
  content: "󱣋";
}

.mdi-store-marker-outline::before {
  content: "󱣌";
}

.mdi-store-minus::before {
  content: "󱙞";
}

.mdi-store-minus-outline::before {
  content: "󱣍";
}

.mdi-store-off::before {
  content: "󱣎";
}

.mdi-store-off-outline::before {
  content: "󱣏";
}

.mdi-store-outline::before {
  content: "󱍡";
}

.mdi-store-plus::before {
  content: "󱙟";
}

.mdi-store-plus-outline::before {
  content: "󱣐";
}

.mdi-store-remove::before {
  content: "󱙠";
}

.mdi-store-remove-outline::before {
  content: "󱣑";
}

.mdi-store-search::before {
  content: "󱣒";
}

.mdi-store-search-outline::before {
  content: "󱣓";
}

.mdi-store-settings::before {
  content: "󱣔";
}

.mdi-store-settings-outline::before {
  content: "󱣕";
}

.mdi-storefront::before {
  content: "󰟇";
}

.mdi-storefront-outline::before {
  content: "󱃁";
}

.mdi-stove::before {
  content: "󰓞";
}

.mdi-strategy::before {
  content: "󱇖";
}

.mdi-stretch-to-page::before {
  content: "󰼫";
}

.mdi-stretch-to-page-outline::before {
  content: "󰼬";
}

.mdi-string-lights::before {
  content: "󱊺";
}

.mdi-string-lights-off::before {
  content: "󱊻";
}

.mdi-subdirectory-arrow-left::before {
  content: "󰘌";
}

.mdi-subdirectory-arrow-right::before {
  content: "󰘍";
}

.mdi-submarine::before {
  content: "󱕬";
}

.mdi-subtitles::before {
  content: "󰨖";
}

.mdi-subtitles-outline::before {
  content: "󰨗";
}

.mdi-subway::before {
  content: "󰚬";
}

.mdi-subway-alert-variant::before {
  content: "󰶝";
}

.mdi-subway-variant::before {
  content: "󰓟";
}

.mdi-summit::before {
  content: "󰞆";
}

.mdi-sun-compass::before {
  content: "󱦥";
}

.mdi-sun-snowflake::before {
  content: "󱞖";
}

.mdi-sun-thermometer::before {
  content: "󱣖";
}

.mdi-sun-thermometer-outline::before {
  content: "󱣗";
}

.mdi-sun-wireless::before {
  content: "󱟾";
}

.mdi-sun-wireless-outline::before {
  content: "󱟿";
}

.mdi-sunglasses::before {
  content: "󰓠";
}

.mdi-surfing::before {
  content: "󱝆";
}

.mdi-surround-sound::before {
  content: "󰗅";
}

.mdi-surround-sound-2-0::before {
  content: "󰟰";
}

.mdi-surround-sound-2-1::before {
  content: "󱜩";
}

.mdi-surround-sound-3-1::before {
  content: "󰟱";
}

.mdi-surround-sound-5-1::before {
  content: "󰟲";
}

.mdi-surround-sound-5-1-2::before {
  content: "󱜪";
}

.mdi-surround-sound-7-1::before {
  content: "󰟳";
}

.mdi-svg::before {
  content: "󰜡";
}

.mdi-swap-horizontal::before {
  content: "󰓡";
}

.mdi-swap-horizontal-bold::before {
  content: "󰯍";
}

.mdi-swap-horizontal-circle::before {
  content: "󰿡";
}

.mdi-swap-horizontal-circle-outline::before {
  content: "󰿢";
}

.mdi-swap-horizontal-variant::before {
  content: "󰣁";
}

.mdi-swap-vertical::before {
  content: "󰓢";
}

.mdi-swap-vertical-bold::before {
  content: "󰯎";
}

.mdi-swap-vertical-circle::before {
  content: "󰿣";
}

.mdi-swap-vertical-circle-outline::before {
  content: "󰿤";
}

.mdi-swap-vertical-variant::before {
  content: "󰣂";
}

.mdi-swim::before {
  content: "󰓣";
}

.mdi-switch::before {
  content: "󰓤";
}

.mdi-sword::before {
  content: "󰓥";
}

.mdi-sword-cross::before {
  content: "󰞇";
}

.mdi-syllabary-hangul::before {
  content: "󱌳";
}

.mdi-syllabary-hiragana::before {
  content: "󱌴";
}

.mdi-syllabary-katakana::before {
  content: "󱌵";
}

.mdi-syllabary-katakana-halfwidth::before {
  content: "󱌶";
}

.mdi-symbol::before {
  content: "󱔁";
}

.mdi-symfony::before {
  content: "󰫦";
}

.mdi-sync::before {
  content: "󰓦";
}

.mdi-sync-alert::before {
  content: "󰓧";
}

.mdi-sync-circle::before {
  content: "󱍸";
}

.mdi-sync-off::before {
  content: "󰓨";
}

.mdi-tab::before {
  content: "󰓩";
}

.mdi-tab-minus::before {
  content: "󰭋";
}

.mdi-tab-plus::before {
  content: "󰝜";
}

.mdi-tab-remove::before {
  content: "󰭌";
}

.mdi-tab-search::before {
  content: "󱦞";
}

.mdi-tab-unselected::before {
  content: "󰓪";
}

.mdi-table::before {
  content: "󰓫";
}

.mdi-table-account::before {
  content: "󱎹";
}

.mdi-table-alert::before {
  content: "󱎺";
}

.mdi-table-arrow-down::before {
  content: "󱎻";
}

.mdi-table-arrow-left::before {
  content: "󱎼";
}

.mdi-table-arrow-right::before {
  content: "󱎽";
}

.mdi-table-arrow-up::before {
  content: "󱎾";
}

.mdi-table-border::before {
  content: "󰨘";
}

.mdi-table-cancel::before {
  content: "󱎿";
}

.mdi-table-chair::before {
  content: "󱁡";
}

.mdi-table-check::before {
  content: "󱏀";
}

.mdi-table-clock::before {
  content: "󱏁";
}

.mdi-table-cog::before {
  content: "󱏂";
}

.mdi-table-column::before {
  content: "󰠵";
}

.mdi-table-column-plus-after::before {
  content: "󰓬";
}

.mdi-table-column-plus-before::before {
  content: "󰓭";
}

.mdi-table-column-remove::before {
  content: "󰓮";
}

.mdi-table-column-width::before {
  content: "󰓯";
}

.mdi-table-edit::before {
  content: "󰓰";
}

.mdi-table-eye::before {
  content: "󱂔";
}

.mdi-table-eye-off::before {
  content: "󱏃";
}

.mdi-table-furniture::before {
  content: "󰖼";
}

.mdi-table-headers-eye::before {
  content: "󱈝";
}

.mdi-table-headers-eye-off::before {
  content: "󱈞";
}

.mdi-table-heart::before {
  content: "󱏄";
}

.mdi-table-key::before {
  content: "󱏅";
}

.mdi-table-large::before {
  content: "󰓱";
}

.mdi-table-large-plus::before {
  content: "󰾇";
}

.mdi-table-large-remove::before {
  content: "󰾈";
}

.mdi-table-lock::before {
  content: "󱏆";
}

.mdi-table-merge-cells::before {
  content: "󰦦";
}

.mdi-table-minus::before {
  content: "󱏇";
}

.mdi-table-multiple::before {
  content: "󱏈";
}

.mdi-table-network::before {
  content: "󱏉";
}

.mdi-table-of-contents::before {
  content: "󰠶";
}

.mdi-table-off::before {
  content: "󱏊";
}

.mdi-table-picnic::before {
  content: "󱝃";
}

.mdi-table-pivot::before {
  content: "󱠼";
}

.mdi-table-plus::before {
  content: "󰩵";
}

.mdi-table-refresh::before {
  content: "󱎠";
}

.mdi-table-remove::before {
  content: "󰩶";
}

.mdi-table-row::before {
  content: "󰠷";
}

.mdi-table-row-height::before {
  content: "󰓲";
}

.mdi-table-row-plus-after::before {
  content: "󰓳";
}

.mdi-table-row-plus-before::before {
  content: "󰓴";
}

.mdi-table-row-remove::before {
  content: "󰓵";
}

.mdi-table-search::before {
  content: "󰤏";
}

.mdi-table-settings::before {
  content: "󰠸";
}

.mdi-table-split-cell::before {
  content: "󱐪";
}

.mdi-table-star::before {
  content: "󱏋";
}

.mdi-table-sync::before {
  content: "󱎡";
}

.mdi-table-tennis::before {
  content: "󰹨";
}

.mdi-tablet::before {
  content: "󰓶";
}

.mdi-tablet-android::before {
  content: "󰓷";
}

.mdi-tablet-cellphone::before {
  content: "󰦧";
}

.mdi-tablet-dashboard::before {
  content: "󰻎";
}

.mdi-taco::before {
  content: "󰝢";
}

.mdi-tag::before {
  content: "󰓹";
}

.mdi-tag-arrow-down::before {
  content: "󱜫";
}

.mdi-tag-arrow-down-outline::before {
  content: "󱜬";
}

.mdi-tag-arrow-left::before {
  content: "󱜭";
}

.mdi-tag-arrow-left-outline::before {
  content: "󱜮";
}

.mdi-tag-arrow-right::before {
  content: "󱜯";
}

.mdi-tag-arrow-right-outline::before {
  content: "󱜰";
}

.mdi-tag-arrow-up::before {
  content: "󱜱";
}

.mdi-tag-arrow-up-outline::before {
  content: "󱜲";
}

.mdi-tag-faces::before {
  content: "󰓺";
}

.mdi-tag-heart::before {
  content: "󰚋";
}

.mdi-tag-heart-outline::before {
  content: "󰯏";
}

.mdi-tag-minus::before {
  content: "󰤐";
}

.mdi-tag-minus-outline::before {
  content: "󱈟";
}

.mdi-tag-multiple::before {
  content: "󰓻";
}

.mdi-tag-multiple-outline::before {
  content: "󱋷";
}

.mdi-tag-off::before {
  content: "󱈠";
}

.mdi-tag-off-outline::before {
  content: "󱈡";
}

.mdi-tag-outline::before {
  content: "󰓼";
}

.mdi-tag-plus::before {
  content: "󰜢";
}

.mdi-tag-plus-outline::before {
  content: "󱈢";
}

.mdi-tag-remove::before {
  content: "󰜣";
}

.mdi-tag-remove-outline::before {
  content: "󱈣";
}

.mdi-tag-search::before {
  content: "󱤇";
}

.mdi-tag-search-outline::before {
  content: "󱤈";
}

.mdi-tag-text::before {
  content: "󱈤";
}

.mdi-tag-text-outline::before {
  content: "󰓽";
}

.mdi-tailwind::before {
  content: "󱏿";
}

.mdi-tangram::before {
  content: "󰓸";
}

.mdi-tank::before {
  content: "󰴺";
}

.mdi-tanker-truck::before {
  content: "󰿥";
}

.mdi-tape-drive::before {
  content: "󱛟";
}

.mdi-tape-measure::before {
  content: "󰭍";
}

.mdi-target::before {
  content: "󰓾";
}

.mdi-target-account::before {
  content: "󰯐";
}

.mdi-target-variant::before {
  content: "󰩷";
}

.mdi-taxi::before {
  content: "󰓿";
}

.mdi-tea::before {
  content: "󰶞";
}

.mdi-tea-outline::before {
  content: "󰶟";
}

.mdi-teamviewer::before {
  content: "󰔀";
}

.mdi-teddy-bear::before {
  content: "󱣻";
}

.mdi-telescope::before {
  content: "󰭎";
}

.mdi-television::before {
  content: "󰔂";
}

.mdi-television-ambient-light::before {
  content: "󱍖";
}

.mdi-television-box::before {
  content: "󰠹";
}

.mdi-television-classic::before {
  content: "󰟴";
}

.mdi-television-classic-off::before {
  content: "󰠺";
}

.mdi-television-guide::before {
  content: "󰔃";
}

.mdi-television-off::before {
  content: "󰠻";
}

.mdi-television-pause::before {
  content: "󰾉";
}

.mdi-television-play::before {
  content: "󰻏";
}

.mdi-television-shimmer::before {
  content: "󱄐";
}

.mdi-television-stop::before {
  content: "󰾊";
}

.mdi-temperature-celsius::before {
  content: "󰔄";
}

.mdi-temperature-fahrenheit::before {
  content: "󰔅";
}

.mdi-temperature-kelvin::before {
  content: "󰔆";
}

.mdi-tennis::before {
  content: "󰶠";
}

.mdi-tennis-ball::before {
  content: "󰔇";
}

.mdi-tent::before {
  content: "󰔈";
}

.mdi-terraform::before {
  content: "󱁢";
}

.mdi-terrain::before {
  content: "󰔉";
}

.mdi-test-tube::before {
  content: "󰙨";
}

.mdi-test-tube-empty::before {
  content: "󰤑";
}

.mdi-test-tube-off::before {
  content: "󰤒";
}

.mdi-text::before {
  content: "󰦨";
}

.mdi-text-account::before {
  content: "󱕰";
}

.mdi-text-box::before {
  content: "󰈚";
}

.mdi-text-box-check::before {
  content: "󰺦";
}

.mdi-text-box-check-outline::before {
  content: "󰺧";
}

.mdi-text-box-minus::before {
  content: "󰺨";
}

.mdi-text-box-minus-outline::before {
  content: "󰺩";
}

.mdi-text-box-multiple::before {
  content: "󰪷";
}

.mdi-text-box-multiple-outline::before {
  content: "󰪸";
}

.mdi-text-box-outline::before {
  content: "󰧭";
}

.mdi-text-box-plus::before {
  content: "󰺪";
}

.mdi-text-box-plus-outline::before {
  content: "󰺫";
}

.mdi-text-box-remove::before {
  content: "󰺬";
}

.mdi-text-box-remove-outline::before {
  content: "󰺭";
}

.mdi-text-box-search::before {
  content: "󰺮";
}

.mdi-text-box-search-outline::before {
  content: "󰺯";
}

.mdi-text-long::before {
  content: "󰦪";
}

.mdi-text-recognition::before {
  content: "󱄽";
}

.mdi-text-search::before {
  content: "󱎸";
}

.mdi-text-shadow::before {
  content: "󰙩";
}

.mdi-text-short::before {
  content: "󰦩";
}

.mdi-text-to-speech::before {
  content: "󰔊";
}

.mdi-text-to-speech-off::before {
  content: "󰔋";
}

.mdi-texture::before {
  content: "󰔌";
}

.mdi-texture-box::before {
  content: "󰿦";
}

.mdi-theater::before {
  content: "󰔍";
}

.mdi-theme-light-dark::before {
  content: "󰔎";
}

.mdi-thermometer::before {
  content: "󰔏";
}

.mdi-thermometer-alert::before {
  content: "󰸁";
}

.mdi-thermometer-bluetooth::before {
  content: "󱢕";
}

.mdi-thermometer-chevron-down::before {
  content: "󰸂";
}

.mdi-thermometer-chevron-up::before {
  content: "󰸃";
}

.mdi-thermometer-high::before {
  content: "󱃂";
}

.mdi-thermometer-lines::before {
  content: "󰔐";
}

.mdi-thermometer-low::before {
  content: "󱃃";
}

.mdi-thermometer-minus::before {
  content: "󰸄";
}

.mdi-thermometer-off::before {
  content: "󱔱";
}

.mdi-thermometer-plus::before {
  content: "󰸅";
}

.mdi-thermostat::before {
  content: "󰎓";
}

.mdi-thermostat-box::before {
  content: "󰢑";
}

.mdi-thought-bubble::before {
  content: "󰟶";
}

.mdi-thought-bubble-outline::before {
  content: "󰟷";
}

.mdi-thumb-down::before {
  content: "󰔑";
}

.mdi-thumb-down-outline::before {
  content: "󰔒";
}

.mdi-thumb-up::before {
  content: "󰔓";
}

.mdi-thumb-up-outline::before {
  content: "󰔔";
}

.mdi-thumbs-up-down::before {
  content: "󰔕";
}

.mdi-thumbs-up-down-outline::before {
  content: "󱤔";
}

.mdi-ticket::before {
  content: "󰔖";
}

.mdi-ticket-account::before {
  content: "󰔗";
}

.mdi-ticket-confirmation::before {
  content: "󰔘";
}

.mdi-ticket-confirmation-outline::before {
  content: "󱎪";
}

.mdi-ticket-outline::before {
  content: "󰤓";
}

.mdi-ticket-percent::before {
  content: "󰜤";
}

.mdi-ticket-percent-outline::before {
  content: "󱐫";
}

.mdi-tie::before {
  content: "󰔙";
}

.mdi-tilde::before {
  content: "󰜥";
}

.mdi-tilde-off::before {
  content: "󱣳";
}

.mdi-timelapse::before {
  content: "󰔚";
}

.mdi-timeline::before {
  content: "󰯑";
}

.mdi-timeline-alert::before {
  content: "󰾕";
}

.mdi-timeline-alert-outline::before {
  content: "󰾘";
}

.mdi-timeline-check::before {
  content: "󱔲";
}

.mdi-timeline-check-outline::before {
  content: "󱔳";
}

.mdi-timeline-clock::before {
  content: "󱇻";
}

.mdi-timeline-clock-outline::before {
  content: "󱇼";
}

.mdi-timeline-help::before {
  content: "󰾙";
}

.mdi-timeline-help-outline::before {
  content: "󰾚";
}

.mdi-timeline-minus::before {
  content: "󱔴";
}

.mdi-timeline-minus-outline::before {
  content: "󱔵";
}

.mdi-timeline-outline::before {
  content: "󰯒";
}

.mdi-timeline-plus::before {
  content: "󰾖";
}

.mdi-timeline-plus-outline::before {
  content: "󰾗";
}

.mdi-timeline-remove::before {
  content: "󱔶";
}

.mdi-timeline-remove-outline::before {
  content: "󱔷";
}

.mdi-timeline-text::before {
  content: "󰯓";
}

.mdi-timeline-text-outline::before {
  content: "󰯔";
}

.mdi-timer::before {
  content: "󱎫";
}

.mdi-timer-10::before {
  content: "󰔜";
}

.mdi-timer-3::before {
  content: "󰔝";
}

.mdi-timer-cog::before {
  content: "󱤥";
}

.mdi-timer-cog-outline::before {
  content: "󱤦";
}

.mdi-timer-off::before {
  content: "󱎬";
}

.mdi-timer-off-outline::before {
  content: "󰔞";
}

.mdi-timer-outline::before {
  content: "󰔛";
}

.mdi-timer-sand::before {
  content: "󰔟";
}

.mdi-timer-sand-complete::before {
  content: "󱦟";
}

.mdi-timer-sand-empty::before {
  content: "󰚭";
}

.mdi-timer-sand-full::before {
  content: "󰞌";
}

.mdi-timer-sand-paused::before {
  content: "󱦠";
}

.mdi-timer-settings::before {
  content: "󱤣";
}

.mdi-timer-settings-outline::before {
  content: "󱤤";
}

.mdi-timetable::before {
  content: "󰔠";
}

.mdi-tire::before {
  content: "󱢖";
}

.mdi-toaster::before {
  content: "󱁣";
}

.mdi-toaster-off::before {
  content: "󱆷";
}

.mdi-toaster-oven::before {
  content: "󰳓";
}

.mdi-toggle-switch::before {
  content: "󰔡";
}

.mdi-toggle-switch-off::before {
  content: "󰔢";
}

.mdi-toggle-switch-off-outline::before {
  content: "󰨙";
}

.mdi-toggle-switch-outline::before {
  content: "󰨚";
}

.mdi-toilet::before {
  content: "󰦫";
}

.mdi-toolbox::before {
  content: "󰦬";
}

.mdi-toolbox-outline::before {
  content: "󰦭";
}

.mdi-tools::before {
  content: "󱁤";
}

.mdi-tooltip::before {
  content: "󰔣";
}

.mdi-tooltip-account::before {
  content: "󰀌";
}

.mdi-tooltip-cellphone::before {
  content: "󱠻";
}

.mdi-tooltip-check::before {
  content: "󱕜";
}

.mdi-tooltip-check-outline::before {
  content: "󱕝";
}

.mdi-tooltip-edit::before {
  content: "󰔤";
}

.mdi-tooltip-edit-outline::before {
  content: "󱋅";
}

.mdi-tooltip-image::before {
  content: "󰔥";
}

.mdi-tooltip-image-outline::before {
  content: "󰯕";
}

.mdi-tooltip-minus::before {
  content: "󱕞";
}

.mdi-tooltip-minus-outline::before {
  content: "󱕟";
}

.mdi-tooltip-outline::before {
  content: "󰔦";
}

.mdi-tooltip-plus::before {
  content: "󰯖";
}

.mdi-tooltip-plus-outline::before {
  content: "󰔧";
}

.mdi-tooltip-remove::before {
  content: "󱕠";
}

.mdi-tooltip-remove-outline::before {
  content: "󱕡";
}

.mdi-tooltip-text::before {
  content: "󰔨";
}

.mdi-tooltip-text-outline::before {
  content: "󰯗";
}

.mdi-tooth::before {
  content: "󰣃";
}

.mdi-tooth-outline::before {
  content: "󰔩";
}

.mdi-toothbrush::before {
  content: "󱄩";
}

.mdi-toothbrush-electric::before {
  content: "󱄬";
}

.mdi-toothbrush-paste::before {
  content: "󱄪";
}

.mdi-torch::before {
  content: "󱘆";
}

.mdi-tortoise::before {
  content: "󰴻";
}

.mdi-toslink::before {
  content: "󱊸";
}

.mdi-tournament::before {
  content: "󰦮";
}

.mdi-tow-truck::before {
  content: "󰠼";
}

.mdi-tower-beach::before {
  content: "󰚁";
}

.mdi-tower-fire::before {
  content: "󰚂";
}

.mdi-town-hall::before {
  content: "󱡵";
}

.mdi-toy-brick::before {
  content: "󱊈";
}

.mdi-toy-brick-marker::before {
  content: "󱊉";
}

.mdi-toy-brick-marker-outline::before {
  content: "󱊊";
}

.mdi-toy-brick-minus::before {
  content: "󱊋";
}

.mdi-toy-brick-minus-outline::before {
  content: "󱊌";
}

.mdi-toy-brick-outline::before {
  content: "󱊍";
}

.mdi-toy-brick-plus::before {
  content: "󱊎";
}

.mdi-toy-brick-plus-outline::before {
  content: "󱊏";
}

.mdi-toy-brick-remove::before {
  content: "󱊐";
}

.mdi-toy-brick-remove-outline::before {
  content: "󱊑";
}

.mdi-toy-brick-search::before {
  content: "󱊒";
}

.mdi-toy-brick-search-outline::before {
  content: "󱊓";
}

.mdi-track-light::before {
  content: "󰤔";
}

.mdi-trackpad::before {
  content: "󰟸";
}

.mdi-trackpad-lock::before {
  content: "󰤳";
}

.mdi-tractor::before {
  content: "󰢒";
}

.mdi-tractor-variant::before {
  content: "󱓄";
}

.mdi-trademark::before {
  content: "󰩸";
}

.mdi-traffic-cone::before {
  content: "󱍼";
}

.mdi-traffic-light::before {
  content: "󰔫";
}

.mdi-traffic-light-outline::before {
  content: "󱠪";
}

.mdi-train::before {
  content: "󰔬";
}

.mdi-train-car::before {
  content: "󰯘";
}

.mdi-train-car-passenger::before {
  content: "󱜳";
}

.mdi-train-car-passenger-door::before {
  content: "󱜴";
}

.mdi-train-car-passenger-door-open::before {
  content: "󱜵";
}

.mdi-train-car-passenger-variant::before {
  content: "󱜶";
}

.mdi-train-variant::before {
  content: "󰣄";
}

.mdi-tram::before {
  content: "󰔭";
}

.mdi-tram-side::before {
  content: "󰿧";
}

.mdi-transcribe::before {
  content: "󰔮";
}

.mdi-transcribe-close::before {
  content: "󰔯";
}

.mdi-transfer::before {
  content: "󱁥";
}

.mdi-transfer-down::before {
  content: "󰶡";
}

.mdi-transfer-left::before {
  content: "󰶢";
}

.mdi-transfer-right::before {
  content: "󰔰";
}

.mdi-transfer-up::before {
  content: "󰶣";
}

.mdi-transit-connection::before {
  content: "󰴼";
}

.mdi-transit-connection-horizontal::before {
  content: "󱕆";
}

.mdi-transit-connection-variant::before {
  content: "󰴽";
}

.mdi-transit-detour::before {
  content: "󰾋";
}

.mdi-transit-skip::before {
  content: "󱔕";
}

.mdi-transit-transfer::before {
  content: "󰚮";
}

.mdi-transition::before {
  content: "󰤕";
}

.mdi-transition-masked::before {
  content: "󰤖";
}

.mdi-translate::before {
  content: "󰗊";
}

.mdi-translate-off::before {
  content: "󰸆";
}

.mdi-transmission-tower::before {
  content: "󰴾";
}

.mdi-transmission-tower-export::before {
  content: "󱤬";
}

.mdi-transmission-tower-import::before {
  content: "󱤭";
}

.mdi-trash-can::before {
  content: "󰩹";
}

.mdi-trash-can-outline::before {
  content: "󰩺";
}

.mdi-tray::before {
  content: "󱊔";
}

.mdi-tray-alert::before {
  content: "󱊕";
}

.mdi-tray-arrow-down::before {
  content: "󰄠";
}

.mdi-tray-arrow-up::before {
  content: "󰄝";
}

.mdi-tray-full::before {
  content: "󱊖";
}

.mdi-tray-minus::before {
  content: "󱊗";
}

.mdi-tray-plus::before {
  content: "󱊘";
}

.mdi-tray-remove::before {
  content: "󱊙";
}

.mdi-treasure-chest::before {
  content: "󰜦";
}

.mdi-tree::before {
  content: "󰔱";
}

.mdi-tree-outline::before {
  content: "󰹩";
}

.mdi-trello::before {
  content: "󰔲";
}

.mdi-trending-down::before {
  content: "󰔳";
}

.mdi-trending-neutral::before {
  content: "󰔴";
}

.mdi-trending-up::before {
  content: "󰔵";
}

.mdi-triangle::before {
  content: "󰔶";
}

.mdi-triangle-outline::before {
  content: "󰔷";
}

.mdi-triangle-wave::before {
  content: "󱑼";
}

.mdi-triforce::before {
  content: "󰯙";
}

.mdi-trophy::before {
  content: "󰔸";
}

.mdi-trophy-award::before {
  content: "󰔹";
}

.mdi-trophy-broken::before {
  content: "󰶤";
}

.mdi-trophy-outline::before {
  content: "󰔺";
}

.mdi-trophy-variant::before {
  content: "󰔻";
}

.mdi-trophy-variant-outline::before {
  content: "󰔼";
}

.mdi-truck::before {
  content: "󰔽";
}

.mdi-truck-cargo-container::before {
  content: "󱣘";
}

.mdi-truck-check::before {
  content: "󰳔";
}

.mdi-truck-check-outline::before {
  content: "󱊚";
}

.mdi-truck-delivery::before {
  content: "󰔾";
}

.mdi-truck-delivery-outline::before {
  content: "󱊛";
}

.mdi-truck-fast::before {
  content: "󰞈";
}

.mdi-truck-fast-outline::before {
  content: "󱊜";
}

.mdi-truck-flatbed::before {
  content: "󱢑";
}

.mdi-truck-minus::before {
  content: "󱦮";
}

.mdi-truck-minus-outline::before {
  content: "󱦽";
}

.mdi-truck-outline::before {
  content: "󱊝";
}

.mdi-truck-plus::before {
  content: "󱦭";
}

.mdi-truck-plus-outline::before {
  content: "󱦼";
}

.mdi-truck-remove::before {
  content: "󱦯";
}

.mdi-truck-remove-outline::before {
  content: "󱦾";
}

.mdi-truck-snowflake::before {
  content: "󱦦";
}

.mdi-truck-trailer::before {
  content: "󰜧";
}

.mdi-trumpet::before {
  content: "󱂖";
}

.mdi-tshirt-crew::before {
  content: "󰩻";
}

.mdi-tshirt-crew-outline::before {
  content: "󰔿";
}

.mdi-tshirt-v::before {
  content: "󰩼";
}

.mdi-tshirt-v-outline::before {
  content: "󰕀";
}

.mdi-tumble-dryer::before {
  content: "󰤗";
}

.mdi-tumble-dryer-alert::before {
  content: "󱆺";
}

.mdi-tumble-dryer-off::before {
  content: "󱆻";
}

.mdi-tune::before {
  content: "󰘮";
}

.mdi-tune-variant::before {
  content: "󱕂";
}

.mdi-tune-vertical::before {
  content: "󰙪";
}

.mdi-tune-vertical-variant::before {
  content: "󱕃";
}

.mdi-tunnel::before {
  content: "󱠽";
}

.mdi-tunnel-outline::before {
  content: "󱠾";
}

.mdi-turkey::before {
  content: "󱜛";
}

.mdi-turnstile::before {
  content: "󰳕";
}

.mdi-turnstile-outline::before {
  content: "󰳖";
}

.mdi-turtle::before {
  content: "󰳗";
}

.mdi-twitch::before {
  content: "󰕃";
}

.mdi-twitter::before {
  content: "󰕄";
}

.mdi-two-factor-authentication::before {
  content: "󰦯";
}

.mdi-typewriter::before {
  content: "󰼭";
}

.mdi-ubisoft::before {
  content: "󰯚";
}

.mdi-ubuntu::before {
  content: "󰕈";
}

.mdi-ufo::before {
  content: "󱃄";
}

.mdi-ufo-outline::before {
  content: "󱃅";
}

.mdi-ultra-high-definition::before {
  content: "󰟹";
}

.mdi-umbraco::before {
  content: "󰕉";
}

.mdi-umbrella::before {
  content: "󰕊";
}

.mdi-umbrella-beach::before {
  content: "󱢊";
}

.mdi-umbrella-beach-outline::before {
  content: "󱢋";
}

.mdi-umbrella-closed::before {
  content: "󰦰";
}

.mdi-umbrella-closed-outline::before {
  content: "󱏢";
}

.mdi-umbrella-closed-variant::before {
  content: "󱏡";
}

.mdi-umbrella-outline::before {
  content: "󰕋";
}

.mdi-undo::before {
  content: "󰕌";
}

.mdi-undo-variant::before {
  content: "󰕍";
}

.mdi-unfold-less-horizontal::before {
  content: "󰕎";
}

.mdi-unfold-less-vertical::before {
  content: "󰝠";
}

.mdi-unfold-more-horizontal::before {
  content: "󰕏";
}

.mdi-unfold-more-vertical::before {
  content: "󰝡";
}

.mdi-ungroup::before {
  content: "󰕐";
}

.mdi-unicode::before {
  content: "󰻐";
}

.mdi-unicorn::before {
  content: "󱗂";
}

.mdi-unicorn-variant::before {
  content: "󱗃";
}

.mdi-unicycle::before {
  content: "󱗥";
}

.mdi-unity::before {
  content: "󰚯";
}

.mdi-unreal::before {
  content: "󰦱";
}

.mdi-update::before {
  content: "󰚰";
}

.mdi-upload::before {
  content: "󰕒";
}

.mdi-upload-lock::before {
  content: "󱍳";
}

.mdi-upload-lock-outline::before {
  content: "󱍴";
}

.mdi-upload-multiple::before {
  content: "󰠽";
}

.mdi-upload-network::before {
  content: "󰛶";
}

.mdi-upload-network-outline::before {
  content: "󰳘";
}

.mdi-upload-off::before {
  content: "󱃆";
}

.mdi-upload-off-outline::before {
  content: "󱃇";
}

.mdi-upload-outline::before {
  content: "󰸇";
}

.mdi-usb::before {
  content: "󰕓";
}

.mdi-usb-flash-drive::before {
  content: "󱊞";
}

.mdi-usb-flash-drive-outline::before {
  content: "󱊟";
}

.mdi-usb-port::before {
  content: "󱇰";
}

.mdi-vacuum::before {
  content: "󱦡";
}

.mdi-vacuum-outline::before {
  content: "󱦢";
}

.mdi-valve::before {
  content: "󱁦";
}

.mdi-valve-closed::before {
  content: "󱁧";
}

.mdi-valve-open::before {
  content: "󱁨";
}

.mdi-van-passenger::before {
  content: "󰟺";
}

.mdi-van-utility::before {
  content: "󰟻";
}

.mdi-vanish::before {
  content: "󰟼";
}

.mdi-vanish-quarter::before {
  content: "󱕔";
}

.mdi-vanity-light::before {
  content: "󱇡";
}

.mdi-variable::before {
  content: "󰫧";
}

.mdi-variable-box::before {
  content: "󱄑";
}

.mdi-vector-arrange-above::before {
  content: "󰕔";
}

.mdi-vector-arrange-below::before {
  content: "󰕕";
}

.mdi-vector-bezier::before {
  content: "󰫨";
}

.mdi-vector-circle::before {
  content: "󰕖";
}

.mdi-vector-circle-variant::before {
  content: "󰕗";
}

.mdi-vector-combine::before {
  content: "󰕘";
}

.mdi-vector-curve::before {
  content: "󰕙";
}

.mdi-vector-difference::before {
  content: "󰕚";
}

.mdi-vector-difference-ab::before {
  content: "󰕛";
}

.mdi-vector-difference-ba::before {
  content: "󰕜";
}

.mdi-vector-ellipse::before {
  content: "󰢓";
}

.mdi-vector-intersection::before {
  content: "󰕝";
}

.mdi-vector-line::before {
  content: "󰕞";
}

.mdi-vector-link::before {
  content: "󰿨";
}

.mdi-vector-point::before {
  content: "󰕟";
}

.mdi-vector-polygon::before {
  content: "󰕠";
}

.mdi-vector-polygon-variant::before {
  content: "󱡖";
}

.mdi-vector-polyline::before {
  content: "󰕡";
}

.mdi-vector-polyline-edit::before {
  content: "󱈥";
}

.mdi-vector-polyline-minus::before {
  content: "󱈦";
}

.mdi-vector-polyline-plus::before {
  content: "󱈧";
}

.mdi-vector-polyline-remove::before {
  content: "󱈨";
}

.mdi-vector-radius::before {
  content: "󰝊";
}

.mdi-vector-rectangle::before {
  content: "󰗆";
}

.mdi-vector-selection::before {
  content: "󰕢";
}

.mdi-vector-square::before {
  content: "󰀁";
}

.mdi-vector-square-close::before {
  content: "󱡗";
}

.mdi-vector-square-edit::before {
  content: "󱣙";
}

.mdi-vector-square-minus::before {
  content: "󱣚";
}

.mdi-vector-square-open::before {
  content: "󱡘";
}

.mdi-vector-square-plus::before {
  content: "󱣛";
}

.mdi-vector-square-remove::before {
  content: "󱣜";
}

.mdi-vector-triangle::before {
  content: "󰕣";
}

.mdi-vector-union::before {
  content: "󰕤";
}

.mdi-vhs::before {
  content: "󰨛";
}

.mdi-vibrate::before {
  content: "󰕦";
}

.mdi-vibrate-off::before {
  content: "󰳙";
}

.mdi-video::before {
  content: "󰕧";
}

.mdi-video-3d::before {
  content: "󰟽";
}

.mdi-video-3d-off::before {
  content: "󱏙";
}

.mdi-video-3d-variant::before {
  content: "󰻑";
}

.mdi-video-4k-box::before {
  content: "󰠾";
}

.mdi-video-account::before {
  content: "󰤙";
}

.mdi-video-box::before {
  content: "󰃽";
}

.mdi-video-box-off::before {
  content: "󰃾";
}

.mdi-video-check::before {
  content: "󱁩";
}

.mdi-video-check-outline::before {
  content: "󱁪";
}

.mdi-video-high-definition::before {
  content: "󱔮";
}

.mdi-video-image::before {
  content: "󰤚";
}

.mdi-video-input-antenna::before {
  content: "󰠿";
}

.mdi-video-input-component::before {
  content: "󰡀";
}

.mdi-video-input-hdmi::before {
  content: "󰡁";
}

.mdi-video-input-scart::before {
  content: "󰾌";
}

.mdi-video-input-svideo::before {
  content: "󰡂";
}

.mdi-video-marker::before {
  content: "󱦩";
}

.mdi-video-marker-outline::before {
  content: "󱦪";
}

.mdi-video-minus::before {
  content: "󰦲";
}

.mdi-video-minus-outline::before {
  content: "󰊺";
}

.mdi-video-off::before {
  content: "󰕨";
}

.mdi-video-off-outline::before {
  content: "󰯛";
}

.mdi-video-outline::before {
  content: "󰯜";
}

.mdi-video-plus::before {
  content: "󰦳";
}

.mdi-video-plus-outline::before {
  content: "󰇓";
}

.mdi-video-stabilization::before {
  content: "󰤛";
}

.mdi-video-switch::before {
  content: "󰕩";
}

.mdi-video-switch-outline::before {
  content: "󰞐";
}

.mdi-video-vintage::before {
  content: "󰨜";
}

.mdi-video-wireless::before {
  content: "󰻒";
}

.mdi-video-wireless-outline::before {
  content: "󰻓";
}

.mdi-view-agenda::before {
  content: "󰕪";
}

.mdi-view-agenda-outline::before {
  content: "󱇘";
}

.mdi-view-array::before {
  content: "󰕫";
}

.mdi-view-array-outline::before {
  content: "󱒅";
}

.mdi-view-carousel::before {
  content: "󰕬";
}

.mdi-view-carousel-outline::before {
  content: "󱒆";
}

.mdi-view-column::before {
  content: "󰕭";
}

.mdi-view-column-outline::before {
  content: "󱒇";
}

.mdi-view-comfy::before {
  content: "󰹪";
}

.mdi-view-comfy-outline::before {
  content: "󱒈";
}

.mdi-view-compact::before {
  content: "󰹫";
}

.mdi-view-compact-outline::before {
  content: "󰹬";
}

.mdi-view-dashboard::before {
  content: "󰕮";
}

.mdi-view-dashboard-edit::before {
  content: "󱥇";
}

.mdi-view-dashboard-edit-outline::before {
  content: "󱥈";
}

.mdi-view-dashboard-outline::before {
  content: "󰨝";
}

.mdi-view-dashboard-variant::before {
  content: "󰡃";
}

.mdi-view-dashboard-variant-outline::before {
  content: "󱒉";
}

.mdi-view-day::before {
  content: "󰕯";
}

.mdi-view-day-outline::before {
  content: "󱒊";
}

.mdi-view-gallery::before {
  content: "󱢈";
}

.mdi-view-gallery-outline::before {
  content: "󱢉";
}

.mdi-view-grid::before {
  content: "󰕰";
}

.mdi-view-grid-outline::before {
  content: "󱇙";
}

.mdi-view-grid-plus::before {
  content: "󰾍";
}

.mdi-view-grid-plus-outline::before {
  content: "󱇚";
}

.mdi-view-headline::before {
  content: "󰕱";
}

.mdi-view-list::before {
  content: "󰕲";
}

.mdi-view-list-outline::before {
  content: "󱒋";
}

.mdi-view-module::before {
  content: "󰕳";
}

.mdi-view-module-outline::before {
  content: "󱒌";
}

.mdi-view-parallel::before {
  content: "󰜨";
}

.mdi-view-parallel-outline::before {
  content: "󱒍";
}

.mdi-view-quilt::before {
  content: "󰕴";
}

.mdi-view-quilt-outline::before {
  content: "󱒎";
}

.mdi-view-sequential::before {
  content: "󰜩";
}

.mdi-view-sequential-outline::before {
  content: "󱒏";
}

.mdi-view-split-horizontal::before {
  content: "󰯋";
}

.mdi-view-split-vertical::before {
  content: "󰯌";
}

.mdi-view-stream::before {
  content: "󰕵";
}

.mdi-view-stream-outline::before {
  content: "󱒐";
}

.mdi-view-week::before {
  content: "󰕶";
}

.mdi-view-week-outline::before {
  content: "󱒑";
}

.mdi-vimeo::before {
  content: "󰕷";
}

.mdi-violin::before {
  content: "󰘏";
}

.mdi-virtual-reality::before {
  content: "󰢔";
}

.mdi-virus::before {
  content: "󱎶";
}

.mdi-virus-off::before {
  content: "󱣡";
}

.mdi-virus-off-outline::before {
  content: "󱣢";
}

.mdi-virus-outline::before {
  content: "󱎷";
}

.mdi-vlc::before {
  content: "󰕼";
}

.mdi-voicemail::before {
  content: "󰕽";
}

.mdi-volleyball::before {
  content: "󰦴";
}

.mdi-volume-high::before {
  content: "󰕾";
}

.mdi-volume-low::before {
  content: "󰕿";
}

.mdi-volume-medium::before {
  content: "󰖀";
}

.mdi-volume-minus::before {
  content: "󰝞";
}

.mdi-volume-mute::before {
  content: "󰝟";
}

.mdi-volume-off::before {
  content: "󰖁";
}

.mdi-volume-plus::before {
  content: "󰝝";
}

.mdi-volume-source::before {
  content: "󱄠";
}

.mdi-volume-variant-off::before {
  content: "󰸈";
}

.mdi-volume-vibrate::before {
  content: "󱄡";
}

.mdi-vote::before {
  content: "󰨟";
}

.mdi-vote-outline::before {
  content: "󰨠";
}

.mdi-vpn::before {
  content: "󰖂";
}

.mdi-vuejs::before {
  content: "󰡄";
}

.mdi-vuetify::before {
  content: "󰹭";
}

.mdi-walk::before {
  content: "󰖃";
}

.mdi-wall::before {
  content: "󰟾";
}

.mdi-wall-sconce::before {
  content: "󰤜";
}

.mdi-wall-sconce-flat::before {
  content: "󰤝";
}

.mdi-wall-sconce-flat-outline::before {
  content: "󱟉";
}

.mdi-wall-sconce-flat-variant::before {
  content: "󰐜";
}

.mdi-wall-sconce-flat-variant-outline::before {
  content: "󱟊";
}

.mdi-wall-sconce-outline::before {
  content: "󱟋";
}

.mdi-wall-sconce-round::before {
  content: "󰝈";
}

.mdi-wall-sconce-round-outline::before {
  content: "󱟌";
}

.mdi-wall-sconce-round-variant::before {
  content: "󰤞";
}

.mdi-wall-sconce-round-variant-outline::before {
  content: "󱟍";
}

.mdi-wallet::before {
  content: "󰖄";
}

.mdi-wallet-giftcard::before {
  content: "󰖅";
}

.mdi-wallet-membership::before {
  content: "󰖆";
}

.mdi-wallet-outline::before {
  content: "󰯝";
}

.mdi-wallet-plus::before {
  content: "󰾎";
}

.mdi-wallet-plus-outline::before {
  content: "󰾏";
}

.mdi-wallet-travel::before {
  content: "󰖇";
}

.mdi-wallpaper::before {
  content: "󰸉";
}

.mdi-wan::before {
  content: "󰖈";
}

.mdi-wardrobe::before {
  content: "󰾐";
}

.mdi-wardrobe-outline::before {
  content: "󰾑";
}

.mdi-warehouse::before {
  content: "󰾁";
}

.mdi-washing-machine::before {
  content: "󰜪";
}

.mdi-washing-machine-alert::before {
  content: "󱆼";
}

.mdi-washing-machine-off::before {
  content: "󱆽";
}

.mdi-watch::before {
  content: "󰖉";
}

.mdi-watch-export::before {
  content: "󰖊";
}

.mdi-watch-export-variant::before {
  content: "󰢕";
}

.mdi-watch-import::before {
  content: "󰖋";
}

.mdi-watch-import-variant::before {
  content: "󰢖";
}

.mdi-watch-variant::before {
  content: "󰢗";
}

.mdi-watch-vibrate::before {
  content: "󰚱";
}

.mdi-watch-vibrate-off::before {
  content: "󰳚";
}

.mdi-water::before {
  content: "󰖌";
}

.mdi-water-alert::before {
  content: "󱔂";
}

.mdi-water-alert-outline::before {
  content: "󱔃";
}

.mdi-water-boiler::before {
  content: "󰾒";
}

.mdi-water-boiler-alert::before {
  content: "󱆳";
}

.mdi-water-boiler-off::before {
  content: "󱆴";
}

.mdi-water-check::before {
  content: "󱔄";
}

.mdi-water-check-outline::before {
  content: "󱔅";
}

.mdi-water-circle::before {
  content: "󱠆";
}

.mdi-water-minus::before {
  content: "󱔆";
}

.mdi-water-minus-outline::before {
  content: "󱔇";
}

.mdi-water-off::before {
  content: "󰖍";
}

.mdi-water-off-outline::before {
  content: "󱔈";
}

.mdi-water-opacity::before {
  content: "󱡕";
}

.mdi-water-outline::before {
  content: "󰸊";
}

.mdi-water-percent::before {
  content: "󰖎";
}

.mdi-water-percent-alert::before {
  content: "󱔉";
}

.mdi-water-plus::before {
  content: "󱔊";
}

.mdi-water-plus-outline::before {
  content: "󱔋";
}

.mdi-water-polo::before {
  content: "󱊠";
}

.mdi-water-pump::before {
  content: "󰖏";
}

.mdi-water-pump-off::before {
  content: "󰾓";
}

.mdi-water-remove::before {
  content: "󱔌";
}

.mdi-water-remove-outline::before {
  content: "󱔍";
}

.mdi-water-sync::before {
  content: "󱟆";
}

.mdi-water-well::before {
  content: "󱁫";
}

.mdi-water-well-outline::before {
  content: "󱁬";
}

.mdi-waterfall::before {
  content: "󱡉";
}

.mdi-watering-can::before {
  content: "󱒁";
}

.mdi-watering-can-outline::before {
  content: "󱒂";
}

.mdi-watermark::before {
  content: "󰘒";
}

.mdi-wave::before {
  content: "󰼮";
}

.mdi-waveform::before {
  content: "󱑽";
}

.mdi-waves::before {
  content: "󰞍";
}

.mdi-waves-arrow-left::before {
  content: "󱡙";
}

.mdi-waves-arrow-right::before {
  content: "󱡚";
}

.mdi-waves-arrow-up::before {
  content: "󱡛";
}

.mdi-waze::before {
  content: "󰯞";
}

.mdi-weather-cloudy::before {
  content: "󰖐";
}

.mdi-weather-cloudy-alert::before {
  content: "󰼯";
}

.mdi-weather-cloudy-arrow-right::before {
  content: "󰹮";
}

.mdi-weather-cloudy-clock::before {
  content: "󱣶";
}

.mdi-weather-fog::before {
  content: "󰖑";
}

.mdi-weather-hail::before {
  content: "󰖒";
}

.mdi-weather-hazy::before {
  content: "󰼰";
}

.mdi-weather-hurricane::before {
  content: "󰢘";
}

.mdi-weather-lightning::before {
  content: "󰖓";
}

.mdi-weather-lightning-rainy::before {
  content: "󰙾";
}

.mdi-weather-night::before {
  content: "󰖔";
}

.mdi-weather-night-partly-cloudy::before {
  content: "󰼱";
}

.mdi-weather-partly-cloudy::before {
  content: "󰖕";
}

.mdi-weather-partly-lightning::before {
  content: "󰼲";
}

.mdi-weather-partly-rainy::before {
  content: "󰼳";
}

.mdi-weather-partly-snowy::before {
  content: "󰼴";
}

.mdi-weather-partly-snowy-rainy::before {
  content: "󰼵";
}

.mdi-weather-pouring::before {
  content: "󰖖";
}

.mdi-weather-rainy::before {
  content: "󰖗";
}

.mdi-weather-snowy::before {
  content: "󰖘";
}

.mdi-weather-snowy-heavy::before {
  content: "󰼶";
}

.mdi-weather-snowy-rainy::before {
  content: "󰙿";
}

.mdi-weather-sunny::before {
  content: "󰖙";
}

.mdi-weather-sunny-alert::before {
  content: "󰼷";
}

.mdi-weather-sunny-off::before {
  content: "󱓤";
}

.mdi-weather-sunset::before {
  content: "󰖚";
}

.mdi-weather-sunset-down::before {
  content: "󰖛";
}

.mdi-weather-sunset-up::before {
  content: "󰖜";
}

.mdi-weather-tornado::before {
  content: "󰼸";
}

.mdi-weather-windy::before {
  content: "󰖝";
}

.mdi-weather-windy-variant::before {
  content: "󰖞";
}

.mdi-web::before {
  content: "󰖟";
}

.mdi-web-box::before {
  content: "󰾔";
}

.mdi-web-cancel::before {
  content: "󱞐";
}

.mdi-web-check::before {
  content: "󰞉";
}

.mdi-web-clock::before {
  content: "󱉊";
}

.mdi-web-minus::before {
  content: "󱂠";
}

.mdi-web-off::before {
  content: "󰪎";
}

.mdi-web-plus::before {
  content: "󰀳";
}

.mdi-web-refresh::before {
  content: "󱞑";
}

.mdi-web-remove::before {
  content: "󰕑";
}

.mdi-web-sync::before {
  content: "󱞒";
}

.mdi-webcam::before {
  content: "󰖠";
}

.mdi-webcam-off::before {
  content: "󱜷";
}

.mdi-webhook::before {
  content: "󰘯";
}

.mdi-webpack::before {
  content: "󰜫";
}

.mdi-webrtc::before {
  content: "󱉈";
}

.mdi-wechat::before {
  content: "󰘑";
}

.mdi-weight::before {
  content: "󰖡";
}

.mdi-weight-gram::before {
  content: "󰴿";
}

.mdi-weight-kilogram::before {
  content: "󰖢";
}

.mdi-weight-lifter::before {
  content: "󱅝";
}

.mdi-weight-pound::before {
  content: "󰦵";
}

.mdi-whatsapp::before {
  content: "󰖣";
}

.mdi-wheel-barrow::before {
  content: "󱓲";
}

.mdi-wheelchair-accessibility::before {
  content: "󰖤";
}

.mdi-whistle::before {
  content: "󰦶";
}

.mdi-whistle-outline::before {
  content: "󱊼";
}

.mdi-white-balance-auto::before {
  content: "󰖥";
}

.mdi-white-balance-incandescent::before {
  content: "󰖦";
}

.mdi-white-balance-iridescent::before {
  content: "󰖧";
}

.mdi-white-balance-sunny::before {
  content: "󰖨";
}

.mdi-widgets::before {
  content: "󰜬";
}

.mdi-widgets-outline::before {
  content: "󱍕";
}

.mdi-wifi::before {
  content: "󰖩";
}

.mdi-wifi-alert::before {
  content: "󱚵";
}

.mdi-wifi-arrow-down::before {
  content: "󱚶";
}

.mdi-wifi-arrow-left::before {
  content: "󱚷";
}

.mdi-wifi-arrow-left-right::before {
  content: "󱚸";
}

.mdi-wifi-arrow-right::before {
  content: "󱚹";
}

.mdi-wifi-arrow-up::before {
  content: "󱚺";
}

.mdi-wifi-arrow-up-down::before {
  content: "󱚻";
}

.mdi-wifi-cancel::before {
  content: "󱚼";
}

.mdi-wifi-check::before {
  content: "󱚽";
}

.mdi-wifi-cog::before {
  content: "󱚾";
}

.mdi-wifi-lock::before {
  content: "󱚿";
}

.mdi-wifi-lock-open::before {
  content: "󱛀";
}

.mdi-wifi-marker::before {
  content: "󱛁";
}

.mdi-wifi-minus::before {
  content: "󱛂";
}

.mdi-wifi-off::before {
  content: "󰖪";
}

.mdi-wifi-plus::before {
  content: "󱛃";
}

.mdi-wifi-refresh::before {
  content: "󱛄";
}

.mdi-wifi-remove::before {
  content: "󱛅";
}

.mdi-wifi-settings::before {
  content: "󱛆";
}

.mdi-wifi-star::before {
  content: "󰸋";
}

.mdi-wifi-strength-1::before {
  content: "󰤟";
}

.mdi-wifi-strength-1-alert::before {
  content: "󰤠";
}

.mdi-wifi-strength-1-lock::before {
  content: "󰤡";
}

.mdi-wifi-strength-1-lock-open::before {
  content: "󱛋";
}

.mdi-wifi-strength-2::before {
  content: "󰤢";
}

.mdi-wifi-strength-2-alert::before {
  content: "󰤣";
}

.mdi-wifi-strength-2-lock::before {
  content: "󰤤";
}

.mdi-wifi-strength-2-lock-open::before {
  content: "󱛌";
}

.mdi-wifi-strength-3::before {
  content: "󰤥";
}

.mdi-wifi-strength-3-alert::before {
  content: "󰤦";
}

.mdi-wifi-strength-3-lock::before {
  content: "󰤧";
}

.mdi-wifi-strength-3-lock-open::before {
  content: "󱛍";
}

.mdi-wifi-strength-4::before {
  content: "󰤨";
}

.mdi-wifi-strength-4-alert::before {
  content: "󰤩";
}

.mdi-wifi-strength-4-lock::before {
  content: "󰤪";
}

.mdi-wifi-strength-4-lock-open::before {
  content: "󱛎";
}

.mdi-wifi-strength-alert-outline::before {
  content: "󰤫";
}

.mdi-wifi-strength-lock-open-outline::before {
  content: "󱛏";
}

.mdi-wifi-strength-lock-outline::before {
  content: "󰤬";
}

.mdi-wifi-strength-off::before {
  content: "󰤭";
}

.mdi-wifi-strength-off-outline::before {
  content: "󰤮";
}

.mdi-wifi-strength-outline::before {
  content: "󰤯";
}

.mdi-wifi-sync::before {
  content: "󱛇";
}

.mdi-wikipedia::before {
  content: "󰖬";
}

.mdi-wind-turbine::before {
  content: "󰶥";
}

.mdi-wind-turbine-alert::before {
  content: "󱦫";
}

.mdi-wind-turbine-check::before {
  content: "󱦬";
}

.mdi-window-close::before {
  content: "󰖭";
}

.mdi-window-closed::before {
  content: "󰖮";
}

.mdi-window-closed-variant::before {
  content: "󱇛";
}

.mdi-window-maximize::before {
  content: "󰖯";
}

.mdi-window-minimize::before {
  content: "󰖰";
}

.mdi-window-open::before {
  content: "󰖱";
}

.mdi-window-open-variant::before {
  content: "󱇜";
}

.mdi-window-restore::before {
  content: "󰖲";
}

.mdi-window-shutter::before {
  content: "󱄜";
}

.mdi-window-shutter-alert::before {
  content: "󱄝";
}

.mdi-window-shutter-open::before {
  content: "󱄞";
}

.mdi-windsock::before {
  content: "󱗺";
}

.mdi-wiper::before {
  content: "󰫩";
}

.mdi-wiper-wash::before {
  content: "󰶦";
}

.mdi-wiper-wash-alert::before {
  content: "󱣟";
}

.mdi-wizard-hat::before {
  content: "󱑷";
}

.mdi-wordpress::before {
  content: "󰖴";
}

.mdi-wrap::before {
  content: "󰖶";
}

.mdi-wrap-disabled::before {
  content: "󰯟";
}

.mdi-wrench::before {
  content: "󰖷";
}

.mdi-wrench-clock::before {
  content: "󱦣";
}

.mdi-wrench-outline::before {
  content: "󰯠";
}

.mdi-xamarin::before {
  content: "󰡅";
}

.mdi-xml::before {
  content: "󰗀";
}

.mdi-xmpp::before {
  content: "󰟿";
}

.mdi-yahoo::before {
  content: "󰭏";
}

.mdi-yeast::before {
  content: "󰗁";
}

.mdi-yin-yang::before {
  content: "󰚀";
}

.mdi-yoga::before {
  content: "󱅼";
}

.mdi-youtube::before {
  content: "󰗃";
}

.mdi-youtube-gaming::before {
  content: "󰡈";
}

.mdi-youtube-studio::before {
  content: "󰡇";
}

.mdi-youtube-subscription::before {
  content: "󰵀";
}

.mdi-youtube-tv::before {
  content: "󰑈";
}

.mdi-yurt::before {
  content: "󱔖";
}

.mdi-z-wave::before {
  content: "󰫪";
}

.mdi-zend::before {
  content: "󰫫";
}

.mdi-zigbee::before {
  content: "󰵁";
}

.mdi-zip-box::before {
  content: "󰗄";
}

.mdi-zip-box-outline::before {
  content: "󰿺";
}

.mdi-zip-disk::before {
  content: "󰨣";
}

.mdi-zodiac-aquarius::before {
  content: "󰩽";
}

.mdi-zodiac-aries::before {
  content: "󰩾";
}

.mdi-zodiac-cancer::before {
  content: "󰩿";
}

.mdi-zodiac-capricorn::before {
  content: "󰪀";
}

.mdi-zodiac-gemini::before {
  content: "󰪁";
}

.mdi-zodiac-leo::before {
  content: "󰪂";
}

.mdi-zodiac-libra::before {
  content: "󰪃";
}

.mdi-zodiac-pisces::before {
  content: "󰪄";
}

.mdi-zodiac-sagittarius::before {
  content: "󰪅";
}

.mdi-zodiac-scorpio::before {
  content: "󰪆";
}

.mdi-zodiac-taurus::before {
  content: "󰪇";
}

.mdi-zodiac-virgo::before {
  content: "󰪈";
}

.mdi-blank::before {
  content: "";
  visibility: hidden;
}

.mdi-18px.mdi-set,
.mdi-18px.mdi:before {
  font-size: 18px;
}

.mdi-24px.mdi-set,
.mdi-24px.mdi:before {
  font-size: 24px;
}

.mdi-36px.mdi-set,
.mdi-36px.mdi:before {
  font-size: 36px;
}

.mdi-48px.mdi-set,
.mdi-48px.mdi:before {
  font-size: 48px;
}

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54);
}

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26);
}

.mdi-light:before {
  color: white;
}

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3);
}

.mdi-rotate-45 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(45deg);
            transform: scaleX(-1) rotate(45deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: scaleY(-1) rotate(45deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-45:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mdi-rotate-90 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(90deg);
            transform: scaleX(-1) rotate(90deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: scaleY(-1) rotate(90deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-90:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mdi-rotate-135 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(135deg);
            transform: scaleX(-1) rotate(135deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: scaleY(-1) rotate(135deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-135:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.mdi-rotate-180 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(180deg);
            transform: scaleX(-1) rotate(180deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: scaleY(-1) rotate(180deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-180:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.mdi-rotate-225 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(225deg);
            transform: scaleX(-1) rotate(225deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(225deg);
            -ms-transform: rotate(225deg);
            transform: scaleY(-1) rotate(225deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-225:before {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

.mdi-rotate-270 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(270deg);
            transform: scaleX(-1) rotate(270deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: scaleY(-1) rotate(270deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-270:before {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.mdi-rotate-315 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(315deg);
            transform: scaleX(-1) rotate(315deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(315deg);
            -ms-transform: rotate(315deg);
            transform: scaleY(-1) rotate(315deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-315:before {
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
}

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear;
}

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/*# sourceMappingURL=materialdesignicons.css.map */